import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserProfileLinks from "./UserProfileLinks/UserProfileLinks";

function mapStateToProps(state: any) {
    return { user: state.user};
}

/*function mapDispatchToProps(dispatch) {
    return {};
}*/

class UserProfile extends Component<any> {
    render() {
        return (
            <div>
                My account
                <UserProfileLinks/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {}
)(UserProfile);
