import React from 'react';
import '../ViewerSettings.scss';
import RoomSettingsOption from '../../SettingsOption/RoomSettingsOption/RoomSettingsOption';

export default class RoomSettingsViewer extends React.Component {
    render() {
        return (
            <div className="settings-menu__box settings-menu__box--room">
                <RoomSettingsOption/>
            </div>
        );
    }
}
