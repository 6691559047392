import React from 'react';
import {connect} from 'react-redux';
import './EditCustomObject.scss';
import {ReactComponent as Close} from '../../../img/svg/close.svg';
import {ReactComponent as Delete} from '../../../img/svg/delete.svg';
import {ReactComponent as CustomObject} from '../../../img/svg/custom-object.svg';
import DetailsHeader from '../DetailsHeader/DetailsHeader';
import {RootState} from "../../../store";
import {FurnitureCustomObject, FurnitureCustomObjectShape} from "../../../models/furnitureCatalog";
import {
    deleteCustomObject,
    selectCustomObject,
    setCustomObjectLength,
    setCustomObjectShape,
    setCustomObjectWidth
} from "../../../actions/furnitureBuilderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {find, map, startCase} from "lodash";
import RangeSlider from "../RangeSlider/RangeSlider";
import {MEASURES_DESC} from "../../../constants/builderDefaults";
import {convertMeasure} from "../../../utils/convertMeasure";
import {Measure} from "../../../models/builder";
import {
    CUSTOM_OBJECT_MAX_LENGTH,
    CUSTOM_OBJECT_MAX_WIDTH,
    CUSTOM_OBJECT_MIN_LENGTH,
    CUSTOM_OBJECT_MIN_WIDTH
} from "../../../constants/furnitureCatalog";
import {RoomSettings} from "../../../models/room";
import cx from "classnames";
import {isMobile} from "../../../utils/responsive";

interface EditCustomObjectProps {
    selectedCustomObjectIndex: number;
    customObject: FurnitureCustomObject;
    deleteCustomObject: () => AnyAction;
    setCustomObjectShape: (shape: FurnitureCustomObjectShape) => AnyAction;
    roomSettings: RoomSettings;
    measure: Measure;
    setCustomObjectWidth: (width: number) => AnyAction;
    setCustomObjectLength: (length: number) => AnyAction;
    selectCustomObject: (itemIndex: null) => AnyAction;
    handleMobileClose: () => void;
}

export class EditCustomObject extends React.Component<EditCustomObjectProps> {

    handleClose = () => {
        if(isMobile()) {
            this.props.handleMobileClose();
            return;
        }
        this.props.selectCustomObject(null)
    }

    render() {
        const {
            selectedCustomObjectIndex, customObject, deleteCustomObject, setCustomObjectShape,
            roomSettings, measure, setCustomObjectWidth, setCustomObjectLength
        } = this.props;
        const maxWidth = Math.min(
            convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MAX_WIDTH),
            roomSettings.width);
        const maxLength = Math.min(
            convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MAX_LENGTH),
            roomSettings.length)
        return (
            <div className="edit-object">
                <div className="edit-object__header edit-object-header">
                    <h2 className="edit-object-header__title">
                        Edit Custom Object #{selectedCustomObjectIndex + 1}
                    </h2>
                    <div className="edit-object-header__close" onClick={() => this.handleClose()}>
                        <Close/>
                    </div>
                </div>
                <div className="edit-object__content">
                    <div className="edit-object-img__wrap">
                        <div
                            className={cx("edit-object-img__content", {'edit-object-img__content--round': customObject.shape === FurnitureCustomObjectShape.round})}>
                            <CustomObject/>
                            <p className="edit-object-img__text">
                                custom object #{selectedCustomObjectIndex + 1}
                            </p>
                        </div>
                        <div className="edit-object-img__delete" onClick={() => deleteCustomObject()}>
                            <Delete/>
                        </div>
                    </div>

                    <div className="edit-object__settings edit-object-settings">
                        <DetailsHeader titleText="Size settings"/>
                        <div className="edit-object-settings__list">
                            <div className="furniture-settings__list furniture-settings__list--range">
                                <div className="edit-object-settings__item">
                                    <RangeSlider
                                        inputLabel={'Width'}
                                        valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                                        onChange={val => setCustomObjectWidth(val)}
                                        maxValue={maxWidth}
                                        minValue={convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MIN_WIDTH)}
                                        value={customObject.width}/>
                                </div>
                                {customObject.shape !== FurnitureCustomObjectShape.round && (
                                    <div className="edit-object-settings__item">
                                        <RangeSlider
                                            inputLabel={'Length'}
                                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                                            onChange={val => setCustomObjectLength(val)}
                                            maxValue={maxLength}
                                            minValue={convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MIN_LENGTH)}
                                            value={customObject.length as number}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="edit-object-settings__list">
                            <div className="edit-object-settings__item">
                                <div className="settings-option settings-option--small">
                                    {map(FurnitureCustomObjectShape, shape => (
                                        <div className="settings-option__input-wrapper"
                                             key={`custom-shape-settings-${shape}`}>
                                            <input
                                                className="settings-option__input"
                                                type="radio"
                                                id={`custom-shape-settings-${shape}`}
                                                name={`custom-shape-settings-${shape}`}
                                                checked={customObject.shape === shape}
                                                onChange={() => setCustomObjectShape(shape)}
                                            />
                                            <label className="settings-option__label"
                                                   htmlFor={`custom-shape-settings-${shape}`}>
                                                {startCase(shape)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="edit-object-settings__item">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    const selectedCustomObjectIndex = state.builder.selectedCustomObjectIndex
    return {
        selectedCustomObjectIndex,
        customObject: state.builder.projectData.furnitureCustomObjects[selectedCustomObjectIndex],
        roomSettings: state.builder.projectData.roomSettings,
        measure: state.builder.projectData.measure,
    };
}

export default connect(
    mapStateToProps,
    {
        deleteCustomObject, setCustomObjectShape, setCustomObjectWidth, setCustomObjectLength,
        selectCustomObject
    }
)(EditCustomObject);
