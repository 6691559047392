import React from 'react';
import '../SettingsOption.scss';
import {RootState} from "../../../../store";
import {connect} from "react-redux";
import {map} from "lodash";
import {RoomType} from "../../../../models/room";
import FurnitureByRoomType from "../../FurnitureCatalog/FurnitureByRoomType/FurnitureByRoomType";
import AllFurniture from "../../FurnitureCatalog/AllFurniture/AllFurniture";
import RecentFurniture from "../../FurnitureCatalog/RecentFurniture/RecentFurniture";
import {getRoomTypeData} from "../../../../actions/furnitureCatalogActions";

const ReactWebTabs = require('react-web-tabs');

interface AddFurnitureOptionProps {
    roomType: RoomType;
    getRoomTypeData: (roomTypeId: string) => Promise<any>;
    roomTypeId: string;
}

interface AddFurnitureOptionState {
    currentTab: string;
}

export class AddFurnitureOption extends React.Component<AddFurnitureOptionProps, AddFurnitureOptionState> {
    constructor(props: AddFurnitureOptionProps) {
        super(props);
        this.state = {
            currentTab: this.allProductsTab
        }
    }

    allProductsTab = 'furniture-tab__all'

    switchTab = (tab: string) => {
        this.setState({currentTab: tab});
    }

    componentDidMount() {
        const {roomType, getRoomTypeData, roomTypeId} = this.props;
        if (!roomType && roomTypeId) {
            getRoomTypeData(roomTypeId)
        }
    }

    render() {
        const {currentTab} = this.state;
        const furnitureTabs = {
            allProducts: {
                id: this.allProductsTab,
                name: 'All Products',
                content: <AllFurniture/>
            },
            roomType: {
                id: 'furniture-tab__type',
                name: this.props.roomType?.name,
                content: <FurnitureByRoomType/>
            },
            recent: {
                id: 'furniture-tab__recent',
                name: 'Recent',
                content: <RecentFurniture/>
            }
        }

        return (
            <ReactWebTabs.Tabs defaultTab={currentTab} className="settings-option__wrapper">
                <ReactWebTabs.TabList className="settings-option">
                    {map(furnitureTabs, tab => (
                        <ReactWebTabs.Tab
                            key={`${tab.id}-tab`}
                            tabFor={tab.id}
                            className="settings-option__input-wrapper"
                        >
                            <input
                                className="settings-option__input"
                                type="radio"
                                id={`${tab.id}-tab-input`}
                                name="add-furniture"
                                checked={currentTab === tab.id}
                                onChange={() => this.switchTab(tab.id)}
                            />
                            <label className="settings-option__label" htmlFor={`${tab.id}-tab-input`}>
                                {tab.name}
                            </label>
                        </ReactWebTabs.Tab>
                    ))}
                </ReactWebTabs.TabList>

                <div className="settings-option__content">
                    {map(furnitureTabs, tab => (
                        <ReactWebTabs.TabPanel key={`${tab.id}-tab-content`} tabId={tab.id}>
                            {currentTab === tab.id ? tab.content : null}
                        </ReactWebTabs.TabPanel>
                    ))}
                </div>
            </ReactWebTabs.Tabs>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        roomType: state.furnitureCatalog.roomType,
        roomTypeId: state.builder.projectData.roomType
    };
}

export default connect(
    mapStateToProps,
    {getRoomTypeData}
)(AddFurnitureOption);
