import React from 'react';
import '../DetailsOption.scss';
import {ReactComponent as WallLeft} from '../../../../img/svg/wall-left.svg';
import {ReactComponent as WallTop} from '../../../../img/svg/wall-top.svg';
import {ReactComponent as WallRight} from '../../../../img/svg/wall-right.svg';
import {ReactComponent as WallBottom} from '../../../../img/svg/wall-bottom.svg';
import {ColorPickerView} from '../../ColorPickerView/ColorPickerView';
import {PositionOptions} from "../../../../models/builder";
import {WALLS_COLOR_DEFAULT} from "../../../../constants/floorWalls";
import {applyColorToAllWalls, selectWall, setWallColor} from "../../../../actions/floorWallsStyleActions";
import {connect} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../store";
import {WallSettings} from "../../../../models/floorWalls";

const ReactWebTabs = require('react-web-tabs');

interface WallsSelectProps {
    setWallColor: (wall: PositionOptions, colorHEX: string) => AnyAction;
    walls: WallSettings[];
    applyColorToAllWalls: (colorHEX: string) => AnyAction;
    selectedWall: PositionOptions | null;
    selectWall: (wall: PositionOptions) => AnyAction;
}

interface WallsSelectState {
    lastSelectedColor: string;
}

export class WallsSelect extends React.Component<WallsSelectProps, WallsSelectState> {
    constructor(props: WallsSelectProps) {
        super(props);
        this.state = {
            lastSelectedColor: WALLS_COLOR_DEFAULT
        }
    }

    wallsSettings = [
        {
            wall: PositionOptions.left,
            icon: <WallLeft/>
        },
        {
            wall: PositionOptions.top,
            icon: <WallTop/>
        },
        {
            wall: PositionOptions.right,
            icon: <WallRight/>
        },
        {
            wall: PositionOptions.bottom,
            icon: <WallBottom/>
        },
    ];

    switchToWallSettings(wall: PositionOptions) {
        this.props.selectWall(wall);
        const wallColor = this.getWallColor(wall);
        this.handleSetColor(wall, wallColor)
    }

    handleSetColor(wall: PositionOptions, colorHEX: string) {
        this.props.setWallColor(wall, colorHEX);
        this.setState({lastSelectedColor: colorHEX});
    }

    getWallColor(wall: PositionOptions) {
        return (
            this.props.walls.find(wallSettings => wallSettings.wall === wall)?.colorHEX
            || this.state.lastSelectedColor
            || WALLS_COLOR_DEFAULT
        )
    }

    render() {
        const {applyColorToAllWalls, selectedWall} = this.props;
        const {lastSelectedColor} = this.state;
        const displaySettings = this.wallsSettings.find(wallsSettings => wallsSettings.wall === selectedWall);
        return (
            <ReactWebTabs.Tabs defaultTab={selectedWall}>
                <ReactWebTabs.TabList className="details-option">
                    {this.wallsSettings.map((wallsSettings, index) => (
                        <ReactWebTabs.Tab key={`walls-tab-${index}`} tabFor={wallsSettings.wall}
                                          className="details-option__item">
                            <input
                                className="details-option__input"
                                type="radio"
                                id={`walls-${index}`}
                                checked={wallsSettings.wall === selectedWall}
                                onChange={() => this.switchToWallSettings(wallsSettings.wall)}
                                name="windows"/>
                            <label className="details-option__label" htmlFor={`walls-${index}`}>
                                  <span className="details-option__img-wrap">
                                    {wallsSettings.icon}
                                  </span>
                                <span className="details-option__text">Wall #{index + 1}</span>
                            </label>
                        </ReactWebTabs.Tab>
                    ))}
                </ReactWebTabs.TabList>

                {displaySettings && (
                    <ReactWebTabs.TabPanel tabId={displaySettings.wall} key={`color-${displaySettings.wall}`}>
                        {/* <ColorPickerViewOld
                            initColor={this.getWallColor(displaySettings.wall)}
                            handleColorChange={color => this.handleSetColor(displaySettings.wall, color)}>
                            <div
                                className={'color-picker-view__header'}>
                                <p className="color-picker-view__title">Wall color</p>
                                <button className="color-picker-view__btn"
                                        onClick={() => applyColorToAllWalls(lastSelectedColor)}
                                >
                                    Apply this color to all walls
                                </button>
                            </div>
                        </ColorPickerViewOld> */}


                        <ColorPickerView
                            initColor={this.getWallColor(displaySettings.wall)}
                            handleColorChange={color => this.handleSetColor(displaySettings.wall, color)}>
                            <div
                                className={'color-picker-view__header'}>
                                <p className="color-picker-view__title">Wall color</p>
                                <button className="color-picker-view__btn"
                                        onClick={() => applyColorToAllWalls(lastSelectedColor)}
                                >
                                    Apply this color to all walls
                                </button>
                            </div>
                        </ColorPickerView>

                    </ReactWebTabs.TabPanel>
                )}
            </ReactWebTabs.Tabs>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        walls: state.builder.projectData.floorWallsStyle.walls,
        selectedWall: state.builder.selectedWall
    };
}

export default connect(
    mapStateToProps,
    {setWallColor, applyColorToAllWalls, selectWall}
)(WallsSelect);
