/*TODO: split into files*/

export const RESET_USER = 'RESET_USER';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const NAVIGATE_TO_PROJECT_STEP = 'NAVIGATE_TO_PROJECT_STEP';

export const VIEWER_DATA_IS_READY = 'VIEWER_DATA_IS_READY';

export const INIT_NEW_PROJECT_START = 'INIT_NEW_PROJECT_START';
export const INIT_NEW_PROJECT_SUCCESS = 'INIT_NEW_PROJECT_SUCCESS';
export const INIT_NEW_PROJECT_ERROR = 'INIT_NEW_PROJECT_ERROR';
export const FETCH_PROJECT_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR';

export const SAVE_PROJECT_START = 'SAVE_PROJECT_START';
export const SAVE_PROJECT_CANCEL = 'SAVE_PROJECT_CANCEL';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const SAVE_PROJECT_ERROR = 'SAVE_PROJECT_ERROR';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';

export const PROJECT_ERROR_NOTIFY = 'PROJECT_ERROR_NOTIFY'

export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';

export const SET_ROOM_TYPE = 'SET_ROOM_TYPE';

export const CHANGE_ROOM_SHAPE = 'CHANGE_ROOM_SHAPE';
export const CHANGE_MEASURE = 'CHANGE_MEASURE';

export const SET_ROOM_WIDTH = 'SET_ROOM_WIDTH';
export const SET_ROOM_LENGTH = 'SET_ROOM_LENGTH';

export const SET_WINDOW_BAY_WIDTH = 'SET_WINDOW_BAY_WIDTH';
export const SET_WINDOW_BAY_MIDDLE_WIDTH = 'SET_WINDOW_BAY_MIDDLE_WIDTH';
export const SET_WINDOW_BAY_LENGTH = 'SET_WINDOW_BAY_LENGTH';
export const SET_WINDOW_BAY_POSITION = 'SET_WINDOW_BAY_POSITION';
export const SET_WINDOW_BAY_INDENT = 'SET_WINDOW_BAY_INDENT';
export const SELECT_WINDOW_BAY = 'SELECT_WINDOW_BAY';

export const ADD_DOOR = 'ADD_DOOR';
export const REMOVE_DOOR = 'REMOVE_DOOR';
export const SELECT_DOOR = 'SELECT_DOOR';
export const SET_DOOR_WIDTH = 'SET_DOOR_WIDTH';
export const SET_DOOR_LENGTH = 'SET_DOOR_LENGTH';
export const SET_DOOR_WALL = 'SET_DOOR_WALL';
export const SET_DOOR_INDENT = 'SET_DOOR_INDENT';
export const MIRROR_DOOR_OPEN = 'MIRROR_DOOR_OPEN';

export const SELECT_WINDOW = 'SELECT_WINDOW';
export const SET_WINDOW_INDENT = 'SET_WINDOW_INDENT';
export const ADD_WINDOW = 'ADD_WINDOW';
export const REMOVE_WINDOW = 'REMOVE_WINDOW';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_WINDOW_WALL = 'SET_WINDOW_WALL';
export const SET_WINDOW_LENGTH = 'SET_WINDOW_LENGTH';
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT';

export const SELECT_WALL = 'SELECT_WALL';
export const SET_WALL_COLOR = 'SET_WALL_COLOR';
export const COLOR_ALL_WALLS = 'COLOR_ALL_WALLS';
export const SET_FLOOR_STYLE = 'SET_FLOOR_STYLE';
export const FETCH_FLOOR_STYLES_START = 'FETCH_FLOOR_STYLES_START';
export const FETCH_FLOOR_STYLES_SUCCESS = 'FETCH_FLOOR_STYLES_SUCCESS';
export const FETCH_FLOOR_STYLES_ERROR = 'FETCH_FLOOR_STYLES_ERROR';

export const SET_BUILDER_SCALE = 'SET_BUILDER_SCALE';

export const FETCH_ROOM_TYPES_START = 'FETCH_ROOM_TYPES_START';
export const FETCH_ROOM_TYPES_SUCCESS = 'FETCH_ROOM_TYPES_SUCCESS';
export const FETCH_ROOM_TYPES_ERROR = 'FETCH_ROOM_TYPES_ERROR';

export const UNSELECT_ROOM_OBJECTS = 'UNSELECT_ROOM_OBJECTS';
