import React, {Component} from 'react';
import {FurnitureCategory, FurnitureCategoryMain} from "../../../../../models/furnitureCatalog";

interface FurnitureCategoriesOptionsProps {
    furnitureCategories: FurnitureCategoryMain[] | FurnitureCategory[];
    selectedCategory: FurnitureCategoryMain | FurnitureCategory;
    handleCategoryChange: (furnitureCategory: FurnitureCategoryMain|FurnitureCategory) => void;
}

class FurnitureCategoriesOptions extends Component<FurnitureCategoriesOptionsProps> {
    render() {
        const {furnitureCategories, selectedCategory, handleCategoryChange} = this.props;
        return (
            <div className="checkbox-dropdown">
                <div className="checkbox-dropdown__list">
                    {furnitureCategories.map((furnitureCategory: FurnitureCategoryMain|FurnitureCategory)  => (
                        <div className="checkbox-dropdown__item" key={`main-category-${furnitureCategory.id}`}>
                            <div className="checkbox">
                                <input
                                    className="checkbox__input"
                                    id={`checkbox-${furnitureCategory.id}`}
                                    type="radio"
                                    checked={furnitureCategory.id === selectedCategory?.id}
                                    onChange={() => handleCategoryChange(furnitureCategory)}
                                />
                                <label className="checkbox__label" htmlFor={`checkbox-${furnitureCategory.id}`}>
                                    <div className="checkbox__item"/>
                                    <span className="checkbox__text">{furnitureCategory.name}</span>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default FurnitureCategoriesOptions;
