import React from 'react';
import cx from 'classnames';
import ReactModal from 'react-modal';
import './MarketplaceModalOverlay.scss';
import './../Modal.scss';

interface MarketplaceModalOverlayProps {
    isOpened: boolean,
    children?: React.ReactNode,
    className?: string,
    overlayClassName?: string,
    portalClassName?: string,
    /*bodyOpenClassName?: string,
    htmlOpenClassName?: string,*/
    onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

ReactModal.setAppElement('#root')

const MarketplaceModalOverlay = ({
                          isOpened,
                          children,
                          className,
                          overlayClassName,
                          portalClassName,
                          /*bodyOpenClassName,
                          htmlOpenClassName,*/
                          onRequestClose,
                      }:MarketplaceModalOverlayProps ) => {
    return (
        <ReactModal
            isOpen={isOpened}
            onRequestClose={onRequestClose}
            className={cx('modalContainer', className)}
            overlayClassName={cx('modalOverlay', overlayClassName)}
            portalClassName={cx('modalPortal', portalClassName)}
           /* bodyOpenClassName={bodyOpenClassName}
            htmlOpenClassName={htmlOpenClassName}*/
        >
            {children}
        </ReactModal>
    )
}

export default MarketplaceModalOverlay
