import React from 'react';
import './RangeSlider.scss';
import InputRange, {Range} from 'react-input-range';

interface RangeSliderProps {
    minValue: number;
    maxValue: number;
    value: number;
    onChange: (value: number) => void;
    inputLabel?: string;
    valueLabel?: string;
}
interface RangeSliderState {
    inputValue: string
}

export default class RangeSlider extends React.Component<RangeSliderProps, RangeSliderState> {
    constructor(props:RangeSliderProps) {
        super(props);
        this.state = {
            inputValue: this.props.value?.toString()
        }
    }
    componentDidUpdate(prevProps: Readonly<RangeSliderProps>, prevState: Readonly<RangeSliderState>, snapshot?: any) {
        if (this.props.value !== prevProps.value) {
            this.setState({inputValue: this.props.value?.toString()})
        }
    }

    handleChange = (value: Range | number) => {
        this.props.onChange(value as number)
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValidationRegexp = /^[+-]?\d{0,6}(\.\d{0,2})?/g;
        const inputValue = e.target.value.match(inputValidationRegexp)?.shift();
        const inputNumberValue = parseFloat(e.target.value);

        const inputSetValue = inputValue || (inputNumberValue? inputNumberValue.toString(): '');
        this.setState({ inputValue: inputSetValue })

        const {minValue, maxValue} = this.props;
        if (inputNumberValue >= minValue && inputNumberValue <= maxValue) {
            this.handleChange(inputNumberValue);
        }
    }

    render() {
        const {inputLabel, minValue, maxValue, value, valueLabel} = this.props;
        const inputValue = this.state.inputValue || '';
        return (
            <div className="range-slider">
                <div className="range-slider__box range-slider__box--range">
                    <InputRange
                        {...{minValue, maxValue}}
                        value={value}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="range-slider__box range-slider__box--input">
                    <input
                        className="range-slider__input"
                        type="text"
                        value={valueLabel ? inputValue + ` ${valueLabel}` : inputValue}
                        onChange={this.handleInputChange}
                    />
                    {inputLabel && <label className="range-slider__input-label" htmlFor="width">{inputLabel}</label>}
                </div>
                <span/>
            </div>
        );
    }
}
