import {PositionOptions} from "./builder";

export interface FloorWallsStyle {
    walls: WallSettings[];
    floor: FloorSettings;
}

export interface WallSettings {
    wall: PositionOptions;
    colorHEX: string;
}

export interface FloorStyle {
    name: string;
    type: FloorStyleType;
    colorHEX?: string;
    textureImg?: string;
    textureId?: string;
    textureOptions?: FloorTextureOption[];
}

export enum FloorStyleType {
    color = 'color',
    texture = 'texture'
}

export interface FloorTextureOption {
    textureImg: string;
    textureId: string;
    name: string;
}

export interface FloorSettings {
    type: FloorStyleType;
    fill: string;
}
