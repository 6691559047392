import {AnyAction} from "@reduxjs/toolkit";
import {SearchData, SearchResultFormatted} from "../models/search";
import {
    FETCH_SEARCH_RESULTS_ERROR,
    FETCH_SEARCH_RESULTS_START,
    FETCH_SEARCH_RESULTS_SUCCESS,
    RESET_SEARCH_RESULTS,
    SET_SEARCH_QUERY
} from "../actions/types/search";
import {concat, isEmpty, keys, map, sortBy, sortedUniqBy} from "lodash";

const initialState: SearchData = {
    query: '',
    results: [],
    loadingSearchResults: false,
}

export default function searchReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_SEARCH_QUERY: {
            return {
                ...state,
                query: action.query
            }
        }
        case RESET_SEARCH_RESULTS: {
            return {
                ...state,
                results: [],
            }
        }
        case FETCH_SEARCH_RESULTS_START: {
            return {
                ...state,
                loadingSearchResults: true
            }
        }
        case FETCH_SEARCH_RESULTS_SUCCESS: {
            if (state.query.length === 0) {
                return state;
            }

            const data = action.payload;

            let hitsResult = map(data, (x) => {
                return {...x._highlightResult};
            });

            let filteredResults: SearchResultFormatted[] = [];
            for (let index = 0; index < hitsResult.length; index++) {
                const hits = hitsResult[index],
                    hitKeys = keys(hits);

                for (let index = 0; index < hitKeys.length; index++) {
                    const key = hitKeys[index];
                    filteredResults =
                        hits[key].matchLevel !== 'none'
                            ? concat(filteredResults, hits[key])
                            : filteredResults;
                }
            }

            if (!isEmpty(filteredResults)) {
                filteredResults = sortedUniqBy(
                    sortBy(filteredResults, (x) => x.value),
                    (x) => x.value,
                );
            }

            return {
                ...state,
                results: filteredResults,
                loadingSearchResults: false
            }
        }
        case FETCH_SEARCH_RESULTS_ERROR: {
            return {
                ...state,
                results: [],
                loadingSearchResults: false,
            }
        }
        default:
            return state
    }
}
