import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../../../store";
import {ReactComponent as HeaderCartIcon} from "../../../../../img/svg/header-cart.svg";
import {ReactComponent as HeaderCartFullIcon} from "../../../../../img/svg/header-cart-full.svg";
import {getCartItemsCount} from "../../../../../actions/cartActions";
import MarketplaceLink from "../../../MarketplaceLink/MarketplaceLink";
import {MARKETPLACE_PATH_CART} from "../../../../../constants/marketplaceLinks";
import {AuthorizedUser} from "../../../../../models/auth";

interface HeaderCartProps {
    itemsCount: number;
    loadingItemsCount: boolean;
    getCartItemsCount: () => Promise<void>;
    user: AuthorizedUser;
}

function mapStateToProps(state: RootState) {
    return {
        itemsCount: state.cart.itemsCount,
        loadingItemsCount: state.cart.loadingItemsCount,
        user: state.user
    };
}

class HeaderCart extends Component<HeaderCartProps> {
    componentDidMount() {
        this.loadItemsCount();
    }

    componentDidUpdate(prevProps: Readonly<HeaderCartProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.user.isAuthenticated !== prevProps.user.isAuthenticated) {
            this.loadItemsCount();
        }
    }

    loadItemsCount() {
        if (this.props.user.isAuthenticated) {
            this.props.getCartItemsCount();
        }
    }

    render() {
        const {itemsCount, loadingItemsCount} = this.props;
        const showCounter = (itemsCount > 0) && !loadingItemsCount;

        return (
            <div className="header-actions__item header-actions__item--cart">
                <MarketplaceLink path={MARKETPLACE_PATH_CART} className="header-actions__link">
                    <span className="header-actions__ico">
                        {showCounter
                            ? <HeaderCartFullIcon/>
                            : <HeaderCartIcon/>
                        }
                    </span>
                    {showCounter && (<span className="header-actions__counter">
                      {itemsCount}
                    </span>)}
                </MarketplaceLink>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    {getCartItemsCount}
)(HeaderCart);
