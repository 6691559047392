import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../store";
import {FloorSettings, FloorStyle, FloorStyleType} from "../../../models/floorWalls";
import {ColorPickerView} from "../ColorPickerView/ColorPickerView";
import {FLOOR_COLOR_DEFAULT} from "../../../constants/floorWalls";
import ColorPickerRadio from "../ColorPickerRadio/ColorPickerRadio";
import {AnyAction} from "@reduxjs/toolkit";
import {setFloorStyle} from "../../../actions/floorWallsStyleActions";

interface FloorStyleSettingsProps {
    displayStyleSettings: FloorStyle;
    selectedStyle: FloorSettings;
    setFloorStyle: (floorSettings: FloorSettings) => AnyAction;
}

function mapStateToProps(state: RootState) {
    return {
        selectedStyle: state.builder.projectData.floorWallsStyle.floor
    };
}

class FloorStyleSettings extends Component<FloorStyleSettingsProps> {

    handleColorChange = (color: string) => {
        this.props.setFloorStyle({
            type: FloorStyleType.color,
            fill: color,
        })
    }

    handleTextureChange = (textureId: string) => {
        this.props.setFloorStyle({
            type: FloorStyleType.texture,
            fill: textureId,
        })
    }

    render() {
        const {displayStyleSettings, selectedStyle} = this.props;

        if (displayStyleSettings.type === FloorStyleType.color) {
            return (
                <div className="floor-list__item floor-list__item--settings">
                    <ColorPickerView
                        initColor={selectedStyle.fill || FLOOR_COLOR_DEFAULT}
                        handleColorChange={color => this.handleColorChange(color)}/>
                </div>
            )
        }
        if (displayStyleSettings.textureOptions && displayStyleSettings.textureOptions?.length > 0) {
            return <div className="floor-list__item floor-list__item--settings">
                <ColorPickerRadio
                    options={displayStyleSettings.textureOptions}
                    parentName={displayStyleSettings.name}
                    selected={selectedStyle.fill as string}
                    handleOptionChange={textureId => this.handleTextureChange(textureId)}
                />
            </div>
        }
        return null;
    }
}

export default connect(
    mapStateToProps,
    {setFloorStyle}
)(FloorStyleSettings);
