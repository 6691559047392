import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../../store";
import {clearProjectError} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import cx from "classnames";
import {ReactComponent as ErrorNotificationIcon} from "../../../../img/svg/error_notification.svg";
import '../Modal.scss';

interface ErrorNotificationProps {
    error: string | null;
    clearProjectError: () => AnyAction;
}

interface ErrorNotificationState {
    showNotificationWindow: boolean;
}

function mapStateToProps(state: RootState) {
    return {
        error: state.builder.projectError
    };
}

class ErrorNotification extends Component<ErrorNotificationProps, ErrorNotificationState> {
    constructor(props: ErrorNotificationProps) {
        super(props);
        this.state = {
            showNotificationWindow: false
        }
    }
    componentDidUpdate(prevProps: Readonly<ErrorNotificationProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.error && this.props.error !== prevProps.error) {
            this.showError();
        }
    }

    showError() {
        this.setState({showNotificationWindow: true})
    }

    closeNotification() {
        this.setState({showNotificationWindow: false});
        this.props.clearProjectError();
    }

    render() {
        const {showNotificationWindow} = this.state;
        const {error} = this.props;
        return (
            <div className={cx('modal__wrap', {'modal__wrap--active': showNotificationWindow})}>
                <div className="modal">
                    <div className="modal__header ">
                        <div className={"modal__icon"}>
                            <ErrorNotificationIcon/>
                        </div>
                        <p className="modal__title">
                            Error
                        </p>
                        <p className="modal__text">
                            {error}
                        </p>
                    </div>
                    <div className="modal__content">
                        <button className="modal__btn" onClick={() => this.closeNotification()}>
                            Got it!
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {clearProjectError}
)(ErrorNotification);
