import React, {Component} from 'react';
import cx from "classnames";
import {ReactComponent as SuccessNotificationIcon} from "../../../../img/svg/success_notification.svg";
import '../Modal.scss';

interface SuccessNotificationProps {
    message: string;
    description?: string;
    buttonCaption?: string;
    showNotificationWindow: boolean;
    handleClose: () => void;
}

export class SuccessNotification extends Component<SuccessNotificationProps> {
    render() {
        const {message, description, buttonCaption, showNotificationWindow, handleClose} = this.props;
        return (
            <div className={cx('modal__wrap', {'modal__wrap--active': showNotificationWindow})}>
                <div className="modal">
                    <div className="modal__header ">
                        <div className={"modal__icon"}>
                            <SuccessNotificationIcon/>
                        </div>
                        <p className="modal__title">
                            {message}
                        </p>
                        {description && (
                            <p className="modal__text">
                                {description}
                            </p>
                        )}
                    </div>
                    <div className="modal__content">
                        <button className="modal__btn" onClick={() => handleClose()}>
                            {buttonCaption ?? `Got it!`}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

