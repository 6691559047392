import React from "react";
import './MobileObjectActionButton.scss';
import cx from "classnames";

interface MobileObjectActionButtonProps {
    name: string;
    icon: React.ReactNode;
    handleAction: () => void;
    styleBig?: boolean
}

class MobileObjectActionButton extends React.Component<MobileObjectActionButtonProps> {
    render() {
        const {name, icon, handleAction, styleBig} = this.props;
        return (
            <button
                className={cx('mobile-object-actions__btn', {'mobile-object-actions__btn--big': styleBig})}
                onClick={() => handleAction()}
            >
                <span className="mobile-object-actions-btn__ico-wrap">
                 {icon}
                </span>
                {name}
            </button>
        );
    }
}

export default MobileObjectActionButton;
