import React from 'react';
import './DetailsHeader.scss';
import cx from "classnames";
import {useAppDispatch, useAppSelector} from "../../../store";
import {changeMeasure} from "../../../actions/builderActions";
import {MEASURES_DESC} from "../../../constants/builderDefaults";

interface DetailsHeaderProps {
    titleText: string;
}

export default function DetailsHeader({titleText}: DetailsHeaderProps) {
    const dispatch = useAppDispatch();
    const projectMeasure = useAppSelector((state) => state.builder.projectData.measure)
    return (
        <div className="details-header">
            <h2 className="details-header__title">{titleText}</h2>
            <div className="details-header__list">
                {MEASURES_DESC.map(measureDesc => (
                    <div
                        key={`measure-${measureDesc.measure}`}
                        className={cx(
                            "details-header__label",
                            {'details-header__label--active': measureDesc.measure === projectMeasure}
                        )}
                        onClick={() => {
                            if (measureDesc.measure !== projectMeasure) {
                                dispatch(changeMeasure(measureDesc.measure))
                            }
                        }}
                    >
                        {measureDesc.name}
                    </div>
                ))}
            </div>
        </div>
    );
}
