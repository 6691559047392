import {RoomShape} from "../models/room";
import {
    ADD_DOOR,
    ADD_WINDOW,
    CHANGE_MEASURE,
    CHANGE_ROOM_SHAPE,
    CREATE_PROJECT_SUCCESS,
    FETCH_FLOOR_STYLES_ERROR,
    FETCH_FLOOR_STYLES_START,
    FETCH_FLOOR_STYLES_SUCCESS,
    FETCH_PROJECT_ERROR,
    FETCH_PROJECT_START,
    FETCH_PROJECT_SUCCESS,
    INIT_NEW_PROJECT_ERROR,
    INIT_NEW_PROJECT_START,
    INIT_NEW_PROJECT_SUCCESS,
    MIRROR_DOOR_OPEN,
    NAVIGATE_TO_PROJECT_STEP,
    PROJECT_ERROR_NOTIFY,
    REMOVE_DOOR,
    REMOVE_WINDOW,
    SAVE_PROJECT_CANCEL,
    SAVE_PROJECT_ERROR,
    SAVE_PROJECT_START,
    SELECT_DOOR,
    SELECT_WINDOW,
    SET_DOOR_INDENT,
    SET_DOOR_LENGTH,
    SET_DOOR_WALL,
    SET_DOOR_WIDTH,
    SET_PROJECT_NAME,
    SET_ROOM_LENGTH,
    SET_ROOM_TYPE,
    SET_ROOM_WIDTH,
    SET_WINDOW_INDENT,
    SET_WINDOW_LENGTH,
    SET_WINDOW_WALL,
    SET_WINDOW_WIDTH,
    SET_WINDOW_HEIGHT,
    UPDATE_PROJECT_SUCCESS,
    VIEWER_DATA_IS_READY,
    SET_BUILDER_SCALE,
    FETCH_ROOM_TYPES_START,
    FETCH_ROOM_TYPES_SUCCESS,
    FETCH_ROOM_TYPES_ERROR,
    UNSELECT_ROOM_OBJECTS
} from "./types";
import {BuilderSteps, Measure, PositionOptions} from "../models/builder";
import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {
    createProjectRequest,
    fetchProjectRequest,
    getRoomTypeRequest, getRoomTypesRequest,
    updateProjectRequest
} from "../requests/projectRequests";
import {AxiosError} from "axios";
import {cloneDeep} from "lodash";
import {
    checkResizedRoomLength,
    checkResizedRoomWidth,
    defaultDoor,
    defaultWindow,
    findPlaceForWallObject,
    findPlaceForWindowBay,
    findPlaceOnWall,
    fitObjectToAvailableSpace, itemsAreInsideWindowBay
} from "../utils/builderRoomSettings";
import store from "../store";
import {getFloorStyleslistRequest} from "../requests/builderDataRequests";
import {PROJECT_ERRORS} from "../constants/errorMessages";

export function saveProjectStart() {
    return {
        type: SAVE_PROJECT_START
    }
}

export function saveProjectCancel() {
    return {
        type: SAVE_PROJECT_CANCEL
    }
}

export function createNewProject(projectName: string) {
    return (dispatch: Dispatch<AnyAction>, getState: Function) => {
        dispatch({
            type: SET_PROJECT_NAME,
            projectName
        });
        const state = getState();
        return createProjectRequest(state.builder.projectData)
            .then(res => {
                dispatch({
                    type: CREATE_PROJECT_SUCCESS,
                    projectId: res.data.id
                });
            }).catch((err: Error) => {
                dispatch({
                    type: SAVE_PROJECT_ERROR
                });
                throw Error(err.message);
            });
    }
}

export function updateProject() {
    return (dispatch: Dispatch<AnyAction>, getState: Function) => {
        const state = getState();
        return updateProjectRequest(state.builder.projectData)
            .then(res => {
                dispatch({
                    type: UPDATE_PROJECT_SUCCESS,
                    //projectId: res.data.id
                });
            }).catch((err: Error) => {
                dispatch({
                    type: SAVE_PROJECT_ERROR
                });
                throw Error(err.message);
            });
    }
}

export function initNewProject(roomTypeSlug: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: INIT_NEW_PROJECT_START})
        return getRoomTypeRequest(roomTypeSlug)
            .then(res => {
                dispatch({
                    type: INIT_NEW_PROJECT_SUCCESS,
                    roomType: res.data.id
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: INIT_NEW_PROJECT_ERROR,
                    error: (err.response?.statusText || err.message)
                });
            });
    }
}

export function fetchProject(projectId: number) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: FETCH_PROJECT_START})
        return fetchProjectRequest(projectId)
            .then(res => {
                dispatch({
                    type: FETCH_PROJECT_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: FETCH_PROJECT_ERROR,
                    error: (err.response?.statusText || err.message)
                });
            });
    }
}

export function setRoomType(roomType: string) {
    return {
        type: SET_ROOM_TYPE,
        roomType
    }
}

export function navigateToProjectStep(step: BuilderSteps) {
    return {
        type: NAVIGATE_TO_PROJECT_STEP,
        step
    }
}

export function changeRoomShape(roomShape: RoomShape) {
    if (roomShape === RoomShape.windowBay) {
        const placeParams = findPlaceForWindowBay();
        if (!placeParams) {
            return {
                type: PROJECT_ERROR_NOTIFY,
                error: 'No available space left'
            }
        }
        return {
            type: CHANGE_ROOM_SHAPE,
            roomShape,
            windowBayParams: placeParams
        }
    }

    const state = store.getState();
    if (state.builder.projectData.roomSettings.shape === RoomShape.windowBay) {
        if(itemsAreInsideWindowBay()) {
            return {
                type: PROJECT_ERROR_NOTIFY,
                error: PROJECT_ERRORS.DEFAULT.BAY_CONTAINS_ITEMS
            }
        }
    }

    return {
        type: CHANGE_ROOM_SHAPE,
        roomShape
    }
}

export function changeMeasure(measure: Measure) {
    return {
        type: CHANGE_MEASURE,
        measure
    }
}

export function setRoomWidth(width: number) {
    const state = store.getState();
    if (state.builder.projectData.roomSettings.width > width) {
        if (!checkResizedRoomWidth(width)) {
            return {
                type: PROJECT_ERROR_NOTIFY,
                error: PROJECT_ERRORS.DEFAULT.RESIZE_ROOM
            }
        }
    }
    return {
        type: SET_ROOM_WIDTH,
        width
    }
}

export function setRoomLength(length: number) {
    const state = store.getState();
    if (state.builder.projectData.roomSettings.length > length) {
        if (!checkResizedRoomLength(length)) {
            return {
                type: PROJECT_ERROR_NOTIFY,
                error: PROJECT_ERRORS.DEFAULT.RESIZE_ROOM
            }
        }
    }
    return {
        type: SET_ROOM_LENGTH,
        length
    }
}

export function addDoor() {
    const state = store.getState();
    const doorData = defaultDoor(state.builder.projectData.measure);
    const placeParams = findPlaceForWallObject(
        state.builder.projectData,
        doorData.width
    )
    if (!placeParams) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space left'
        }
    }
    const newDoor = {...doorData, ...placeParams};

    return {
        type: ADD_DOOR,
        door: newDoor
    }
}

export function removeDoor(index: number) {
    return {
        type: REMOVE_DOOR,
        index
    }
}

export function selectDoor(index: number | null) {
    return {
        type: SELECT_DOOR,
        index
    }
}

export function setDoorWidth(width: number) {
    const state = store.getState();
    const selectedDoorIndex = state.builder.selectedDoorIndex || 0;
    const indent = fitObjectToAvailableSpace(state.builder.projectData.doors[selectedDoorIndex], width);
    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: `Can't fit the door to available space`
        }
    }
    return {
        type: SET_DOOR_WIDTH,
        width,
        indent
    }
}

export function setDoorLength(length: number) {
    return {
        type: SET_DOOR_LENGTH,
        length
    }
}

export function setDoorWall(wall: PositionOptions) {
    const state = store.getState();
    const selectedDoorIndex = state.builder.selectedDoorIndex || 0;
    const door = state.builder.projectData.doors[selectedDoorIndex];
    const indent = findPlaceOnWall(state.builder.projectData, door.width, wall);

    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space'
        }
    } else {
        return {
            type: SET_DOOR_WALL,
            wall,
            indent
        }
    }
}

export function setDoorIndent(indent: number) {
    return {
        type: SET_DOOR_INDENT,
        indent
    }
}

export function mirrorDoorOpen() {
    return {
        type: MIRROR_DOOR_OPEN
    }
}

export function copyDoor() {
    const state = store.getState();
    const selectedDoorIndex = state.builder.selectedDoorIndex || 0;
    let doorCopy = cloneDeep(state.builder.projectData.doors[selectedDoorIndex])

    const placeParams = findPlaceForWallObject(
        state.builder.projectData,
        doorCopy.width
    )
    if (!placeParams) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space left'
        }
    }
    doorCopy = {...doorCopy, ...placeParams}
    return {
        type: ADD_DOOR,
        door: doorCopy
    }
}

export function setWindowIndent(indent: number) {
    return {
        type: SET_WINDOW_INDENT,
        indent
    }
}

export function selectWindow(index: number | null) {
    return {
        type: SELECT_WINDOW,
        index
    }
}

export function addWindow() {
    const state = store.getState();
    const windowData = defaultWindow(state.builder.projectData.measure);
    const placeParams = findPlaceForWallObject(
        state.builder.projectData,
        windowData.width
    )
    if (!placeParams) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space left'
        }
    }
    const newWindow = {...windowData, ...placeParams}
    return {
        type: ADD_WINDOW,
        window: newWindow
    }
}

export function removeWindow(index: number) {
    return {
        type: REMOVE_WINDOW,
        index
    }
}

export function setWindowWidth(width: number) {
    const state = store.getState();
    const selectedWindowIndex = state.builder.selectedWindowIndex || 0;
    const indent = fitObjectToAvailableSpace(state.builder.projectData.windows[selectedWindowIndex], width);
    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: `Can't fit the window to available space`
        }
    }
    return {
        type: SET_WINDOW_WIDTH,
        width,
        indent
    }
}

export function setWindowLength(length: number) {
    return {
        type: SET_WINDOW_LENGTH,
        length
    }
}

export function setWindowHeight(height: number) {
    return {
        type: SET_WINDOW_HEIGHT,
        height
    }
}

export function setWindowWall(wall: PositionOptions) {
    const state = store.getState();
    const selectedWindowIndex = state.builder.selectedWindowIndex || 0;
    const window = state.builder.projectData.windows[selectedWindowIndex];

    const indent = findPlaceOnWall(state.builder.projectData, window.width, wall);

    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space'
        }
    }

    return {
        type: SET_WINDOW_WALL,
        wall,
        indent
    }
}

export function copyWindow() {
    const state = store.getState();
    const selectedWindowIndex = state.builder.selectedWindowIndex || 0;
    let windowCopy = cloneDeep(state.builder.projectData.windows[selectedWindowIndex]);

    const placeParams = findPlaceForWallObject(
        state.builder.projectData,
        windowCopy.width
    )
    if (!placeParams) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space left'
        }
    }

    windowCopy = {...windowCopy, ...placeParams}

    return {
        type: ADD_WINDOW,
        window: windowCopy
    }
}


export function raiseProjectError(errorMessage: string) {
    return {
        type: PROJECT_ERROR_NOTIFY,
        error: errorMessage
    }
}

export function clearProjectError() {
    return {
        type: PROJECT_ERROR_NOTIFY,
        error: null
    }
}

export function fetchFloorStyles() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: FETCH_FLOOR_STYLES_START})
        return getFloorStyleslistRequest()
            .then(res => {
                dispatch({
                    type: FETCH_FLOOR_STYLES_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: FETCH_FLOOR_STYLES_ERROR,
                    error: (err.response?.statusText || err.message)
                });
            });
    }
}

export function setViewerIsReady() {
    return {
        type: VIEWER_DATA_IS_READY
    }
}

export function setBuilderScale(scale: number) {
    return {
        type: SET_BUILDER_SCALE,
        scale
    }
}

export function getRoomTypes() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: FETCH_ROOM_TYPES_START})
        return getRoomTypesRequest()
            .then(res => {
                dispatch({
                    type: FETCH_ROOM_TYPES_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: FETCH_ROOM_TYPES_ERROR
                });
            });
    }
}

export function unSelectRoomObjects() {
    return {
        type: UNSELECT_ROOM_OBJECTS,
    }
}
