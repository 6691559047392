import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Header.scss';
import {ReactComponent as PeppersqLogo} from "../../../img/svg/peppersq-logo.svg";
import {ReactComponent as HeaderHamburger} from "../../../img/svg/header-hamburger.svg";
import {RootState} from "../../../store";
import {AuthorizedUser} from "../../../models/auth";
import HeaderActions from "./HeaderActions/HeaderActions";
import MarketplaceLink from "../MarketplaceLink/MarketplaceLink";
import HeaderDropdownSection from "./HeaderDropdownSection/HeaderDropdownSection";
import {fetchHeaderMenu} from "../../../actions/marketplaceContentActions";
import {MarketplaceCategory, MarketplaceMenuItem} from "../../../models/marketplaceContent";
import {cloneDeep, find} from "lodash";
import {getCategoryImage, getMenuItem} from "../../../utils/marketplaceContent";
/*import { ReactComponent as SearchWhite } from '../../../img/svg/search-white.svg';*/
import cx from "classnames";
import {
    MARKETPLACE_PATH_ACCOUNT,
    MARKETPLACE_PATH_BECOME_PARTNER,
    MARKETPLACE_PATH_CONTACT,
    MARKETPLACE_PATH_LOGIN,
    MARKETPLACE_PATH_RETURNS,
    MARKETPLACE_PATH_TRACK_ORDER
} from "../../../constants/marketplaceLinks";
import HeaderMobileCollapsible from "./HeaderMobileCollapsible/HeaderMobileCollapsible";
import SearchMobile from "./SearchMobile/SearchMobile";
import MarketplaceModalOverlay from "../Modal/MarketplaceModalOverlay/MarketplaceModalOverlay";
import RoomTypeModal from "../RoomTypeModal/RoomTypeModal";
import builderLink from '../../../img/builder-link.jpg';

interface HeaderProps {
    user: AuthorizedUser;
    fetchHeaderMenu: () => Promise<void>;
    headerMenu: MarketplaceMenuItem[];
    categoriesData: MarketplaceCategory[];
}

interface HeaderState {
    furnitureCategoryImage: null | string;
    showMobileMenu: boolean;
    showRoomTypeModal: boolean;
}

function mapStateToProps(state: RootState) {
    return {
        user: state.user,
        headerMenu: state.marketplaceContent.headerMenu,
        categoriesData: state.marketplaceContent.categoriesData,
    };
}

export class Header extends Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            furnitureCategoryImage: null,
            showMobileMenu: false,
            showRoomTypeModal: false,
        };
    }

    componentDidMount() {
        this.props.fetchHeaderMenu()
    }

    handleFurnitureMenuImage(furnitureMenuImage: string | null) {
        this.setState({furnitureCategoryImage: furnitureMenuImage})
    }

    toggleMobileMenu = () => {
        this.setState(prevState => ({showMobileMenu: !prevState.showMobileMenu}))
    }

    handleRoomTypeModal(show: boolean) {
        this.setState({showRoomTypeModal: show})
    }

    render() {
        const {headerMenu, categoriesData, user} = this.props;
        const {furnitureCategoryImage, showMobileMenu, showRoomTypeModal} = this.state;

        const buyTheLookMenu = find(headerMenu, {value: 'rooms'});
        const interiorCapsules = find(headerMenu, {value: 'interior-capsules'})?.items
            .map((item: MarketplaceMenuItem) => getMenuItem(item));

        let furniture = cloneDeep(find(headerMenu, {value: 'furniture'}));
        const furnitureLighting = find(headerMenu, {value: 'furniture-lighting'});
        if (furniture && furnitureLighting) {
            furniture.items = [
                ...furniture.items.slice(0, furniture.items.length - 1),
                furnitureLighting,
                ...furniture.items.slice(furniture.items.length - 1),
            ]
        }
        const defaultFurnitureImage = furniture ? getCategoryImage(categoriesData, furniture.value) : null;
        const furnitureMenuImage = furnitureCategoryImage || defaultFurnitureImage;

        const shopByRoom = [
            {
                name: 'Dining Room Furniture',
                to: '/categories/dining-room-furniture',
                categoryId: '5d774f01058c426c5dc6bfcd',
            },
            {
                name: 'Bedroom Furniture',
                to: '/categories/bedroom-furniture',
                categoryId: '5ce695d7dedd3b3dda2f3fbf',
            },
            {
                name: 'Living Room Furniture',
                to: '/categories/living-room-furniture',
                categoryId: '5c9a402def2dc21e727f6e21',
            },
            {
                name: 'Home Office Furniture',
                to: '/categories/home-office-furniture',
                categoryId: '5d3aeb1a78122a65c2d386d5',
            },
            {
                name: 'Hallway Essentials',
                to: '/categories/hallway-furniture',
                categoryId: '5d3aead978122a65c2d37747',
            },
        ];

        const spaceMaking = find(headerMenu, menuItem => (menuItem.name.toLowerCase().indexOf('spacemaking') !== -1));
        const spaceMakingLink = spaceMaking && getMenuItem(spaceMaking);

        const sale = find(headerMenu, {value: 'sale'});
        const saleLink = sale && getMenuItem(sale);

        return (
            <div className="header__wrap">
                <div className={cx('header', {'header--active': showMobileMenu})}>
                    <div className="header-hamburger__wrap">
                        <div className="header-hamburger" onClick={() => this.toggleMobileMenu()}>
                            <HeaderHamburger/>
                        </div>
                    </div>

                    <div className="header__logo header-logo">
                        <MarketplaceLink path={''} className="header-logo__link">
                            <PeppersqLogo/>
                        </MarketplaceLink>
                    </div>

                    <div className="header__menu header-menu">

                        {buyTheLookMenu && (
                            <HeaderDropdownSection name={'Buy The Look'}>
                                <div className="header-menu-drop__list">
                                    {buyTheLookMenu.items.map((menuItem, index) => {
                                        const menuLink = getMenuItem(menuItem);
                                        const categoryImage = getCategoryImage(categoriesData, menuItem.value);
                                        return (
                                            <React.Fragment key={`buy-the-look-${index}`}>
                                                {index === 3 && (
                                                    <div className="header-menu-drop__item">
                                                        <div className="header-menu-drop__title">Design your Own</div>
                                                        <div
                                                            className="header-menu-drop__image-wrap header-menu-drop__image-wrap--builder"
                                                            onClick={() => this.handleRoomTypeModal(true)}
                                                        >
                                                            <img src={builderLink} alt="Design your own" className="header-menu-drop__image"/>
                                                            <p className="header-menu-drop__text">
                                                                <b>Reimagine</b> your space
                                                                <br />
                                                                with our new <b>Room Planner</b>
                                                            </p>
                                                        </div>

                                                        <MarketplaceModalOverlay isOpened={showRoomTypeModal}>
                                                            <RoomTypeModal onModalClose={() => this.handleRoomTypeModal(false)}/>
                                                        </MarketplaceModalOverlay>
                                                    </div>
                                                )}
                                                <div className="header-menu-drop__item">
                                                    <div>
                                                        <MarketplaceLink path={menuLink.to}
                                                                         className="header-menu-drop__box">
                                                            <div className="header-menu-drop__title">
                                                                {menuItem.name}
                                                            </div>
                                                            {categoryImage && (
                                                                <div className="header-menu-drop__image-wrap">
                                                                    <img
                                                                        src={`${categoryImage}?width=600&height=400`}
                                                                        alt={menuItem.name}
                                                                        className="header-menu-drop__image"/>
                                                                </div>)}
                                                        </MarketplaceLink>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                    {interiorCapsules && (
                                        <div className="header-menu-drop__item">
                                            <div className="header-menu-drop__title">Interior Capsules</div>
                                            <div className="header-menu-drop__thumb header-menu-drop__thumb--links">
                                                {interiorCapsules.map((menuLink, index) => (
                                                    <MarketplaceLink
                                                        key={`interior-capsules-${index}`}
                                                        path={menuLink.to} className="header-menu-drop__link"
                                                    >
                                                        {menuLink.name}
                                                    </MarketplaceLink>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </HeaderDropdownSection>
                        )}

                        {furniture && (
                            <HeaderDropdownSection name={`Products`}>
                                <div className="header-menu-data">
                                    <div className="header-menu-data__content">
                                        {furniture.items.map((furnitureCategory: MarketplaceMenuItem, index: number) => {
                                            const categoryLink = getMenuItem(furnitureCategory);
                                            const categoryImage = getCategoryImage(categoriesData, furnitureCategory.value);
                                            return (
                                                <div
                                                    className="header-menu-data__item"
                                                    key={`menu-furniture-${index}`}
                                                    onMouseEnter={() => this.handleFurnitureMenuImage(categoryImage)}
                                                    onMouseLeave={() => this.handleFurnitureMenuImage(null)}
                                                >
                                                    <div className="header-menu-data__title">
                                                        <MarketplaceLink path={categoryLink.to}
                                                                         className="header-menu-data__link">
                                                            {furnitureCategory.name}
                                                        </MarketplaceLink>
                                                    </div>
                                                    <div className="header-menu-data__list">
                                                        {furnitureCategory.items.map((subCategory: MarketplaceMenuItem, subIndex: number) => {
                                                            const subCategoryLink = getMenuItem(subCategory);
                                                            return (
                                                                <MarketplaceLink
                                                                    path={subCategoryLink.to}
                                                                    className="header-menu-data__link"
                                                                    key={`menu-furniture-${index}-${subIndex}`}
                                                                >
                                                                    {subCategory.name}
                                                                </MarketplaceLink>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="header-menu-data__sidebar">
                                        {furnitureMenuImage && (
                                            <div className="header-menu-data__image-wrap">
                                                <img
                                                    src={`${furnitureMenuImage}?width=780&height=480`}
                                                    alt="" className="header-menu-data__image"/>
                                            </div>
                                        )}
                                        {shopByRoom && (
                                            <div className="header-menu-data__item">
                                                <div className="header-menu-data__title">
                                                    <div className="header-menu-data__link">Shop by Room</div>
                                                </div>
                                                <div className="header-menu-data__list">
                                                    {shopByRoom.map((menuLink, index) => {
                                                            const categoryImage = getCategoryImage(categoriesData, menuLink.categoryId)
                                                            return (
                                                                <div
                                                                    key={`shop-by-room-${index}`}
                                                                    onMouseEnter={() => this.handleFurnitureMenuImage(categoryImage)}
                                                                    onMouseLeave={() => this.handleFurnitureMenuImage(null)}
                                                                >
                                                                    <MarketplaceLink
                                                                        path={menuLink.to}
                                                                        className="header-menu-data__link"
                                                                    >
                                                                        {menuLink.name}
                                                                    </MarketplaceLink>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </HeaderDropdownSection>
                        )}


                        {spaceMakingLink && (
                            <div className="header-menu__item">
                                <MarketplaceLink path={spaceMakingLink.to} className="header-menu__link">
                                    Spacemaking Ideas
                                </MarketplaceLink>
                            </div>
                        )}

                        {saleLink && (
                            <div className="header-menu__item">
                                <MarketplaceLink
                                    path={saleLink.to}
                                    className="header-menu__link header-menu__link--sale"
                                >
                                    {saleLink.name}
                                </MarketplaceLink>
                            </div>
                        )}
                    </div>

                    <div className="header__actions header-actions"
                         onClick={() => {
                             if (showMobileMenu) {
                                 this.toggleMobileMenu()
                             }
                         }}
                    >
                        <HeaderActions/>
                    </div>

                </div>

                <div className={cx('mobile-nav__bg', {'mobile-nav__bg--active': showMobileMenu})}/>
                <div className={cx('mobile-nav', {'mobile-nav--active': showMobileMenu})}>
                    <SearchMobile/>
                    <div className="mobile-nav__links">
                        <div className="mobile-nav__link-wrap">
                            {user.isAuthenticated
                                ? (
                                    <MarketplaceLink path={MARKETPLACE_PATH_ACCOUNT} className="mobile-nav__link">
                                        Account
                                    </MarketplaceLink>
                                )
                                : (
                                    <MarketplaceLink path={MARKETPLACE_PATH_LOGIN} className="mobile-nav__link">
                                        Login
                                    </MarketplaceLink>
                                )
                            }
                        </div>

                        {buyTheLookMenu && (<HeaderMobileCollapsible title={'Buy The Look'}>
                            {buyTheLookMenu.items.map((menuItem, index) => {
                                const menuLink = getMenuItem(menuItem);
                                return (
                                    <div className="collapsible__subitem" key={`mobile-buy-the-look-${index}`}>
                                        <MarketplaceLink path={menuLink.to} className="collapsible__sublink">
                                            {menuItem.name}
                                        </MarketplaceLink>
                                    </div>
                                )
                            })}
                        </HeaderMobileCollapsible>)}

                        {/* <div className="mobile-nav__link-wrap" onClick={() => this.handleRoomTypeModal(true)}>
                            <div className="mobile-nav__link">
                              Design your own
                            </div>
                        </div> */}

                        {furniture && (<HeaderMobileCollapsible title={'Furniture'}>
                            {furniture.items.map((furnitureCategory: MarketplaceMenuItem, index: number) => {
                                const categoryLink = getMenuItem(furnitureCategory);
                                return (
                                    <HeaderMobileCollapsible
                                        title={furnitureCategory.name}
                                        link={categoryLink.to}
                                        key={`mobile-furniture-${index}`}
                                    >
                                        <div className="collapsible__subdropdown">
                                            {furnitureCategory.items.map((subCategory: MarketplaceMenuItem, subIndex: number) => {
                                                const subCategoryLink = getMenuItem(subCategory);
                                                return (
                                                    <div className="collapsible__subitem"
                                                         key={`mobile-furniture-sub-${subIndex}`}
                                                    >
                                                        <MarketplaceLink
                                                            path={subCategoryLink.to}
                                                            className="collapsible__sublink"
                                                        >
                                                            {subCategory.name}
                                                        </MarketplaceLink>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </HeaderMobileCollapsible>
                                )
                            })}
                        </HeaderMobileCollapsible>)}

                        {spaceMakingLink && (
                            <div className="mobile-nav__link-wrap">
                                <MarketplaceLink path={spaceMakingLink.to} className="mobile-nav__link">
                                    Spacemaking Ideas
                                </MarketplaceLink>
                            </div>
                        )}
                        {saleLink && (
                            <div className="mobile-nav__link-wrap">
                                <MarketplaceLink
                                    path={saleLink.to}
                                    className="mobile-nav__link"
                                >
                                    {saleLink.name}
                                </MarketplaceLink>
                            </div>
                        )}

                        <div className="mobile-nav__link-wrap">
                            <MarketplaceLink path={MARKETPLACE_PATH_BECOME_PARTNER} className="mobile-nav__link">
                                Become a Partner
                            </MarketplaceLink>
                        </div>
                        <div className="mobile-nav__link-wrap">
                            <MarketplaceLink
                                path={user.isAuthenticated
                                    ? MARKETPLACE_PATH_TRACK_ORDER
                                    : MARKETPLACE_PATH_LOGIN}
                                className="mobile-nav__link"
                            >
                                Track Your Order
                            </MarketplaceLink>
                        </div>
                        <div className="mobile-nav__link-wrap">
                            <MarketplaceLink path={MARKETPLACE_PATH_CONTACT} className="mobile-nav__link">
                                Contact Us
                            </MarketplaceLink>
                        </div>
                        <div className="mobile-nav__link-wrap">
                            <MarketplaceLink path={MARKETPLACE_PATH_RETURNS} className="mobile-nav__link">
                                Delivery and Return
                            </MarketplaceLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        fetchHeaderMenu
    }
)(Header);
