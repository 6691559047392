import React, {Component, SyntheticEvent} from 'react';
import {connect} from 'react-redux';
import {logout} from "../../../../actions/userActions";

interface LogoutProps {
    logout: Function,
    className?: string
}
class Logout extends Component<LogoutProps> {
    handleLogout = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.logout();
    }
    render() {
        return (
            <button onClick={this.handleLogout} className={this.props.className}>
               Sign out
            </button>
        );
    }
}

export default connect(
    null,
    {logout}
)(Logout);
