import {AnyAction} from "@reduxjs/toolkit";
import {FurnitureCatalog} from "../models/furnitureCatalog";
import {
    APPLY_CATALOG_CATEGORY_ALL,
    APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE,
    APPLY_CATALOG_FILTERS_ALL,
    APPLY_CATALOG_FILTERS_BY_ROOM_TYPE,
    FETCH_CATEGORY_CONTENT_ALL_ERROR,
    FETCH_CATEGORY_CONTENT_ALL_START,
    FETCH_CATEGORY_CONTENT_ALL_SUCCESS,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS,
    FETCH_CATEGORY_FILTERS_ALL_ERROR,
    FETCH_CATEGORY_FILTERS_ALL_START,
    FETCH_CATEGORY_FILTERS_ALL_SUCCESS,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS,
    FETCH_CATEGORY_PRODUCTS_ALL_ERROR,
    FETCH_CATEGORY_PRODUCTS_ALL_START,
    FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
    FETCH_FILTERED_PRODUCTS_ALL_ERROR,
    FETCH_FILTERED_PRODUCTS_ALL_START,
    FETCH_FILTERED_PRODUCTS_ALL_SUCCESS,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
    FETCH_FURNITURE_CATEGORIES_ALL_ERROR,
    FETCH_FURNITURE_CATEGORIES_ALL_START,
    FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS,
    GET_RECENT_ITEMS_ERROR,
    GET_RECENT_ITEMS_START,
    GET_RECENT_ITEMS_SUCCESS, GET_ROOM_TYPE_ERROR, GET_ROOM_TYPE_START, GET_ROOM_TYPE_SUCCESS,
    GET_SIMILAR_ITEMS_ERROR,
    GET_SIMILAR_ITEMS_START,
    GET_SIMILAR_ITEMS_SUCCESS
} from "../actions/types/furnitureCatalog";

const initialTabContent = {
    loadingCategories: false,
    furnitureCategories: null,
    selectedCategory: null,

    loadingCategoryContent: false,

    loadingFilters: false,
    catalogFiltersContent: null,
    catalogFiltersApplied: null,

    loadingProducts: false,
    productsList: []
}

const initialState = {
    roomTypeContent: initialTabContent,
    allProductsContent: initialTabContent,

    recentProducts: {
        products: [],
        loadingProducts: false
    },

    loadingProductSimilarItems: false,
    similarItems: [],

    loadingRoomType: false,
} as FurnitureCatalog

export default function furnitureCatalogReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    furnitureCategories: null,
                    selectedCategory: null,
                    loadingCategories: true
                }
            }
        case FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                roomType: action.payload.roomType,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    furnitureCategories: action.payload.categories,
                    loadingCategories: false
                }
            }
        case FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingCategories: false
                }
            }

        case FETCH_FURNITURE_CATEGORIES_ALL_START:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    furnitureCategories: null,
                    selectedCategory: null,
                    loadingCategories: true
                }
            }
        case FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    furnitureCategories: action.payload,
                    loadingCategories: false
                }
            }
        case FETCH_FURNITURE_CATEGORIES_ALL_ERROR:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingCategories: false
                }
            }

        case APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    selectedCategory: action.category
                }
            }

        case APPLY_CATALOG_CATEGORY_ALL:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    selectedCategory: action.category
                }
            }

        case FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingCategoryContent: true,
                    productsList: [],
                    catalogFiltersContent: null,
                    catalogFiltersApplied: null
                }
            }

        case FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingCategoryContent: false,
                }
            }
        case FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingCategoryContent: false,
                }
            }

        case FETCH_CATEGORY_CONTENT_ALL_START:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingCategoryContent: true,
                    productsList: [],
                    catalogFiltersContent: null,
                    catalogFiltersApplied: null
                }
            }
        case FETCH_CATEGORY_CONTENT_ALL_SUCCESS:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingCategoryContent: false,
                }
            }
        case FETCH_CATEGORY_CONTENT_ALL_ERROR:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingCategoryContent: false,
                }
            }

        case FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START:
        case FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingProducts: true,
                    productsList: []
                }
            }

        case FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS:
        case FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingProducts: false,
                    productsList: action.payload.results,
                }
            }

        case FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR:
        case FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingProducts: false
                }
            }

        case FETCH_CATEGORY_PRODUCTS_ALL_START:
        case FETCH_FILTERED_PRODUCTS_ALL_START:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingProducts: true,
                    productsList: []
                }
            }

        case FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS:
        case FETCH_FILTERED_PRODUCTS_ALL_SUCCESS:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingProducts: false,
                    productsList: action.payload.results,
                }
            }

        case FETCH_CATEGORY_PRODUCTS_ALL_ERROR:
        case FETCH_FILTERED_PRODUCTS_ALL_ERROR:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingProducts: false
                }
            }

        case FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingFilters: true
                }
            }
        case FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingFilters: false,
                    catalogFiltersContent: action.payload,
                    catalogFiltersApplied: {...action.payload, color: [], material: []},
                }
            }
        case FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    loadingFilters: false
                }
            }

        case FETCH_CATEGORY_FILTERS_ALL_START:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingFilters: true
                }
            }
        case FETCH_CATEGORY_FILTERS_ALL_SUCCESS:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingFilters: false,
                    catalogFiltersContent: action.payload,
                    catalogFiltersApplied: {...action.payload, color: [], material: []},
                }
            }
        case FETCH_CATEGORY_FILTERS_ALL_ERROR:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    loadingFilters: false
                }
            }

        case APPLY_CATALOG_FILTERS_BY_ROOM_TYPE:
            return {
                ...state,
                roomTypeContent: {
                    ...state.roomTypeContent,
                    catalogFiltersApplied: action.filters
                }
            }

        case APPLY_CATALOG_FILTERS_ALL:
            return {
                ...state,
                allProductsContent: {
                    ...state.allProductsContent,
                    catalogFiltersApplied: action.filters
                }
            }

        case GET_SIMILAR_ITEMS_START:
            return {
                ...state,
                loadingProductSimilarItems: true,
            };
        case GET_SIMILAR_ITEMS_SUCCESS:
            const {productId, items} = action;
            return {
                ...state,
                similarItems: [...state.similarItems, {productId, items}],
                loadingProductSimilarItems: false
            };
        case GET_SIMILAR_ITEMS_ERROR:
            return {
                ...state,
                loadingProductSimilarItems: false
            };

        case GET_RECENT_ITEMS_START:
            return {
                ...state,
                recentProducts: {
                    ...state.recentProducts,
                    loadingProducts: true,
                    products: []
                }
            };
        case GET_RECENT_ITEMS_SUCCESS:
            return {
                ...state,
                recentProducts: {
                    ...state.recentProducts,
                    loadingProducts: false,
                    products: action.payload
                }
            };
        case GET_RECENT_ITEMS_ERROR:
            return {
                ...state,
                recentProducts: {
                    ...state.recentProducts,
                    loadingProducts: false,
                }
            };

        case GET_ROOM_TYPE_START:
            return {
                ...state,
                loadingRoomType: true,
            }
        case GET_ROOM_TYPE_ERROR:
            return {
                ...state,
                loadingRoomType: false,
            }

        case GET_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                roomType: action.roomType,
                loadingRoomType: false,
            }

        default:
            return state;
    }
}
