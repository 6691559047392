import React, {SyntheticEvent} from 'react';
import './PositionOption.scss';
import cx from "classnames";
import {ReactComponent as PositionLeft} from '../../../img/svg/position-left.svg';
import {ReactComponent as PositionBottom} from '../../../img/svg/position-bottom.svg';
import {ReactComponent as PositionTop} from '../../../img/svg/position-top.svg';
import {ReactComponent as PositionRight} from '../../../img/svg/position-right.svg';
import {PositionOptions} from "../../../models/builder";

interface PositionOptionProps {
    title?: string;
    current: PositionOptions;
    changePositionOption: (value: PositionOptions) => void
}

export default class PositionOption extends React.Component<PositionOptionProps> {
    handleChangePosition = (e: SyntheticEvent, option: PositionOptions) => {
        e.preventDefault();
        e.stopPropagation();
        const {changePositionOption} = this.props;
        changePositionOption(option);
    }

    render() {
        const {title, current} = this.props;
        return (
            <div className="position-option">
                {title && <p className="position-option__title">{title}</p>}
                <div className="position-option__list"
                >
                    <label className="position-option__item"
                           onClick={(e: SyntheticEvent) => this.handleChangePosition(e, PositionOptions.left)}>
                        <div className={cx(
                            "position-option__content",
                            {'position-option__content--active': current === PositionOptions.left}
                        )}>
                            <PositionLeft/>
                        </div>
                    </label>

                    <label className="position-option__item"
                           onClick={(e: SyntheticEvent) => this.handleChangePosition(e, PositionOptions.bottom)}>
                        <div className={cx(
                            "position-option__content",
                            {'position-option__content--active': current === PositionOptions.bottom}
                        )}>
                            <PositionBottom/>
                        </div>
                    </label>

                    <label className="position-option__item"
                           onClick={(e: SyntheticEvent) => this.handleChangePosition(e, PositionOptions.top)}>
                        <div className={cx(
                            "position-option__content",
                            {'position-option__content--active': current === PositionOptions.top}
                        )}>
                            <PositionTop/>
                        </div>
                    </label>

                    <label className="position-option__item"
                           onClick={(e: SyntheticEvent) => this.handleChangePosition(e, PositionOptions.right)}>
                        <div className={cx(
                            "position-option__content",
                            {'position-option__content--active': current === PositionOptions.right}
                        )}>
                            <PositionRight/>
                        </div>
                    </label>
                </div>
            </div>
        );
    }
}
