import React, {Component} from 'react';
import {connect} from 'react-redux';
import DetailsHeader from "../../DetailsHeader/DetailsHeader";
import {ReactComponent as AddFurniture} from "../../../../img/svg/add-furniture.svg";
import RangeSlider from "../../RangeSlider/RangeSlider";
import {RootState} from "../../../../store";
import {RoomSettings} from "../../../../models/room";
import {
    CUSTOM_OBJECT_DEFAULT_LENGTH,
    CUSTOM_OBJECT_DEFAULT_SHAPE,
    CUSTOM_OBJECT_DEFAULT_WIDTH,
    CUSTOM_OBJECT_MAX_LENGTH,
    CUSTOM_OBJECT_MAX_WIDTH,
    CUSTOM_OBJECT_MIN_LENGTH,
    CUSTOM_OBJECT_MIN_WIDTH
} from "../../../../constants/furnitureCatalog";
import {Measure} from "../../../../models/builder";
import {convertMeasure} from "../../../../utils/convertMeasure";
import {FurnitureCustomObjectShape} from "../../../../models/furnitureCatalog";
import {find, map, startCase} from "lodash";
import {MEASURES_DESC} from "../../../../constants/builderDefaults";
import {addCustomObject} from "../../../../actions/furnitureBuilderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {CustomObjectSettings} from "../FurnitureList";

interface FurnitureCustomItemSettingsProps {
    roomSettings: RoomSettings;
    measure: Measure;
    addCustomObject: (shape: FurnitureCustomObjectShape, width: number, length?: number) => AnyAction;
    handleSettings: (settings: CustomObjectSettings) => void;
}

interface FurnitureCustomItemSettingsState {
    objectWidth: number;
    objectLength: number;
    objectShape: FurnitureCustomObjectShape;
}

class FurnitureCustomItemSettings extends Component<FurnitureCustomItemSettingsProps, FurnitureCustomItemSettingsState> {
    constructor(props: FurnitureCustomItemSettingsProps) {
        super(props);
        this.state = {
            objectWidth: convertMeasure(Measure.cm, props.measure, CUSTOM_OBJECT_DEFAULT_WIDTH),
            objectLength: convertMeasure(Measure.cm, props.measure, CUSTOM_OBJECT_DEFAULT_LENGTH),
            objectShape: CUSTOM_OBJECT_DEFAULT_SHAPE
        }
    }

    componentDidMount() {
        this.afterSettingsSet();
    }

    afterSettingsSet() {
        const {objectWidth, objectLength, objectShape} = this.state;
        this.props.handleSettings({objectWidth, objectLength, objectShape});
    }

    componentDidUpdate(prevProps: Readonly<FurnitureCustomItemSettingsProps>, prevState: Readonly<FurnitureCustomItemSettingsState>, snapshot?: any) {
        if (prevProps.measure !== this.props.measure) {
            this.setState((prevState) => ({
                objectWidth: convertMeasure(prevProps.measure, this.props.measure, prevState.objectWidth),
                objectLength: convertMeasure(prevProps.measure, this.props.measure, prevState.objectLength),
            }), () => this.afterSettingsSet())
        }
    }

    handleWidthChange(width: number) {
        this.setState({objectWidth: width}, () => this.afterSettingsSet())
    }

    handleLengthChange(length: number) {
        this.setState({objectLength: length}, () => this.afterSettingsSet())
    }

    handleShapeChange(shape: FurnitureCustomObjectShape) {
        this.setState({objectShape: shape}, () => this.afterSettingsSet())
    }

    handleAdd = () => {
        const {objectWidth, objectLength, objectShape} = this.state
        this.props.addCustomObject(objectShape, objectWidth, objectLength)
    }

    render() {
        const {roomSettings, measure} = this.props;
        const {objectWidth, objectLength, objectShape} = this.state;
        const maxWidth = Math.min(
            convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MAX_WIDTH),
            roomSettings.width);
        const maxLength = Math.min(
            convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MAX_LENGTH),
            roomSettings.length)
        return (
            <div className="furniture-settings furniture-settings--custom">
                <h4 className="furniture-settings__custom-title">Custom object</h4>
                <p className="furniture-settings__text">Add placeholder to fill space for some specific elements that yo’ure already have</p>
                <DetailsHeader titleText="Size settings"/>
                <div className="furniture-settings__list furniture-settings__list--range">
                    <div className="furniture-settings__item">
                        <RangeSlider
                            inputLabel={'Width'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={val => this.handleWidthChange(val)}
                            maxValue={maxWidth}
                            minValue={convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MIN_WIDTH)}
                            value={objectWidth}/>
                    </div>
                    {objectShape !== FurnitureCustomObjectShape.round && (
                        <div className="furniture-settings__item">
                            <RangeSlider
                                inputLabel={'Length'}
                                valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                                onChange={val => this.handleLengthChange(val)}
                                maxValue={maxLength}
                                minValue={convertMeasure(Measure.cm, measure, CUSTOM_OBJECT_MIN_LENGTH)}
                                value={objectLength}/>
                        </div>
                    )}
                </div>
                <div className="furniture-settings__list">
                    <div className="furniture-settings__item">
                        <div className="settings-option settings-option--small">
                            {map(FurnitureCustomObjectShape, shape => (
                                <div className="settings-option__input-wrapper" key={`custom-shape-${shape}`}>
                                    <input
                                        className="settings-option__input"
                                        type="radio"
                                        id={`custom-shape-${shape}`}
                                        name={`custom-shape-${shape}`}
                                        checked={objectShape === shape}
                                        onChange={() => this.handleShapeChange(shape)}
                                    />
                                    <label className="settings-option__label" htmlFor={`custom-shape-${shape}`}>
                                        {startCase(shape)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="furniture-settings__item">
                        <button
                            className="furniture-settings__add-btn-custom"
                            onClick={() => this.handleAdd()}
                        >
                            Add to room
                            <AddFurniture/>
                        </button>
                    </div> */}
                    <div className="furniture-list__active-content-custom">
                        {/* <div className="furniture-list__active-content-wrap"> */}
                        <button
                            className="furniture-settings__add-btn-custom"
                            onClick={() => this.handleAdd()}
                        >
                            Add to room
                            <AddFurniture/>
                        </button>
                        {/* </div> */}
                    </div>
                </div>

                {/* <div className="furniture-settings__text">
                    Or simply drag & drop it to the scene
                </div> */}
            </div>
        );
    }
}


function mapStateToProps(state: RootState) {
    return {
        roomSettings: state.builder.projectData.roomSettings,
        measure: state.builder.projectData.measure,
    };
}

export default connect(
    mapStateToProps,
    {addCustomObject}
)(FurnitureCustomItemSettings);
