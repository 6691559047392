import React, {Component} from 'react';
import {connect} from 'react-redux';
import FurnitureList from "../../FurnitureList/FurnitureList";
import FurnitureCategories from "../FurnitureCategories/FurnitureCategories";
import FurnitureCatalogFilters from "../FurnitureCatalogFilters/FurnitureCatalogFilters";
import {RootState} from "../../../../store";
import {CatalogFilters, FurnitureCategoryMain, FurnitureProduct} from "../../../../models/furnitureCatalog";
import {
    applyRoomTypeFilters,
    getFurnitureCategoriesByRoomType,
    loadRoomTypeCategoryContent
} from "../../../../actions/furnitureCatalogActions";
import {head} from "lodash";

interface FurnitureByRoomTypeProps {
    getFurnitureCategories: () => Promise<void>;
    furnitureCategories: null | FurnitureCategoryMain[];
    selectedCategory: string | null;
    loadCategoryContent: (category: string) => void;
    filters: null | CatalogFilters;
    appliedFilters: null | CatalogFilters;
    loadingFilters: boolean;
    applyFilters: (filters: CatalogFilters) => Promise<void>;
    furnitureProducts: FurnitureProduct[];
}

function mapStateToProps(state: RootState) {
    return {
        furnitureCategories: state.furnitureCatalog.roomTypeContent.furnitureCategories,
        selectedCategory: state.furnitureCatalog.roomTypeContent.selectedCategory,
        filters: state.furnitureCatalog.roomTypeContent.catalogFiltersContent,
        appliedFilters: state.furnitureCatalog.roomTypeContent.catalogFiltersApplied,
        loadingFilters: state.furnitureCatalog.roomTypeContent.loadingFilters,
        furnitureProducts: state.furnitureCatalog.roomTypeContent.productsList
    };
}

export class FurnitureByRoomType extends Component<FurnitureByRoomTypeProps> {
    componentDidMount() {
        const {furnitureCategories} = this.props;
        if (!furnitureCategories) {
            this.loadCategories().then(() => this.loadDefaultCategory());
        }
    }

    loadCategories = async () => {
        await this.props.getFurnitureCategories()
    }

    loadDefaultCategory() {
        const {furnitureCategories} = this.props;
        const defaultMainCategory = head(furnitureCategories);
        if (defaultMainCategory) {
            const defaultSecondaryCategory = head(defaultMainCategory.secondaryCategories)
            if (defaultSecondaryCategory) {
                this.props.loadCategoryContent(defaultSecondaryCategory.id)
            }
        }
    }

    render() {
        const {
            furnitureCategories, selectedCategory,
            filters, appliedFilters, loadingFilters, applyFilters,
            furnitureProducts
        } = this.props;
        return (
            <>
                {furnitureCategories && (
                    <FurnitureCategories
                        {...{furnitureCategories, selectedCategory}}
                        handleCategoryChange={this.props.loadCategoryContent}
                    />
                )}
                {filters && appliedFilters && (<FurnitureCatalogFilters
                    {...{
                        filters,
                        appliedFilters,
                        loadingFilters,
                        applyFilters
                    }}
                />)}
                <FurnitureList {...{furnitureProducts}} addCustomObject/>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        getFurnitureCategories: getFurnitureCategoriesByRoomType,
        loadCategoryContent: loadRoomTypeCategoryContent,
        applyFilters: applyRoomTypeFilters
    }
)(FurnitureByRoomType);
