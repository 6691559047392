import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from "classnames";
import './Viewer.scss';
import RoomSettingsViewer from '../../ui/ViewerSettings/RoomSettingsViewer/RoomSettingsViewer';
import FloorWalls from '../../ui/ViewerSettings/FloorWalls/FloorWalls';
import ContentMenu from '../../ui/ViewerNavigation/ViewerNavigation';
import AddFurniture from '../../ui/ViewerSettings/AddFurniture/AddFurniture';
import ViewerFooterExtended from '../../ui/ViewerFooterExtended/ViewerFooterExtended';
import ViewerSettingsFooter from '../../ui/ViewerSettingsFooter/ViewerSettingsFooter';
import Builder from "../../builder/Builder/Builder";
import {RootState} from "../../../store";
import {BuilderSteps} from "../../../models/builder";
import ViewerSettingsHeader from "../../ui/ViewerSettingsHeader/ViewerSettingsHeader";
import ViewerSettingsToggle from "../../ui/ViewerSettingsToggle/ViewerSettingsToggle";
import ViewerNavigationMobile from '../../ui/ViewerNavigationMobile/ViewerNavigationMobile';
import Breadcrumbs from '../../ui/Breadcrumbs/Breadcrumbs';
import ErrorNotification from "../../ui/Modal/ErrorNotification/ErrorNotification";
import {fetchFloorStyles, setViewerIsReady} from "../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import Header from '../../ui/Header/Header';
import EditFurniture from "../../ui/EditFurniture/EditFurniture";
import EditCustomObject from "../../ui/EditCustomObject/EditCustomObject";
import MobileObjectActions from "../../builder/MobileObjectActions/MobileObjectActions";
import {isMobile} from "../../../utils/responsive";
import {selectCustomObject, selectFurnitureItem} from "../../../actions/furnitureBuilderActions";
/*import ModalSavePdf from "../../ui/Modal/ModalSavePdf/ModalSavePdf";*/
/*import Notification from "../../ui/InfoNotifications/Notification";*/
import SafariNotification from "../../ui/InfoNotifications/SafariNotification/SafariNotification";

const ReactWebTabs = require('react-web-tabs');


function mapStateToProps(state: RootState) {
    return {
        currentProjectStep: state.builder.currentProjectStep,
        viewerDataIsReady: state.builder.viewerDataIsReady,
        showFurnitureEditBlock: state.builder.selectedFurnitureItemIndex !== null,
        showCustomObjectEditBlock: state.builder.selectedCustomObjectIndex !== null,
    };
}

interface ViewerProps {
    currentProjectStep: BuilderSteps;
    fetchFloorStyles: Function;
    setViewerIsReady: () => AnyAction;
    viewerDataIsReady: boolean;
    showFurnitureEditBlock: boolean;
    showCustomObjectEditBlock: boolean;
    selectFurnitureItem: (index: number | null) => AnyAction;
    selectCustomObject: (itemIndex: number | null) => AnyAction;
}

interface ViewerState {
    showSettings: boolean;
    showCart: boolean;
    // showFooter: boolean;
    showMobileFurnitureSettings: boolean;
    showMobileCustomObjectSettings: boolean;
}

class Viewer extends Component<ViewerProps, ViewerState> {
    constructor(props: ViewerProps) {
        super(props);
        this.state = {
            showSettings: true,
            showCart: false,
            // showFooter: false,
            showMobileFurnitureSettings: false,
            showMobileCustomObjectSettings: false,
        }
    }

    componentDidMount() {
        this.loadViewerData().then(() => this.props.setViewerIsReady())
    }

    componentDidUpdate(prevProps: Readonly<ViewerProps>, prevState: Readonly<ViewerState>, snapshot?: any) {
        const {showFurnitureEditBlock, showCustomObjectEditBlock} = this.props;
        if (!showFurnitureEditBlock && prevProps.showFurnitureEditBlock) {
            this.setState({showMobileFurnitureSettings: false})
        }
        if (!showCustomObjectEditBlock && prevProps.showCustomObjectEditBlock) {
            this.setState({showMobileCustomObjectSettings: false})
        }
    }

    loadViewerData = async () => {
        await this.props.fetchFloorStyles();
    }

    toggleSettings = () => {
        this.setState(prevState => ({
            showSettings: !prevState.showSettings
        }));
    }

    toggleCart = () => {
        this.setState(prevState => ({
            showCart: !prevState.showCart
        }))
    }

    // toggleFooter = () => {
    //   this.setState(prevState => ({
    //       showFooter: !prevState.showFooter
    //   }))
  // }

    handleCartMobile = () => {
        if (this.state.showSettings) {
            this.toggleSettings();
        }
        if (isMobile() && this.props.showFurnitureEditBlock) {
            this.props.selectFurnitureItem(null)
        }
        if (isMobile() && this.props.showCustomObjectEditBlock) {
            this.props.selectCustomObject(null)
        }
        this.toggleCart();
    }

    toggleFurnitureSettingsMobile = () => {
        this.setState(prevState => ({
            showMobileFurnitureSettings: !prevState.showMobileFurnitureSettings
        }));
    }

    toggleCustomObjectSettingsMobile = () => {
        this.setState(prevState => ({
            showMobileCustomObjectSettings: !prevState.showMobileCustomObjectSettings
        }));
    }

    render() {
        const {showSettings, showCart, showMobileFurnitureSettings, showMobileCustomObjectSettings} = this.state;
        const {currentProjectStep, viewerDataIsReady, showFurnitureEditBlock, showCustomObjectEditBlock} = this.props;
        if (!viewerDataIsReady) {
            return null;
        }

        const hideMobileFurnitureSettings = isMobile() && !showMobileFurnitureSettings;
        const hideMobileCustomObjectSettings = isMobile() && !showMobileCustomObjectSettings;

        return (
            <div className="viewer">
                <Header/>
                <div className="viewer__box">
                    <ReactWebTabs.TabProvider defaultTab={currentProjectStep}>
                        <div
                            className={cx(
                                'viewer__settings-menu-wrap',
                                {'viewer__settings-menu-wrap--hidden': !showSettings}
                            )}>
                            <div className="viewer__settings-menu">
                                <div className={cx({
                                    //'viewer__settings-content--hidden': (showFurnitureEditBlock || showCustomObjectEditBlock)
                                })}>
                                    <div className="settings-menu">
                                        <Breadcrumbs/>
                                        <ViewerSettingsHeader handleToggle={this.toggleSettings}/>

                                        <ReactWebTabs.TabPanel tabId={BuilderSteps.RoomSettings}>
                                            <RoomSettingsViewer/>
                                        </ReactWebTabs.TabPanel>

                                        <ReactWebTabs.TabPanel tabId={BuilderSteps.FloorWallsSettings}>
                                            <FloorWalls/>
                                        </ReactWebTabs.TabPanel>

                                        <ReactWebTabs.TabPanel tabId={BuilderSteps.Furniture}>
                                            <AddFurniture/>
                                        </ReactWebTabs.TabPanel>
                                    </div>
                                    <ViewerSettingsFooter
                                        toggleCart={() => this.handleCartMobile()}
                                        cartIsVisible={showCart}
                                    />
                                </div>

                                <div className={cx(
                                    "settings-menu settings-menu--furniture-edit",
                                    {'settings-menu--furniture-edit--hidden': !showFurnitureEditBlock || hideMobileFurnitureSettings}
                                )}>
                                    {showFurnitureEditBlock && (
                                        <EditFurniture handleMobileClose={this.toggleFurnitureSettingsMobile}/>)}
                                </div>
                                <div className={cx(
                                    "settings-menu settings-menu--furniture-edit",
                                    {'settings-menu--furniture-edit--hidden': !showCustomObjectEditBlock || hideMobileCustomObjectSettings}
                                )}>
                                    {showCustomObjectEditBlock && (<EditCustomObject handleMobileClose={this.toggleCustomObjectSettingsMobile}/>)}
                                </div>

                            </div>
                        </div>
                        <div className="viewer-content-nav__wrap">
                          <div className="viewer__content viewer-content">
                              <ViewerSettingsToggle
                                  handleToggle={this.toggleSettings}
                                  className={cx(
                                      'viewer-settings-header__menu viewer-settings-header__menu--content',
                                      {'viewer-settings-header__menu--hidden': showSettings}
                                  )}/>
                              {/* <ContentMenu/> */}
                              <ViewerNavigationMobile handleToggle={this.toggleSettings}/>
                              <div className="viewer-content__builder">
                                  <Builder/>
                              </div>
                              
                              <MobileObjectActions
                                  toggleSettings={this.toggleSettings}
                                  toggleFurnitureSettings={this.toggleFurnitureSettingsMobile}
                                  toggleCustomObjectSettings={this.toggleCustomObjectSettingsMobile}
                              />
                          </div>
                          <div className="viewer__nav viewer-nav">
                              <ContentMenu toggleFooter={() => this.toggleCart()}/>
                          </div>
                          <ViewerFooterExtended
                              toggleFooter={() => this.toggleCart()}
                              expandFooter={showCart}
                          />
                        </div>
                    </ReactWebTabs.TabProvider>
                </div>
                <ErrorNotification/>
                {/*<Notification/>*/}
                <SafariNotification/>
      {/*<ModalSavePdf/>*/}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {fetchFloorStyles, setViewerIsReady, selectCustomObject, selectFurnitureItem}
)(Viewer);
