import SILVER_SRC from '../img/furniture-variants/silver.png'
import GOLD_SRC from '../img/furniture-variants/gold.png'
import BRASS_SRC from '../img/furniture-variants/brass.png'
import TRANSPARENT_SRC from '../img/furniture-variants/transparent.png'
import {FurnitureCustomObjectShape} from "../models/furnitureCatalog";

export const VARIANTS_COLORS = {
    white: { hex: '#fff' },
    red: { hex: '#cc092f' },
    multy: { hex: '#fff' },
    black: { hex: '#000' },
    green: { hex: '#00693e' },
    grey: { hex: '#747679' },
    gray: { hex: '#747679' },
    dark_grey: { hex: '#616161' },
    brown: { hex: '#4e3227' },
    orange: { hex: '#d95e17' },
    blue: { hex: '#032366' },
    beige: { hex: '#b6985e' },
    purple: { hex: '#663399' },
    yellow: { hex: '#ffc82e' },
    pink: { hex: '#e6a1ae' },
    olive: { hex: '#808000' },
    deep_blue: { hex: '#220878' },
    fuchsia: { hex: '#a50069' },
    transparent_2: { hex: 'transparent' },

    silver: { url: SILVER_SRC },
    gold: { url: GOLD_SRC },
    brass: { url: BRASS_SRC },
    transparent: { url: TRANSPARENT_SRC },
}

export const CUSTOM_OBJECT_MIN_WIDTH = 10;
export const CUSTOM_OBJECT_MIN_LENGTH = 10;
export const CUSTOM_OBJECT_MAX_WIDTH = 1000;
export const CUSTOM_OBJECT_MAX_LENGTH = 1000;
export const CUSTOM_OBJECT_DEFAULT_WIDTH = 100;
export const CUSTOM_OBJECT_DEFAULT_LENGTH = 100;
export const CUSTOM_OBJECT_DEFAULT_SHAPE = FurnitureCustomObjectShape.square;
