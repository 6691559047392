import {Builder} from "../../Builder";
import {FurnitureItemPosition} from "../../../../../models/builder";
import {Element, SVG} from "@svgdotjs/svg.js";
import {ItemMenu} from "../../ItemMenu/ItemMenu";
import React from "react";
import {ReactComponent as CopyIcon} from "../../../../../img/svg/new-tab.svg";
import {ReactComponent as DeleteIcon} from "../../../../../img/svg/delete.svg";
import {ReactComponent as RotateLeftIcon} from "../../../../../img/svg/rotate-left.svg";
import {ReactComponent as RotateRightIcon} from "../../../../../img/svg/rotate-right.svg";
import {ReactComponent as SendToBackIcon} from "../../../../../img/svg/send-to-back.svg";
import {ReactComponent as SendBackwardIcon} from "../../../../../img/svg/send-backward.svg";
import {ReactComponent as BringForwardIcon} from "../../../../../img/svg/bring-forward.svg";
import {ReactComponent as BringToFrontIcon} from "../../../../../img/svg/bring-to-front.svg";
import {FURNITURE_ITEM_ROTATE_ANGLE} from "../../../../../constants/furnitureBuilder";
import {FurnitureCustomObject, FurnitureCustomObjectShape} from "../../../../../models/furnitureCatalog";
import './BuilderCustomObject.scss';
import cx from 'classnames'
import {getBaseMeasureValue} from "../../../../../utils/convertMeasure";
import {
    checkFurnitureBoundaries,
    checkRomAreaLimitsX,
    checkRomAreaLimitsY,
    checkWindowBayLimits,
    getFurnitureCurrentHighestLevel,
    getFurnitureCurrentLowestLevel
} from "../../../../../utils/furniture";
import {RESPONSIVE_BREAKPOINTS} from "../../../../../constants/builderResponsive";
import {PROJECT_ERRORS} from "../../../../../constants/errorMessages";

export class BuilderCustomObject {
    protected builder: Builder;
    public itemData: FurnitureCustomObject;
    protected index: number;

    protected item: Element | null;
    protected view: Element | null;
    protected itemMenu: ItemMenu | null;

    constructor(builder: Builder, index: number, item: FurnitureCustomObject) {
        this.builder = builder;
        this.itemData = item;
        this.index = index;

        this.item = null;
        this.view = null;
        this.itemMenu = new ItemMenu(this.builder, this.getItemMenuId());

        this.draw();
        if (this.index === this.builder.props.selectedCustomObjectIndex) {
            this.selectItem()
        }
        this.handleDrag();
    }

    getItemId = () => (`builder-furniture-custom-${this.index}`);
    getViewId = () => (`builder-furniture-custom-${this.index}-view`);

    getItemMenuId = () => (`builder-furniture-custom-menu-${this.index}`);

    draw() {
        const {drawArea, furniture, roomContent} = this.builder;
        if (!drawArea || !furniture || !roomContent) {
            return;
        }
        this.remove();
        const {position, rotate, level, shape, width, length} = this.itemData;

        this.item = drawArea.group()
            .attr('id', this.getItemId())
            .css({cursor: "move"})
            .data("level", level)

        const elementAbove = Array.from(furniture.children()).find(
            (item) => item.data("level") > level
        );
        elementAbove ? elementAbove.before(this.item) : furniture.add(this.item);

        this.itemMenu = new ItemMenu(this.builder, this.getItemMenuId());

        const objectIsRound = shape === FurnitureCustomObjectShape.round

        const objectWidth = getBaseMeasureValue(this.builder.props.projectData.measure, width);
        const objectLength = getBaseMeasureValue(this.builder.props.projectData.measure, length as number);

        const responsiveBreakpoints = {
            downScale: {
                [FurnitureCustomObjectShape.square]: {
                    width: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.SQUARE.DOWNSCALE.WIDTH,
                    length: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.SQUARE.DOWNSCALE.LEN,
                },
                [FurnitureCustomObjectShape.round]: {
                    width: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.ROUND.DOWNSCALE.WIDTH,
                    length: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.ROUND.DOWNSCALE.LEN
                },
            },
            hideCaption: {
                [FurnitureCustomObjectShape.square]: {
                    width: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.SQUARE.CAPTION.WIDTH,
                    length: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.SQUARE.CAPTION.LEN
                },
                [FurnitureCustomObjectShape.round]: {
                    width: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.ROUND.CAPTION.WIDTH,
                    length: RESPONSIVE_BREAKPOINTS.CUSTOM_OBJECT.ROUND.CAPTION.LEN
                },
            }
        }

        const downScale = objectWidth < responsiveBreakpoints.downScale[shape].width
            || (objectLength && (objectLength < responsiveBreakpoints.downScale[shape].length));
        const hideCaption = objectWidth < responsiveBreakpoints.hideCaption[shape].width
            || (objectLength && (objectLength < responsiveBreakpoints.hideCaption[shape].length));

        this.view = drawArea
            .foreignObject(objectWidth, objectIsRound ? objectWidth : objectLength)
            .attr('id', this.getViewId())
            .add(
                SVG(`<div><div>custom object<br/>#${this.index + 1}</div></div>`)
                    .attr('class', cx(
                        'builder__custom-object',
                        {
                            'builder__custom-object--round': objectIsRound,
                            'builder__custom-object--downscaled': downScale,
                            'builder__custom-object--caption-hidden': hideCaption,
                        }
                    ))
            )

        if (!objectIsRound) {
            this.view.rotate(rotate)
        }

        this.item.add(this.view)

        this.item.center(position.x, position.y)
    }

    handleDrag = () => {
        const {roomContent} = this.builder;
        const item = this.item;

        if (!item || !roomContent) {
            return
        }

        item.draggable()
            .on('mouseover', () => {
            })
            .on('beforedrag', () => {
                this.handleBeforeDrag();
            })
            .on('dragmove', (event: any) => {
                event.preventDefault();
                const {handler, box} = event.detail;
                let {x, y} = box;

                const prevX = item.x() as number;
                const prevY = item.y() as number;

                if (!checkRomAreaLimitsX(this.builder, box)) {
                    x = prevX
                }
                if (!checkRomAreaLimitsY(this.builder, box)) {
                    y = prevY
                }

                const transformedObject = handler.el.findOne('foreignObject')
                const dragDiff = {
                    x: box.x - prevX,
                    y: box.y - prevY
                }
                if (!checkWindowBayLimits(this.builder, transformedObject, dragDiff)) {
                    x = prevX;
                    y = prevY;
                }

                handler.move(x, y);
                this.removeMenu();
            })
            .on('dragend', (e: any) => {
                const newPosition = {
                    x: (item.cx() as number),
                    y: (item.cy() as number)
                } as FurnitureItemPosition
                this.setItemPosition(newPosition);

                this.drawMenu();
            })
    }

    handleBeforeDrag() {
        if (!this.builder.isMobile) {
            this.builder.props.selectCustomObject(this.index);
        }
        this.builder.furnitureItems.forEach(item => item.handleMobileUnSelect());
        this.builder.customObjects.forEach(item => item.handleMobileUnSelect());
        this.handleMobileSelect();
    }

    setItemPosition(position: FurnitureItemPosition) {
        this.builder.props.setCustomObjectPosition(position, this.index)
    }

    remove() {
        this.builder.builderContent?.findOne(`#${this.getItemMenuId()}`)?.remove();
        this.builder.builderContent?.findOne(`#${this.getItemId()}`)?.remove();
    }

    drawMenu() {
        if (!this.item || !this.itemMenu) {
            return;
        }
        if (this.builder.isMobile) {
            this.builder.props.selectCustomObject(this.index);
            return;
        }

        const menuTopOptions = [
            {
                icon: <RotateLeftIcon/>,
                handler: () => this.rotateView(-FURNITURE_ITEM_ROTATE_ANGLE)
            },
            {
                icon: <RotateRightIcon/>,
                handler: () => this.rotateView(FURNITURE_ITEM_ROTATE_ANGLE)
            },
            {
                icon: <SendToBackIcon/>,
                handler: () => this.handleSendToBack()
            },
            {
                icon: <BringToFrontIcon/>,
                handler: () => this.handleBringToFront()
            },
        ]
        const menuBottomOptions = [
            {
                icon: <SendBackwardIcon/>,
                handler: () => this.handleSendBackward()
            },
            {
                icon: <BringForwardIcon/>,
                handler: () => this.handleBringForward()
            },
            {
                icon: <CopyIcon/>,
                handler: () => this.builder?.props.duplicateCustomObject(),
            },
            {
                icon: <DeleteIcon/>,
                handler: () => this.builder?.props.deleteCustomObject()
            }
        ]

        this.itemMenu.drawMenu(this.item, menuTopOptions, menuBottomOptions)
        const {scale, builderDefaults: {extra}} = this.builder;
        const {windowBayMarginX, windowBayMarginY} = this.builder.getWindowBayMargin();
        this.itemMenu.menu?.center(
            extra / 2 + (this.item.cx() as number)*scale + windowBayMarginX,
            extra / 2 + (this.item.cy() as number)*scale + windowBayMarginY
        )
    }

    removeMenu() {
        this.itemMenu?.removeMenu();
    }

    rotateView(rotate: number) {
        if (this.itemData.shape === FurnitureCustomObjectShape.square) {
            const view = this.builder.builderContent?.findOne(`#${this.getViewId()}`) as Element;
            view.rotate(rotate)
            if (!checkFurnitureBoundaries(this.builder, view, {x:0, y:0})){
                view.rotate(-rotate);
                this.builder?.props.raiseProjectError(PROJECT_ERRORS.DEFAULT.ROTATE_ITEM)
                return;
            }
        }
        this.builder?.props.rotateCustomObject(rotate)
    }

    selectItem() {
        this.drawMenu();
        this.handleMobileSelect();
    }

    unSelectItem() {
        this.removeMenu();
        this.handleMobileUnSelect();
    }


    handleMobileSelect() {
        if (this.builder.isMobile) {
            this.view?.css({border: '1px dashed #828B8B'})
        }
    }

    handleMobileUnSelect() {
        if (this.builder.isMobile) {
            this.view?.css({border: 'none'})
        }
    }

    handleSendBackward() {
        const {level} = this.itemData;
        this.setLevel(level - 1)
    }

    handleSendToBack() {
        this.setLevel(getFurnitureCurrentLowestLevel(this.builder.props.projectData) - 1)
    }

    handleBringForward() {
        const {level} = this.itemData;
        this.setLevel(level + 1)
    }

    handleBringToFront() {
        this.setLevel(getFurnitureCurrentHighestLevel(this.builder.props.projectData) + 1)
    }

    setLevel(level: number) {
        this.builder.props.setCustomObjectLevel(level)
    }
}
