import {AnyAction} from "@reduxjs/toolkit";
import {cloneDeep, findIndex, map} from "lodash";
import {BuilderSteps, BuilderStoreData, Measure, PositionOptions, ProjectData} from "../models/builder";
import {RoomShape} from "../models/room";
import {
    ADD_DOOR,
    ADD_WINDOW,
    CHANGE_MEASURE,
    CHANGE_ROOM_SHAPE,
    COLOR_ALL_WALLS,
    CREATE_PROJECT_SUCCESS,
    FETCH_FLOOR_STYLES_ERROR,
    FETCH_FLOOR_STYLES_START,
    FETCH_FLOOR_STYLES_SUCCESS,
    FETCH_PROJECT_ERROR,
    FETCH_PROJECT_START,
    FETCH_PROJECT_SUCCESS, FETCH_ROOM_TYPES_ERROR, FETCH_ROOM_TYPES_START, FETCH_ROOM_TYPES_SUCCESS,
    INIT_NEW_PROJECT_ERROR,
    INIT_NEW_PROJECT_START,
    INIT_NEW_PROJECT_SUCCESS,
    MIRROR_DOOR_OPEN,
    NAVIGATE_TO_PROJECT_STEP,
    PROJECT_ERROR_NOTIFY,
    REMOVE_DOOR,
    REMOVE_WINDOW,
    SAVE_PROJECT_CANCEL,
    SAVE_PROJECT_ERROR,
    SAVE_PROJECT_START,
    SAVE_PROJECT_SUCCESS,
    SELECT_DOOR,
    SELECT_WALL,
    SELECT_WINDOW, SELECT_WINDOW_BAY, SET_BUILDER_SCALE,
    SET_DOOR_INDENT,
    SET_DOOR_LENGTH,
    SET_DOOR_WALL,
    SET_DOOR_WIDTH,
    SET_FLOOR_STYLE,
    SET_PROJECT_NAME,
    SET_ROOM_LENGTH,
    SET_ROOM_TYPE,
    SET_ROOM_WIDTH,
    SET_WALL_COLOR,
    SET_WINDOW_BAY_INDENT,
    SET_WINDOW_BAY_LENGTH,
    SET_WINDOW_BAY_MIDDLE_WIDTH,
    SET_WINDOW_BAY_POSITION,
    SET_WINDOW_BAY_WIDTH,
    SET_WINDOW_HEIGHT,
    SET_WINDOW_INDENT,
    SET_WINDOW_LENGTH,
    SET_WINDOW_WALL,
    SET_WINDOW_WIDTH, UNSELECT_ROOM_OBJECTS,
    UPDATE_PROJECT_SUCCESS,
    VIEWER_DATA_IS_READY
} from "../actions/types";
import {
    DEFAULT_ROOM_SHAPE,
    ROOM_LENGTH_DEFAULT,
    ROOM_WIDTH_DEFAULT,
    WINDOW_BAY_LENGTH_DEFAULT,
    WINDOW_BAY_MIDDLE_WIDTH_DEFAULT,
} from "../constants/roomSettings";
import {convertMeasure} from "../utils/convertMeasure";
import {BASE_MEASURE} from "../constants/builderDefaults";
import {calculateWindowBayParams, defaultDoor, defaultWindow} from "../utils/builderRoomSettings";
import {FloorStyleType} from "../models/floorWalls";
import {FLOOR_COLOR_DEFAULT} from "../constants/floorWalls";
import {
    ADD_CUSTOM_OBJECT,
    ADD_FURNITURE_ITEM,
    ADD_ITEMS_TO_CART_ERROR,
    ADD_ITEMS_TO_CART_START,
    ADD_ITEMS_TO_CART_SUCCESS,
    CHANGE_FURNITURE_VARIANT,
    DELETE_CUSTOM_OBJECT,
    DELETE_FURNITURE_ITEM,
    DUPLICATE_CUSTOM_OBJECT,
    DUPLICATE_FURNITURE_ITEM,
    REPLACE_FURNITURE_ITEM, ROTATE_CUSTOM_OBJECT,
    ROTATE_FURNITURE_ITEM,
    SELECT_CUSTOM_OBJECT,
    SELECT_FURNITURE_ITEM,
    SET_CUSTOM_OBJECT_LENGTH,
    SET_CUSTOM_OBJECT_LEVEL,
    SET_CUSTOM_OBJECT_POSITION,
    SET_CUSTOM_OBJECT_SHAPE,
    SET_CUSTOM_OBJECT_WIDTH,
    SET_FURNITURE_ITEM_LEVEL,
    SET_FURNITURE_ITEM_POSITION
} from "../actions/types/furnitureBuilder";
import {
    FURNITURE_ITEM_DEFAULT_LEVEL,
    FURNITURE_ITEM_DEFAULT_ROTATE
} from "../constants/furnitureBuilder";
import {FurnitureCustomObjectShape} from "../models/furnitureCatalog";
import {getFurnitureCurrentHighestLevel, getRoomCenter} from "../utils/furniture";

const initialState = {
    projectData: {
        measure: Measure.cm,
        roomSettings: {
            shape: DEFAULT_ROOM_SHAPE,
            width: ROOM_WIDTH_DEFAULT,
            length: ROOM_LENGTH_DEFAULT,
            windowBayWidth: null,
            windowBayMiddleWidth: null,
            windowBayLength: null,
            windowBayPosition: null,
            windowBayIndent: null,
        },
        doors: [
            defaultDoor(Measure.cm)
        ],
        windows: [
            defaultWindow(Measure.cm)
        ],
        floorWallsStyle: {
            walls: [],
            floor: {
                type: FloorStyleType.color,
                fill: FLOOR_COLOR_DEFAULT,
            }
        },
        furnitureItems: [],
        furnitureCustomObjects: []
    } as ProjectData,

    currentProjectStep: BuilderSteps.RoomSettings,

    viewerDataIsReady: false,

    projectIsFetching: false,
    projectFetchError: null,
    projectFetchSuccess: false,

    projectInitInProgress: false,
    projectInitError: null,

    projectSavingInProgress: false,

    selectedDoorIndex: null,
    selectedWindowIndex: null,
    selectedWall: null,

    windowBaySelected: false,

    projectError: null,

    floorStyles: [],
    floorStylesFetchStarted: false,
    floorStylesFetchError: null,

    selectedFurnitureItemIndex: null,
    selectedCustomObjectIndex: null,

    addToCartProcessing: false,
    addToCartError: null,
    addToCartSuccess: false,

    builderScale: 0,

    roomTypes: null,
    roomTypesAreLoading: false

} as BuilderStoreData;

export default function builderReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case INIT_NEW_PROJECT_START: {
            return {
                ...initialState,
                projectInitInProgress: true,
                projectInitError: null
            };
        }
        case INIT_NEW_PROJECT_ERROR: {
            return {
                ...initialState,
                projectInitInProgress: false,
                projectInitError: action.error
            };
        }
        case INIT_NEW_PROJECT_SUCCESS: {
            return {
                ...initialState,
                projectInitInProgress: false,
                projectData: {
                    ...initialState.projectData,
                    roomType: action.roomType
                }
            };
        }
        case FETCH_PROJECT_START: {
            return {
                ...initialState,
                projectIsFetching: true
            };
        }
        case FETCH_PROJECT_SUCCESS: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    ...action.payload
                },
                projectIsFetching: false,
                projectFetchSuccess: true
            };
        }
        case FETCH_PROJECT_ERROR: {
            return {
                ...state,
                projectFetchError: action.error,
                projectIsFetching: false
            };
        }
        case VIEWER_DATA_IS_READY: {
            return {
                ...state,
                viewerDataIsReady: true
            };
        }
        case SAVE_PROJECT_START: {
            return {
                ...state,
                projectSavingInProgress: true
            };
        }
        case SAVE_PROJECT_CANCEL:
        case SAVE_PROJECT_SUCCESS:
        case SAVE_PROJECT_ERROR:
        case UPDATE_PROJECT_SUCCESS: {
            return {
                ...state,
                projectSavingInProgress: false
            };
        }
        case CREATE_PROJECT_SUCCESS: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    id: action.projectId
                },
                projectSavingInProgress: false
            };
        }
        case SET_PROJECT_NAME: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    name: action.projectName
                }
            };
        }
        case PROJECT_ERROR_NOTIFY: {
            return {
                ...state,
                projectError: action.error
            };
        }

        case SET_ROOM_TYPE: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomType: action.roomType
                }
            };
        }
        case NAVIGATE_TO_PROJECT_STEP: {
            return {
                ...state,
                currentProjectStep: action.step,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedWall: null,
                windowBaySelected: false,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null,
            };
        }

        case CHANGE_ROOM_SHAPE: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        shape: action.roomShape,
                        ...(action.roomShape === RoomShape.square && {
                            length: state.projectData.roomSettings.width
                        }),
                        ...(action.roomShape === RoomShape.windowBay
                            ? ({
                                windowBayPosition: action.windowBayParams.wall,
                                windowBayLength: convertMeasure(
                                    BASE_MEASURE, state.projectData.measure, WINDOW_BAY_LENGTH_DEFAULT
                                ),
                                windowBayWidth: action.windowBayParams.width,
                                windowBayMiddleWidth: convertMeasure(BASE_MEASURE, state.projectData.measure,
                                    Math.min(WINDOW_BAY_MIDDLE_WIDTH_DEFAULT, ROOM_LENGTH_DEFAULT)),
                                windowBayIndent: action.windowBayParams.indent,
                            })
                            : ({
                                windowBayPosition: null,
                                windowBayLength: null,
                                windowBayWidth: null,
                                windowBayMiddleWidth: null,
                                windowBayIndent: null,
                            }))
                    }
                }
            };
        }

        case CHANGE_MEASURE: {
            if (state.projectData.measure === action.measure) {
                return state;
            }
            const doors = cloneDeep(state.projectData.doors).map(door => ({
                ...door,
                width: convertMeasure(state.projectData.measure, action.measure, door.width),
                length: convertMeasure(state.projectData.measure, action.measure, door.length),
            }))
            const windows = cloneDeep(state.projectData.windows).map(window => ({
                ...window,
                width: convertMeasure(state.projectData.measure, action.measure, window.width),
                length: convertMeasure(state.projectData.measure, action.measure, window.length),
                height: convertMeasure(state.projectData.measure, action.measure, window.height),
            }))
            const furnitureCustomObjects = cloneDeep(state.projectData.furnitureCustomObjects).map(customObject => ({
                ...customObject,
                width: convertMeasure(state.projectData.measure, action.measure, customObject.width),
                ...(customObject.length && {length: convertMeasure(state.projectData.measure, action.measure, customObject.length)}),
            }))
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    measure: action.measure,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        width: convertMeasure(state.projectData.measure, action.measure, state.projectData.roomSettings.width),
                        length: convertMeasure(state.projectData.measure, action.measure, state.projectData.roomSettings.length),
                        ...(state.projectData.roomSettings.windowBayWidth && {
                            windowBayWidth: convertMeasure(state.projectData.measure, action.measure, state.projectData.roomSettings.windowBayWidth)
                        }),
                        ...(state.projectData.roomSettings.windowBayLength && {
                            windowBayLength: convertMeasure(state.projectData.measure, action.measure, state.projectData.roomSettings.windowBayLength)
                        }),
                        ...(state.projectData.roomSettings.windowBayMiddleWidth && {
                            windowBayMiddleWidth: convertMeasure(state.projectData.measure, action.measure, state.projectData.roomSettings.windowBayMiddleWidth)
                        }),
                    },
                    doors,
                    windows,
                    furnitureCustomObjects
                }
            };
        }
        case SET_ROOM_WIDTH: {
            const length = state.projectData.roomSettings.shape === RoomShape.square
                ? action.width
                : state.projectData.roomSettings.length;
            const bayParams = state.projectData.roomSettings.windowBayPosition
                ? calculateWindowBayParams({...state.projectData.roomSettings, length})
                : {}

            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        width: action.width,
                        length,
                        ...bayParams,
                        windowBayIndent: null,
                    }
                }
            };
        }
        case SET_ROOM_LENGTH: {
            const width = state.projectData.roomSettings.shape === RoomShape.square
                ? action.length
                : state.projectData.roomSettings.width
            const bayParams = state.projectData.roomSettings.windowBayPosition
                ? calculateWindowBayParams({...state.projectData.roomSettings, width})
                : {}

            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        length: action.length,
                        width,
                        ...bayParams,
                        windowBayIndent: null,
                    }
                }
            };
        }
        case SET_WINDOW_BAY_WIDTH: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        windowBayWidth: action.width,
                    }
                }
            };
        }
        case SET_WINDOW_BAY_MIDDLE_WIDTH: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        windowBayMiddleWidth: action.width,
                    }
                }
            };
        }
        case SET_WINDOW_BAY_LENGTH: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        windowBayLength: action.length,
                    }
                }
            };
        }

        case SET_WINDOW_BAY_POSITION: {
            const bayParams = calculateWindowBayParams(
                {...state.projectData.roomSettings, windowBayPosition: action.wall})
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        windowBayPosition: action.wall,
                        ...bayParams,
                        windowBayIndent: action.indent,
                    }
                }
            };
        }

        case SET_WINDOW_BAY_INDENT: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    roomSettings: {
                        ...state.projectData.roomSettings,
                        windowBayIndent: action.indent
                    }
                }
            };
        }


        case SELECT_WINDOW_BAY: {
            return {
                ...state,
                windowBaySelected: true,
                selectedWall: null,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null,
                currentProjectStep: BuilderSteps.RoomSettings
            }
        }

        case ADD_DOOR: {
            const newDoorIndex = state.projectData.doors.length;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors: [
                        ...state.projectData.doors,
                        action.door
                    ]
                },
                selectedDoorIndex: newDoorIndex
            }
        }
        case REMOVE_DOOR: {
            let doors = cloneDeep(state.projectData.doors);
            doors.splice(action.index, 1);
            let selectedDoorIndex = state.selectedDoorIndex || 0;
            if (selectedDoorIndex >= action.index) {
                selectedDoorIndex = (selectedDoorIndex - 1) >= 0 ? selectedDoorIndex - 1 : 0
            }
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                },
                selectedDoorIndex
            }
        }
        case SELECT_DOOR: {
            return {
                ...state,
                windowBaySelected: false,
                selectedDoorIndex: action.index,
                selectedWindowIndex: null,
                selectedWall: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null,
                currentProjectStep: BuilderSteps.RoomSettings
            }
        }
        case SET_DOOR_WIDTH: {
            let doors = cloneDeep(state.projectData.doors);
            const selectedDoorIndex = state.selectedDoorIndex || 0;
            doors[selectedDoorIndex].width = action.width;
            doors[selectedDoorIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                }
            }
        }
        case SET_DOOR_LENGTH: {
            let doors = cloneDeep(state.projectData.doors);
            const selectedDoorIndex = state.selectedDoorIndex || 0;
            doors[selectedDoorIndex].length = action.length;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                }
            }
        }
        case SET_DOOR_WALL: {
            let doors = cloneDeep(state.projectData.doors);
            const selectedDoorIndex = state.selectedDoorIndex || 0;
            doors[selectedDoorIndex].wall = action.wall;
            doors[selectedDoorIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                }
            }
        }
        case SET_DOOR_INDENT: {
            let doors = cloneDeep(state.projectData.doors);
            const selectedDoorIndex = state.selectedDoorIndex || 0;
            doors[selectedDoorIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                }
            }
        }
        case MIRROR_DOOR_OPEN: {
            let doors = cloneDeep(state.projectData.doors);
            const selectedDoorIndex = state.selectedDoorIndex || 0;
            doors[selectedDoorIndex].doorOpensOutside = !doors[selectedDoorIndex].doorOpensOutside;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    doors
                }
            }
        }

        case SET_WINDOW_INDENT: {
            let windows = cloneDeep(state.projectData.windows);
            const selectedWindowIndex = state.selectedWindowIndex || 0;
            windows[selectedWindowIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                }
            }
        }
        case SELECT_WINDOW: {
            return {
                ...state,
                windowBaySelected: false,
                selectedWindowIndex: action.index,
                selectedDoorIndex: null,
                selectedWall: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null,
                currentProjectStep: BuilderSteps.RoomSettings
            }
        }
        case ADD_WINDOW: {
            const newWindowIndex = state.projectData.windows.length;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows: [
                        ...state.projectData.windows,
                        action.window
                    ]
                },
                selectedWindowIndex: newWindowIndex
            }
        }
        case REMOVE_WINDOW: {
            let windows = cloneDeep(state.projectData.windows);
            windows.splice(action.index, 1);
            let selectedWindowIndex = state.selectedWindowIndex || 0;
            if (selectedWindowIndex >= action.index) {
                selectedWindowIndex = (selectedWindowIndex - 1) >= 0 ? selectedWindowIndex - 1 : 0
            }
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                },
                selectedWindowIndex
            }
        }
        case SET_WINDOW_WIDTH: {
            let windows = cloneDeep(state.projectData.windows);
            const selectedWindowIndex = state.selectedWindowIndex || 0;
            windows[selectedWindowIndex].width = action.width;
            windows[selectedWindowIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                }
            }
        }
        case SET_WINDOW_LENGTH: {
            let windows = cloneDeep(state.projectData.windows);
            const selectedWindowIndex = state.selectedWindowIndex || 0;
            windows[selectedWindowIndex].length = action.length;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                }
            }
        }
        case SET_WINDOW_HEIGHT: {
            let windows = cloneDeep(state.projectData.windows);
            const selectedWindowIndex = state.selectedWindowIndex || 0;
            windows[selectedWindowIndex].height = action.height;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                }
            }
        }
        case SET_WINDOW_WALL: {
            let windows = cloneDeep(state.projectData.windows);
            const selectedWindowIndex = state.selectedWindowIndex || 0;
            windows[selectedWindowIndex].wall = action.wall;
            windows[selectedWindowIndex].indent = action.indent;
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    windows
                }
            }
        }

        case SELECT_WALL: {
            return {
                ...state,
                windowBaySelected: false,
                selectedWall: action.wall,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null,
                currentProjectStep: BuilderSteps.FloorWallsSettings
            }
        }

        case SET_WALL_COLOR: {
            const walls = cloneDeep(state.projectData.floorWallsStyle.walls);
            const existWallDataIndex = findIndex(walls, {wall: action.wall})
            if (existWallDataIndex >= 0) {
                walls.splice(existWallDataIndex, 1)
            }
            walls.push({
                wall: action.wall,
                colorHEX: action.colorHEX
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    floorWallsStyle: {
                        ...state.projectData.floorWallsStyle,
                        walls
                    }
                }
            }
        }

        case COLOR_ALL_WALLS: {
            const walls = map(PositionOptions, wall => ({
                wall,
                colorHEX: action.colorHEX
            }));
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    floorWallsStyle: {
                        ...state.projectData.floorWallsStyle,
                        walls
                    }
                }
            }
        }

        case SET_FLOOR_STYLE: {
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    floorWallsStyle: {
                        ...state.projectData.floorWallsStyle,
                        floor: action.floorSettings
                    }
                }
            }
        }

        case FETCH_FLOOR_STYLES_START: {
            return {
                ...state,
                floorStylesFetchStarted: true
            };
        }
        case FETCH_FLOOR_STYLES_SUCCESS: {
            return {
                ...state,
                floorStyles: action.payload,
                floorStylesFetchStarted: false
            };
        }
        case FETCH_FLOOR_STYLES_ERROR: {
            return {
                ...state,
                floorStylesFetchError: action.error,
                floorStylesFetchStarted: false,
            };
        }

        case ADD_FURNITURE_ITEM: {
            const {product, variant} = action;
            const furnitureItems = [
                ...state.projectData.furnitureItems,
                {
                    product,
                    variant,
                    position: getRoomCenter(state.projectData.roomSettings),
                    rotate: FURNITURE_ITEM_DEFAULT_ROTATE,
                    level: getFurnitureCurrentHighestLevel(state.projectData)
                }
            ];
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                },
                selectedFurnitureItemIndex: furnitureItems.length - 1
            };
        }

        case SET_FURNITURE_ITEM_POSITION: {
            const {position, itemIndex} = action;
            const furnitureItems = state.projectData.furnitureItems.map((furnitureItem, index) => {
                if (index === itemIndex) {
                    return ({
                        ...furnitureItem,
                        position
                    })
                }
                return furnitureItem
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }
        case SELECT_FURNITURE_ITEM: {
            return {
                ...state,
                windowBaySelected: false,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedWall: null,
                selectedCustomObjectIndex: null,

                selectedFurnitureItemIndex: action.itemIndex
            };
        }
        case CHANGE_FURNITURE_VARIANT: {
            const {variant, itemIndex} = action;
            const furnitureItems = state.projectData.furnitureItems.map((furnitureItem, index) => {
                if (index === itemIndex) {
                    return ({
                        ...furnitureItem,
                        variant
                    })
                }
                return furnitureItem
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }
        case DELETE_FURNITURE_ITEM: {
            const {itemIndex} = action;
            const furnitureItems = state.projectData.furnitureItems.filter(
                (_, index) => index !== itemIndex);
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                },
                selectedFurnitureItemIndex: null
            };
        }
        case REPLACE_FURNITURE_ITEM: {
            const {selectedFurnitureItemIndex} = state;
            const {product, variant} = action;
            const furnitureItems = state.projectData.furnitureItems.map(
                (furnitureItem, index) => {
                    if (index === selectedFurnitureItemIndex) {
                        return ({
                            product,
                            variant,
                            position: getRoomCenter(state.projectData.roomSettings),
                            rotate: FURNITURE_ITEM_DEFAULT_ROTATE,
                            level: FURNITURE_ITEM_DEFAULT_LEVEL
                        })
                    }
                    return furnitureItem
                });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }
        case DUPLICATE_FURNITURE_ITEM: {
            const duplicateItem = state.projectData.furnitureItems.find(
                (_, index) => index === state.selectedFurnitureItemIndex
            );
            const furnitureItems = [
                ...state.projectData.furnitureItems,
                {
                    ...duplicateItem,
                    position: getRoomCenter(state.projectData.roomSettings),
                    //level: getFurnitureCurrentHighestLevel(state.projectData),
                    rotate: FURNITURE_ITEM_DEFAULT_ROTATE
                }
            ]
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }
        case ROTATE_FURNITURE_ITEM: {
            const {selectedFurnitureItemIndex} = state;
            const {rotate} = action;
            const furnitureItems = state.projectData.furnitureItems.map((furnitureItem, index) => {
                if (index === selectedFurnitureItemIndex) {
                    return ({
                        ...furnitureItem,
                        rotate: furnitureItem.rotate + rotate,
                    })
                }
                return furnitureItem
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }
        case SET_FURNITURE_ITEM_LEVEL: {
            const {selectedFurnitureItemIndex} = state;
            const {level} = action;
            const furnitureItems = state.projectData.furnitureItems.map((furnitureItem, index) => {
                if (index === selectedFurnitureItemIndex) {
                    return ({
                        ...furnitureItem,
                        level
                    })
                }
                return furnitureItem
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureItems
                }
            };
        }

        case ADD_CUSTOM_OBJECT: {
            const {
                shape,
                width,
                length
            } = action;

            const furnitureCustomObjects = [
                ...state.projectData.furnitureCustomObjects,
                {
                    shape,
                    width,
                    length,
                    position: getRoomCenter(state.projectData.roomSettings),
                    rotate: FURNITURE_ITEM_DEFAULT_ROTATE,
                    level: getFurnitureCurrentHighestLevel(state.projectData)
                }
            ];
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                },
                selectedCustomObjectIndex: furnitureCustomObjects.length - 1
            };
        }
        case SELECT_CUSTOM_OBJECT: {
            return {
                ...state,
                windowBaySelected: false,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedWall: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: action.itemIndex
            };
        }

        case SET_CUSTOM_OBJECT_POSITION: {
            const {position, itemIndex} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((customObject, index) => {
                if (index === itemIndex) {
                    return ({
                        ...customObject,
                        position
                    })
                }
                return customObject
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case DUPLICATE_CUSTOM_OBJECT: {
            const duplicateItem = state.projectData.furnitureCustomObjects.find(
                (_, index) => index === state.selectedCustomObjectIndex
            );
            const furnitureCustomObjects = [
                ...state.projectData.furnitureCustomObjects,
                {
                    ...duplicateItem,
                    position: getRoomCenter(state.projectData.roomSettings),
                    //level: getFurnitureCurrentHighestLevel(state.projectData),
                    rotate: FURNITURE_ITEM_DEFAULT_ROTATE
                }
            ]
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case DELETE_CUSTOM_OBJECT: {
            const {selectedCustomObjectIndex} = state;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.filter(
                (_, index) => index !== selectedCustomObjectIndex);
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                },
                selectedCustomObjectIndex: null
            };
        }
        case SET_CUSTOM_OBJECT_LEVEL: {
            const {selectedCustomObjectIndex} = state;
            const {level} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((customObject, index) => {
                if (index === selectedCustomObjectIndex) {
                    return ({
                        ...customObject,
                        level
                    })
                }
                return customObject
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case SET_CUSTOM_OBJECT_SHAPE: {
            const {selectedCustomObjectIndex} = state;
            const {shape} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((customObject, index) => {
                if (index === selectedCustomObjectIndex) {
                    if (shape === FurnitureCustomObjectShape.round) {
                        return ({
                            ...customObject,
                            shape,
                            length: customObject.width
                        })
                    }
                    return ({
                        ...customObject,
                        shape
                    })
                }
                return customObject
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case SET_CUSTOM_OBJECT_WIDTH: {
            const {selectedCustomObjectIndex} = state;
            const {width} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((customObject, index) => {
                if (index === selectedCustomObjectIndex) {
                    return ({
                        ...customObject,
                        width
                    })
                }
                return customObject
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case SET_CUSTOM_OBJECT_LENGTH: {
            const {selectedCustomObjectIndex} = state;
            const {length} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((customObject, index) => {
                if (index === selectedCustomObjectIndex) {
                    return ({
                        ...customObject,
                        length
                    })
                }
                return customObject
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }
        case ROTATE_CUSTOM_OBJECT: {
            const {selectedCustomObjectIndex} = state;
            const {rotate} = action;
            const furnitureCustomObjects = state.projectData.furnitureCustomObjects.map((furnitureItem, index) => {
                if (index === selectedCustomObjectIndex) {
                    return ({
                        ...furnitureItem,
                        rotate: furnitureItem.rotate + rotate,
                    })
                }
                return furnitureItem
            });
            return {
                ...state,
                projectData: {
                    ...state.projectData,
                    furnitureCustomObjects
                }
            };
        }

        case ADD_ITEMS_TO_CART_START:
            return {
                ...state,
                addToCartProcessing: true,
                addToCartError: null
            }
        case ADD_ITEMS_TO_CART_SUCCESS:
            return {
                ...state,
                addToCartProcessing: false
            }
        case ADD_ITEMS_TO_CART_ERROR:
            return {
                ...state,
                addToCartProcessing: false,
                addToCartError: action.error,
                projectError: action.error
            }

        case SET_BUILDER_SCALE:
            return {
                ...state,
                builderScale: action.scale
            }

        case FETCH_ROOM_TYPES_START:
            return {
                ...state,
                roomTypes: [],
                roomTypesAreLoading: true
            };

        case FETCH_ROOM_TYPES_SUCCESS:
            return {
                ...state,
                roomTypes: action.payload,
                roomTypesAreLoading: false
            };

        case FETCH_ROOM_TYPES_ERROR:
            return {
                ...state,
                roomTypesAreLoading: false
            };

        case UNSELECT_ROOM_OBJECTS:
            return {
                ...state,
                selectedDoorIndex: null,
                selectedWindowIndex: null,
                selectedFurnitureItemIndex: null,
                selectedCustomObjectIndex: null
            };

        default:
            return state;
    }
}
