import React from "react";
import cx from "classnames";

interface HeaderDropdownSectionProps {
    children?: React.ReactNode;
    name: string;
}

interface HeaderDropdownSectionState {
    showDropdown: boolean;
}

class HeaderDropdownSection extends React.Component<HeaderDropdownSectionProps, HeaderDropdownSectionState> {
    constructor(props:HeaderDropdownSectionProps) {
        super(props);

        this.state = {
            showDropdown: false
        };
    }

    setDropdownVisibility(showDropdown: boolean) {
        this.setState({showDropdown})
    }

    render() {
        const {showDropdown} = this.state;
        const {children, name} = this.props;

        return (
            <div className="header-menu__item header-menu__item--dropdown"
                 onMouseEnter={() => this.setDropdownVisibility(true)}
                 onMouseLeave={() => this.setDropdownVisibility(false)}
            >
                <button className={cx('header-menu__btn', {'header-menu__btn--active': showDropdown})}>
                    {name}
                </button>

                <div
                    className={cx('header-menu-drop__bg', {'header-menu-drop__bg--active': showDropdown})}
                    onMouseEnter={() => this.setDropdownVisibility(false)}
                />

                <div className={cx('header-menu-drop', {'header-menu-drop--active': showDropdown})}>
                    <div className="header-menu-drop__content">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderDropdownSection;
