import {FurnitureCustomObject, FurnitureProduct, FurnitureProductsVariant} from "../models/furnitureCatalog";
import {FURNITURE_ITEM_DEFAULT_LEVEL} from "../constants/furnitureBuilder";
import {FurnitureItem, FurnitureItemPosition, ProjectData} from "../models/builder";
import {RoomSettings} from "../models/room";
import {Box, Element} from "@svgdotjs/svg.js";
import {Builder} from "../components/builder/Builder/Builder";
import {checkIntersection} from "line-intersect";

export function getFurnitureProductVariant(product: FurnitureProduct, variantId: string): FurnitureProductsVariant | undefined {
    let productVariants = [] as FurnitureProductsVariant[];
    product.productOptions.forEach(option => {
        productVariants = [...productVariants, ...option.variants]
    });
    return productVariants.find(variant => variant.id === variantId);
}

export function getFurnitureCurrentHighestLevel(projectData: ProjectData):number {
    return Math.max(
        ...projectData.furnitureItems.map((furnitureItem:FurnitureItem) => furnitureItem.level),
        ...projectData.furnitureCustomObjects.map((customObject:FurnitureCustomObject) => customObject.level),
        FURNITURE_ITEM_DEFAULT_LEVEL
    )
}

export function getRoomCenter(roomSettings: RoomSettings):FurnitureItemPosition {
    return ({
        x: roomSettings.width / 2,
        y: roomSettings.length / 2,
    })
}

export function getFurnitureCurrentLowestLevel(projectData: ProjectData):number {
    return Math.min(
        ...projectData.furnitureItems.map((furnitureItem:FurnitureItem) => furnitureItem.level),
        ...projectData.furnitureCustomObjects.map((customObject:FurnitureCustomObject) => customObject.level),
        FURNITURE_ITEM_DEFAULT_LEVEL
    )
}

export function getTransformedCoordinates(
    transformedObject: Element, dragDiff: FurnitureItemPosition
): FurnitureItemPosition[] {
    let {width, height, cx, cy} = transformedObject.bbox()
    const matrix = transformedObject.ctm()
    const {a, b, c, d, e, f} = matrix;

    cx += dragDiff.x;
    cy += dragDiff.y;

    const originObjectCoordinates = [
        {
            x: cx - width/2,
            y: cy - height/2
        },
        {
            x: cx + width/2,
            y: cy - height/2
        },
        {
            x: cx + width/2,
            y: cy + height/2
        },
        {
            x: cx - width/2,
            y: cy + height/2
        },
    ]

    return originObjectCoordinates.map(coord => {
        const {x, y} = {
            x: a*coord.x + c*coord.y + e,
            y: b*coord.x + d*coord.y + f
        }
        return ({x, y})
    })
}

export function checkFurnitureBoundaries(builder: Builder, transformedObject: Element, dragDiff: FurnitureItemPosition): boolean {
    return (
        checkRomAreaLimitsX(builder, transformedObject.rbox(builder.roomContent as Element))
        && checkRomAreaLimitsY(builder, transformedObject.rbox(builder.roomContent as Element))
        && checkWindowBayLimits(builder, transformedObject, dragDiff)
    )
}

export function checkRomAreaLimitsX(builder: Builder, box: Box): boolean {
    const [limitX, , limitX2] = builder.getRoomAreaLimits();
    let {x, x2} = box;
    return (x > limitX && x2 < limitX2)
}

export function checkRomAreaLimitsY(builder: Builder, box: Box): boolean {
    const [, limitY, , limitY2] = builder.getRoomAreaLimits();
    let {y, y2} = box;
    return (y > limitY && y2 < limitY2)
}

export function checkWindowBayLimits(
    builder: Builder, transformedObject: Element, dragDiff: FurnitureItemPosition
): boolean {
    const {windowBay} = builder
    if (windowBay) {
        const bayCoordinates = windowBay.getWindowBayWallCoordinates();
        const transformedCoordinates = getTransformedCoordinates(transformedObject, dragDiff);

        return bayCoordinates
            .filter(bayWallCoordinates => {
                return transformedCoordinates
                    .filter((objectPoint, i) => {
                        const nextObjectPoint = transformedCoordinates[i+1] ?? transformedCoordinates[0]
                        const [bayWallX, bayWallY, bayWallX2, bayWallY2] = bayWallCoordinates;
                        const intersection = checkIntersection(
                            bayWallX, bayWallY, bayWallX2, bayWallY2,
                            objectPoint.x, objectPoint.y,nextObjectPoint.x, nextObjectPoint.y
                        )

                        return intersection.type === 'intersecting';

                    })
                    .length !== 0
            })
            .length === 0
    }
    return true
}
