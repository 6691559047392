import React, { Component } from 'react';
import { map, isArray, isEmpty } from 'lodash';
import './ErrorField.scss';

interface ErrorFieldProps {
    errors: string[] | string;
}

class ErrorField extends Component<ErrorFieldProps> {
    render() {
        let { errors } = this.props;

        if (!errors || isEmpty(errors)) {
            return null;
        }

        if (!isArray(errors)) {
            errors = [errors];
        }

        return (
            <div className='errorContainer'>
                {map(errors, (error, key) =>
                    <span
                        key={'error-' + key}
                        className='errorText'
                    >
                        {error}
                    </span>
                )}
            </div>
        );
    }
}

export default ErrorField;
