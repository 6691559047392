import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {fetchUser, logout} from "./actions/userActions";
import {getLocalAccessToken, setAccessToken, setAuthorizationHeader} from "./utils/accessToken";
import {connect} from "react-redux";
import UserProfile from "./components/ui/UserProfile/UserProfile";
import {AuthorizedUser} from "./models/auth";
import RequireAuth from "./components/ui/Auth/RequireAuth/RequireAuth";
import {get} from "lodash";
import NewProject from "./components/builder/NewProject/NewProject";
import SavedProject from "./components/builder/SavedProject/SavedProject";
/*import Pdf from "./components/layout/Pdf/Pdf"*/
import Error404 from "./components/layout/ErrorPages/Error404"
/*import Error403 from "./components/layout/ErrorPages/Error403"*/

interface AppProps extends RouteComponentProps {
    fetchUser: Function;
    user: AuthorizedUser;
    logout: Function;
}

interface AppState {
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.handleExternalAuth();
        this.initUser();
    }

    handleExternalAuth = () => {
        const {history, location} = this.props;
        let query = new URLSearchParams(location.search);
        const accessToken = query.get('accessToken');
        if (accessToken) {
            setAccessToken(accessToken);
            query.delete('accessToken')
            history.replace({pathname: location.pathname, search: "?" + query.toString()});
        }
    }

    initUser = () => {
        const localAccessToken = getLocalAccessToken();
        if (localAccessToken) {
            setAuthorizationHeader(localAccessToken);
            this.props.fetchUser();
        } else {
            this.props.logout();
        }
    }

    render() {
        return (
            <div className="app">
                <Switch>
                    <Route path="/profile">
                        <RequireAuth>
                            <UserProfile/>
                        </RequireAuth>
                    </Route>
                    <Route path={'/project/:id(\\d+)'}>
                        {(props: RouteComponentProps) => (
                            <RequireAuth>
                                <SavedProject id={get(props.match.params, 'id')}/>
                            </RequireAuth>
                        )}
                    </Route>
                    <Route path={'/project/:roomTypeSlug'}>
                        {(props: RouteComponentProps) => (
                            <NewProject roomTypeSlug={get(props.match.params, 'roomTypeSlug')}/>
                        )}
                    </Route>
                    <Error404/>
                    {/*<Route path={'/pdf'}>
                      <Pdf/>
                    </Route>
                    <Route path={'/404'}>
                      <Error404/>
                    </Route>
                    <Route path={'/403'}>
                      <Error403/>
                    </Route>*/}
                </Switch>
            </div>
        );
    }
}

export default withRouter(connect(
    (state: { user: AuthorizedUser }) => ({user: state.user}),
    {fetchUser, logout}
)(App));
