import React from 'react';
import '../ViewerSettings.scss';
import AddFurnitureOption from '../../SettingsOption/AddFurnitureOption/AddFurnitureOption';

export default class AddFurniture extends React.Component {

    render() {
        return (
            <AddFurnitureOption/>
        );
    }
}
