import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {
    FETCH_SEARCH_RESULTS_ERROR,
    FETCH_SEARCH_RESULTS_START,
    FETCH_SEARCH_RESULTS_SUCCESS,
    RESET_SEARCH_RESULTS,
    SET_SEARCH_QUERY
} from "./types/search";
import {loadSearchResults} from "../requests/searchRequests";

export function handleSearch(query: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: SET_SEARCH_QUERY, query})
        if (!query.length) {
            dispatch({type: RESET_SEARCH_RESULTS})
            return;
        }

        dispatch({type: FETCH_SEARCH_RESULTS_START})
        loadSearchResults(query)
            .then((res) => {
                dispatch({
                    type: FETCH_SEARCH_RESULTS_SUCCESS,
                    payload: res.data
                })
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_SEARCH_RESULTS_ERROR,
                });
            });
    }
}
