import React, {Component} from 'react';
import './FurnitureCatalogFilters.scss';
import FurnitureCatalogDropdown from "../FurnitureCatalogDropdown/FurnitureCatalogDropdown";
import InputRange, {Range} from "react-input-range";
import {CatalogFilters} from "../../../../models/furnitureCatalog";
import {differenceWith, fromPairs, isEmpty, isEqual, map, snakeCase, startCase, toPairs} from "lodash"
import {ReactComponent as Close} from "../../../../img/svg/close-bold.svg";

interface FurnitureCatalogFiltersProps {
    filters: CatalogFilters;
    appliedFilters: CatalogFilters;
    loadingFilters: boolean;
    applyFilters: (filters: CatalogFilters) => Promise<void>
}

interface ResetCatalogFilters {
    dimensions?: {
        length?: Range;
        width?: Range;
        height?: Range;
    }
    price?: Range;
    color?: string[];
    material?: string[];
}

export default class FurnitureCatalogFilters extends Component<FurnitureCatalogFiltersProps> {

    handleLength = (lengthRange: Range) => {
        this.props.applyFilters({
            ...this.props.appliedFilters,
            dimensions: {
                ...this.props.appliedFilters.dimensions,
                length: lengthRange
            }
        })
    }

    handleWidth = (widthRange: Range) => {
        this.props.applyFilters({
            ...this.props.appliedFilters,
            dimensions: {
                ...this.props.appliedFilters.dimensions,
                width: widthRange
            }
        })
    }

    handleHeight = (heightRange: Range) => {
        this.props.applyFilters({
            ...this.props.appliedFilters,
            dimensions: {
                ...this.props.appliedFilters.dimensions,
                height: heightRange
            }
        })
    }

    handlePrice = (priceRange: Range) => {
        this.props.applyFilters({...this.props.appliedFilters, price: priceRange})
    }

    handleColor = (color: string) => {
        const colorsApplied = Array.from(this.props.appliedFilters.color);
        const newColors = colorsApplied.includes(color)
            ? colorsApplied.filter(appliedColor => appliedColor !== color)
            : [...colorsApplied, color]
        this.props.applyFilters({...this.props.appliedFilters, color: newColors})
    }

    handleMaterial = (material: string) => {
        const materialsApplied = Array.from(this.props.appliedFilters.material);
        const newMaterials = materialsApplied.includes(material)
            ? materialsApplied.filter(appliedMaterial => appliedMaterial !== material)
            : [...materialsApplied, material]
        this.props.applyFilters({...this.props.appliedFilters, material: newMaterials})
    }

    defaultFilters(): CatalogFilters {
        return ({...this.props.filters, color: [], material: []});
    }

    availableToReset(): ResetCatalogFilters {
        const {appliedFilters} = this.props;
        const defaultFilters = this.defaultFilters();

        const objectDiff = (a: any, b: any) => fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));

        let resetFilters = objectDiff(appliedFilters, defaultFilters)
        if (resetFilters.dimensions) {
            resetFilters.dimensions = objectDiff(appliedFilters.dimensions, defaultFilters.dimensions)
        }

        return resetFilters
    }

    resetDimensionsFilter(dimension: string) {
        const {filters} = this.props;
        switch (dimension) {
            case 'length':
                this.handleLength(filters.dimensions.length);
                break;
            case 'width':
                this.handleWidth(filters.dimensions.width);
                break;
            case 'height':
                this.handleHeight(filters.dimensions.height);
                break;
        }
    }

    handleResetAll = () => {
        this.props.applyFilters({...this.defaultFilters()})
    }


    render() {
        const {filters, loadingFilters, appliedFilters} = this.props;
        if (loadingFilters) { // TODO remove loading?
            return null
        }

        const availableToReset = this.availableToReset();

        return (
            <div className="filters">
                <div className="filters__list">
                    <FurnitureCatalogDropdown title={`Dimensions`}>
                        <div className="slider-dropdown__container">
                            <div className="slider-dropdown__title">Length</div>
                            <div className="slider-dropdown__slider">
                                <InputRange
                                    maxValue={filters.dimensions.length.max}
                                    minValue={filters.dimensions.length.min}
                                    value={appliedFilters.dimensions.length}
                                    onChange={lenRange => this.handleLength(lenRange as Range)}
                                />
                            </div>
                            <div className="slider-dropdown__values">
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.length.min} cm
                                </div>
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.length.max} cm
                                </div>
                            </div>
                        </div>

                        <div className="slider-dropdown__container">
                            <div className="slider-dropdown__title">Width</div>
                            <div className="slider-dropdown__slider">
                                <InputRange
                                    maxValue={filters.dimensions.width.max}
                                    minValue={filters.dimensions.width.min}
                                    value={appliedFilters.dimensions.width}
                                    onChange={widthRange => this.handleWidth(widthRange as Range)}
                                />
                            </div>
                            <div className="slider-dropdown__values">
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.width.min} cm
                                </div>
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.width.max} cm
                                </div>
                            </div>
                        </div>

                        <div className="slider-dropdown__container">
                            <div className="slider-dropdown__title">Height</div>
                            <div className="slider-dropdown__slider">
                                <InputRange
                                    maxValue={filters.dimensions.height.max}
                                    minValue={filters.dimensions.height.min}
                                    value={appliedFilters.dimensions.height}
                                    onChange={heightRange => this.handleHeight(heightRange as Range)}
                                />
                            </div>
                            <div className="slider-dropdown__values">
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.height.min} cm
                                </div>
                                <div className="slider-dropdown__value">
                                    {appliedFilters.dimensions.height.max} cm
                                </div>
                            </div>
                        </div>

                    </FurnitureCatalogDropdown>

                    <FurnitureCatalogDropdown title={`Price`}>
                        <div className="slider-dropdown__title">Price</div>
                        <div className="slider-dropdown__slider">
                            <InputRange
                                maxValue={filters.price.max}
                                minValue={filters.price.min}
                                value={appliedFilters.price}
                                onChange={priceRange => this.handlePrice(priceRange as Range)}
                            />
                        </div>
                        <div className="slider-dropdown__values">
                            <div className="slider-dropdown__value">
                                £{appliedFilters.price.min}
                            </div>
                            <div className="slider-dropdown__value">
                                £{appliedFilters.price.max}
                            </div>
                        </div>

                    </FurnitureCatalogDropdown>

                    <FurnitureCatalogDropdown title={`Color`}>
                        <div className="checkbox-dropdown">
                            <div className="checkbox-dropdown__list">
                                {filters.color.map(color => (
                                    <div className="checkbox-dropdown__item" key={`color-${snakeCase(color)}`}>
                                        <div className="checkbox">
                                            <input
                                                className="checkbox__input"
                                                id={`color-${snakeCase(color)}`}
                                                type="checkbox"
                                                checked={appliedFilters.color.includes(color)}
                                                onChange={() => this.handleColor(color)}
                                            />
                                            <label className="checkbox__label" htmlFor={`color-${snakeCase(color)}`}>
                                                <div className="checkbox__item"/>
                                                <span className="checkbox__text">{color}</span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </FurnitureCatalogDropdown>

                    <FurnitureCatalogDropdown title={`Material`}>
                        <div className="checkbox-dropdown">
                            <div className="checkbox-dropdown__list">
                                {filters.material.map(material => (
                                    <div className="checkbox-dropdown__item" key={`material-${snakeCase(material)}`}>
                                        <div className="checkbox">
                                            <input
                                                className="checkbox__input"
                                                id={`material-${snakeCase(material)}`}
                                                type="checkbox"
                                                checked={appliedFilters.material.includes(material)}
                                                onChange={() => this.handleMaterial(material)}
                                            />
                                            <label className="checkbox__label"
                                                   htmlFor={`material-${snakeCase(material)}`}>
                                                <div className="checkbox__item"/>
                                                <span className="checkbox__text">{material}</span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </FurnitureCatalogDropdown>

                </div>

                {!isEmpty(availableToReset) && (
                    <div className="selected-filters">
                        <div className="selected-filters__list">
                            {map(availableToReset.dimensions, (value: Range, dimension) => (
                                <div
                                    key={`reset-dimension-${dimension}`}
                                    className="selected-filters__item"
                                     onClick={() => this.resetDimensionsFilter(dimension)}>
                                    {startCase(dimension)}: {value.min}cm - {value.max}cm
                                    <div className="selected-filters__delete">
                                        <Close/>
                                    </div>
                                </div>
                            ))}
                            {availableToReset.price && (
                                <div className="selected-filters__item" onClick={() => this.handlePrice(filters.price)}>
                                    £{availableToReset.price.min} - £{availableToReset.price.max}
                                    <div className="selected-filters__delete">
                                        <Close/>
                                    </div>
                                </div>
                            )}
                            {availableToReset.color?.map(color => (
                                <div
                                    key={`reset-color-${color}`}
                                    className="selected-filters__item" onClick={() => this.handleColor(color)}>
                                    {color}
                                    <div className="selected-filters__delete">
                                        <Close/>
                                    </div>
                                </div>
                            ))}
                            {availableToReset.material?.map(material => (
                                <div
                                    key={`reset-material-${material}`}
                                    className="selected-filters__item" onClick={() => this.handleMaterial(material)}>
                                    {material}
                                    <div className="selected-filters__delete">
                                        <Close/>
                                    </div>
                                </div>
                            ))}


                            <div className="selected-filters__clear" onClick={() => this.handleResetAll()}>
                                Clear all
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

