import {Builder} from "../../Builder";
import {
    BASE_MEASURE,
    BUILDER_AREA_BORDER_COLOR,
    BUILDER_MARKUP_COLOR_LIGHT,
    BUILDER_MARKUP_COLOR_LIGHT_DASH,
    MARKUP_BLOCK_HEIGHT,
    MARKUP_FONT_SIZE,
    MARKUP_LINE_MARGIN,
    MARKUP_LINE_MARGIN_MOBILE,
    MARKUP_LINE_WIDTH,
    MARKUP_OBJECT_BOUNDARY_HEIGHT,
    MARKUP_OBJECT_BOUNDARY_HEIGHT_MOBILE,
    MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT,
    MARKUP_OBJECT_BOUNDARY_ICON_WIDTH,
    MEASURES_DESC,
    OBJECT_MARKUP_BLOCK_HEIGHT_BOTTOM_MOBILE,
    OBJECT_MARKUP_BLOCK_HEIGHT_MOBILE
} from "../../../../../constants/builderDefaults";
import {Element, SVG} from "@svgdotjs/svg.js";
import {renderToString} from "react-dom/server";
import {ReactComponent as MarkupArrowLeft} from "../../../../../img/svg/markup-arrow-left.svg";
import {ReactComponent as MarkupArrowRight} from "../../../../../img/svg/markup-arrow-right.svg";
import {find} from "lodash";
import React from "react";
import {PositionOptions} from "../../../../../models/builder";
import {convertMeasure} from "../../../../../utils/convertMeasure";

interface MarkupSpaceParams {
    start: number;
    end: number;
}

export class PositionMarkup {
    protected builder: Builder;
    protected object: Element;
    protected wall: PositionOptions;

    protected markup: Element | null;

    protected spaceBefore: MarkupSpaceParams | null;
    protected spaceAfter: MarkupSpaceParams | null;
    protected guideLineLevel: number | null;

    protected markupBeforeLine: Element | null;
    protected markupAfterLine: Element | null;

    constructor(builder: Builder, object: Element, wall: PositionOptions) {
        this.builder = builder;
        this.object = object;
        this.wall = wall;
        this.markup = null;

        this.spaceBefore = null;
        this.guideLineLevel = null;
        this.spaceAfter = null;

        this.markupBeforeLine = null;
        this.markupAfterLine = null;

        this.drawMarkup();
    }

    drawMarkup() {
        if (!this.builder.drawArea || !this.builder.builderContent || !this.builder.roomMainArea) {
            return
        }
        this.initMarkupObject();

        this.calculateMarkupParams();

        this.drawGuideLines();

        this.drawObjectBoundaries();

        this.drawSizeCaptions()

    }

    removeMarkup() {
        this.builder.builderContent?.findOne(`[data-markup-object="${this.object.attr('id')}"]`)?.remove();
    }

    initMarkupObject() {
        if (!this.builder.drawArea || !this.builder.builderContent) {
            return
        }
        this.removeMarkup();
        this.markup = this.builder.drawArea.group()
            .data('markup-object', this.object.attr('id'))
            .addTo(this.builder.builderContent);
    }

    calculateMarkupParams() {
        if (!this.builder.drawArea || !this.builder.roomMainArea || !this.markup) {
            return;
        }
        const markupLineMargin = this.builder.isMobile ? MARKUP_LINE_MARGIN_MOBILE : MARKUP_LINE_MARGIN;

        switch (this.wall) {
            case PositionOptions.top:
                this.spaceBefore = {
                    start: this.builder.roomMainArea.x() as number,
                    end: this.object.x() as number,
                }
                this.spaceAfter = {
                    start: this.spaceBefore.end + (this.object.width() as number),
                    end: (this.builder.roomMainArea.x() as number) + (this.builder.roomMainArea.width() as number),
                }
                this.guideLineLevel = this.builder.roomMainArea.y() as number - markupLineMargin;
                break;
            case PositionOptions.bottom:
                this.spaceBefore = {
                    start: this.builder.roomMainArea.x() as number,
                    end: this.object.x() as number,
                }
                this.spaceAfter = {
                    start: this.spaceBefore.end + (this.object.width() as number),
                    end: (this.builder.roomMainArea.x() as number) + (this.builder.roomMainArea.width() as number),
                }
                this.guideLineLevel = (this.builder.roomMainArea.y() as number)
                    + (this.builder.roomMainArea.height() as number) + markupLineMargin;
                break;
            case PositionOptions.left:
                this.spaceBefore = {
                    start: (this.builder.roomMainArea.y() as number)
                        + (this.builder.roomMainArea.height() as number),
                    end: (this.object.y() as number) + (this.object.height() as number),
                }
                this.spaceAfter = {
                    start: this.object.y() as number,
                    end: this.builder.roomMainArea.y() as number,
                }
                this.guideLineLevel = (this.builder.roomMainArea.x() as number) - markupLineMargin;
                break;
            case PositionOptions.right:
                this.spaceBefore = {
                    start: this.builder.roomMainArea.y() as number,
                    end: this.object.y() as number,
                }
                this.spaceAfter = {
                    start: this.spaceBefore.end + (this.object.height() as number),
                    end: (this.builder.roomMainArea.y() as number) + (this.builder.roomMainArea.height() as number),
                }
                this.guideLineLevel = (this.builder.roomMainArea.x() as number) + (this.builder.roomMainArea.width() as number) + markupLineMargin;
                break;
        }
    }

    drawGuideLines() {
        if (!this.builder.drawArea || !this.markup
            || !this.spaceBefore || !this.spaceAfter || !this.guideLineLevel) {
            return
        }

        const verticalDirection = (this.wall === PositionOptions.left || this.wall === PositionOptions.right);

        const markupBeforeCoord = verticalDirection
            ? [this.guideLineLevel, this.spaceBefore.start, this.guideLineLevel, this.spaceBefore.end]
            : [this.spaceBefore.start, this.guideLineLevel, this.spaceBefore.end, this.guideLineLevel]
        const markupAfterCoord = verticalDirection
            ? [this.guideLineLevel, this.spaceAfter.start, this.guideLineLevel, this.spaceAfter.end]
            : [this.spaceAfter.start, this.guideLineLevel, this.spaceAfter.end, this.guideLineLevel]

        this.markupBeforeLine = this.builder.drawArea
            .line(markupBeforeCoord)
            .stroke({color: BUILDER_MARKUP_COLOR_LIGHT_DASH, width: MARKUP_LINE_WIDTH})
            .addTo(this.markup)

        this.markupAfterLine = this.builder.drawArea
            .line(markupAfterCoord)
            .stroke({color: BUILDER_MARKUP_COLOR_LIGHT_DASH, width: MARKUP_LINE_WIDTH})
            .addTo(this.markup)
    }

    drawObjectBoundaries() {
        if (!this.builder.drawArea || !this.markup || !this.builder.roomMainArea
            || !this.spaceBefore || !this.spaceAfter || !this.guideLineLevel) {
            return;
        }

        const boundaryLeft = this.builder.drawArea
            .line()
            .stroke({color: BUILDER_AREA_BORDER_COLOR, width: MARKUP_LINE_WIDTH})
            .addTo(this.markup)
        const boundaryRight = this.builder.drawArea
            .line()
            .stroke({color: BUILDER_AREA_BORDER_COLOR, width: MARKUP_LINE_WIDTH})
            .addTo(this.markup)

        const arrowLeft = this.builder.drawArea
            .foreignObject(MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT)
            .add(SVG(renderToString(
                <MarkupArrowLeft/>)).size(MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT).translate(0, 0))
            .css({display: 'flex'})
            .addTo(this.markup)
        const arrowRight = this.builder.drawArea
            .foreignObject(MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT)
            .add(SVG(renderToString(
                <MarkupArrowRight/>)).size(MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT).translate(0, 0))
            .css({display: 'flex'})
            .addTo(this.markup)

        const boundaryHeight = this.builder.isMobile
            ? MARKUP_OBJECT_BOUNDARY_HEIGHT_MOBILE
            : MARKUP_OBJECT_BOUNDARY_HEIGHT

        switch (this.wall) {
            case PositionOptions.top:
                const roomTop = (this.builder.roomMainArea.y() as number);
                boundaryLeft.plot(this.spaceBefore.end, roomTop - boundaryHeight, this.spaceBefore.end, roomTop)
                boundaryRight.plot(this.spaceAfter.start, roomTop - boundaryHeight, this.spaceAfter.start, roomTop)
                arrowLeft.translate(this.spaceBefore.end, this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2)
                arrowRight.translate(this.spaceAfter.start - MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2)
                break;
            case PositionOptions.bottom:
                const roomBottom = (this.builder.roomMainArea.y() as number) + (this.builder.roomMainArea.height() as number);
                boundaryLeft.plot(this.spaceBefore.end, roomBottom + boundaryHeight, this.spaceBefore.end, roomBottom)
                boundaryRight.plot(this.spaceAfter.start, roomBottom + boundaryHeight, this.spaceAfter.start, roomBottom)
                arrowLeft.translate(this.spaceBefore.end, this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2)
                arrowRight.translate(this.spaceAfter.start - MARKUP_OBJECT_BOUNDARY_ICON_WIDTH, this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2)
                break;
            case PositionOptions.left:
                const roomLeft = (this.builder.roomMainArea.x() as number);
                boundaryLeft.plot(roomLeft - boundaryHeight, this.spaceBefore.end, roomLeft, this.spaceBefore.end)
                boundaryRight.plot(roomLeft - boundaryHeight, this.spaceAfter.start, roomLeft, this.spaceAfter.start)
                arrowLeft
                    .translate(this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2, this.spaceBefore.end - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT)
                    .rotate(-90)
                arrowRight
                    .translate(this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2, this.spaceAfter.start)
                    .rotate(-90)
                break;
            case PositionOptions.right:
                const roomRight = (this.builder.roomMainArea.x() as number) + (this.builder.roomMainArea.width() as number)
                boundaryLeft.plot(roomRight + boundaryHeight, this.spaceBefore.end, roomRight, this.spaceBefore.end)
                boundaryRight.plot(roomRight + boundaryHeight, this.spaceAfter.start, roomRight, this.spaceAfter.start)
                arrowLeft
                    .translate(this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2, this.spaceBefore.end)
                    .rotate(90)
                arrowRight
                    .translate(this.guideLineLevel - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT / 2, this.spaceAfter.start - MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT)
                    .rotate(90)
                break;
            default:
                break;
        }
    }

    drawSizeCaptions() {
        if (!this.builder.drawArea || !this.builder.roomMainArea || !this.markup || !this.guideLineLevel
            || !this.spaceBefore || !this.spaceAfter
            || !this.markupBeforeLine || !this.markupAfterLine) {
            return;
        }
        const measure = this.builder.props.projectData.measure;
        const extraMargin = this.builder.builderDefaults.extra / 2;

        const spaceBeforeWidth = Math.round((Math.abs(this.spaceBefore.end - this.spaceBefore.start) - extraMargin) / this.builder.scale)
        const spaceAfterWidth = Math.round((Math.abs(this.spaceAfter.end - this.spaceAfter.start) - extraMargin) / this.builder.scale)

        const textBefore = this.builder.drawArea
            .text((add) => {
                add.tspan(`${convertMeasure(BASE_MEASURE, measure, spaceBeforeWidth)} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .font({size: MARKUP_FONT_SIZE, anchor: 'middle'})
                    .fill(BUILDER_MARKUP_COLOR_LIGHT)
            })
            .addTo(this.markup)
        const textAfter = this.builder.drawArea
            .text((add) => {
                add.tspan(`${convertMeasure(BASE_MEASURE, measure, spaceAfterWidth)} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .font({size: MARKUP_FONT_SIZE, anchor: 'middle'})
                    .fill(BUILDER_MARKUP_COLOR_LIGHT)
            })
            .addTo(this.markup)

        let markupBlockHeight: any;
        markupBlockHeight = this.builder.isMobile
            ? (this.wall === PositionOptions.bottom)
                ? OBJECT_MARKUP_BLOCK_HEIGHT_BOTTOM_MOBILE
                : OBJECT_MARKUP_BLOCK_HEIGHT_MOBILE
            : MARKUP_BLOCK_HEIGHT;

        switch (this.wall) {
            case PositionOptions.top:
                textBefore
                    .translate(0, this.guideLineLevel - markupBlockHeight)
                    .cx(this.markupBeforeLine.cx())
                textAfter
                    .translate(0, this.guideLineLevel - markupBlockHeight)
                    .cx(this.markupAfterLine.cx())
                break;
            case PositionOptions.bottom:
                textBefore
                    .translate(0, this.guideLineLevel + markupBlockHeight)
                    .cx(this.markupBeforeLine.cx())
                textAfter
                    .translate(0, this.guideLineLevel + markupBlockHeight)
                    .cx(this.markupAfterLine.cx())
                break;
            case PositionOptions.left:
                textBefore
                    .translate(this.guideLineLevel - markupBlockHeight, 0)
                    .cy(this.markupBeforeLine.cy())
                    .rotate(-90)
                textAfter
                    .translate(this.guideLineLevel - markupBlockHeight, 0)
                    .cy(this.markupAfterLine.cy())
                    .rotate(-90)
                break;
            case PositionOptions.right:
                textBefore
                    .translate(this.guideLineLevel + markupBlockHeight, 0)
                    .cy(this.markupBeforeLine.cy())
                    .rotate(90)
                textAfter
                    .translate(this.guideLineLevel + markupBlockHeight, 0)
                    .cy(this.markupAfterLine.cy())
                    .rotate(90)
                break;
            default:
                break;
        }
    }
}
