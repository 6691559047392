import {Builder} from "../Builder";
import {Element, Text, Tspan} from "@svgdotjs/svg.js";
import {ItemMenu, MenuOptionParams} from "../ItemMenu/ItemMenu";
import {
    BUILDER_AREA_ACCENT_COLOR,
    BUILDER_AREA_BORDER_WIDTH,
    WALL_OBJECT_COLOR,
    WALL_OBJECT_FONT_SIZE,
    WALL_OBJECT_HEIGHT,
    WALL_OBJECT_HEIGHT_MOBILE,
    WALL_OBJECT_LINE_WIDTH,
    WALL_OBJECT_LINE_WIDTH_MOBILE
} from "../../../../constants/builderDefaults";
import {upperCase} from "lodash";
import {PositionOptions, WallObject, WallObjectType} from "../../../../models/builder";
import {ReactComponent as PositionLeft} from "../../../../img/svg/position-left.svg";
import {ReactComponent as PositionBottom} from "../../../../img/svg/position-bottom.svg";
import {ReactComponent as PositionTop} from "../../../../img/svg/position-top.svg";
import {ReactComponent as PositionRight} from "../../../../img/svg/position-right.svg";
import React from "react";
import {PositionMarkup} from "./PositionMarkup/PositionMarkup";
import {getBaseMeasureValue} from "../../../../utils/convertMeasure";
import {checkAvailabilityToPlace} from "../../../../utils/builderRoomSettings";

export enum ObjectRefs {
    object = 'builder-object',
    caption = 'object-caption',
    menu = 'object-menu',
}

export class BuilderWallObject {
    protected builder: Builder;
    protected index: number;

    protected object: Element | null;
    protected objectData: WallObject;
    protected objectType: WallObjectType;

    protected objectMenu: ItemMenu | null;
    protected menuTopOptions: MenuOptionParams[];
    protected menuBottomOptions: MenuOptionParams[];

    protected objectLine: Element | null;
    protected objectView: Element | null;

    protected objectPositionMarkup: PositionMarkup | null;

    constructor(builder: Builder, objectGroupIndex: number, objectData: WallObject) {
        this.builder = builder;
        this.index = objectGroupIndex;
        this.objectData = objectData;
        this.objectType = objectData.type;

        this.object = null;
        this.objectMenu = null;
        this.menuTopOptions = [];
        this.menuBottomOptions = [];

        this.objectLine = null;
        this.objectView = null;

        this.objectPositionMarkup = null;

        this.init();
    }

    getObjectId = () => (`${ObjectRefs.object}-${this.objectType}-${this.index}`);

    getObjectMenuId = () => (`${ObjectRefs.menu}-${this.objectType}-${this.index}`)

    init = () => {
        if (!this.builder.drawArea || !this.builder.builderContent) {
            return;
        }

        this.removeObject();

        this.object = this.builder.drawArea.group()
            .attr('id', this.getObjectId())
            .addTo(this.builder.builderContent);

        this.objectMenu = new ItemMenu(this.builder, this.getObjectMenuId());
    }

    removeObject() {
        this.builder.builderContent?.findOne(`#${this.getObjectMenuId()}`)?.remove();
        this.builder.builderContent?.findOne(`#${this.getObjectId()}`)?.remove();
        this.objectPositionMarkup?.removeMarkup();
    }

    drawView() {
        const objectData = this.objectData;
        const object = this.object;
        if (!this.builder.drawArea || !objectData || !object) {
            return
        }

        const drawArea = this.builder.drawArea;

        const isMobile = this.builder.isMobile;

        this.objectView = drawArea.group();

        const objectWidth = getBaseMeasureValue(this.builder.props.projectData.measure, objectData.width);

        const objectLineWidth = isMobile? WALL_OBJECT_LINE_WIDTH_MOBILE : WALL_OBJECT_LINE_WIDTH;
        this.objectLine = drawArea
            .line(0, 0, objectWidth * this.builder.scale, 0)
            .stroke({color: WALL_OBJECT_COLOR, width: objectLineWidth})
        this.objectView.add(this.objectLine)

        const captionPosition = 0 - (isMobile? WALL_OBJECT_HEIGHT_MOBILE : WALL_OBJECT_HEIGHT) / 2
        this.objectView.add(drawArea
            .text((add: Tspan) => {
                add.tspan(`${upperCase(this.objectType)} #${this.index + 1}`)
                    .font({size: WALL_OBJECT_FONT_SIZE, anchor: 'middle'})
            })
            .data(ObjectRefs.caption, true)
            .center(this.objectView.width() as number / 2, captionPosition)
        )

        object.add(this.objectView);
    }

    position = () => {
        const objectData = this.objectData;
        const object = this.object;
        const objectView = this.objectView;

        if (!this.builder.drawArea || !this.builder.roomMainArea || !objectData || !object || !objectView) {
            return
        }

        const roomMainAreaWidth = this.builder.roomMainArea.width() as number;
        const roomMainAreaHeight = this.builder.roomMainArea.height() as number;
        const roomMainAreaX = this.builder.roomMainArea.x() as number;
        const roomMainAreaY = this.builder.roomMainArea.y() as number;
        const extraMargin = this.builder.builderDefaults.extra / 2;

        switch (objectData.wall) {
            case PositionOptions.top:
                object
                    .dx(roomMainAreaX + extraMargin + (objectData.indent * this.builder.scale))
                    .dy(roomMainAreaY + (WALL_OBJECT_LINE_WIDTH - BUILDER_AREA_BORDER_WIDTH) / 2)
                break;
            case PositionOptions.right:
                objectView.rotate(90, 0, 0);
                object
                    .dx(roomMainAreaX + roomMainAreaWidth - (WALL_OBJECT_LINE_WIDTH - BUILDER_AREA_BORDER_WIDTH) / 2)
                    .dy(roomMainAreaY + extraMargin + objectData.indent * this.builder.scale)
                break;
            case PositionOptions.bottom:
                objectView.rotate(180, 0, 0);
                object
                    .dx(roomMainAreaX + extraMargin + objectData.indent * this.builder.scale + objectData.width * this.builder.scale)
                    .dy(roomMainAreaY + roomMainAreaHeight - (WALL_OBJECT_LINE_WIDTH - BUILDER_AREA_BORDER_WIDTH) / 2);
                (object.findOne(`[data-${ObjectRefs.caption}]`) as Text).rotate(180)
                break;
            case PositionOptions.left:
                objectView.rotate(270, 0, 0);
                object
                    .dx(roomMainAreaX + (WALL_OBJECT_LINE_WIDTH - BUILDER_AREA_BORDER_WIDTH) / 2)
                    .dy(roomMainAreaY + extraMargin + objectData.indent * this.builder.scale + objectData.width * this.builder.scale)
                break;
        }
    }

    handleDrag = () => {
        const objectData = this.objectData;
        const object = this.object;

        if (!this.builder.drawArea || !this.builder.roomMainArea || !objectData || !object) {
            return
        }
        const wallIsVertical = objectData.wall === PositionOptions.left || objectData.wall === PositionOptions.right;

        const roomMainAreaWidth = this.builder.roomMainArea.width() as number;
        const roomMainAreaHeight = this.builder.roomMainArea.height() as number;
        const roomMainAreaX = this.builder.roomMainArea.x() as number;
        const roomMainAreaY = this.builder.roomMainArea.y() as number;
        const objectWidth = wallIsVertical ? object.height() as number : object.width() as number;
        const extraMargin = this.builder.builderDefaults.extra / 2;

        const dragLimits = wallIsVertical
            ? {
                start: roomMainAreaY + extraMargin,
                end: roomMainAreaY + roomMainAreaHeight - extraMargin - objectWidth,
                guide: object.x()
            }
            : {

                start: roomMainAreaX + extraMargin,
                end: roomMainAreaX + roomMainAreaWidth - extraMargin - objectWidth,
                guide: object.y()
            }

        object
            .draggable()
            .on('mouseover', () => {
                object.css({cursor: 'move'})
            })
            .on('beforedrag', () => {
                this.handleBeforeDrag();
            })
            .on('dragmove', (e: any) => {
                e.preventDefault();
                const {handler, box} = e.detail;
                let {x, y} = box;
                if (wallIsVertical) {
                    x = dragLimits.guide;
                    if (y < dragLimits.start) {
                        y = dragLimits.start
                    }
                    if (y > dragLimits.end) {
                        y = dragLimits.end
                    }
                } else {
                    y = dragLimits.guide;
                    if (x < dragLimits.start) {
                        x = dragLimits.start
                    }
                    if (x > dragLimits.end) {
                        x = dragLimits.end
                    }
                }
                handler.move(x, y);
                this.removeMenu();
                this.drawMarkup();
            })
            .on('dragend', (e: any) => {
                const {extra} = this.builder.builderDefaults
                const extraMargin = extra / 2;
                const windowBayTopMargin = this.builder.props.projectData.roomSettings.windowBayPosition === PositionOptions.top
                    ? (this.builder.roomMainArea?.y() as number)
                    : 0
                const windowBayLeftMargin = this.builder.props.projectData.roomSettings.windowBayPosition === PositionOptions.left
                    ? (this.builder.roomMainArea?.x() as number)
                    : 0
                const indent = Math.round(wallIsVertical
                    ? (((object.y() as number) - extraMargin - windowBayTopMargin) / this.builder.scale)
                    : ((object.x() as number) - extraMargin - windowBayLeftMargin) / this.builder.scale)
                if (indent !== objectData.indent) {
                    if (!checkAvailabilityToPlace(objectData, indent)) {
                        const previousIndent = extraMargin + objectData.indent * this.builder.scale;
                        const {handler} = e.detail;
                        wallIsVertical
                            ? handler.move(object.x(), previousIndent)
                            : handler.move(previousIndent, object.y())
                        this.drawMarkup();
                        this.builder.props.raiseProjectError(`Can't move object here`)
                    } else {
                        this.setObjectIndent(indent);
                    }
                }
                this.drawMenu();
            })
    }

    drawMenu() {
        if (!this.object || !this.objectMenu || this.builder.isMobile) {
            return;
        }
        const isVertical = this.objectData.wall === PositionOptions.left || this.objectData.wall === PositionOptions.right
        this.objectMenu.drawMenu(this.object, this.menuTopOptions, this.menuBottomOptions, isVertical)
    }

    setPositionMenuOptions(handleWallChange: (wall: PositionOptions) => void) {
        if (!this.objectData) {
            return;
        }
        this.menuTopOptions = [
            {
                icon: <PositionLeft/>,
                handler: () => handleWallChange(PositionOptions.left),
                active: this.objectData.wall === PositionOptions.left
            },
            {
                icon: <PositionBottom/>,
                handler: () => handleWallChange(PositionOptions.bottom),
                active: this.objectData.wall === PositionOptions.bottom
            },
            {
                icon: <PositionTop/>,
                handler: () => handleWallChange(PositionOptions.top),
                active: this.objectData.wall === PositionOptions.top
            },
            {
                icon: <PositionRight/>,
                handler: () => handleWallChange(PositionOptions.right),
                active: this.objectData.wall === PositionOptions.right
            }
        ]
    }

    removeMenu() {
        this.objectMenu?.removeMenu();
    }

    drawMarkup() {
        if (!this.object || !this.objectData) {
            return
        }
        this.objectPositionMarkup = new PositionMarkup(this.builder, this.object, this.objectData.wall);
    }

    removeMarkup() {
        this.objectPositionMarkup?.removeMarkup();
    }

    selectObject() {
        this.drawMarkup();
        this.drawMenu();
        this.objectLine?.stroke({color: BUILDER_AREA_ACCENT_COLOR})
    }

    unSelectObject() {
        this.removeMenu();
        this.removeMarkup();
        this.objectLine?.stroke({color: WALL_OBJECT_COLOR})
    }

    setObjectIndent(indent: number) {
    }

    handleBeforeDrag() {
    }

}
