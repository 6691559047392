import {AnyAction} from "@reduxjs/toolkit";
import {CartInfo} from "../models/cart";
import {
    GET_CART_ITEMS_COUNT_ERROR,
    GET_CART_ITEMS_COUNT_START,
    GET_CART_ITEMS_COUNT_SUCCESS, UPDATE_CART_ITEMS_COUNT
} from "../actions/types/cart";

const initialState = {
    itemsCount: 0,
    loadingItemsCount: false,
    itemsCountLoadError: null
} as CartInfo

export default function cartReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case GET_CART_ITEMS_COUNT_START:
            return {
                ...state,
                itemsCount: 0,
                loadingItemsCount: true,
                itemsCountLoadError: null
            }
        case GET_CART_ITEMS_COUNT_SUCCESS:
            return {
                ...state,
                itemsCount: action.count,
                loadingItemsCount: false
            }
        case GET_CART_ITEMS_COUNT_ERROR:
            return {
                ...state,
                loadingItemsCount: false,
                itemsCountLoadError: action.error
            }
        case UPDATE_CART_ITEMS_COUNT:
            return {
                ...state,
                itemsCount: action.count
            }

        default:
            return state;
    }
}
