import React, {Component} from 'react';
import {connect} from 'react-redux';
import FurnitureList from "../../FurnitureList/FurnitureList";
import {RootState} from "../../../../store";
import {FurnitureProduct} from "../../../../models/furnitureCatalog";
import {getRecentProducts} from "../../../../actions/furnitureCatalogActions";
import Cookies from "universal-cookie";
import {COOKIES_RECENT} from "../../../../constants/cookiesNames";

interface RecentFurnitureProps {
    furnitureProducts: FurnitureProduct[];
    getRecentProducts: (recentProductIds: string[]) => Promise<void>;
}

class RecentFurniture extends Component<RecentFurnitureProps> {
    componentDidMount() {
        const cookies = new Cookies();
        const recent = cookies.get(COOKIES_RECENT) || [];
        if (recent.length) {
            this.props.getRecentProducts(recent)
        }
    }

    render() {
        const {furnitureProducts} = this.props;
        return (
            <FurnitureList {...{furnitureProducts}}/>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        furnitureProducts: state.furnitureCatalog.recentProducts.products
    };
}


export default connect(
    mapStateToProps,
    {getRecentProducts}
)(RecentFurniture);
