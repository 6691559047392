import React, {Component, DragEvent} from 'react';
import {ReactComponent as FurnitureCustomImg} from "../../../../img/svg/furniture-custom.svg";
import {CustomObjectSettings} from "../FurnitureList";
import {
    DND_HANDLED_EVENT,
    DND_HANDLED_EVENT_DATA,
    DROP_FURNITURE_CUSTOM_OBJECT_EVENT
} from "../../../../constants/dragDropEvents";

interface FurnitureListCustomItemProps{
    appliedSettings?: CustomObjectSettings
}

class FurnitureListCustomItem extends Component<FurnitureListCustomItemProps> {
    dragItem = (e: DragEvent) => {
        const {appliedSettings} = this.props;
        if (!appliedSettings) {
            e.preventDefault();
            return;
        }
        e.dataTransfer.setData(DND_HANDLED_EVENT, DROP_FURNITURE_CUSTOM_OBJECT_EVENT)
        e.dataTransfer.setData(DND_HANDLED_EVENT_DATA, JSON.stringify(appliedSettings))

    };

    render() {
        return (
            <div className="furniture-list__item--custom">
                <div className="furniture-list__label">
                    <div className="furniture-list__img-wrap"
                         draggable="true"
                         onDragStart={this.dragItem}>
                        <FurnitureCustomImg/>
                    </div>
                    <div className="furniture-list__content">
                        <div className="furniture-list__title">Custom object</div>
                        <div className="furniture-list__description">
                            Add placeholder to fill space for some specific elements that yo’ure already have
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FurnitureListCustomItem;
