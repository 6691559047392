import React from 'react';
import './RoomSettingsWindowed.scss';
import RangeSlider from '../../../RangeSlider/RangeSlider';
import DetailsHeader from '../../../DetailsHeader/DetailsHeader';
/*import {ReactComponent as RotateLeft} from '../../../../../img/svg/rotate-left.svg';
import {ReactComponent as RotateRight} from '../../../../../img/svg/rotate-right.svg';
import {ReactComponent as MirrorHorizontal} from '../../../../../img/svg/mirror-horizontal.svg';
import {ReactComponent as MirrorVertical} from '../../../../../img/svg/mirror-vertical.svg';*/
import PositionOption from '../../../PositionOption/PositionOption';
import {convertMeasure} from "../../../../../utils/convertMeasure";
import {Measure, PositionOptions, WallObject} from "../../../../../models/builder";
import {
    ROOM_LENGTH_MAX,
    ROOM_LENGTH_MIN,
    ROOM_WIDTH_MAX,
    ROOM_WIDTH_MIN,
    WINDOW_BAY_LENGTH_DEFAULT,
    WINDOW_BAY_LENGTH_MIN,
    WINDOW_BAY_MIDDLE_WIDTH_DEFAULT,
    WINDOW_BAY_MIDDLE_WIDTH_MIN,
    WINDOW_BAY_WIDTH_DEFAULT,
    WINDOW_BAY_WIDTH_MIN
} from "../../../../../constants/roomSettings";
import {find} from "lodash";
import {MEASURES_DESC} from "../../../../../constants/builderDefaults";
import {RoomSettings as RoomSettingsType} from "../../../../../models/room";
import {AnyAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../../store";
import {connect} from "react-redux";
import {setRoomLength, setRoomWidth,} from "../../../../../actions/builderActions";
import {
    setWindowBayLength,
    setWindowBayMiddleWidth,
    setWindowBayPosition,
    setWindowBayWidth
} from "../../../../../actions/windowBayActions";
import {maxAvailableObjectSpace} from "../../../../../utils/builderRoomSettings";

interface RoomSettingsWindowedProps {
    roomSettings: RoomSettingsType;
    measure: Measure;
    setRoomWidth: (width: number) => AnyAction;
    setRoomLength: (length: number) => AnyAction;
    setWindowBayWidth: (width: number) => AnyAction;
    setWindowBayLength: (length: number) => AnyAction;
    setWindowBayMiddleWidth: (width: number) => AnyAction;
    setWindowBayPosition: (position: PositionOptions) => AnyAction;
}

function mapStateToProps(state: RootState) {
    return {
        roomSettings: state.builder.projectData.roomSettings,
        measure: state.builder.projectData.measure
    };
}

class RoomSettingsWindowed extends React.Component<RoomSettingsWindowedProps> {
    handleWidthChange = (value: number) => {
        this.props.setRoomWidth(value)
    }
    handleLengthChange = (value: number) => {
        this.props.setRoomLength(value)
    }
    handleWindowBayWidthChange = (value: number) => {
        this.props.setWindowBayWidth(value)
    }
    handleWindowBayLengthChange = (value: number) => {
        this.props.setWindowBayLength(value)
    }
    handleWindowBayMiddleWidthChange = (value: number) => {
        this.props.setWindowBayMiddleWidth(value)
    }

    render() {
        const {roomSettings, measure, setWindowBayPosition} = this.props;
        if (!roomSettings.windowBayWidth
            || !roomSettings.windowBayMiddleWidth
            || !roomSettings.windowBayLength
            || !roomSettings.windowBayPosition) {
            return null;
        }
        const bayIsVertical = (roomSettings.windowBayPosition === PositionOptions.top
            || roomSettings.windowBayPosition === PositionOptions.bottom);

        const windowBayObject = {
            width: roomSettings.windowBayWidth,
            wall: roomSettings.windowBayPosition,
            indent: roomSettings.windowBayIndent,
        } as WallObject;
        const maxWindowBayWidth = Math.min(
            convertMeasure(Measure.cm, measure, bayIsVertical ? roomSettings.width : roomSettings.length),
            maxAvailableObjectSpace(windowBayObject)
        );

        return (
            <div className="room-settings-windowed">
                <DetailsHeader titleText="Room settings"/>
                <div className="room-settings-windowed__range-list">
                    <div className="room-settings-windowed__range-item">
                        <RangeSlider
                            inputLabel={'Width'}
                            minValue={convertMeasure(Measure.cm, measure, ROOM_WIDTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, ROOM_WIDTH_MAX)}
                            value={roomSettings.width}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleWidthChange}/>
                    </div>
                    <div className="room-settings-windowed__range-item">
                        <RangeSlider
                            inputLabel={'Length'}
                            minValue={convertMeasure(Measure.cm, measure, ROOM_LENGTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, ROOM_LENGTH_MAX)}
                            value={roomSettings.length}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleLengthChange}
                        />
                    </div>
                </div>
                <div className="details-header">
                    <h2 className="details-header__title">Window bay settings</h2>
                </div>
                <div className="room-settings-windowed__range-list">
                    <div className="room-settings-windowed__range-item">
                        <RangeSlider
                            inputLabel={'bay Width'}
                            minValue={roomSettings.windowBayMiddleWidth
                                ? Math.max(
                                    convertMeasure(Measure.cm, measure, WINDOW_BAY_WIDTH_MIN),
                                    roomSettings.windowBayMiddleWidth)
                                : convertMeasure(Measure.cm, measure, WINDOW_BAY_WIDTH_MIN)
                            }
                            maxValue={maxWindowBayWidth}
                            value={roomSettings.windowBayWidth
                            || convertMeasure(Measure.cm, measure, WINDOW_BAY_WIDTH_DEFAULT)}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleWindowBayWidthChange}
                        />
                    </div>
                    <div className="room-settings-windowed__range-item">
                        <RangeSlider
                            inputLabel={'bay Length'}
                            minValue={convertMeasure(Measure.cm, measure, WINDOW_BAY_LENGTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure,
                                bayIsVertical ? roomSettings.length : roomSettings.width)}
                            value={roomSettings.windowBayLength
                            || convertMeasure(Measure.cm, measure, WINDOW_BAY_LENGTH_DEFAULT)}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleWindowBayLengthChange}
                        />
                    </div>
                </div>
                <div className="room-settings-windowed__range-list">
                    <div className="room-settings-windowed__range-item room-settings-windowed__range-item--single">
                        <RangeSlider
                            inputLabel={'front Width'}
                            minValue={convertMeasure(Measure.cm, measure, WINDOW_BAY_MIDDLE_WIDTH_MIN)}
                            maxValue={roomSettings.windowBayWidth
                            || convertMeasure(Measure.cm, measure,
                                bayIsVertical ? roomSettings.length : roomSettings.width)}
                            value={roomSettings.windowBayMiddleWidth
                            || convertMeasure(Measure.cm, measure, WINDOW_BAY_MIDDLE_WIDTH_DEFAULT)}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleWindowBayMiddleWidthChange}
                        />
                    </div>
                </div>
                <div className="room-settings-windowed__range-list">
                    <div className="room-settings-windowed__range-item">
                        <PositionOption
                            title={`Window bay Position`}
                            current={roomSettings.windowBayPosition || PositionOptions.right}
                            changePositionOption={value => {
                                setWindowBayPosition(value)
                            }}
                        />
                    </div>
                </div>
                {/*<div className="room-settings-windowed__btn-list">
                    <button className="room-settings-windowed__btn room-settings-windowed-btn">
            <span className="room-settings-windowed-btn__ico-wrap room-settings-windowed-btn__ico-wrap--rotate">
              <RotateLeft/>
            </span>
                        Rotate left
                    </button>
                    <button className="room-settings-windowed__btn room-settings-windowed-btn">
            <span className="room-settings-windowed-btn__ico-wrap room-settings-windowed-btn__ico-wrap--rotate">
              <RotateRight/>
            </span>
                        Rotate right
                    </button>
                </div>
                <div className="room-settings-windowed__btn-list">
                    <button className="room-settings-windowed__btn room-settings-windowed-btn">
            <span className="room-settings-windowed-btn__ico-wrap">
              <MirrorHorizontal/>
            </span>
                        Mirror Horizontally
                    </button>
                    <button className="room-settings-windowed__btn room-settings-windowed-btn">
            <span className="room-settings-windowed-btn__ico-wrap">
              <MirrorVertical/>
            </span>
                        Mirror Vertically
                    </button>
                </div>*/}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        setRoomWidth,
        setRoomLength,
        setWindowBayWidth,
        setWindowBayLength,
        setWindowBayMiddleWidth,
        setWindowBayPosition
    }
)(RoomSettingsWindowed);
