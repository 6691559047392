import React, {Component, SyntheticEvent} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from "react-router";
import {RootState} from "../../../store";
import {ReactComponent as BtnSave} from "../../../img/svg/btn-save.svg";
import ModalSave from "../../ui/Modal/ModalSave/ModalSave";
import ModalLogin from "../../ui/Modal/ModalLogin/ModalLogin";
import {ProjectData} from "../../../models/builder";
import {AuthorizedUser} from "../../../models/auth";
import {saveProjectCancel, saveProjectStart, updateProject} from "../../../actions/builderActions";
import {SuccessNotification} from "../../ui/Modal/SuccessNotification/SuccessNotification";

function mapStateToProps(state: RootState) {
    return {
        projectData: state.builder.projectData,
        projectSavingInProgress: state.builder.projectSavingInProgress,
        user: state.user
    };
}

interface SaveProjectDialogProps extends RouteComponentProps {
    projectData: ProjectData;
    user: AuthorizedUser;
    saveProjectStart: Function;
    saveProjectCancel: Function;
    updateProject: Function;
    projectSavingInProgress: boolean;
}

interface SaveProjectDialogState {
    showModalSave: boolean;
    showModalLogin: boolean;
    showSuccessNotification: boolean;
    redirectToProjectPage: boolean;
}

class SaveProjectDialog extends Component<SaveProjectDialogProps, SaveProjectDialogState> {
    constructor(props: SaveProjectDialogProps) {
        super(props);
        this.state = {
            showModalSave: false,
            showModalLogin: false,
            showSuccessNotification: false,
            redirectToProjectPage: false,
        }
    }

    handleSaveProject = (e: SyntheticEvent) => {
        e.preventDefault();
        const {projectData, user, saveProjectStart} = this.props;
        saveProjectStart();
        if (!user.isAuthenticated) {
            this.setState({showModalLogin: true});
            return;
        }
        if (!projectData.id) {
            this.setState({showModalSave: true});
        } else {
            this.props.updateProject()
                .then(() => this.handleProjectSaveSuccess())
                .catch((err: Error) => console.log(err));
        }
    }

    handleLoginClose = () => {
        this.setState({showModalLogin: false});
        this.props.saveProjectCancel();
    }

    handleLoginSuccess = () => {
        const {projectSavingInProgress} = this.props;
        this.setState({
            showModalLogin: false,
            showModalSave: projectSavingInProgress
        })
    }

    handleProjectSaveSuccess = () => {
        this.setState({showSuccessNotification: true})
    }

    handleCloseSuccessNotification = () => {
        this.setState({showSuccessNotification: false}, this.redirectToProjectPage)
    }

    handleProjectCreateSuccess = () => {
        this.setState({showModalSave: false, redirectToProjectPage: true});
        this.handleProjectSaveSuccess();
    }

    redirectToProjectPage() {
        if (this.state.redirectToProjectPage) {
            const {history, projectData} = this.props;
            history.push(`/project/${projectData.id}`);
        }
    }

    handleCloseSaveModal = () => {
        this.setState({showModalSave: false});
        this.props.saveProjectCancel();
    }

    render() {
        const {showModalSave, showModalLogin, showSuccessNotification} = this.state;
        const {user, projectSavingInProgress} = this.props;

        return (
            <>
                <button
                    className="viewer-navigation__btn viewer-navigation__btn--save"
                    onClick={this.handleSaveProject}
                    disabled={projectSavingInProgress}
                >
                    <BtnSave/>
                    <span className="viewer-navigation__text">
                      Save
                    </span>
                </button>
                <ModalSave
                    isModalOpen={showModalSave}
                    onModalClose={() => this.handleCloseSaveModal()}
                    onSaveSuccess={() => this.handleProjectCreateSuccess()}
                />
                {!user.isAuthenticated && (
                    <ModalLogin isModalOpen={showModalLogin}
                                closeModal={() => this.handleLoginClose()}
                                onLoginSuccess={() => this.handleLoginSuccess()}/>
                )}
                <SuccessNotification
                    message={'Your project was saved'}
                    buttonCaption={'Thanks'}
                    showNotificationWindow={showSuccessNotification}
                    handleClose={() => this.handleCloseSuccessNotification()}
                />
            </>
        );
    }
}

export default withRouter(connect(
    mapStateToProps, {saveProjectStart, saveProjectCancel, updateProject}
)(SaveProjectDialog));


