import React from 'react';
import '../Modal.scss';
import {ReactComponent as CloseIcon} from '../../../../img/svg/close.svg';
import {ReactComponent as RotateLeftIcon} from '../../../../img/svg/rotate_right_360.svg';
import {ReactComponent as RotateRightIcon} from '../../../../img/svg/rotate_left_360.svg';
import {ReactComponent as Rotation360MobileIcon} from '../../../../img/svg/rotation_360_mobile.svg';
import './View360Modal.scss';
import MarketplaceModalOverlay from "../MarketplaceModalOverlay/MarketplaceModalOverlay";
import {FurnitureProduct, MarketplaceImage} from "../../../../models/furnitureCatalog";
import InputRange from "react-input-range";
import {getFurnitureProductVariant} from "../../../../utils/furniture";
import {FurnitureDimensions} from "../../FurnitureList/FurnitureDimensions/FurnitureDimensions";
import FurnitureVariants from "../../FurnitureList/FurnitureVariants/FurnitureVariants";
import {isEqual} from "lodash";

interface View360ModalProps {
    isModalOpen: boolean;
    onModalClose: Function;
    product360images: MarketplaceImage[];
    product: FurnitureProduct;
    selectedVariant?: string;
    handleVariantChange: (variantId: string) => void;
}

interface View360ModalState {
    index: number;
    lastIndex: number;
    degreesValue: number;
    product360images: string[];
}

class View360Modal extends React.Component<View360ModalProps, View360ModalState> {
    constructor(props: View360ModalProps) {
        super(props);

        this.state = this.initState;
    }

    get360Images = () => {
        const { product360images } = this.props;
        return product360images.map(item => item.file.url).reverse()
    }

    initState = {
        index: 0,
        lastIndex: this.get360Images().length - 1,
        degreesValue: 0,
        product360images: this.get360Images()
    };


    handleButtonsRotate = (direction: string) => {
        const {index, lastIndex} = this.state;
        const rotateTo = (direction === 'left')
            ? index === lastIndex ? 0 : index + 1
            : index === 0 ? lastIndex : index - 1
        const degreesPerImage = 360 / lastIndex
        this.setState({index: rotateTo, degreesValue: degreesPerImage * rotateTo})
    }

    handleSliderRotate = (degreesValue: number) => {
        const imagesPerDegree = this.state.lastIndex / 360
        this.setState({index: Math.floor(degreesValue * imagesPerDegree), degreesValue})
    }

    handleVariantChange (variantId: string) {
        this.props.handleVariantChange(variantId);
    }

    componentDidUpdate(prevProps: Readonly<View360ModalProps>, prevState: Readonly<View360ModalState>, snapshot?: any) {
        if (!isEqual(prevProps.product360images, this.props.product360images)) {
            this.setState(this.initState)
        }
    }

    render() {
        const {isModalOpen, onModalClose, product, selectedVariant} = this.props;
        const {index, degreesValue, product360images} = this.state;
        const sliderClassNames = {
            activeTrack: `activeTrack`,
            disabledInputRange: `disabledInputRange`,
            inputRange: `inputRange`,
            labelContainer: `labelContainer`,
            maxLabel: `maxLabel`,
            minLabel: `minLabel`,
            slider: `slider`,
            sliderContainer: `sliderHandleContainer`,
            track: `track`,
            valueLabel: `valueLabelg`,
        }

        const selectedVariantData = getFurnitureProductVariant(product, selectedVariant as string);
        const productFullTitle = product.name + (selectedVariantData ? `, ${selectedVariantData.name}` : '');

        let excludeVariants: string[] = []
        product.productOptions.forEach(optionsGroup => {
            excludeVariants = [...excludeVariants,
            ...optionsGroup.variants
                .filter(
                    variant => variant.view360 && !variant.view360?.length
                )
                .map(variant => variant.id)
            ]
        });

        return (
            <MarketplaceModalOverlay isOpened={isModalOpen} className={`modal360__wrap`}>
                <div className={`modal360__header`}>
                    <div className={`modal360__header-info`}>
                        <span className={`modal360__itemId`}>Item # {selectedVariantData?.sku || product.sku}</span>
                        <span className={`modal360__itemName`}>{productFullTitle}</span>
                        <span
                            className={`modal360__itemDimensions`}><FurnitureDimensions {...product.attributes}/></span>
                    </div>
                    <div
                        className={`modal360__closeWrap`}
                        onClick={() => onModalClose()}
                    >
                        <CloseIcon/>
                    </div>
                </div>
                <div className={`modal360__box`}>
                    <div className={`modal360__rotateBox`}>
                        <div className={`rotateButtonBox`}>
                            <div className={`rotateButtonRight`} onClick={() => this.handleButtonsRotate('right')}>
                                <RotateRightIcon/>
                            </div>
                        </div>
                        <div className={`rotate`}>
                            <img src={product360images[index]} alt={productFullTitle+index}/>
                        </div>
                        <div className={`rotateButtonBox`}>
                            <div className={`rotateButtonLeft`} onClick={() => this.handleButtonsRotate('left')}>
                                <RotateLeftIcon/>
                            </div>
                        </div>
                    </div>
                    <div className={`modal360__sliderBox`}>
                        <div className={`sliderIco`}>
                            <Rotation360MobileIcon/>
                        </div>
                        <div className={`sliderWrap`}>
                            <InputRange
                                classNames={sliderClassNames}
                                minValue={0}
                                maxValue={360}
                                value={degreesValue}
                                onChange={value => this.handleSliderRotate(value as number)}
                            />
                            <div className={`sliderNumbers`}>
                                <span className={`sliderNumbersText`}>0</span>
                                <span className={`sliderNumbersText`}>90</span>
                                <span className={`sliderNumbersText`}>180</span>
                                <span className={`sliderNumbersText`}>270</span>
                                <span className={`sliderNumbersText`}>360</span>
                            </div>
                        </div>
                    </div>
                    <div className={`modal360__optionsBox`}>
                        <FurnitureVariants
                            product={product}
                            selectedVariant={selectedVariant}
                            handleVariantChange={variantId => this.handleVariantChange(variantId)}
                            pickerNamePreffix={'view360'}
                            excludeVariants={excludeVariants}
                            wrapClassname={'modal360__variants'}
                        />
                    </div>
                </div>
            </MarketplaceModalOverlay>
        );
    }
}

export default View360Modal;
