import React from 'react';
import './WindowsSettings.scss';
import RangeSlider from '../../RangeSlider/RangeSlider';
import PositionOption from '../../PositionOption/PositionOption';
import DetailsHeader from '../../DetailsHeader/DetailsHeader';
import {Measure, PositionOptions, WindowObject} from "../../../../models/builder";
import {find} from "lodash";
import {MEASURES_DESC} from "../../../../constants/builderDefaults";
import {convertMeasure} from "../../../../utils/convertMeasure";
import {
    WINDOW_HEIGHT_MAX,
    WINDOW_HEIGHT_MIN,
    WINDOW_LENGTH_MAX,
    WINDOW_LENGTH_MIN,
    WINDOW_WIDTH_MAX,
    WINDOW_WIDTH_MIN
} from "../../../../constants/doorsWindowsSettings";
import {RootState} from "../../../../store";
import {connect} from "react-redux";
import {setWindowLength, setWindowWall, setWindowWidth, setWindowHeight} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {maxAvailableObjectSpace} from "../../../../utils/builderRoomSettings";

interface WindowsSettingsProps {
    window: WindowObject;
    measure: Measure;
    setWindowWidth: (width: number) => AnyAction;
    setWindowLength: (length: number) => AnyAction;
    setWindowHeight: (height: number) => AnyAction;
    setWindowWall: (wall: PositionOptions) => AnyAction;
}

export class WindowsSettings extends React.Component<WindowsSettingsProps> {
    render() {
        const {window, measure, setWindowWidth, setWindowWall, setWindowLength, setWindowHeight} = this.props
        const maxWindowWidth = Math.min(
            convertMeasure(Measure.cm, measure, WINDOW_WIDTH_MAX),
            maxAvailableObjectSpace(window)
        )
        return (
            <div className="windows-settings">
                <DetailsHeader titleText="Setup Window"/>
                <div className="windows-settings__list">
                    <div className="windows-settings__item">
                        <RangeSlider
                            inputLabel={'Width'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            value={window.width}
                            minValue={convertMeasure(Measure.cm, measure, WINDOW_WIDTH_MIN)}
                            maxValue={maxWindowWidth}
                            onChange={size => setWindowWidth(size)}
                        />
                    </div>
                    <div className="windows-settings__item">
                        <RangeSlider
                            inputLabel={'Length'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            value={window.length}
                            minValue={convertMeasure(Measure.cm, measure, WINDOW_LENGTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, WINDOW_LENGTH_MAX)}
                            onChange={size => setWindowLength(size)}
                        />
                    </div>
                </div>
                <div className="windows-settings__list">
                    <div className="windows-settings__item">
                        <RangeSlider
                            inputLabel={'Height'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            value={window.height}
                            minValue={convertMeasure(Measure.cm, measure, WINDOW_HEIGHT_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, WINDOW_HEIGHT_MAX)}
                            onChange={size => setWindowHeight(size)}
                        />
                    </div>
                    <div className="windows-settings__item">
                        <PositionOption
                            title={`Window Position`}
                            current={window.wall}
                            changePositionOption={wall => setWindowWall(wall)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        measure: state.builder.projectData.measure
    };
}

export default connect(
    mapStateToProps,
    {setWindowWidth, setWindowWall, setWindowLength, setWindowHeight}
)(WindowsSettings);
