import {AnyAction} from "@reduxjs/toolkit";
import {WishlistInfo} from "../models/wishlist";
import {
    LOAD_WISHLIST_ERROR,
    LOAD_WISHLIST_START,
    LOAD_WISHLIST_SUCCESS,
    REMOVE_WISHLIST
} from "../actions/types/wishlist";

const initialState = {
    items: [],
    wishlistIsLoading: false,
    wishlistLoadError: null
} as WishlistInfo

export default function wishlistReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case LOAD_WISHLIST_START: {
            return {
                ...state,
                items: [],
                wishlistIsLoading: true,
                wishlistLoadError: null
            }
        }
        case LOAD_WISHLIST_SUCCESS: {
            return {
                ...state,
                items: action.items,
                wishlistIsLoading: false,
            }
        }
        case LOAD_WISHLIST_ERROR: {
            return {
                ...state,
                wishlistLoadError: action.error,
                wishlistIsLoading: false,
            }
        }
        case REMOVE_WISHLIST: {
            return {
                ...state,
                items: [],
            }
        }

        default:
            return state;
    }
}
