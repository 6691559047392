import {RoomShape} from "../models/room";

export const ROOM_WIDTH_DEFAULT = 500;
export const ROOM_LENGTH_DEFAULT = 400;
export const ROOM_WIDTH_MIN = 100;
export const ROOM_WIDTH_MAX = 3000;
export const ROOM_LENGTH_MIN = 100;
export const ROOM_LENGTH_MAX = 3000;
export const WINDOW_BAY_WIDTH_DEFAULT = 200;
export const WINDOW_BAY_WIDTH_MIN = 50;
export const WINDOW_BAY_MIDDLE_WIDTH_DEFAULT = 120;
export const WINDOW_BAY_MIDDLE_WIDTH_MIN = 50;
export const WINDOW_BAY_LENGTH_DEFAULT = 60;
export const WINDOW_BAY_LENGTH_MIN = 50;
export const DEFAULT_ROOM_SHAPE = RoomShape.rectangle
