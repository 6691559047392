import React, {Component} from 'react';
import {connect} from 'react-redux';
import Viewer from "../../layout/Viewer/Viewer";
import {initNewProject} from "../../../actions/builderActions";
import {AuthorizedUser} from "../../../models/auth";
import {RootState} from "../../../store";
import Error404 from "../../layout/ErrorPages/Error404";

interface NewProjectProps {
    roomTypeSlug: string;
    initNewProject: Function;
    user: AuthorizedUser;
    projectInitInProgress: boolean;
    projectInitError: string;
}

function mapStateToProps(state: RootState) {
    return {
        user: state.user,
        projectInitInProgress: state.builder.projectInitInProgress,
        projectInitError: state.builder.projectInitError,
    };
}

class NewProject extends Component<NewProjectProps> {
    componentDidMount() {
        const {initNewProject, roomTypeSlug} = this.props;
        initNewProject(roomTypeSlug);
    }

    render() {
        const {projectInitInProgress, projectInitError} = this.props;
        if (projectInitInProgress) {
            return null;
        }
        if (projectInitError) {
            return (<Error404/>)
        }

        return <Viewer/>
    }
}

export default connect(
    mapStateToProps,
    {initNewProject}
)(NewProject);
