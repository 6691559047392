import React from "react";
import {FurnitureProduct} from "../../../../models/furnitureCatalog";
import {get, lowerCase, snakeCase} from "lodash";
import {VARIANTS_COLORS} from "../../../../constants/furnitureCatalog";
import ColorPickerRadioSmall, {ColorPickerRadioSmallOption} from "../../ColorPickerRadio/ColorPickerRadioSmall/ColorPickerRadioSmall";

interface FurnitureVariantsProps {
    product: FurnitureProduct;
    selectedVariant?: string;
    handleVariantChange: (variantId: string) => void;
    wrapClassname?: string;
    pickerNamePreffix: string;
    excludeVariants?: string[];
}

class FurnitureVariants extends React.Component<FurnitureVariantsProps> {

    render() {
        const {product, selectedVariant, handleVariantChange, wrapClassname, pickerNamePreffix, excludeVariants} = this.props;

        if (!product.productOptions.length) {
            return null;
        }

        return (
            <div className={wrapClassname}>
                {product.productOptions.map(optionsGroup => {
                    let optionsPreviews = optionsGroup.variants.map(
                        variant => ({
                            id: variant.id,
                            previewImg: variant.materialSwatch?.file.url,
                            color: get(VARIANTS_COLORS, snakeCase(lowerCase(variant.optionName)))?.hex,
                            name: variant.optionName
                        })
                    ) as ColorPickerRadioSmallOption[];
                    if (excludeVariants) {
                        optionsPreviews = optionsPreviews.filter(variant => !excludeVariants.includes(variant.id))
                    }

                    return (
                        <div className="furniture-settings__item furniture-settings__list--variants"
                             key={`${product.id}-option-${optionsGroup.id}`}>
                            <p className="furniture-settings__title">{optionsGroup.name}</p>
                            <ColorPickerRadioSmall
                                options={optionsPreviews}
                                selected={selectedVariant}
                                handleOptionSelect={variantId => handleVariantChange(variantId)}
                                pickerName={`${pickerNamePreffix}-picker-option-${product.id}`}
                            />
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default FurnitureVariants;
