export const FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START = 'FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START';
export const FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS = 'FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS';
export const FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR = 'FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR';

export const FETCH_FURNITURE_CATEGORIES_ALL_START = 'FETCH_FURNITURE_CATEGORIES_ALL_START';
export const FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS = 'FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS';
export const FETCH_FURNITURE_CATEGORIES_ALL_ERROR = 'FETCH_FURNITURE_CATEGORIES_ALL_ERROR';

export const APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE = 'APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE';
export const APPLY_CATALOG_CATEGORY_ALL = 'APPLY_CATALOG_CATEGORY_ALL';

export const FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START = 'FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START';
export const FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS = 'FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS';
export const FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR = 'FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR';

export const FETCH_CATEGORY_CONTENT_ALL_START = 'FETCH_CATEGORY_CONTENT_ALL_START';
export const FETCH_CATEGORY_CONTENT_ALL_SUCCESS = 'FETCH_CATEGORY_CONTENT_ALL_SUCCESS';
export const FETCH_CATEGORY_CONTENT_ALL_ERROR = 'FETCH_CATEGORY_CONTENT_ALL_ERROR';

export const FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START = 'FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START';
export const FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS = 'FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS';
export const FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR = 'FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR';

export const FETCH_CATEGORY_PRODUCTS_ALL_START = 'FETCH_CATEGORY_PRODUCTS_ALL_START';
export const FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS = 'FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS';
export const FETCH_CATEGORY_PRODUCTS_ALL_ERROR = 'FETCH_CATEGORY_PRODUCTS_ALL_ERROR';

export const FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START = 'FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START';
export const FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS = 'FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS';
export const FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR = 'FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR';

export const FETCH_CATEGORY_FILTERS_ALL_START = 'FETCH_CATEGORY_FILTERS_ALL_START';
export const FETCH_CATEGORY_FILTERS_ALL_SUCCESS = 'FETCH_CATEGORY_FILTERS_ALL_SUCCESS';
export const FETCH_CATEGORY_FILTERS_ALL_ERROR = 'FETCH_CATEGORY_FILTERS_ALL_ERROR';

export const APPLY_CATALOG_FILTERS_BY_ROOM_TYPE = 'APPLY_CATALOG_FILTERS_BY_ROOM_TYPE';
export const APPLY_CATALOG_FILTERS_ALL = 'APPLY_CATALOG_FILTERS_ALL';

export const FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START = 'FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START';
export const FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS = 'FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS';
export const FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR = 'FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR';

export const FETCH_FILTERED_PRODUCTS_ALL_START = 'FETCH_FILTERED_PRODUCTS_ALL_START';
export const FETCH_FILTERED_PRODUCTS_ALL_SUCCESS = 'FETCH_FILTERED_PRODUCTS_ALL_SUCCESS';
export const FETCH_FILTERED_PRODUCTS_ALL_ERROR = 'FETCH_FILTERED_PRODUCTS_ALL_ERROR';

export const GET_SIMILAR_ITEMS_START = 'GET_SIMILAR_ITEMS_START';
export const GET_SIMILAR_ITEMS_SUCCESS = 'GET_SIMILAR_ITEMS_SUCCESS';
export const GET_SIMILAR_ITEMS_ERROR = 'GET_SIMILAR_ITEMS_ERROR';

export const GET_RECENT_ITEMS_START = 'GET_RECENT_ITEMS_START';
export const GET_RECENT_ITEMS_SUCCESS = 'GET_RECENT_ITEMS_SUCCESS';
export const GET_RECENT_ITEMS_ERROR = 'GET_RECENT_ITEMS_ERROR';

export const GET_ROOM_TYPE_START = 'GET_ROOM_TYPE_START';
export const GET_ROOM_TYPE_SUCCESS = 'GET_ROOM_TYPE_SUCCESS';
export const GET_ROOM_TYPE_ERROR = 'GET_ROOM_TYPE_ERROR';
