import React from 'react';
import './ViewerNavigationMobile.scss';
import {ReactComponent as NavigationArrowDown} from '../../../img/svg/navigation-arrow-down.svg';
import {BuilderSteps, builderStepsTitles} from "../../../models/builder";
import {connect} from "react-redux";
import {RootState} from "../../../store";

interface ViewerSettingsToggleProps {
    className?: string
    handleToggle: Function;
    currentProjectStep: BuilderSteps;
}

export function ViewerNavigationMobile({className, handleToggle, currentProjectStep}: ViewerSettingsToggleProps) {
    return (
        <div
            className="viewer-navigation-mobile"
            onClick={() => handleToggle()}>
            <h2 className="viewer-navigation-mobile__title">
                {builderStepsTitles[currentProjectStep]}
            </h2>
            <div className="viewer-navigation-mobile__ico">
                <NavigationArrowDown/>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        currentProjectStep: state.builder.currentProjectStep
    };
}

export default connect(
    mapStateToProps, {}
)(ViewerNavigationMobile);
