import {RoomType} from "./room";
import {Range} from "react-input-range";
import {FurnitureObject} from "./builder";

export interface FurnitureCatalog {
    roomType?: RoomType;
    loadingRoomType: boolean;

    roomTypeContent: CatalogTabContent;
    allProductsContent: CatalogTabContent;
    recentProducts: {
        products: FurnitureProduct[];
        loadingProducts: boolean;
    }

    loadingProductSimilarItems: boolean;
    similarItems: ProductSimilarItems[]
}

export interface CatalogTabContent {
    loadingCategories: boolean;
    furnitureCategories: null | FurnitureCategoryMain[];
    selectedCategory:  null | string;

    loadingCategoryContent: boolean;

    loadingFilters: boolean;
    catalogFiltersContent: null | CatalogFilters;
    catalogFiltersApplied: null | CatalogFilters;

    loadingProducts: boolean;
    productsList: FurnitureProduct[];
}

export interface CatalogFilters {
    dimensions: {
        length: Range;
        width: Range;
        height: Range;
    }
    price: Range;
    color: string[];
    material: string[];
}

export interface FurnitureCategory {
    id: string;
    name: string;
}

export interface FurnitureCategoryMain extends FurnitureCategory {
    secondaryCategories: FurnitureCategory[]
}

export interface FurnitureProduct {
    id: string;
    name: string;
    sku: string;
    slug: string;
    images: MarketplaceImage[];
    price: number;
    attributes: FurnitureProductAttributes;
    productOptions: FurnitureProductOptions[];
    topView?: MarketplaceImage;
    salePrice: number | null;
}

export interface MarketplaceImage {
    file: {
        id: string,
        date_uploaded: string;//"2019-05-17T13:18:28.976Z",
        length: number;
        md5: string,
        filename: string | null;
        content_type: string;
        metadata: string | null;
        url: string;
        width: number;
        height: number;
    };
    id: string;
}

export interface FurnitureProductAttributes {
    length: number;
    width: number;
    width_2: number;
    height: number;
    height2: number;
    view360?: MarketplaceImage[];
}

export interface FurnitureProductOptions {
    id: string;
    name: string;
    variants: FurnitureProductsVariant[];
}

export interface FurnitureProductsVariant {
    id: string;
    name: string;
    optionName: string;
    sku: string;
    images: MarketplaceImage[];
    materialSwatch: MarketplaceImage;
    topView?: MarketplaceImage;
    view360?: MarketplaceImage[];
    price: number;
    salePrice: number | null;
}

export interface FurnitureCustomObject extends FurnitureObject {
    width: number;
    length?: number;
    shape: FurnitureCustomObjectShape,
}

 export enum FurnitureCustomObjectShape {
    square = 'square',
    round = 'round'
}

export interface ProductSimilarItems {
    productId: string,
    items: FurnitureProduct[]
}
