import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../store";
import {RoomType} from "../../../models/room";
import {getRoomTypes} from "../../../actions/builderActions";
import './RoomTypeModal.scss'
import {ReactComponent as CloseIcon} from "../../../img/svg/close.svg";
import {ReactComponent as ArrowNextIcon} from "../../../img/svg/arrow-next.svg";
import {head} from 'lodash'
import cx from "classnames";

interface RoomTypeModalProps {
    roomTypes: RoomType[] | null;
    getRoomTypes: () => Promise<void>;
    onModalClose: Function;
}

interface RoomTypeModalState {
    selectedRoomTypeIndex: number;
}

function mapStateToProps(state: RootState) {
    return {
        roomTypes: state.builder.roomTypes
    };
}

class RoomTypeModal extends Component<RoomTypeModalProps, RoomTypeModalState> {
    constructor(props: RoomTypeModalProps) {
        super(props);
        this.state = {
            selectedRoomTypeIndex: 0
        }
    }
    componentDidMount() {
        if (!this.props.roomTypes) {
            this.props.getRoomTypes();
        }
    }

    handleRoomTypeSelect(selectedRoomTypeIndex: number) {
        if (selectedRoomTypeIndex !== this.state.selectedRoomTypeIndex) {
            this.setState({selectedRoomTypeIndex})
        }
    }

    handleRoomTypeSubmit() {
        this.props.onModalClose()
    }

    render() {
        const {roomTypes, onModalClose} = this.props;
        const {selectedRoomTypeIndex} = this.state;
        if (!roomTypes) {
            return null
        }
        return (
            <div className={`room-type-modal__wrap`}>
                <div className={"room-type-modal__header"}>
                    <div className={`room-type-modal__closeWrap`}>
                        <CloseIcon onClick={() => onModalClose()}/>
                    </div>
                    <h2>Be your own interior designer</h2>
                    <p>Try THE ROOM BUILDER to reimagine your home space with our furniture</p>
                </div>
                <div className={`room-type-modal__list`}>
                    {roomTypes.map((roomType, i) => {
                        const roomTypePreview = head(roomType.images)?.file.url
                        return (
                            <div
                                key={roomType.slug}
                                className={cx(
                                    'room-type-list__item',
                                    {'room-type-list__item--active': i === selectedRoomTypeIndex}
                                )}
                                onClick={() => this.handleRoomTypeSelect(i)}
                            >
                                    {roomTypePreview && (
                                        <div className="room-type-list__img-wrap">
                                          <img src={roomTypePreview} alt={roomType.name}/>
                                        </div>
                                      )
                                    }
                                    <p>{roomType.name}</p>
                            </div>
                            )
                        }
                    )}
                </div>
                <div className={`room-type-modal__action-desc`}>
                    <p className="room-type-modal__select-text"> Select your Room Type</p>
                </div>
                {roomTypes.length > 0 && (
                    <div className={'room-type-modal__footer'}>
                        <a
                            target={'_blank'} rel="noreferrer"
                            href={`/project/${roomTypes[selectedRoomTypeIndex].slug}`}
                            onClick={() => this.handleRoomTypeSubmit()}
                        >
                            Start
                            <span><ArrowNextIcon/></span>
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {getRoomTypes}
)(RoomTypeModal);
