import React, {Component, SyntheticEvent} from 'react';
import {connect} from 'react-redux';
import {find, isEqual} from 'lodash';
import '@svgdotjs/svg.draggable.js'
import {RootState} from "../../../store";
import './Builder.scss';
import {Element, Svg, SVG} from "@svgdotjs/svg.js";
import {
    BUILDER_AREA_BORDER_COLOR,
    BUILDER_AREA_BORDER_WIDTH,
    BUILDER_OUTER_SPACE, BUILDER_OUTER_SPACE_MOBILE,
    BUILDER_ROOM_WALLS_WIDTH, BUILDER_ROOM_WALLS_WIDTH_MOBILE,
    CONTAINER_DESKTOP_HEIGHT,
    CONTAINER_DESKTOP_WIDTH,
    MARKUP_BLOCK_HEIGHT, MARKUP_BLOCK_HEIGHT_MOBILE,
    MARKUP_FONT_SIZE,
    MARKUP_FONT_WEIGTH,
    MARKUP_VALUE_FONT_WEIGTH,
    MEASURES_DESC
} from "../../../constants/builderDefaults";
import {
    DoorObject,
    FurnitureItem,
    FurnitureItemPosition,
    PositionOptions,
    ProjectData,
    WallObject
} from "../../../models/builder";
import {getBaseMeasureValue} from "../../../utils/convertMeasure";
import {
    copyDoor,
    copyWindow,
    mirrorDoorOpen,
    raiseProjectError,
    removeDoor,
    removeWindow,
    selectDoor,
    selectWindow, setBuilderScale,
    setDoorIndent,
    setDoorWall,
    setWindowIndent,
    setWindowWall, unSelectRoomObjects
} from "../../../actions/builderActions";
import {setWindowBayIndent, selectWindowBay} from "../../../actions/windowBayActions";
import {AnyAction} from "@reduxjs/toolkit";
import {BuilderDoor} from "./WallObjects/Door/BuilderDoor";
import {BuilderWindow} from "./WallObjects/Window/BuilderWindow";
import {BuilderWalls} from "./Walls/BuilderWalls";
import {BuilderFloor} from "./Floor/BuilderFloor";
import {FloorStyle} from "../../../models/floorWalls";
import {selectWall} from "../../../actions/floorWallsStyleActions";
import {BuilderFurnitureItem} from "./Furniture/BuilderFurnitureItem";
import {
    addCustomObject,
    addFurnitureItem,
    deleteCustomObject,
    deleteFurnitureItem,
    duplicateCustomObject,
    duplicateFurnitureItem, rotateCustomObject,
    rotateFurnitureItem,
    selectCustomObject,
    selectFurnitureItem,
    setCustomObjectLevel,
    setCustomObjectPosition,
    setFurnitureItemLevel,
    setFurnitureItemPosition
} from "../../../actions/furnitureBuilderActions";
import {BuilderCustomObject} from "./Furniture/BuilderCustomObject/BuilderCustomObject";
import {FurnitureCustomObject, FurnitureCustomObjectShape, FurnitureProduct} from "../../../models/furnitureCatalog";
import {
    DND_HANDLED_EVENT,
    DND_HANDLED_EVENT_DATA,
    DROP_FURNITURE_CUSTOM_OBJECT_EVENT, DROP_FURNITURE_ITEM_EVENT
} from "../../../constants/dragDropEvents";
import {TABLET_MAX_WIDTH} from "../../../utils/responsive";
import {BuilderWindowBay} from "./WindowBay/BuilderWindowBay";

export interface BuilderProps {
    projectData: ProjectData
    converted: {
        roomSettings: {
            width: number,
            length: number,
            windowBayWidth: number,
            windowBayMiddleWidth: number,
            windowBayLength: number,
        }
    }

    raiseProjectError: (errorMessage: string) => AnyAction;

    setWindowBayIndent: (indent: number) => AnyAction;
    selectWindowBay: () => AnyAction;
    windowBaySelected: boolean;

    selectedDoorIndex: number | null;
    selectDoor: (index: number) => AnyAction;
    removeDoor: (index: number) => AnyAction;
    setDoorWall: (wall: PositionOptions) => AnyAction;
    mirrorDoorOpen: () => AnyAction;
    copyDoor: () => AnyAction;

    selectedWindowIndex: number | null;
    selectWindow: (index: number) => AnyAction;
    setDoorIndent: (index: number) => AnyAction;
    setWindowIndent: (index: number) => AnyAction;
    setWindowWall: (wall: PositionOptions) => AnyAction;
    copyWindow: () => AnyAction;
    removeWindow: (index: number) => AnyAction;

    floorStyles: FloorStyle[];
    selectWall: (wall: PositionOptions) => AnyAction;

    setFurnitureItemPosition: (position: FurnitureItemPosition, itemIndex: number) => AnyAction;
    selectFurnitureItem: (itemIndex: number) => AnyAction;
    deleteFurnitureItem: () => AnyAction;
    duplicateFurnitureItem: () => AnyAction;
    rotateFurnitureItem: (rotate: number) => AnyAction;
    setFurnitureItemLevel: (level: number) => AnyAction;
    selectedFurnitureItemIndex: number | null;

    selectedCustomObjectIndex: number | null;
    selectCustomObject: (itemIndex: number) => AnyAction;
    setCustomObjectPosition: (position: FurnitureItemPosition, itemIndex: number) => AnyAction;
    duplicateCustomObject: () => AnyAction;
    deleteCustomObject: () => AnyAction;
    setCustomObjectLevel: (level: number) => AnyAction;
    rotateCustomObject: (rotate: number) => AnyAction;

    catalogFurniture: FurnitureProduct[];
    addFurnitureItem: (item: FurnitureProduct, selectedVariant?: string) => AnyAction;
    addCustomObject: (shape: FurnitureCustomObjectShape, width: number, length?: number) => AnyAction;

    setBuilderScale: (scale: number) => AnyAction;
    unSelectRoomObjects: () => AnyAction;
}

function mapStateToProps(state: RootState) {
    const {measure, roomSettings} = state.builder.projectData
    return {
        projectData: state.builder.projectData,
        converted: {
            roomSettings: {
                width: getBaseMeasureValue(measure, roomSettings.width),
                length: getBaseMeasureValue(measure, roomSettings.length),
                windowBayWidth: getBaseMeasureValue(measure, roomSettings.windowBayWidth || 0),
                windowBayMiddleWidth: getBaseMeasureValue(measure, roomSettings.windowBayMiddleWidth || 0),
                windowBayLength: getBaseMeasureValue(measure, roomSettings.windowBayLength || 0),
            }
        },
        windowBaySelected: state.builder.windowBaySelected,
        selectedDoorIndex: state.builder.selectedDoorIndex,
        selectedWindowIndex: state.builder.selectedWindowIndex,
        floorStyles: state.builder.floorStyles,
        selectedFurnitureItemIndex: state.builder.selectedFurnitureItemIndex,
        selectedCustomObjectIndex: state.builder.selectedCustomObjectIndex,

        catalogFurniture: [
            ...state.furnitureCatalog.roomTypeContent.productsList,
            ...state.furnitureCatalog.allProductsContent.productsList
        ]
    }
}

enum ElementsRefs {
    furnitureGroup = 'builder__furniture',
    furnitureCustomGroup = 'builder__furniture-custom',
}

export class Builder extends Component<BuilderProps> {
    public container: React.RefObject<HTMLDivElement>;
    public drawArea: Svg | null;
    public builderContent: Element | null;
    public roomMainArea: Element | null;
    public room: Element | null;
    public roomContent: Element | null;
    public roomSizeMarkup: Element | null;
    public windowBay: BuilderWindowBay | null;

    public doors: BuilderDoor[];
    public windows: BuilderWindow[];

    public walls: BuilderWalls | null;
    public floor: BuilderFloor | null;

    public furniture: Element | null;
    public furnitureItems: BuilderFurnitureItem[];

    public furnitureCustom: Element | null;
    public customObjects: BuilderCustomObject[];

    public builderDefaults = {
        defaultContainerSize: {
            width: CONTAINER_DESKTOP_WIDTH,
            height: CONTAINER_DESKTOP_HEIGHT,
        },
        borderWidth: BUILDER_AREA_BORDER_WIDTH,
        wallsWidth: BUILDER_ROOM_WALLS_WIDTH,
        extra: BUILDER_AREA_BORDER_WIDTH * 2 + BUILDER_ROOM_WALLS_WIDTH * 2
    }
    public containerSize = {
        width: CONTAINER_DESKTOP_WIDTH,
        height: CONTAINER_DESKTOP_HEIGHT,
    }
    public roomAreaSize = {
        width: 0,
        height: 0,
    }
    public windowBayAreaSize = {
        length: 0,
        width: 0,
        middleWidth: 0,
    }
    public scale = 0;

    public isMobile = false;

    constructor(props: BuilderProps) {
        super(props);
        this.container = React.createRef();
        this.drawArea = null;
        this.builderContent = null;
        this.roomMainArea = null;
        this.room = null;
        this.roomContent = null;
        this.roomSizeMarkup = null;
        this.windowBay = null;
        this.doors = [];
        this.windows = [];
        this.walls = null;
        this.floor = null;

        this.furniture = null;
        this.furnitureItems = [];
        this.furnitureCustom = null;
        this.customObjects = [];
    }

    componentDidUpdate(prevProps: Readonly<BuilderProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (!isEqual(prevProps.converted, this.props.converted)
            || !isEqual(prevProps.projectData.roomSettings.shape, this.props.projectData.roomSettings.shape)
            || !isEqual(prevProps.projectData.roomSettings.windowBayPosition, this.props.projectData.roomSettings.windowBayPosition)
            || !isEqual(prevProps.projectData.roomSettings.windowBayIndent, this.props.projectData.roomSettings.windowBayIndent)
        ) {
            this.drawBuilder();
        } else {
            if (!isEqual(prevProps.projectData.measure, this.props.projectData.measure)) {
                this.drawRoomSizeMarkup();
                this.windowBay?.updateMarkup();
                this.windowBay?.drawPositionMarkup();
            }

            if (!isEqual(prevProps.projectData.doors, this.props.projectData.doors)) {
                this.drawDoors();
            }
            if (!isEqual(prevProps.projectData.windows, this.props.projectData.windows)) {
                this.drawWindows();
            }

            if (
                !isEqual(prevProps.selectedDoorIndex, this.props.selectedDoorIndex)
                || !isEqual(prevProps.selectedWindowIndex, this.props.selectedWindowIndex)
            ) {
                this.handleWallObjectsSelect();
            }

            if (!this.props.windowBaySelected && prevProps.windowBaySelected) {
                this.windowBay?.drawPositionMarkup();
            }

            if (!isEqual(prevProps.projectData.floorWallsStyle.walls, this.props.projectData.floorWallsStyle.walls)) {
                this.drawWalls();
                if (this.windowBay) {
                    this.windowBay?.colorWalls();
                }
                this.drawRoomSizeMarkup();
            }

            if (!isEqual(prevProps.projectData.floorWallsStyle.floor, this.props.projectData.floorWallsStyle.floor)) {
                this.drawFloor();
            }

            if (!isEqual(prevProps.projectData.furnitureItems, this.props.projectData.furnitureItems)) {
                this.drawFurnitureItems();
            }

            if (!isEqual(prevProps.projectData.furnitureCustomObjects, this.props.projectData.furnitureCustomObjects)) {
                this.drawCustomObjects();
            }
            if (!isEqual(prevProps.selectedFurnitureItemIndex, this.props.selectedFurnitureItemIndex)) {
                this.handleFurnitureItemSelect();
            }
            if (!isEqual(prevProps.selectedCustomObjectIndex, this.props.selectedCustomObjectIndex)) {
                this.handleCustomObjectSelect();
            }
        }

    }

    componentDidMount() {
        this.drawBuilder();
        window.addEventListener('resize', this.drawBuilder);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.drawBuilder);
    }

    drawBuilder = () => {
        if (this.drawArea) {
            this.drawArea.remove();
        }

        this.initDrawArea();

        this.calculateScale();
        this.centerBuilderContent();

        this.drawRoomLevel();
        this.drawWalls();
        this.drawWindowBay();

        this.initRoomContent();
        this.drawFloor();

        this.drawRoomSizeMarkup();

        this.drawDoors()
        this.drawWindows()

        this.drawFurnitureItems();
        this.drawCustomObjects();
    }

    initDrawArea = () => {
        const {defaultContainerSize} = this.builderDefaults;

        this.containerSize = {
            width: this.container.current?.clientWidth || defaultContainerSize.width,
            height: this.container.current?.clientHeight || defaultContainerSize.height
        };

        this.drawArea = SVG()
            .addTo(this.container.current as HTMLElement)
            .size(this.containerSize.width, this.containerSize.height)
            .move(0, 0)
            .on("drop", (e) => this.handleDrop(e as DragEvent));

        this.builderContent = this.drawArea.group().attr('id', 'builder-content');

        if (window.innerWidth <= TABLET_MAX_WIDTH) {
            this.isMobile = true;
            this.builderDefaults.wallsWidth = BUILDER_ROOM_WALLS_WIDTH_MOBILE;
            this.builderDefaults.extra = BUILDER_AREA_BORDER_WIDTH * 2 + BUILDER_ROOM_WALLS_WIDTH_MOBILE * 2;
        }
    }

    calculateScale = () => {
        const {roomSettings} = this.props.converted;
        const {windowBayPosition} = this.props.projectData.roomSettings;
        const {extra} = this.builderDefaults;

        const builderOuterSpace = this.isMobile ? BUILDER_OUTER_SPACE_MOBILE : BUILDER_OUTER_SPACE;

        let fullRoomWidth = roomSettings.width
        let fullRoomHeight = roomSettings.length
        let availableWidth = this.containerSize.width - extra - builderOuterSpace * 2
        let availableHeight = this.containerSize.height - extra - builderOuterSpace * 2
        if (windowBayPosition === PositionOptions.left || windowBayPosition === PositionOptions.right) {
            fullRoomWidth += roomSettings.windowBayLength
            availableWidth -= extra + MARKUP_BLOCK_HEIGHT
        }
        if (windowBayPosition === PositionOptions.top || windowBayPosition === PositionOptions.bottom) {
            fullRoomHeight = fullRoomHeight + roomSettings.windowBayLength - builderOuterSpace
            availableHeight -= extra + MARKUP_BLOCK_HEIGHT
        }

        this.scale = Math.min(
            availableWidth / fullRoomWidth,
            availableHeight / fullRoomHeight
        );

        this.props.setBuilderScale(this.scale);

        this.roomAreaSize = {
            width: Math.round(roomSettings.width * this.scale),
            height: Math.round(roomSettings.length * this.scale),
        }
    }

    centerBuilderContent = () => {
        if (!(this.drawArea && this.builderContent)) {
            return
        }
        const {roomSettings} = this.props.converted;
        const {extra, wallsWidth} = this.builderDefaults;

        const builderOuterSpace = this.isMobile ? BUILDER_OUTER_SPACE_MOBILE : BUILDER_OUTER_SPACE;

        let freeSpaceWidth = (this.drawArea.width() as number) - this.roomAreaSize.width - extra - builderOuterSpace * 2;
        const {windowBayPosition} = this.props.projectData.roomSettings;
        this.windowBayAreaSize = {
            length: Math.round(roomSettings.windowBayLength * this.scale),
            width: Math.round(roomSettings.windowBayWidth * this.scale),
            middleWidth: Math.round(roomSettings.windowBayMiddleWidth * this.scale),
        }
        if (windowBayPosition === PositionOptions.left || windowBayPosition === PositionOptions.right) {
            freeSpaceWidth -= this.windowBayAreaSize.length + wallsWidth
        }

        const topIndent = (windowBayPosition === PositionOptions.top)
            ? 0
            : builderOuterSpace

        const leftIndent = freeSpaceWidth / 2 + builderOuterSpace;
        this.builderContent.translate(leftIndent, topIndent);
    }

    drawRoomLevel = () => {
        if (!(this.drawArea && this.builderContent)) {
            return
        }
        // whole room (border level)
        const {extra} = this.builderDefaults;

        this.roomMainArea = this.drawArea.group().attr('id', 'builder-room-main-area')
            .attr('position', 'relative');
        this.builderContent.add(this.roomMainArea);

        this.room = this.drawArea
            .rect(this.roomAreaSize.width + extra, this.roomAreaSize.height + extra)
            .fill(BUILDER_AREA_BORDER_COLOR)
        this.roomMainArea.add(this.room);
    }

    drawWalls = () => {
        if (!this.walls) {
            this.walls = new BuilderWalls(this);
        }
        this.walls.draw();
    }

    drawWindowBay = () => {
        this.windowBay = new BuilderWindowBay(this);
    }

    getWindowBayMargin() {
        let windowBayMarginX = 0;
        let windowBayMarginY = 0;
        if (this.windowBay) {
            const {windowBayPosition} = this.props.projectData.roomSettings;
            const {builderDefaults: {extra}, scale} =  this;

            if (windowBayPosition === PositionOptions.left) {
                windowBayMarginX = this.windowBayAreaSize.length*scale + extra/2
            }
            if (windowBayPosition === PositionOptions.top) {
                windowBayMarginY = this.windowBayAreaSize.length*scale + extra/2  + MARKUP_BLOCK_HEIGHT * 2
            }
        }

        return ({windowBayMarginX, windowBayMarginY})
    }

    initRoomContent = () => {
        if (!(this.drawArea && this.roomMainArea)) {
            return;
        }
        const {extra} = this.builderDefaults;
        this.roomContent = this.drawArea.group()
            .attr('id', 'builder-room-content')
            .scale(this.scale)
            .translate(
                this.roomMainArea.x() as number + extra / 2,
                this.roomMainArea.y() as number + extra / 2)
        this.roomMainArea.add(this.roomContent)
    }

    drawFloor = () => {
        if (!this.floor) {
            this.floor = new BuilderFloor(this)
        }
        this.floor.draw();
        this.windowBay?.styleFloor();
    }

    removeRoomSizeMarkup = () => {
        if (this.roomSizeMarkup) {
            this.roomSizeMarkup.remove();
        }
    }

    drawRoomSizeMarkup = () => {
        if (!(this.drawArea && this.roomMainArea && this.room)) {
            return;
        }
        const {roomSettings, measure} = this.props.projectData;
        const {extra} = this.builderDefaults;

        this.removeRoomSizeMarkup();
        this.roomSizeMarkup = this.drawArea.group()
            .attr('id', 'builder-room-size-markup')
            .addTo(this.roomMainArea)
            .translate(this.roomMainArea.x() as number, 0);

        const widthMarkup = this.drawArea.text(function (add) {
            add.tspan('Width ')
                .font({size: MARKUP_FONT_SIZE, weight: MARKUP_FONT_WEIGTH})
            add.tspan(`${roomSettings.width} ${find(MEASURES_DESC, {measure})?.shortName}`)
                .font({size: MARKUP_FONT_SIZE, weight: MARKUP_VALUE_FONT_WEIGTH})
        })
        this.roomSizeMarkup.add(widthMarkup);

        const lengthMarkup = this.drawArea.text(function (add) {
            add.tspan('Length ')
                .font({size: MARKUP_FONT_SIZE, weight: MARKUP_FONT_WEIGTH})
            add.tspan(`${roomSettings.length} ${find(MEASURES_DESC, {measure})?.shortName}`)
                .font({size: MARKUP_FONT_SIZE, weight: MARKUP_VALUE_FONT_WEIGTH})
        })
        this.roomSizeMarkup.add(lengthMarkup);

        const innerMarkupMargin = this.isMobile ? MARKUP_BLOCK_HEIGHT_MOBILE : MARKUP_BLOCK_HEIGHT

        const widthX = (this.room.width() as number) / 2;
        let widthY = (this.room.y() as number) + (this.room.height() as number) - extra/2 + innerMarkupMargin;
        widthMarkup.center(widthX, widthY)
        const {windowBayPosition} = this.props.projectData.roomSettings;
        if (windowBayPosition === PositionOptions.bottom) {
            widthMarkup.cy(extra/2 - innerMarkupMargin)
        }

        const lengthX = windowBayPosition === PositionOptions.left
            ? (this.room.width() as number) - (extra/2 - innerMarkupMargin)
            : extra/2 - innerMarkupMargin;
        const lengthY = (this.room.y() as number) +  (this.room.height() as number) / 2;
        const lengthRotate = windowBayPosition === PositionOptions.left ? 90 : 270;
        lengthMarkup.center(lengthX, lengthY).rotate(lengthRotate)
    }

    drawDoors = () => {
        if (this.doors.length) {
            this.doors.forEach(builderDoor => builderDoor.removeObject());
            this.doors = [];
        }

        this.props.projectData.doors.forEach((doorData: DoorObject, index: number) => {
            this.doors.push(new BuilderDoor(this, index, doorData))
        })
    }

    drawWindows = () => {
        if (this.windows.length) {
            this.windows.forEach(builderDoor => builderDoor.removeObject());
            this.windows = [];
        }

        this.props.projectData.windows.forEach((windowData: WallObject, index: number) => {
            this.windows.push(new BuilderWindow(this, index, windowData))
        })
    }

    drawFurnitureItems = () => {
        if (!this.drawArea || !this.builderContent) {
            return
        }
        if (this.furnitureItems.length) {
            this.furnitureItems.forEach(furnitureItem => furnitureItem.remove());
            this.furnitureItems = [];
        }

        if (!this.builderContent.findOne(`#${ElementsRefs.furnitureGroup}`)) {
            const {extra} = this.builderDefaults
            this.furniture = this.drawArea.group().attr('id', ElementsRefs.furnitureGroup)
                .scale(this.scale)
                .translate(
                    this.roomMainArea?.x() as number + extra / 2,
                    this.roomMainArea?.y() as number + extra / 2)
            this.builderContent.add(this.furniture)
        }

        this.props.projectData.furnitureItems.forEach((furnitureItem: FurnitureItem, index: number) => {
            this.furnitureItems.push(new BuilderFurnitureItem(this, index, furnitureItem))
        })
    }

    drawCustomObjects = () => {
        if (!this.drawArea || !this.roomContent) {
            return
        }
        if (this.customObjects.length) {
            this.customObjects.forEach(furnitureItem => furnitureItem.remove());
            this.customObjects = [];
        }

        if (!this.roomContent.findOne(`#${ElementsRefs.furnitureCustomGroup}`)) {
            this.furnitureCustom = this.drawArea.group().attr('id', ElementsRefs.furnitureCustomGroup)
            this.roomContent.add(this.furnitureCustom)
        }

        this.props.projectData.furnitureCustomObjects.forEach((furnitureCustomObject: FurnitureCustomObject, index: number) => {
            this.customObjects.push(new BuilderCustomObject(this, index, furnitureCustomObject))
        })
    }

    handleWallObjectsSelect = () => {
        this.doors.forEach((door, index) => {
            if (index === this.props.selectedDoorIndex) {
                door.selectObject();
            } else {
                door.unSelectObject();
            }
        });
        this.windows.forEach((window, index) => {
            if (index === this.props.selectedWindowIndex) {
                window.selectObject();
            } else {
                window.unSelectObject();
            }
        });
    }

    handleFurnitureItemSelect = () => {
        this.furnitureItems.forEach((furnitureItem, index) => {
            if (index === this.props.selectedFurnitureItemIndex) {
                furnitureItem.selectItem()
            } else {
                furnitureItem.unSelectItem()
            }
        })
    }

    handleCustomObjectSelect = () => {
        this.customObjects.forEach((customObject, index) => {
            if (index === this.props.selectedCustomObjectIndex) {
                customObject.selectItem()
            } else {
                customObject.unSelectItem()
            }
        })
    }

    allowDrop = (e: SyntheticEvent) => {
        e.preventDefault();
    };

    handleDrop = (e: DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer) {
            const event = e.dataTransfer.getData(DND_HANDLED_EVENT);
            const eventData = JSON.parse(e.dataTransfer.getData(DND_HANDLED_EVENT_DATA));
            switch (event) {
                case DROP_FURNITURE_CUSTOM_OBJECT_EVENT:
                    const {objectWidth, objectLength, objectShape} = eventData;
                    this.props.addCustomObject(objectShape, objectWidth, objectLength);
                    break;
                case DROP_FURNITURE_ITEM_EVENT:
                    const {productId, variantId} = eventData
                    const product = this.props.catalogFurniture.find(catalogProduct => catalogProduct.id === productId);
                    if (product) {
                        this.props.addFurnitureItem(product, variantId)
                    }
                    break;
                default:
                    break;
            }
        }
    }

    getRoomAreaLimits(): number[] {
        const floorView = this.floor?.floorView
        if (!floorView || !this.roomContent) {
            return [0,0,0,0]
        }

        const floorArea = floorView.rbox(this.roomContent);
        let {x: limitX, y: limitY, x2: limitX2, y2: limitY2} = floorArea;

        const {windowBay} = this
        const windowBayFloorArea = windowBay?.windowBayFloor?.rbox(this.roomContent);
        if (windowBayFloorArea) {
            limitX = Math.min(limitX, windowBayFloorArea.x);
            limitY = Math.min(limitY, windowBayFloorArea.y);
            limitX2 = Math.max(limitX2, windowBayFloorArea.x2);
            limitY2 = Math.max(limitY2, windowBayFloorArea.y2);
        }

        return [limitX, limitY, limitX2, limitY2];
    }

    render() {
        return (
            <div
                id={`container`}
                ref={this.container}
                onDragOver={e => this.allowDrop(e)}
            >

            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        raiseProjectError,
        setWindowBayIndent, selectWindowBay,
        selectDoor, setDoorWall, setDoorIndent, removeDoor, mirrorDoorOpen, copyDoor,
        selectWindow, setWindowIndent, setWindowWall, copyWindow, removeWindow,
        selectWall,
        setFurnitureItemPosition, selectFurnitureItem, deleteFurnitureItem, duplicateFurnitureItem, rotateFurnitureItem,
        setFurnitureItemLevel,
        selectCustomObject, setCustomObjectPosition, duplicateCustomObject, deleteCustomObject, setCustomObjectLevel,
        addFurnitureItem, addCustomObject, rotateCustomObject,
        setBuilderScale,
        unSelectRoomObjects
    }
)(Builder);
