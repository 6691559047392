import React, {Component} from "react";
import {connect} from "react-redux";
import {AuthorizedUser} from "../../../../models/auth";
import Error403 from "../../../layout/ErrorPages/Error403";

interface RequireAuthProps {
    children: React.ReactNode;
    user: AuthorizedUser;
}
class RequireAuth extends Component<RequireAuthProps> {
    render() {
        const {user, children} = this.props;
        if (user.isAuthenticated === null || user.userIsLoading) {
            return (<div>Loading...</div>)
        }
        if (!user.isAuthenticated) {
            return (<Error403/>)
        }
        return children
    };
}

export default connect((state: { user: AuthorizedUser }) => ({user: state.user}))(RequireAuth)
