import {Link} from 'react-router-dom';
import React, {Component} from "react";
import {trimStart} from "lodash"

interface MarketplaceLinkProps {
    path: string;
    className?: string;
}

class MarketplaceLink extends Component<MarketplaceLinkProps> {
    render() {
        const marketplaceUrl = process.env.REACT_APP_MARKETPLACE_URL;
        const {className, path} = this.props
        return (
            <Link className={className} to={{ pathname: marketplaceUrl + trimStart(path, '/') }} target="_blank">
                {this.props.children}
            </Link>
        );
    }
}

export default MarketplaceLink;
