import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./reducers/userReducer";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import builderReducer from "./reducers/builderReducer";
import furnitureCatalogReducer from "./reducers/furnitureCatalogReducer";
import cartReducer from "./reducers/cartReducer";
import wishlistReducer from "./reducers/wishlistReducer";
import marketplaceContentReducer from "./reducers/marketplaceContentReducer";
import searchReducer from "./reducers/searchReducer";

const store = configureStore({
    reducer: {
        user: userReducer,
        builder: builderReducer,
        furnitureCatalog: furnitureCatalogReducer,
        cart: cartReducer,
        wishlist: wishlistReducer,
        marketplaceContent: marketplaceContentReducer,
        search: searchReducer,
    },
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
