export const DOOR_WIDTH_DEFAULT = 83.8;
export const DOOR_WIDTH_MIN = 70;
export const DOOR_WIDTH_MAX = 300;
export const DOOR_LENGTH_DEFAULT = 200;
export const DOOR_LENGTH_MIN = 70;
export const DOOR_LENGTH_MAX = 300;
export const DOOR_INDENT_DEFAULT = 10;

export const WINDOW_WIDTH_DEFAULT = 120;
export const WINDOW_LENGTH_DEFAULT = 150;
export const WINDOW_HEIGHT_DEFAULT = 100;
export const WINDOW_WIDTH_MIN = 50;
export const WINDOW_WIDTH_MAX = 300;
export const WINDOW_LENGTH_MIN = 50;
export const WINDOW_LENGTH_MAX = 300;
export const WINDOW_HEIGHT_MIN = 0;
export const WINDOW_HEIGHT_MAX = 200;
export const WINDOW_INDENT_DEFAULT = 10;

