import React from 'react';
import '../Notification.scss';
import { ReactComponent as Close } from '../../../../img/svg/close.svg';

const { detect } = require('detect-browser');
const browser = detect();

const SafariNotification = () => {
  const [isActive, setActive] = React.useState(true);

  if (browser?.name !== 'safari' && browser?.name !== 'ios') {
    return null
  }
  return (
    <div className="notification-modal">
      <div className={isActive ? 'notification notification--active': 'notification'}>
        <div className="notification__header">
          <div className="notification__box">
            <p className="notification__title">
              Using Safari?
            </p>
            <p className="notification__text">
              Unfortunately, some of our features may not work properly here.<br/>
              We recommend you to switch to another browser to get full experience.
            </p>
          </div>
          <div className="notification__box">
            <div className="notification__close" onClick={() => setActive(false)}>
              <Close/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafariNotification;
