import React from 'react';
import './ViewerSettingsHeader.scss';
import {useSelector} from 'react-redux';
import {RootState} from "../../../store";
import {BuilderSteps} from "../../../models/builder";
import {get} from "lodash"
import ViewerSettingsToggle from "../ViewerSettingsToggle/ViewerSettingsToggle";



const builderStepsDescriptions = {
    [BuilderSteps.RoomSettings]: {
        title: 'Room Settings',
        description: 'Choose the format and proportions that best suits your room'
    },
    [BuilderSteps.FloorWallsSettings]: {
        title: 'Floor & Walls Style',
        description: 'Choose the format and proportions that best suits your room'
    },
    [BuilderSteps.Furniture]: {
        title: 'Add Furniture',
        description: 'Fill your room with furniture'
    },
}

interface ViewerSettingsHeaderProps {
    handleToggle: Function;
}

function ViewerSettingsHeader({handleToggle}: ViewerSettingsHeaderProps) {
    const currentProjectStep = useSelector((state: RootState) => state.builder.currentProjectStep);
    const {title, description} = get(builderStepsDescriptions, currentProjectStep);
    return (
        <div className="viewer-settings-header">
            <div className="viewer-settings-header__box">
                {title && <h1 className="viewer-settings-header__title">{title}</h1>}
                <ViewerSettingsToggle
                    handleToggle={() => handleToggle()}
                    className="viewer-settings-header__menu"/>
            </div>
            {description && <p className="viewer-settings-header__text">{description}</p>}
        </div>
    );
}

export default ViewerSettingsHeader;
