import {FurnitureCustomObjectShape, FurnitureProduct, FurnitureProductsVariant} from "../models/furnitureCatalog";
import {
    ADD_CUSTOM_OBJECT,
    ADD_FURNITURE_ITEM,
    CHANGE_FURNITURE_VARIANT,
    DELETE_CUSTOM_OBJECT,
    DELETE_FURNITURE_ITEM,
    DUPLICATE_CUSTOM_OBJECT,
    DUPLICATE_FURNITURE_ITEM,
    REPLACE_FURNITURE_ITEM, ROTATE_CUSTOM_OBJECT,
    ROTATE_FURNITURE_ITEM,
    SELECT_CUSTOM_OBJECT,
    SELECT_FURNITURE_ITEM,
    SET_CUSTOM_OBJECT_LENGTH,
    SET_CUSTOM_OBJECT_LEVEL,
    SET_CUSTOM_OBJECT_POSITION,
    SET_CUSTOM_OBJECT_SHAPE,
    SET_CUSTOM_OBJECT_WIDTH,
    SET_FURNITURE_ITEM_LEVEL,
    SET_FURNITURE_ITEM_POSITION
} from "./types/furnitureBuilder";
import store from "../store";
import {PROJECT_ERROR_NOTIFY} from "./types";
import {FurnitureItemPosition} from "../models/builder";
import {getFurnitureProductVariant} from "../utils/furniture";
import {head} from "lodash";

export function addFurnitureItem(product: FurnitureProduct, selectedVariant?: string) {
    const state = store.getState();
    const {roomSettings} = state.builder.projectData
    if (product.attributes.width > roomSettings.width || product.attributes.length > roomSettings.length) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: `Can't fit item to available space`
        }
    }
    if (selectedVariant) {
        const variant = getFurnitureProductVariant(product, selectedVariant);
        if (variant) {
            return {
                type: ADD_FURNITURE_ITEM,
                product,
                variant
            }
        }
    }

    return {
        type: ADD_FURNITURE_ITEM,
        product
    }
}

export function setFurnitureItemPosition(position: FurnitureItemPosition, itemIndex: number) {
    return {
        type: SET_FURNITURE_ITEM_POSITION,
        position,
        itemIndex
    }
}

export function selectFurnitureItem(itemIndex: number | null) {
    return {
        type: SELECT_FURNITURE_ITEM,
        itemIndex
    }
}

export function changeFurnitureItemVariant(itemIndex: number, variant: FurnitureProductsVariant) {
    return {
        type: CHANGE_FURNITURE_VARIANT,
        itemIndex,
        variant
    }
}

export function deleteFurnitureItem(itemIndex: number | null = null) {
    if (itemIndex === null) {
        const state = store.getState();
        itemIndex = state.builder.selectedFurnitureItemIndex;
    }

    return {
        type: DELETE_FURNITURE_ITEM,
        itemIndex
    }
}

export function replaceProductWithSimilar(similarProduct: FurnitureProduct) {
    const variant = head(head(similarProduct.productOptions)?.variants)
    return {
        type: REPLACE_FURNITURE_ITEM,
        product: similarProduct,
        variant
    }
}

export function duplicateFurnitureItem() {
    return {
        type: DUPLICATE_FURNITURE_ITEM
    }
}

export function rotateFurnitureItem(rotate: number) {
    return {
        type: ROTATE_FURNITURE_ITEM,
        rotate
    }
}

export function setFurnitureItemLevel(level: number) {
    return {
        type: SET_FURNITURE_ITEM_LEVEL,
        level
    }
}

export function addCustomObject(shape: FurnitureCustomObjectShape, width: number, length?: number) {
    const state = store.getState();
    const {roomSettings} = state.builder.projectData
    if (width > roomSettings.width || (length && length > roomSettings.length)) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: `Can't fit item to available space`
        }
    }

    return {
        type: ADD_CUSTOM_OBJECT,
        shape,
        width,
        length
    }
}


export function selectCustomObject(itemIndex: number | null) {
    return {
        type: SELECT_CUSTOM_OBJECT,
        itemIndex
    }
}

export function setCustomObjectPosition(position: FurnitureItemPosition, itemIndex: number) {
    return {
        type: SET_CUSTOM_OBJECT_POSITION,
        position, itemIndex
    }
}

export function duplicateCustomObject() {
    return {
        type: DUPLICATE_CUSTOM_OBJECT
    }
}

export function deleteCustomObject() {
    return {
        type: DELETE_CUSTOM_OBJECT
    }
}

export function setCustomObjectLevel(level: number) {
    return {
        type: SET_CUSTOM_OBJECT_LEVEL,
        level
    }
}

export function setCustomObjectShape(shape: FurnitureCustomObjectShape) {
    return {
        type: SET_CUSTOM_OBJECT_SHAPE,
        shape
    }
}

export function setCustomObjectWidth(width: number) {
    return {
        type: SET_CUSTOM_OBJECT_WIDTH,
        width
    }
}

export function setCustomObjectLength(length: number) {
    return {
        type: SET_CUSTOM_OBJECT_LENGTH,
        length
    }
}

export function rotateCustomObject(rotate: number) {
    return {
        type: ROTATE_CUSTOM_OBJECT,
        rotate
    }
}

