import {PositionOptions} from './builder'
import {MarketplaceImage} from "./furnitureCatalog";

export interface RoomSettings {
    shape: RoomShape;
    width: number;
    length: number;
    windowBayWidth: number|null;
    windowBayMiddleWidth: number|null;
    windowBayLength: number|null;
    windowBayPosition: PositionOptions|null;
    windowBayIndent: number|null;
}

export enum RoomShape {
    square = 'square',
    rectangle = 'rectangle',
    windowBay = 'windowBay'
}

export interface RoomType {
    id: string;
    name: string;
    slug: string;
    images: MarketplaceImage[]
}
