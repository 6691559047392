import axios from 'axios';

export function setAuthorizationHeader(token:string) {
    if (token.length > 0) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export const localAccessTokenKey = 'accessToken';

export function setAccessToken (token:string) {
    if (token.length > 0) {
        localStorage.setItem(localAccessTokenKey, token);
    } else {
        localStorage.removeItem(localAccessTokenKey);
    }
}

export function getLocalAccessToken():string|null {
    return localStorage.getItem(localAccessTokenKey);
}
