import React, {SyntheticEvent} from "react";
import {connect} from 'react-redux';
import {ReactComponent as SearchWhite} from "../../../../img/svg/search-white.svg";
import './SearchMobile.scss'
import {RootState} from "../../../../store";
import {SearchResultFormatted} from "../../../../models/search";
import {handleSearch} from "../../../../actions/searchActions";
import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";
import {marketplaceUrl} from "../../../../utils/viewer";

interface SearchMobileProps {
    query: string;
    handleSearch: (query: string) => void;
    results: SearchResultFormatted[];
}

const MAX_SEARCH_RESULTS_DISPLAY_MOBILE = 10;

class SearchMobile extends React.Component<SearchMobileProps> {
    handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleSearch(e.target.value)
    }

    handleSearchRedirect = (e: SyntheticEvent) => {
        e.preventDefault();
        const {query} = this.props;
        if (query.length) {
            window.open(`${marketplaceUrl()}search?search=${query}`, '_blank')?.focus();
        }
    }

    render() {
        const {query, results} = this.props;
        return (
            <form className="mobile-search" onSubmit={this.handleSearchRedirect}>
                <input
                    type="text" className="mobile-search__input" placeholder="I’m looking for..."
                    value={query}
                    onChange={this.handleSearchInput}
                />
                <button className="mobile-search__btn" type={`submit`}>
                        <span className="mobile-search__btn-ico">
                          <SearchWhite/>
                        </span>
                    Search
                </button>
                {results.length > 0 && (<div className={`header-search__results`}>
                    {results.slice(0, MAX_SEARCH_RESULTS_DISPLAY_MOBILE)
                        .map((searchData, index) => (
                                <MarketplaceLink
                                    key={`search-result-${index}`}
                                    path={`/search?search=${searchData.value.replace(/<[^>]*>?/gm, '')}`}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: searchData.value,
                                        }}
                                    />
                                </MarketplaceLink>
                            )
                        )}
                </div>)}
            </form>
        );
    }
}


function mapStateToProps(state: RootState) {
    return {
        query: state.search.query,
        results: state.search.results,
    };
}


export default connect(
    mapStateToProps,
    {handleSearch}
)(SearchMobile);
