import React from 'react';
import '../Modal.scss';
import {ReactComponent as Close} from '../../../../img/svg/close.svg';
import LoginForm from "../../Auth/LoginForm/LoginForm";

interface ModalLoginProps {
    isModalOpen: boolean;
    closeModal: Function;
    onLoginSuccess: Function;
}

const ModalLogin = ({isModalOpen, closeModal, onLoginSuccess}: ModalLoginProps) => {
    return (
        <div className={isModalOpen ? 'modal__wrap modal__wrap--active' : 'modal__wrap'}>
            <div className="modal">
                <div className="modal__header modal__header--login">
                    <p className="modal__title modal__title--login">
                      Please log in to your account to save your project
                    </p>
                    <p className="modal__text modal__text--login">
                      Have not registered yet? Sign up now.
                    </p>
                    <div className="modal__close">
                        <Close onClick={() => closeModal()}/>
                    </div>
                </div>
                <div className="modal__content modal__content--login">
                    <LoginForm onLoginSuccess={onLoginSuccess}/>
                </div>
            </div>
        </div>
    );
}

export default ModalLogin;
