import axios from "axios";
import {CatalogFilters} from "../models/furnitureCatalog";
import qs from "qs";

export function getRoomTypesCategoriesRequest(roomTypeId: string) {
    return axios.get('furniture-catalog/categories', {params: {roomTypeId}});
}

export function getAllFurnitureCategoriesRequest() {
    return axios.get('furniture-catalog/categories/all');
}

export function getCategoryFiltersRequest(categoryId: string) {
    return axios.get(`furniture-catalog/category-filters-content/${categoryId}`);
}

export function getCategoryProductsRequest(category?: string, filters?: CatalogFilters) {
    return axios.get('furniture-catalog/products', {params: {category, filters: filters},
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'brackets'})
        }});
}

export function getProductSimilarItemsRequest(productId: string) {
    return axios.get(`furniture-catalog/products/${productId}/similar`);
}

export function getRecentProductsRequest(products: string[]) {
    return axios.get(`furniture-catalog/products/recent`, {params: {products},
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'brackets'})
        }});
}
