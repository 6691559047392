import React from 'react';
import '../SettingsOption.scss';
import WindowsSelect from '../../DetailsOption/WindowsSelect/WindowsSelect';
import DoorsSelect from '../../DetailsOption/DoorsSelect/DoorsSelect';
import RoomSelect from '../../DetailsOption/RoomSelect/RoomSelect';
import {connect} from "react-redux";
import {RootState} from "../../../../store";
import {map, find} from "lodash";
import {selectDoor, selectWindow} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";

const ReactWebTabs = require('react-web-tabs');

interface RoomSettingsOptionProps {
    selectedDoorIndex: number | null;
    selectedWindowIndex: number | null;
    windowBaySelected: boolean;
    selectDoor: (index: null) => AnyAction;
    selectWindow: (index: null) => AnyAction;
}

interface RoomSettingsOptionState {
    currentTab: string
}

interface RoomSettingsOptionTab {
    id: string;
    name: string;
    content: React.ReactNode
}

class RoomSettingsOption extends React.Component<RoomSettingsOptionProps, RoomSettingsOptionState> {
    constructor(props: RoomSettingsOptionProps) {
        super(props);
        this.state = {
            currentTab: this.roomSettingsOptionTabs.roomPlan.id
        };
    }

    roomSettingsOptionTabs = {
        roomPlan: {
            id: 'rs__room-plan',
            name: 'Room plan',
            content: <RoomSelect/>
        },
        doors: {
            id: 'rs__doors',
            name: 'Doors',
            content: <DoorsSelect/>
        },
        windows: {
            id: 'rs__windows',
            name: 'Windows',
            content: <WindowsSelect/>
        },
    };

    switchTab = (tab: string) => {
        this.setState({currentTab: tab})
        if (tab === this.roomSettingsOptionTabs.roomPlan.id) {
            if (this.props.selectedDoorIndex !== null) {
                this.props.selectDoor(null)
            }
            if (this.props.selectedWindowIndex !== null) {
                this.props.selectWindow(null)
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<RoomSettingsOptionProps>, prevState: Readonly<RoomSettingsOptionState>, snapshot?: any) {
        if (prevProps.selectedDoorIndex === null && this.props.selectedDoorIndex !== null) {
            this.switchTab(this.roomSettingsOptionTabs.doors.id)
        }
        if (prevProps.selectedWindowIndex === null && this.props.selectedWindowIndex !== null) {
            this.switchTab(this.roomSettingsOptionTabs.windows.id)
        }
        if (!prevProps.windowBaySelected && this.props.windowBaySelected) {
            this.switchTab(this.roomSettingsOptionTabs.roomPlan.id)
        }
    }

    render() {
        const {currentTab} = this.state;
        const displaySettings = find(this.roomSettingsOptionTabs, tab => currentTab === tab.id)
        return (
            <ReactWebTabs.Tabs defaultTab={currentTab}>
                <ReactWebTabs.TabList className="settings-option">
                    {map(this.roomSettingsOptionTabs,
                        (tab: RoomSettingsOptionTab) => (
                            <ReactWebTabs.Tab tabFor={tab.id} className="settings-option__input-wrapper" key={tab.id}>
                                <input className="settings-option__input" type="radio" id={tab.id} name="section-1"
                                       checked={currentTab === tab.id}
                                       onChange={() => this.switchTab(tab.id)}
                                />
                                <label className="settings-option__label" htmlFor={tab.id}>{tab.name}</label>
                            </ReactWebTabs.Tab>
                        ))}
                </ReactWebTabs.TabList>

                {displaySettings && (
                    <ReactWebTabs.TabPanel tabId={displaySettings.id}>
                        {displaySettings.content}
                    </ReactWebTabs.TabPanel>
                )}
            </ReactWebTabs.Tabs>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        selectedDoorIndex: state.builder.selectedDoorIndex,
        selectedWindowIndex: state.builder.selectedWindowIndex,
        windowBaySelected: state.builder.windowBaySelected,
    };
}

export default connect(
    mapStateToProps,
    {selectDoor, selectWindow}
)(RoomSettingsOption);

