import {Builder} from "../Builder";
import {FloorStyleType} from "../../../../models/floorWalls";
import {
    BUILDER_FLOOR_PATTERN_HEIGHT,
    BUILDER_FLOOR_PATTERN_WIDTH,
    FLOOR_COLOR_DEFAULT
} from "../../../../constants/floorWalls";
import {Element, Pattern} from "@svgdotjs/svg.js";

export class BuilderFloor {
    protected builder: Builder;
    public floorFill: string | Pattern;
    public floorView: Element | null

    constructor(builder: Builder) {
        this.builder = builder;
        this.floorFill = FLOOR_COLOR_DEFAULT;
        this.floorView = null
    }

    floorElementId = 'builder__floor';

    draw() {
        if (!(this.builder.drawArea && this.builder.roomContent && this.builder.roomMainArea)) {
            return;
        }
        const {roomSettings} = this.builder.props.converted;

        this.builder.roomContent.findOne(`#${this.floorElementId}`)?.remove()
        this.floorView = this.builder.drawArea
            .rect(roomSettings.width, roomSettings.length)
            .attr('id', this.floorElementId)
            .addTo(this.builder.roomContent)
            .on('click', () => this.builder.props.unSelectRoomObjects())

        const floorStyle = this.builder.props.projectData.floorWallsStyle.floor;

        if (floorStyle.type === FloorStyleType.color) {
            this.floorFill = floorStyle.fill || FLOOR_COLOR_DEFAULT
            this.floorView.fill(this.floorFill)
        } else {
            let textureImage = '';
            this.builder.props.floorStyles.forEach(floorTexture => {
                if (floorTexture.textureId === floorStyle.fill) {
                    textureImage = floorTexture.textureImg as string;
                    return;
                }
                floorTexture.textureOptions?.forEach(textureOption => {
                    if (textureOption.textureId === floorStyle.fill) {
                        textureImage = textureOption.textureImg;
                        return;
                    }
                })
            })

            this.floorFill = this.builder.drawArea.pattern(
                BUILDER_FLOOR_PATTERN_WIDTH*this.builder.scale, BUILDER_FLOOR_PATTERN_HEIGHT*this.builder.scale,
                function (add) {
                    add.image(textureImage)
                        .width(1)
                        .height(1)
                        .attr('preserveAspectRatio', 'xMidYMid slice');
                })
                .attr('patternContentUnits', 'objectBoundingBox')
                .viewbox('0 0 1 1')


            this.floorView.fill(this.floorFill);
        }
    }
}
