import React from 'react';
import {connect} from 'react-redux';
import './FurnitureList.scss';
import {FurnitureCustomObjectShape, FurnitureProduct} from "../../../models/furnitureCatalog";
import FurnitureListItemPreview from "./FurnitureListItemPreview/FurnitureListItemPreview";
import FurnitureListCustomItem from "./FurnitureListCustomItem/FurnitureListCustomItem";
import {chunk, find, head} from "lodash";
import cx from "classnames"
import FurnitureItemExpanded from "./FurnitureItemExpanded/FurnitureItemExpanded";
import FurnitureCustomItemSettings from "./FurnitureCustomItemSettings/FurnitureCustomItemSettings";
import Cookies from 'universal-cookie';
import {COOKIES_RECENT} from "../../../constants/cookiesNames";
import {RootState} from "../../../store";
import {getRecentProducts} from "../../../actions/furnitureCatalogActions";

interface FurnitureListProps {
    furnitureProducts: FurnitureProduct[];
    addCustomObject?: boolean;
    handleRecentProductsUpdate: (recentProductIds: string[]) => Promise<void>
}

interface FurnitureListState {
    selected: string | null;
    selectedVariant?: string;
    customObjectSettings?: CustomObjectSettings;
}

export interface CustomObjectSettings {
    objectWidth: number;
    objectLength: number;
    objectShape: FurnitureCustomObjectShape;
}

const FURNITURE_LIST_DISPLAY_COLUMNS = 2;
const CUSTOM_OBJECT_ID = 'custom';

class FurnitureList extends React.Component<FurnitureListProps, FurnitureListState> {
    constructor(props: FurnitureListProps) {
        super(props);
        this.state = {
            selected: null,
        }
    }

    selectItem = (selected: string) => {
        if (this.state.selected === selected) {
            return;
        }
        const {furnitureProducts} = this.props;
        const selectedProduct = find(furnitureProducts, {id: selected});
        const availableVariants = head(selectedProduct?.productOptions)?.variants
        const selectedVariant = head(availableVariants)?.id;

        this.setState({selected, selectedVariant});

        if (selectedProduct) {
            this.addProductToRecent(selected)
        }
    }

    selectVariant = (variantId: string) => {
        if (this.state.selectedVariant === variantId) {
            return;
        }
        this.setState({selectedVariant: variantId})
    }

    saveCustomObjectSettings = (settings: CustomObjectSettings) => {
        this.setState({customObjectSettings: settings})
    }

    addProductToRecent = (productId: string) => {
        const cookies = new Cookies();
        const recent = cookies.get(COOKIES_RECENT) || []
        recent.push(productId)
        cookies.set(COOKIES_RECENT, recent, {path: '/'});
        this.props.handleRecentProductsUpdate(recent)
    }

    render() {
        const {selected, selectedVariant, customObjectSettings} = this.state;
        const {furnitureProducts, addCustomObject} = this.props;

        const furnitureItemsList = addCustomObject
            ? [{id: CUSTOM_OBJECT_ID}, ...furnitureProducts]
            : furnitureProducts;

        return (
            <div className="furniture-list__wrap">
                <div className="furniture-list">
                    {chunk(furnitureItemsList, FURNITURE_LIST_DISPLAY_COLUMNS)
                        .map((productsRow, rowIndex) => {
                            const expandProduct = productsRow.find(product => {
                                return product.id === selected
                            });
                            return (
                                <React.Fragment key={`furniture-list__product-row-${rowIndex}`}>
                                    {productsRow.map((product, productIndex) => {
                                        return (
                                            <div key={`furniture-product-${productIndex}`}
                                                 className={cx(
                                                     'furniture-list__item',
                                                     {'furniture-item--selected': product.id === selected}
                                                 )}
                                                 onClick={() => this.selectItem(product.id)}>
                                                {product.id === CUSTOM_OBJECT_ID
                                                    ? <FurnitureListCustomItem appliedSettings={customObjectSettings}/>
                                                    : <FurnitureListItemPreview
                                                        product={product as FurnitureProduct}
                                                        selectedVariant={selectedVariant}
                                                        handleVariantChange={variantId => this.selectVariant(variantId)}
                                                    />
                                                }
                                            </div>
                                        )
                                    })}
                                    {expandProduct && (<>
                                        {expandProduct.id === CUSTOM_OBJECT_ID
                                            ? <FurnitureCustomItemSettings
                                                handleSettings={this.saveCustomObjectSettings}
                                            />
                                            : <FurnitureItemExpanded
                                                product={expandProduct as FurnitureProduct}
                                                selectedVariant={selectedVariant}
                                                handleVariantChange={variantId => this.selectVariant(variantId)}
                                            />
                                        }
                                    </>)}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}


function mapStateToProps(state: RootState) {
    return {};
}


export default connect(
    mapStateToProps,
    {handleRecentProductsUpdate: getRecentProducts}
)(FurnitureList);

