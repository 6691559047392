import React, {useEffect, useState} from 'react';
import './ColorPickerView.scss';
import {toColor} from "react-color-palette";
import {Color} from "react-color-palette/lib/interfaces/Color.interface";
import { HexColorPicker } from "react-colorful";

interface ColorPickerViewProps {
    children?: React.ReactNode
    initColor: string;
    handleColorChange: (color: string) => void;
}

export function ColorPickerView({children, initColor, handleColorChange}: ColorPickerViewProps) {
    const [color, setColor] = useState(toColor("hex", initColor));
    const [hexInputColor, setHexInputColor] = useState(initColor);
    const [rInputColor, setRInputColor] = useState(0);
    const [gInputColor, setGInputColor] = useState(0);
    const [bInputColor, setBInputColor] = useState(0);

    useEffect(() => {
        setHexInputColor(color.hex);
        setRInputColor(color.rgb.r);
        setGInputColor(color.rgb.g);
        setBInputColor(color.rgb.b);
    } , [color])

    const handleColorPick = (color:React.SetStateAction<Color>) => {
        setColor(color);
        if (handleColorChange) {
            handleColorChange((color as Color).hex)
        }
    }

    const handleHexInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const inputColor = e.target.value;
        if (!/^#[0-9A-F]{6}$/i.test(inputColor)) {
            setHexInputColor(inputColor);
            return;
        }
        handleColorPick(toColor("hex", inputColor));
    }

    const handleRGBInput = (e: React.ChangeEvent<HTMLInputElement>, colorChannel: 'r'| 'g'| 'b') => {
        e.preventDefault();
        let inputValue = parseInt(e.target.value);
        if (isNaN(inputValue)) {
            inputValue = 0
        }
        if (inputValue < 0 || inputValue > 255) {
            setRInputColor(inputValue)
            return;
        }
        handleColorPick(toColor("rgb", {...color.rgb, [colorChannel]: inputValue}));
    }

    return (
        <div className="color-picker-view">
            {children}
            <HexColorPicker
                         color={color.hex}
                         onChange={hexColor => handleColorPick(toColor("hex", hexColor))}
            />
            <div className={`color-picker-inputs`}>
                <div className={`color-picker-input__wrap`}>
                    Hex
                    <input value={`${hexInputColor}`} onChange={handleHexInput}/>
                </div>
                <div className={`color-picker-input__wrap`}>
                    R
                    <input value={rInputColor} onChange={e => handleRGBInput(e, 'r')}/>
                </div>
                <div className={`color-picker-input__wrap`}>
                    G
                    <input value={gInputColor} onChange={e => handleRGBInput(e, 'g')}/>
                </div>
                <div className={`color-picker-input__wrap`}>
                    B
                    <input value={bInputColor} onChange={e => handleRGBInput(e, 'b')}/>
                </div>
            </div>
        </div>
    );
}
