import React from 'react';
import '../ViewerSettings.scss';
import FloorWallsOption from '../../SettingsOption/FloorWallsOption/FloorWallsOption';

export default class FloorWalls extends React.Component {

    render() {
        return (
            <FloorWallsOption/>
        );
    }
}
