import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {LOAD_WISHLIST_ERROR, LOAD_WISHLIST_START, LOAD_WISHLIST_SUCCESS, REMOVE_WISHLIST} from "./types/wishlist";
import {getWishlistRequest} from "../requests/wishlistRequests";

export function loadWishlist() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: LOAD_WISHLIST_START})
        return getWishlistRequest()
            .then((res) => {
                dispatch({
                    type: LOAD_WISHLIST_SUCCESS,
                    items: res.data.items
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: LOAD_WISHLIST_ERROR
                });
            })
    }
}

export function removeWishlist() {
    return {
        type: REMOVE_WISHLIST
    }
}
