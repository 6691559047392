import {
    FETCH_USER_ERROR,
    FETCH_USER_START,
    FETCH_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_START,
    LOGIN_USER_SUCCESS,
    RESET_USER
} from './types';
import {AnyAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {LoginCredentials} from "../models/auth";
import {setAccessToken, setAuthorizationHeader} from "../utils/accessToken";
import {userLoginRequest, userProfileRequest} from "../requests/userRequests";
import {loadWishlist, removeWishlist} from "./wishlistActions";

export function fetchUser() {
    return (dispatch: Dispatch<AnyAction | Function>) => {
        dispatch({type: FETCH_USER_START})
        return userProfileRequest()
            .then(res => {
                dispatch({
                    type: FETCH_USER_SUCCESS,
                    payload: res.data
                });
                dispatch(loadWishlist());
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_USER_ERROR
                });
                dispatch(logout())
            });
    }
}

export function login(data: LoginCredentials) {
    return (dispatch: Dispatch<AnyAction | Function>) => {
        dispatch({type: LOGIN_USER_START})
        return userLoginRequest(data)
            .then(res => {
                const token = res.data.accessToken;
                setAccessToken(token);
                setAuthorizationHeader(token);
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: res.data.user
                });

                dispatch(loadWishlist());

            }).catch((err: Error) => {
                dispatch({
                    type: LOGIN_USER_ERROR
                });
                throw Error('Wrong e-mail or password');
            });
    }
}

export function logout() {
    setAccessToken('');
    setAuthorizationHeader('');

    return (dispatch: Dispatch<AnyAction>) => {
        dispatch(removeWishlist());
        dispatch({
            type: RESET_USER
        })
    }
}
