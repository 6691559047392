import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    applyAllProductsFilters,
    loadAllProductsCategoryContent
} from "../../../../actions/furnitureCatalogActions";
import {CatalogFilters, FurnitureCategoryMain, FurnitureProduct} from "../../../../models/furnitureCatalog";
import {RootState} from "../../../../store";
import {head} from "lodash";
import FurnitureCategories from "../FurnitureCategories/FurnitureCategories";
import FurnitureCatalogFilters from "../FurnitureCatalogFilters/FurnitureCatalogFilters";
import FurnitureList from "../../FurnitureList/FurnitureList";
import {MarketplaceCategory, MarketplaceMenuItem} from "../../../../models/marketplaceContent";
import {fetchHeaderMenu} from "../../../../actions/marketplaceContentActions";

interface AllFurnitureProps {
    getFurnitureCategories: () => Promise<void>;
    furnitureCategories: null | FurnitureCategoryMain[];
    selectedCategory: string | null;
    loadCategoryContent: (category: string) => void;
    filters: null | CatalogFilters;
    appliedFilters: null | CatalogFilters;
    loadingFilters: boolean;
    applyFilters: (filters: CatalogFilters) => Promise<void>;
    furnitureProducts: FurnitureProduct[];
}

function mapStateToProps(state: RootState) {
    const {headerMenu, categoriesData} = state.marketplaceContent;
    let furnitureCategories = null;
    if (headerMenu?.length > 0) {
        const furnitureMenuCategories = [
            ...headerMenu.find((category: MarketplaceMenuItem) => category.value === "furniture")?.items?.filter((category: MarketplaceMenuItem) => category.value !== "furniture-accessories"),
            headerMenu.find((category: MarketplaceMenuItem) => category.value === "furniture-lighting"),
            ...headerMenu.find((category: MarketplaceMenuItem) => category.value === "furniture")?.items?.filter((category: MarketplaceMenuItem) => category.value === "furniture-accessories"),
        ]
        if (furnitureMenuCategories?.length > 0) {
            furnitureCategories = furnitureMenuCategories.map((topCategory: MarketplaceMenuItem) => ({
                    id: categoriesData.find((categoryData: MarketplaceCategory) => categoryData.slug === topCategory.value)?.id,
                    name: topCategory.name,
                    secondaryCategories: topCategory.items.map((subCategory: MarketplaceMenuItem) => ({
                        id: categoriesData.find((categoryData: MarketplaceCategory) => categoryData.slug === subCategory.value)?.id,
                        name: subCategory.name,
                    }))
                }))
        }
    }
    return {
        furnitureCategories,
        selectedCategory: state.furnitureCatalog.allProductsContent.selectedCategory,
        filters: state.furnitureCatalog.allProductsContent.catalogFiltersContent,
        appliedFilters: state.furnitureCatalog.allProductsContent.catalogFiltersApplied,
        loadingFilters: state.furnitureCatalog.allProductsContent.loadingFilters,
        furnitureProducts: state.furnitureCatalog.allProductsContent.productsList,
    };
}

class AllFurniture extends Component<AllFurnitureProps> {
    componentDidMount() {
        this.loadCategories()
    }

    loadCategories = async () => {
        const {furnitureCategories} = this.props;
        if (!furnitureCategories) {
            await this.props.getFurnitureCategories()
        }
        this.loadDefaultCategory()
    }

    loadDefaultCategory() {
        const {furnitureCategories} = this.props;
        const defaultMainCategory = head(furnitureCategories);
        if (defaultMainCategory) {
            const defaultSecondaryCategory = head(defaultMainCategory.secondaryCategories)
            if (defaultSecondaryCategory) {
                this.props.loadCategoryContent(defaultSecondaryCategory.id)
            }
        }
    }

    render() {
        const {
            furnitureCategories, selectedCategory,
            filters, appliedFilters, loadingFilters, applyFilters,
            furnitureProducts
        } = this.props;
        return (
            <>
                {furnitureCategories && (
                    <FurnitureCategories
                        {...{furnitureCategories, selectedCategory}}
                        handleCategoryChange={this.props.loadCategoryContent}
                    />
                )}
                {filters && appliedFilters && (<FurnitureCatalogFilters
                    {...{
                        filters,
                        appliedFilters,
                        loadingFilters,
                        applyFilters
                    }}
                />)}
                <FurnitureList {...{furnitureProducts}} addCustomObject/>
            </>
        );
    }

}


export default connect(
    mapStateToProps,
    {
        getFurnitureCategories: fetchHeaderMenu,
        loadCategoryContent: loadAllProductsCategoryContent,
        applyFilters: applyAllProductsFilters
    }
)(AllFurniture);
