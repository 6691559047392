import axios from "axios";
import {FurnitureCartItem, ProjectData} from "../models/builder";

export function createProjectRequest(projectData: ProjectData) {
    return axios.post('room-projects', projectData)
}

export function fetchProjectRequest(projectId: number) {
    return axios.get(`room-projects/${projectId}`)
}

export function updateProjectRequest(projectData: ProjectData) {
    const {id} = projectData;
    return axios.patch(`room-projects/${id}`, projectData)
}

export function getRoomTypesListRequest() {
    return axios.get(`marketplace/room-types`)
}

export function getRoomTypeRequest(roomTypeSlug: string) {
    return axios.get(`marketplace/room-types/${roomTypeSlug}`)
}

export function addItemsToCartRequest(items: FurnitureCartItem[]) {
    return axios.post('room-projects/cart', {items})
}

export function getRoomTypesRequest() {
    return axios.get(`marketplace/room-types`);
}
