import React, {Component} from 'react';
import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";
import {map} from 'lodash';
import './UserProfileLinks.scss';

class UserProfileLinks extends Component {
    render() {
        const accountLinks = [
            { name: 'My Orders', url: 'orders', key: 'order' },
            { name: 'My Wish List', url: 'wish-list', key: 'wishlist' },
            { name: 'My Projects', url: 'my-projects', key: 'project' },
            { name: 'Personal Details', url: 'personal-details', key: 'detail' },
            {
                name: 'Marketing Preferences',
                url: 'marketing-preferences',
                key: 'marketing',
            },
            { name: 'Privacy', url: 'privacy-account', key: 'privacy' },
        ];
        return (
            <div>
                {map(
                    accountLinks,
                    (link: {name: string; url: string; key: string}) => (
                        <MarketplaceLink className={'user-profile__link'} path={link.url} key={link.key}>
                            {link.name}
                        </MarketplaceLink>
                    )
                )}
            </div>
        );
    }
}

export default (UserProfileLinks);
