import {useEffect, useState} from "react";

export const TABLET_MAX_WIDTH = 1025;

export interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Set size at the first client-side load
        handleResize();
        window.addEventListener("resize", handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);

    }, []);
    return windowSize;
}

export function isMobile() {
    return (window?.innerWidth <= TABLET_MAX_WIDTH)
}
