import React, {SyntheticEvent} from 'react';
import './ViewerSettingsFooter.scss';
import {ReactComponent as ArrowBack} from '../../../img/svg/arrow-back.svg';
import {ReactComponent as ArrowNext} from '../../../img/svg/arrow-next.svg';
import {connect} from "react-redux";
import {navigateToProjectStep} from "../../../actions/builderActions";
import {RootState} from "../../../store";
import {BuilderSteps} from "../../../models/builder";
import {ReactComponent as ArrowDown} from "../../../img/svg/arrow-down.svg";
import {ReactComponent as ArrowUp} from "../../../img/svg/arrow-up.svg";
import {isMobile} from "../../../utils/responsive";
import cx from "classnames";
import RoomTypeModal from "../RoomTypeModal/RoomTypeModal";
import MarketplaceModalOverlay from "../Modal/MarketplaceModalOverlay/MarketplaceModalOverlay";

function mapStateToProps(state: RootState) {
    return {
        currentProjectStep: state.builder.currentProjectStep,
    };
}

interface ViewerSettingsFooterProps {
    currentProjectStep: BuilderSteps;
    navigateToProjectStep: Function;
    toggleCart: () => void;
    cartIsVisible: boolean;
}

interface ViewerSettingsFooterState {
    showRoomTypeModal: boolean;
}

class ViewerSettingsFooter extends React.Component<ViewerSettingsFooterProps, ViewerSettingsFooterState> {
    constructor(props: ViewerSettingsFooterProps) {
        super(props);
        this.state = {
            showRoomTypeModal: false
        }
    }

    navigation = [
        {
            name: 'Room Type',
            step: null,
            isRoomType: true
        },
        {
            step: BuilderSteps.RoomSettings,
            name: 'Room Settings',
        },
        {
            step: BuilderSteps.FloorWallsSettings,
            name: 'Floor & Walls',
        },
        {
            step: BuilderSteps.Furniture,
            name: 'Add Furniture',
        },
    ];

    currentStepIndex = () => this.navigation.findIndex(builderStep => builderStep.step === this.props.currentProjectStep)

    getPrevStep = () => this.navigation[this.currentStepIndex() - 1]
    getNextStep = () => this.navigation[this.currentStepIndex() + 1]

    navigatePrev = (e: SyntheticEvent) => {
        e.preventDefault();
        const {navigateToProjectStep} = this.props;
        const prevStep = this.getPrevStep();
        if (prevStep?.step) {
            navigateToProjectStep(prevStep.step);
        }
        if(prevStep?.isRoomType) {
            this.handleRoomTypeModal(true);
        }
    }

    navigateNext = (e: SyntheticEvent) => {
        e.preventDefault();
        const {navigateToProjectStep} = this.props;
        const nextStep = this.getNextStep();
        if (nextStep) {
            navigateToProjectStep(nextStep.step);
        }
    }

    handleRoomTypeModal(show: boolean) {
        this.setState({showRoomTypeModal: show})
    }

    render() {
        const prevStep = this.getPrevStep(),
            nextStep = this.getNextStep();
        const {toggleCart, cartIsVisible} = this.props;
        const {showRoomTypeModal} = this.state;

        return (
            <div className="settings-menu__btns">
                <div className="settings-menu__btns-list">
                    {prevStep && (
                        <button className="settings-menu__btn settings-menu-btn settings-menu-btn--previous"
                                onClick={this.navigatePrev}>
                            <span className="setting-menu-btn__ico-wrap">
                              <ArrowBack/>
                            </span>
                            {prevStep.name}
                        </button>
                    )}
                    {nextStep && (
                        <button className="settings-menu__btn settings-menu-btn settings-menu-btn--next"
                                onClick={this.navigateNext}>
                            <span className="setting-menu-btn__ico-wrap">
                              <ArrowNext/>
                            </span>
                            Next
                        </button>
                    )}
                    {!nextStep && isMobile() && (
                        <button
                            className={cx(
                                "settings-menu__btn settings-menu-btn settings-menu-btn--next settings-menu-btn--review-mobile",
                                {"settings-menu-btn--hide": cartIsVisible}
                            )}
                            onClick={() => toggleCart()}>
                            {cartIsVisible
                                ? (
                                    <>
                                        <ArrowUp/>
                                        Hide
                                    </>
                                )
                                : (
                                    <>
                                        <ArrowDown/>
                                        Review
                                    </>
                                )
                            }

                        </button>
                    )}
                </div>
                <MarketplaceModalOverlay isOpened={showRoomTypeModal}>
                    <RoomTypeModal onModalClose={() => this.handleRoomTypeModal(false)}/>
                </MarketplaceModalOverlay>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, {navigateToProjectStep}
)(ViewerSettingsFooter);
