import {FurnitureItem, Measure, PositionOptions, ProjectData, WallObject, WallObjectType} from "../models/builder";
import {convertMeasure} from "./convertMeasure";
import {
    DOOR_INDENT_DEFAULT, DOOR_LENGTH_DEFAULT,
    DOOR_WIDTH_DEFAULT, WINDOW_HEIGHT_DEFAULT,
    WINDOW_INDENT_DEFAULT,
    WINDOW_LENGTH_DEFAULT,
    WINDOW_WIDTH_DEFAULT
} from "../constants/doorsWindowsSettings";
import {RoomSettings} from "../models/room";
import {findLast, forEach, head, last, filter} from "lodash";
import store from "../store";
import {BASE_MEASURE, BUILDER_AREA_BORDER_WIDTH, BUILDER_ROOM_WALLS_WIDTH} from "../constants/builderDefaults";
import {ROOM_LENGTH_DEFAULT, WINDOW_BAY_WIDTH_DEFAULT} from "../constants/roomSettings";
import {FurnitureCustomObject} from "../models/furnitureCatalog";

interface WindowBayParams {
    windowBayWidth: number,
    windowBayMiddleWidth: number,
    windowBayLength: number,
    windowBayIndent: number
}

export function calculateWindowBayParams(roomSettings: RoomSettings): WindowBayParams {
    const bayIsVertical = (roomSettings.windowBayPosition === PositionOptions.top
        || roomSettings.windowBayPosition === PositionOptions.bottom);
    const windowBayWidth = Math.min(
        roomSettings.windowBayWidth as number,
        bayIsVertical ? roomSettings.width : roomSettings.length)
    const windowBayMiddleWidth = Math.min(
        roomSettings.windowBayMiddleWidth as number,
        windowBayWidth)
    const windowBayLength = Math.min(
        roomSettings.windowBayLength as number,
        bayIsVertical ? roomSettings.length : roomSettings.width)

    return ({windowBayWidth, windowBayMiddleWidth, windowBayLength, windowBayIndent: roomSettings.windowBayIndent as number})
}

export function defaultDoor (measure: Measure) {
    return ({
        width: convertMeasure(Measure.cm, measure, DOOR_WIDTH_DEFAULT),
        length: convertMeasure(Measure.cm, measure, DOOR_LENGTH_DEFAULT),
        wall: PositionOptions.top,
        indent: convertMeasure(Measure.cm, measure, DOOR_INDENT_DEFAULT),
        type: WallObjectType.door,
        doorOpensOutside: true
    });
}

export function defaultWindow (measure: Measure) {
    return ({
        width: convertMeasure(Measure.cm, measure, WINDOW_WIDTH_DEFAULT),
        length: convertMeasure(Measure.cm, measure, WINDOW_LENGTH_DEFAULT),
        height: convertMeasure(Measure.cm, measure, WINDOW_HEIGHT_DEFAULT),
        wall: PositionOptions.left,
        indent: convertMeasure(Measure.cm, measure, WINDOW_INDENT_DEFAULT),
        type: WallObjectType.window
    });
}

interface WallObjectPlaceInterface {
    wall: PositionOptions;
    indent: number;
}

export function findPlaceForWallObject(projectData: ProjectData, objectWidth: number): WallObjectPlaceInterface | null {

    let indent = null, resultWall = null;

    forEach(PositionOptions, wall => {
        indent = findPlaceOnWall(projectData, objectWidth, wall)
        if (indent !== null) {
            resultWall = wall;
            return false;
        }
    });

    if (indent !== null && resultWall) {
        return ({
            indent,
            wall: resultWall
        })
    }
    return null;
}

interface WindowBayPlaceInterface extends WallObjectPlaceInterface {
    width: number
}

export function findPlaceForWindowBay(): WindowBayPlaceInterface | null {
    const state = store.getState();
    const projectData = state.builder.projectData;

    const windowBayWidth = convertMeasure(
        BASE_MEASURE,
        state.builder.projectData.measure,
        Math.min(WINDOW_BAY_WIDTH_DEFAULT, ROOM_LENGTH_DEFAULT)
    )

    let indent = null, resultWall = null;

    const defaultWall = PositionOptions.right;
    const walls = [defaultWall, ...Object.values(PositionOptions).filter(option => option !== defaultWall)]

    forEach(walls, wall => {
        const wallObjects = getWallObjects(wall);
        if (!wallObjects.length) {
            indent = getWallWidth(projectData, wall)/2 - windowBayWidth/2
            resultWall = wall;
            return false;
        }

        indent = findPlaceOnWall(projectData, windowBayWidth, wall, true)
        if (indent !== null) {
            resultWall = wall;
            return false;
        }
    });

    if (indent !== null && resultWall) {
        return ({
            indent,
            wall: resultWall,
            width: windowBayWidth
        })
    }
    return null;
}

export function getWallObjects(wall: PositionOptions): WallObject [] {
    const state = store.getState();
    const projectData = state.builder.projectData;

    let wallObjects = [
        ...projectData.doors.filter((door: WallObject) => door.wall === wall),
        ...projectData.windows.filter((window: WallObject) => window.wall === wall)
    ] as WallObject [];

    if (projectData.roomSettings.windowBayPosition === wall) {
        const windowBayObject = {
            width: projectData.roomSettings.windowBayWidth,
            indent: projectData.roomSettings.windowBayIndent
        } as WallObject
        wallObjects.push(windowBayObject)
    }

    wallObjects.sort((wo1, wo2) => wo1.indent - wo2.indent);

    return wallObjects;
}

export function getWallWidth(projectData: ProjectData, wall: PositionOptions) {
    return wall === PositionOptions.top || wall === PositionOptions.bottom
        ? projectData.roomSettings.width
        : projectData.roomSettings.length
}

export function findPlaceOnWall(
    projectData: ProjectData,
    objectWidth: number, wall: PositionOptions,
    includeWallIndent: boolean = false
): number | null {
    if (includeWallIndent) {
        objectWidth += getBuilderWallsIndent();
    }
    const wallWidth = getWallWidth(projectData, wall);
    if (wallWidth < objectWidth) {
        return null
    }

    const wallObjects = getWallObjects(wall);
    if (!wallObjects.length) {
        return 0
    }

    let indent = null;

    wallObjects.forEach((object, index) => {
        if (index === 0 && object.indent > objectWidth) {
            indent = 0;
            return
        }

        let from = object.indent + object.width, to = wallWidth;
        if (index + 1 < wallObjects.length) {
            const nextObject = wallObjects[index + 1];
            to = nextObject.indent;
        }

        const availableSpace = to - from;
        if (availableSpace > objectWidth) {
            indent = from;
            if (includeWallIndent) {
                indent += getBuilderWallsIndent()/2;
            }
            return
        }
    })
    return indent;
}

export function checkAvailabilityToPlace(
    object: WallObject,
    newIndent: number,
    includeWallIndent: boolean = false
): boolean {
    const wallObjects = getWallObjects(object.wall)
        .filter(wallObject => wallObject.indent !== object.indent);

    if (includeWallIndent) {
        const builderWallsIndent = getBuilderWallsIndent();
        newIndent -= builderWallsIndent/2;
        object = {...object, width: object.width + builderWallsIndent}
    }

    const intersectWallObjects = wallObjects.filter(wallObject => {
            const wallObjectLeft = wallObject.indent;
            const wallObjectRight = wallObject.indent + wallObject.width;

            const pasteObjectLeft = newIndent;
            const pasteObjectRight = newIndent + object.width;

            return (
                (pasteObjectLeft >= wallObjectLeft && pasteObjectLeft <= wallObjectRight)
                || (pasteObjectRight >= wallObjectLeft && pasteObjectRight <= wallObjectRight)
            )
        }
    )

    return intersectWallObjects.length === 0;
}

export function calculateObjectFitLimits(object: WallObject):{ from: number, to: number } {
    const wallObjects = getWallObjects(object.wall);

    const prevObject = findLast(wallObjects, wallObject => wallObject.indent < object.indent);
    const nextObject = head(wallObjects.filter(wallObject => wallObject.indent > object.indent));

    const state = store.getState();
    const projectData = state.builder.projectData;
    const wallWidth = object.wall === PositionOptions.top || object.wall === PositionOptions.bottom
        ? projectData.roomSettings.width
        : projectData.roomSettings.length

    const from = prevObject
        ? prevObject.indent + prevObject.width
        : 0
    const to = nextObject
        ? nextObject.indent
        : wallWidth

    return {from,to}
}

export function fitObjectToAvailableSpace(object: WallObject, newWidth: number): number|null {
    const {from, to} = calculateObjectFitLimits(object);
    if ((newWidth + object.indent) < to) {
        return object.indent
    }
    if(newWidth <= (to - from)) {
        return (to - newWidth)
    }
    return null;
}

export function maxAvailableObjectSpace (object: WallObject): number {
    const {from, to} = calculateObjectFitLimits(object);
    return (to-from)
}

export function checkResizedRoomWidth(newWidthValue: number): boolean {
    const wallObjectsTop = getWallObjects(PositionOptions.top);
    const lastTopObject = last(wallObjectsTop);
    if (lastTopObject) {
        const availableWidthTop = lastTopObject.indent + lastTopObject.width;
        if (newWidthValue < availableWidthTop) {
            return false
        }
    }
    const wallObjectsBottom = getWallObjects(PositionOptions.bottom);
    const lastBottomObject = last(wallObjectsBottom);
    if (lastBottomObject) {
        const availableWidthBottom = lastBottomObject.indent + lastBottomObject.width;
        if (newWidthValue < availableWidthBottom) {
            return false
        }
    }

    const state = store.getState();
    const {furnitureItems, furnitureCustomObjects} = state.builder.projectData;
    if (
        furnitureItems.find((furnitureItem: FurnitureItem) =>
            (furnitureItem.position.x + furnitureItem.product.attributes.width / 2) > newWidthValue)
    ) {
        return false;
    }
    if (
        furnitureCustomObjects.find((customObject: FurnitureCustomObject) =>
            (customObject.position.x + customObject.width / 2) > newWidthValue)
    ) {
        return false;
    }

    return true
}

export function checkResizedRoomLength(newLengthValue: number): boolean {
    const wallObjectsleft = getWallObjects(PositionOptions.left);
    const lastLeftObject = last(wallObjectsleft);
    if (lastLeftObject) {
        const availableLengthLeft = lastLeftObject.indent + lastLeftObject.width;
        if (newLengthValue < availableLengthLeft) {
            return false
        }
    }
    const wallObjectRight = getWallObjects(PositionOptions.right);
    const lastRightObject = last(wallObjectRight);
    if (lastRightObject) {
        const availableLengthRight = lastRightObject.indent + lastRightObject.width;
        if (newLengthValue < availableLengthRight) {
            return false
        }
    }

    const state = store.getState();
    const {furnitureItems, furnitureCustomObjects} = state.builder.projectData;
    if (
        furnitureItems.find((furnitureItem: FurnitureItem) =>
            (furnitureItem.position.y + furnitureItem.product.attributes.length / 2) > newLengthValue)
    ) {
        return false;
    }
    if (
        furnitureCustomObjects.find((customObject: FurnitureCustomObject) =>
            (customObject.position.y + (customObject.length ?? customObject.width) / 2) > newLengthValue)
    ) {
        return false;
    }

    return true
}

export function getBuilderWallsIndent(): number {
    const state = store.getState();
    const {projectData, builderScale} = state.builder;
    return convertMeasure(
        BASE_MEASURE,
        projectData.measure,
        ((BUILDER_ROOM_WALLS_WIDTH+BUILDER_AREA_BORDER_WIDTH) * 2)/builderScale
    )
}

export function itemsAreInsideWindowBay() {
    const windowBayElement = document.querySelector('#builder__window-bay')
    if (windowBayElement) {
        const wbayBox = windowBayElement.getBoundingClientRect()
        const itemsInsideBay = filter(document.querySelector('#builder__furniture')?.children,
                element => {
                    const elementBox = element.getBoundingClientRect();
                    const elementCoords = [
                        [elementBox.left, elementBox.top],
                        [elementBox.left, elementBox.bottom],
                        [elementBox.right, elementBox.top],
                        [elementBox.right, elementBox.bottom],
                    ];

                    return (
                        filter(elementCoords, points => {
                            const [x, y] = points;
                            return (
                                (x >= wbayBox.left && x <= wbayBox.right)
                                && (y >= wbayBox.top && y <= wbayBox.bottom)
                            )
                        }).length > 0
                    )
                })

        return itemsInsideBay.length > 0;
    }
    return false;
}
