import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from "classnames";
import {RootState} from "../../../../../store";
import {AuthorizedUser} from "../../../../../models/auth";
import {ReactComponent as HeaderProfileIcon} from "../../../../../img/svg/header-profile.svg";
import {MARKETPLACE_PATH_ACCOUNT} from "../../../../../constants/marketplaceLinks";
import MarketplaceLink from "../../../MarketplaceLink/MarketplaceLink";
import Logout from "../../../Auth/Logout/Logout";
import ModalLogin from "../../../Modal/ModalLogin/ModalLogin";

interface HeaderUserProfileProps {
    user: AuthorizedUser;
}

function mapStateToProps(state: RootState) {
    return {
        user: state.user
    };
}

interface HeaderUserProfileState {
    showProfileDropdown: boolean;
    showLoginModal: boolean;
}

class HeaderUserProfile extends Component<HeaderUserProfileProps, HeaderUserProfileState> {
    constructor(props: HeaderUserProfileProps) {
        super(props);
        this.state = {
            showProfileDropdown: false,
            showLoginModal: false,
        }
    }

    toggleProfileDropdown = () => {
        this.setState((prevState) => ({showProfileDropdown: !prevState.showProfileDropdown}))
    }

    handleModalLogin = (showModal: boolean) => {
        this.setState({showLoginModal: showModal})
    }

    render() {
        const {user} = this.props;
        const {name} = user.user;
        const {showProfileDropdown, showLoginModal} = this.state;

        if (user.isAuthenticated) {
            return (<div className="header-actions__item header-actions__item--profile">
                <button className="header-actions__profile"
                        onClick={() => this.toggleProfileDropdown()}
                >
                    {name?.split(' ').map((namePart: string, index: number) => (
                        <span key={`username-initials-${index}`}>{namePart.charAt(0)}</span>
                    ))}
                </button>
                <div className={cx(
                    'header-actions-profile__dropdown',
                    {'header-actions-profile__dropdown--active': showProfileDropdown}
                )}>
                    <p className="header-actions-profile__text">Hello, <span>{name}</span></p>
                    <MarketplaceLink path={MARKETPLACE_PATH_ACCOUNT} className="header-actions-profile__btn">
                        My account
                    </MarketplaceLink>
                    <Logout className="header-actions-profile__btn header-actions-profile__btn--gray"/>
                </div>
            </div>)
        }
        return (
            <div className="header-actions__item header-actions__item--profile">
                <a href="/" className="header-actions__link"
                   onClick={e => {
                       e.preventDefault();
                       this.handleModalLogin(true)
                   }}>
                    <HeaderProfileIcon/>
                </a>
                <ModalLogin isModalOpen={showLoginModal}
                            closeModal={() => this.handleModalLogin(false)}
                            onLoginSuccess={() => this.handleModalLogin(false)}/>
            </div>
        );
    }

}

export default connect(
mapStateToProps,
)(HeaderUserProfile);
