export const ADD_FURNITURE_ITEM = 'ADD_FURNITURE_ITEM';
export const SET_FURNITURE_ITEM_POSITION = 'SET_FURNITURE_ITEM_POSITION';
export const SELECT_FURNITURE_ITEM = 'SELECT_FURNITURE_ITEM';
export const CHANGE_FURNITURE_VARIANT = 'CHANGE_FURNITURE_VARIANT';
export const DELETE_FURNITURE_ITEM = 'DELETE_FURNITURE_ITEM';
export const REPLACE_FURNITURE_ITEM = 'REPLACE_FURNITURE_ITEM';
export const DUPLICATE_FURNITURE_ITEM = 'DUPLICATE_FURNITURE_ITEM';
export const ROTATE_FURNITURE_ITEM = 'ROTATE_FURNITURE_ITEM';
export const SET_FURNITURE_ITEM_LEVEL = 'SET_FURNITURE_ITEM_LEVEL';
export const ADD_CUSTOM_OBJECT = 'ADD_CUSTOM_OBJECT';
export const SELECT_CUSTOM_OBJECT = 'SELECT_CUSTOM_OBJECT';
export const SET_CUSTOM_OBJECT_POSITION = 'SET_CUSTOM_OBJECT_POSITION';
export const DUPLICATE_CUSTOM_OBJECT = 'DUPLICATE_CUSTOM_OBJECT';
export const DELETE_CUSTOM_OBJECT = 'DELETE_CUSTOM_OBJECT';
export const SET_CUSTOM_OBJECT_LEVEL = 'SET_CUSTOM_OBJECT_LEVEL';
export const SET_CUSTOM_OBJECT_SHAPE = 'SET_CUSTOM_OBJECT_SHAPE';
export const SET_CUSTOM_OBJECT_WIDTH = 'SET_CUSTOM_OBJECT_WIDTH';
export const SET_CUSTOM_OBJECT_LENGTH = 'SET_CUSTOM_OBJECT_LENGTH';
export const ROTATE_CUSTOM_OBJECT = 'ROTATE_CUSTOM_OBJECT';

export const ADD_ITEMS_TO_CART_START = 'ADD_ITEMS_TO_CART_START';
export const ADD_ITEMS_TO_CART_SUCCESS = 'ADD_ITEMS_TO_CART_SUCCESS';
export const ADD_ITEMS_TO_CART_ERROR = 'ADD_ITEMS_TO_CART_ERROR';
