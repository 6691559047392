import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {getCartItemsCountRequest} from "../requests/cartRequests";
import {
    GET_CART_ITEMS_COUNT_ERROR,
    GET_CART_ITEMS_COUNT_START,
    GET_CART_ITEMS_COUNT_SUCCESS,
    UPDATE_CART_ITEMS_COUNT
} from "./types/cart";
import {FurnitureCartItem} from "../models/builder";
import {ADD_ITEMS_TO_CART_ERROR, ADD_ITEMS_TO_CART_START, ADD_ITEMS_TO_CART_SUCCESS} from "./types/furnitureBuilder";
import {addItemsToCartRequest} from "../requests/projectRequests";
import {AxiosError} from "axios";

export function getCartItemsCount() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({
            type: GET_CART_ITEMS_COUNT_START
        });
        return getCartItemsCountRequest()
            .then(res => {
                dispatch({
                    type: GET_CART_ITEMS_COUNT_SUCCESS,
                    count: res.data.count
                });
            }).catch((err: Error) => {
                dispatch({
                    type: GET_CART_ITEMS_COUNT_ERROR,
                    error: err.message
                });
            });
    }
}

export function addFurnitureItemsToCart(items: FurnitureCartItem[]) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: ADD_ITEMS_TO_CART_START})
        return addItemsToCartRequest(items)
            .then(res => {
                dispatch({
                    type: ADD_ITEMS_TO_CART_SUCCESS
                });

                let itemsCount = 0;
                res.data.items.forEach((cartItem: FurnitureCartItem) => {itemsCount += cartItem.quantity})
                dispatch({
                    type: UPDATE_CART_ITEMS_COUNT,
                    count: itemsCount
                });

                return true;
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: ADD_ITEMS_TO_CART_ERROR,
                    error: `Can't add items to cart: server request failed`
                });
                return false;
            });
    }
}

