import React, {SyntheticEvent} from 'react';
import {login} from "../../../../actions/userActions";
import {connect} from "react-redux";
import {User} from "../../../../models/auth";
import ErrorField from "../../Form/ErrorField/ErrorField";
import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";
import "./LoginForm.scss";
import {get, mapValues, filter} from 'lodash';
import cx from "classnames"

interface LoginFormProps {
    user: {
        user: User;
        isAuthenticated: boolean;
    },
    loginIsProcessing: boolean;
    login: Function,
    onLoginSuccess: Function
}

interface LoginFormState {
    email: string;
    password: string;
    errors: string;
    invalid: {
        email: string | null;
        password: string | null;
    }
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    constructor(props: LoginFormProps) {
        super(props);

        this.state = {
            email: '',
            password: '',
            errors: '',
            invalid: {
                email: null,
                password: null
            }
        };
    }

    submitLogin = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return
        }
        this.props.login({
            login: this.state.email,
            password: this.state.password,
        })
            .then(() => {
                this.props.onLoginSuccess()
            })
            .catch((error: Error) => {
                this.setState({errors: error.message})
            });
    }

    validationErrors = {
        email: 'The field "Email Address" is required.',
        password: 'The field "Password" is required.'
    }

    fieldIsValid = (field: string, value: string) => value.length === 0? get(this.validationErrors, field): null

    handleFieldChange = (field: string, value: string) => {
        this.setState(prevState => ({
            ...prevState,
            [field]: value,
            invalid: {
                ...prevState.invalid,
                [field]: this.fieldIsValid(field, value)
            }
        }))
    }

    validateForm = () => {
        const invalid = mapValues(
            this.state.invalid,
            (_, field: string) => this.fieldIsValid(field, get(this.state, field))
        );
        this.setState({invalid})
        return filter(invalid, (value: string | null) => value !== null).length === 0;
    }

    render() {
        const {errors, invalid} = this.state;
        const {loginIsProcessing} = this.props;
        return (
            <form action="" className="login__form" onSubmit={this.submitLogin}>
                <div className="login__box login__box--input">
                    <label className="login__label">
                        Email Address
                        <span title="required to fill" className="login__">&nbsp;*</span>
                    </label>
                    <div className="login__input-wrap">
                        <input className={cx('login__input', {'login__input--error': invalid.email})} type="email" placeholder="Email Address"
                               onChange={e => this.handleFieldChange('email', e.target.value)}/>
                        <div className="login-field__error">{invalid.email}</div>
                    </div>
                </div>
                <div className="login__box login__box--input">
                    <label className="login__label">
                        Password
                        <span title="required to fill" className="login__">&nbsp;*</span>
                    </label>
                    <div className="login__input-wrap">
                        <input className={cx('login__input', {'login__input--error': invalid.password})} type="password" placeholder="Password"
                               onChange={e => this.handleFieldChange('password', e.target.value)}/>
                        <div className="login-field__error">{invalid.password}</div>
                    </div>
                </div>
                <ErrorField {...{errors}}/>
                <div className="login__box login__box--forgot">
                    <MarketplaceLink path={'forgotten-password'} className={`login__link`}>
                        Forgot your password?
                    </MarketplaceLink>
                </div>
                <div className="login__box login__box--controls">
                    <span className="login__signup-wrap">
                        <MarketplaceLink path={'register'} className={`login__signup`}>
                            Sign up
                        </MarketplaceLink>
                    </span>
                    <button type="submit" className="login__btn" disabled={loginIsProcessing}>
                        Log in
                    </button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        loginIsProcessing: state.user.loginIsProcessing
    };
};

export default connect(mapStateToProps, {login})(LoginForm);
