import React, {Component} from 'react';
import {connect} from 'react-redux';
import Viewer from "../../layout/Viewer/Viewer";
import {AppDispatch, RootState} from "../../../store";
import {fetchProject} from "../../../actions/builderActions";
import Error403 from "../../layout/ErrorPages/Error403";

function mapStateToProps(state: RootState) {
    const {projectIsFetching, projectFetchError, projectFetchSuccess} = state.builder;
    return {projectIsFetching, projectFetchError, projectFetchSuccess};
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return {
        fetchProject: (projectId: number) => dispatch(fetchProject(projectId))
    };
}

interface SavedProjectProps {
    id: number;
    data?: any;
    fetchProject: Function;
    projectIsFetching: boolean;
    projectFetchSuccess: boolean;
    projectFetchError: null | string;
}

class SavedProject extends Component<SavedProjectProps> {
    componentDidMount() {
        const {id, fetchProject} = this.props;
        fetchProject(id);
    }

    render() {
        const {projectIsFetching, projectFetchError} = this.props;

        if (projectIsFetching) {
            return null;
        }
        if (projectFetchError) {
            return (<Error403/>)
        }

        return <Viewer/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedProject);
