import {BuilderWallObject} from "../BuilderWallObject";
import {Builder} from "../../Builder";
import {WallObject} from "../../../../../models/builder";
import {ReactComponent as CopyIcon} from "../../../../../img/svg/new-tab.svg";
import {ReactComponent as DeleteIcon} from "../../../../../img/svg/delete.svg";
import React from "react";

export class BuilderWindow extends BuilderWallObject {
    constructor(builder: Builder, index: number, windowData: WallObject) {
        super(builder, index, windowData);

        this.drawView();
        this.position();
        this.handleDrag();

        if(index === this.builder.props.selectedWindowIndex) {
            this.selectObject();
        }
    }

    drawMenu = () => {
        super.setPositionMenuOptions(
            wall => this.builder?.props.setWindowWall(wall)
        )

        this.menuBottomOptions = [
            {
                icon: <CopyIcon/>,
                handler: () => this.builder?.props.copyWindow()
            },
            {
                icon: <DeleteIcon/>,
                handler: () => this.builder?.props.removeWindow(this.index)
            }
        ]
        super.drawMenu()
    }

    handleBeforeDrag() {
        this.builder.props.selectWindow(this.index);
    }
    setObjectIndent(indent: number) {
        this.builder.props.setWindowIndent(indent)
    }
}
