import {Builder} from "../Builder";
import {Element, SVG} from "@svgdotjs/svg.js";
import "./ItemMenu.scss";
import {
    ITEM_MENU_MIDDLE_SPACE,
    ITEM_MENU_OPTION_ICON_MARGIN,
    ITEM_MENU_OPTION_ICON_SIZE,
    ITEM_MENU_OPTION_MARGIN,
    ITEM_MENU_OPTION_SIZE
} from "../../../../constants/builderDefaults";
import {ReactElement} from "react";
import {renderToString} from "react-dom/server";
import cx from "classnames";

export interface MenuOptionParams {
    icon: ReactElement<any>;
    handler: () => void;
    active?: boolean;
}

export class ItemMenu {
    protected builder: Builder;
    public menu: Element | null;
    protected menuId: string;
    protected topGroupOptions: Element | null;
    protected bottomGroupOptions: Element | null;

    constructor(builder: Builder, menuId: string) {
        this.builder = builder;
        this.menu = null;
        this.menuId = menuId;
        this.topGroupOptions = null;
        this.bottomGroupOptions = null;
    }

    drawMenu = (item: Element, topOptions: MenuOptionParams[], bottomOptions: MenuOptionParams[], isVertical = false) => {
        if (!this.builder.drawArea || !this.builder.builderContent) {
            return
        }
        this.removeMenu();

        this.menu = this.builder.drawArea.group().attr('id', this.menuId);
        this.builder.builderContent.add(this.menu)

        const topGroupOptions = this.builder.drawArea.group()
        const bottomGroupOptions = this.builder.drawArea.group()

        topOptions.forEach((option, index) => {
            const optionObject = this.drawMenuOption(option)
            if (optionObject) {
                isVertical
                    ? topGroupOptions.add(
                    optionObject
                        .dy((ITEM_MENU_OPTION_SIZE + ITEM_MENU_OPTION_MARGIN) * index)
                    )
                    : topGroupOptions.add(
                        optionObject
                            .dx((ITEM_MENU_OPTION_SIZE + ITEM_MENU_OPTION_MARGIN) * index)
                    )
            }
        })

        bottomOptions.forEach((option, index) => {
            const optionObject = this.drawMenuOption(option)
            if (optionObject) {
                isVertical
                    ? bottomGroupOptions.add(
                    optionObject
                        .dy((ITEM_MENU_OPTION_SIZE + ITEM_MENU_OPTION_MARGIN) * index)
                    )
                    : bottomGroupOptions.add(
                    optionObject
                        .dx((ITEM_MENU_OPTION_SIZE + ITEM_MENU_OPTION_MARGIN) * index)
                    )
            }
        })

        if (isVertical) {
            topGroupOptions
                .cy(this.menu.cy());
            bottomGroupOptions
                .cy(this.menu.cy())
                .dx(ITEM_MENU_OPTION_SIZE + ITEM_MENU_MIDDLE_SPACE);
        } else {
            topGroupOptions
                .cx(this.menu.cx());
            bottomGroupOptions
                .cx(this.menu.cx())
                .dy(ITEM_MENU_OPTION_SIZE + ITEM_MENU_MIDDLE_SPACE);
        }

        this.menu
            .add(topGroupOptions)
            .add(bottomGroupOptions)

        this.menu.center(item.cx(), item.cy());
    }

    drawMenuOption = (menuOption: MenuOptionParams) => {
        if (!this.builder.drawArea) {
            return
        }
        return (this.builder.drawArea
            .foreignObject(ITEM_MENU_OPTION_SIZE, ITEM_MENU_OPTION_SIZE)
            .attr('class', cx('builder-item__menu__option', {'active': menuOption.active}))
            .add(SVG(renderToString(menuOption.icon))
                .size(ITEM_MENU_OPTION_ICON_SIZE, ITEM_MENU_OPTION_ICON_SIZE)
                .translate(ITEM_MENU_OPTION_ICON_MARGIN, ITEM_MENU_OPTION_ICON_MARGIN)
            )
            .on('click', (e: Event) => {
                e.preventDefault();
                e.stopPropagation();
                menuOption.handler()
            }) as Element)
    }

    removeMenu = () => {
        this.builder.builderContent?.findOne(`#${this.menuId}`)?.remove();
    }

}
