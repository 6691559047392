import {MarketplaceCategory, MarketplaceMenuItem, MarketplaceMenuLink} from "../models/marketplaceContent";
import {map, get, find} from "lodash";
import {
    MARKETPLACE_PATH_PAGES,
    MARKETPLACE_PATH_PRODUCT,
    MARKETPLACE_PATH_PRODUCTS,
    MARKETPLACE_PATH_SEARCH
} from "../constants/marketplaceLinks";

export function getMenuItem(item: MarketplaceMenuItem): MarketplaceMenuLink {
    const {name, type, value, items} = item;
    let menuItem = {
            name: name,
            links: map(items, getMenuItem),
            isCollapsible: get(item, 'options.expand'),
            isNewTab: get(item, 'options.target') === 'blank'
        } as MarketplaceMenuLink;

    switch (type) {
        case 'link': {
            menuItem.to = value;
            break;
        }
        case 'product': {
            menuItem.to = MARKETPLACE_PATH_PRODUCT + '/' + value;
            break;
        }
        case 'category': {
            menuItem.to = MARKETPLACE_PATH_PRODUCTS + '/' + value;
            menuItem.categoryId = value;
            break;
        }
        case 'search': {
            menuItem.to = MARKETPLACE_PATH_SEARCH + '?q=' + value;
            break;
        }
        case 'home': {
            menuItem.to = '';
            break;
        }
        case 'page': {
            menuItem.to = MARKETPLACE_PATH_PAGES + '/' + value;
            break;
        }
        default:
            break;
    }

    return menuItem;
}


export function getCategoryImage(categories: MarketplaceCategory[], categoryIdOrSlug: string): string|null {
    const category = find(categories, { id: categoryIdOrSlug }) || find(categories, { slug: categoryIdOrSlug })
    return get(category, `images[0].file.url`, null) || null
}
