import React, {Component} from 'react';
import {connect} from 'react-redux';
/*import {ReactComponent as LikeFull} from "../../../img/svg/like-full.svg";*/
import {RootState} from "../../../store";
import {FurnitureCartItem, FurnitureItem} from "../../../models/builder";
import CheckoutListItem from "./CheckoutListItem/CheckoutListItem";
import {deleteFurnitureItem} from "../../../actions/furnitureBuilderActions";
import {AnyAction} from "@reduxjs/toolkit";
import ModalLogin from "../Modal/ModalLogin/ModalLogin";
import {AuthorizedUser} from "../../../models/auth";
import {addFurnitureItemsToCart} from "../../../actions/cartActions";
import {ReactComponent as Close} from "../../../img/svg/close.svg";
import {SuccessNotification} from "../Modal/SuccessNotification/SuccessNotification";

interface CheckoutProps {
    furnitureItems: FurnitureItem[];
    deleteFurnitureItem: (index: number) => AnyAction;
    addFurnitureItemsToCart: (items: FurnitureCartItem[]) => Promise<boolean>;
    addToCartProcessing: boolean;
    user: AuthorizedUser;
    handleHide: () => void;
}

interface CheckoutState {
    selectedToCart: number[];
    totalCartSum: number;
    showModalLogin: boolean;
    showSuccessNotification: boolean;
}

function mapStateToProps(state: RootState) {
    return {
        furnitureItems: state.builder.projectData.furnitureItems,
        addToCartProcessing: state.builder.addToCartProcessing,
        user: state.user
    };
}

class Checkout extends Component<CheckoutProps, CheckoutState> {
    constructor(props: CheckoutProps) {
        super(props);
        this.state = {
            selectedToCart: [],
            totalCartSum: 0,
            showModalLogin: false,
            showSuccessNotification: false
        }
    }

    handleDelete = (index: number) => {
        this.props.deleteFurnitureItem(index);
        const {selectedToCart} = this.state;
        if (selectedToCart.includes(index)) {
            this.handleSelectToCart(index);
        }
    }

    handleSelectToCart = (index: number) => {
        const {selectedToCart} = this.state;
        const {furnitureItems} = this.props;
        const updatedSelect = selectedToCart.includes(index)
            ? selectedToCart.filter(selectedIndex => selectedIndex !== index)
            : [...selectedToCart, index];

        let totalCartSum = 0;
        updatedSelect.forEach(index => {
            const furnitureItem = furnitureItems[index]
            const price = furnitureItem.variant?.price || furnitureItem.product.price;
            const salePrice = furnitureItem.variant?.salePrice || furnitureItem.product.salePrice;
            totalCartSum += salePrice || price;
        })

        this.setState({selectedToCart: updatedSelect, totalCartSum})
    }

    handleAddToCart = () => {
        const {totalCartSum, selectedToCart} = this.state;
        if (!totalCartSum) {
            return;
        }
        const {user} = this.props;
        if (!user.isAuthenticated) {
            this.setState({showModalLogin: true})
            return;
        }

        const {furnitureItems, addFurnitureItemsToCart} = this.props;

        const cartItems = furnitureItems
            .filter((_, index) => selectedToCart.includes(index))
            .map(furnitureItem => ({
                product_id: furnitureItem.product.id,
                variant_id: furnitureItem.variant?.id,
                quantity: 1
            } as FurnitureCartItem))

        addFurnitureItemsToCart(cartItems)
            .then((addToCartSuccess) => {
                if (addToCartSuccess) {
                    this.setState({selectedToCart: [], totalCartSum: 0, showSuccessNotification: true});
                }
            })
    }

    proceedToMarketplaceCart() {
        this.setState({showSuccessNotification: false})
        window.open(process.env.REACT_APP_MARKETPLACE_URL+'cart', '_blank')?.focus();
    }

    handleLoginClose = () => {
        this.setState({showModalLogin: false});
    }

    handleLoginSuccess = () => {
        this.setState({
            showModalLogin: false,
        }, () => this.handleAddToCart())
    }

    render() {
        const {furnitureItems, addToCartProcessing, user} = this.props;
        const {selectedToCart, totalCartSum, showModalLogin, showSuccessNotification} = this.state;
        return (
            <div className="viewer-footer-extended__project project">
                <div className="project-header__wrap">
                    <div className="project-header">
                        <p className="project-header__title">My Project</p>
                        <div className="project-header__counter"><p>{furnitureItems.length}</p></div>
                        <p className="project-header__text">
                            {furnitureItems.length === 1 ? 'Item' : 'Items'}
                        </p>
                    </div>
                    <div className="viewer-settings-header__close" onClick={() => this.props.handleHide()}>
                        <Close/>
                    </div>
                </div>
                {furnitureItems.length > 0 && (
                    <>
                        <div className="project__list">
                            {furnitureItems.map((furnitureItem, index) => (
                                <CheckoutListItem
                                    key={`checkout-item-${index}`}
                                    furnitureItem={furnitureItem}
                                    handleDelete={() => this.handleDelete(index)}
                                    handleAddToCart={() => this.handleSelectToCart(index)}
                                    selectedToCart={selectedToCart.includes(index)}
                                />
                            ))}
                        </div>
                        <div className="project-footer">
                            <div className="project-footer__subtotal">
                                <p className="project-footer__text">Subtotal:</p>
                                <p className="project-footer__price">£{totalCartSum.toFixed(2)}</p>
                            </div>
                            <button className="project-footer__btn" disabled={!totalCartSum || addToCartProcessing}
                                    onClick={() => this.handleAddToCart()}>
                                Add To Cart
                            </button>
                            {!user.isAuthenticated && (
                                <ModalLogin isModalOpen={showModalLogin}
                                            closeModal={() => this.handleLoginClose()}
                                            onLoginSuccess={() => this.handleLoginSuccess()}/>
                            )}
                        </div>
                    </>
                )}
                <SuccessNotification
                    showNotificationWindow={showSuccessNotification}
                    handleClose={() => this.proceedToMarketplaceCart()}
                    message={'Selected items were added to the cart'}
                    buttonCaption={'Ok'}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {deleteFurnitureItem, addFurnitureItemsToCart}
)(Checkout);
