import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../../../store";
import {ReactComponent as HeaderLikeIcon} from "../../../../../img/svg/header-like.svg";
import {ReactComponent as HeaderLikeFullIcon} from "../../../../../img/svg/header-like-full.svg";
import MarketplaceLink from "../../../MarketplaceLink/MarketplaceLink";
import {MARKETPLACE_PATH_WISHLIST} from "../../../../../constants/marketplaceLinks";

interface HeaderWishlistProps {
    count: number;
    wishlistIsLoading: boolean;
}

function mapStateToProps(state: RootState) {
    return {
        count: state.wishlist.items?.length || 0,
        wishlistIsLoading: state.wishlist.wishlistIsLoading
    };
}

class HeaderWishlist extends Component<HeaderWishlistProps> {
    render() {
        const {count, wishlistIsLoading} = this.props;
        const showCounter = (count > 0) && !wishlistIsLoading;
        return (
            <div className="header-actions__item header-actions__item--like">
                <MarketplaceLink path={MARKETPLACE_PATH_WISHLIST} className="header-actions__link">
                    <span className="header-actions__ico">
                      {showCounter ? <HeaderLikeFullIcon/> : <HeaderLikeIcon/>}
                    </span>
                    {showCounter && (
                        <span className="header-actions__counter">
                          {count}
                        </span>
                    )}
                </MarketplaceLink>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(HeaderWishlist);
