import React from 'react';
import '../SettingsOption.scss';
import FloorList from '../../FloorList/FloorList';
import WallsSelect from '../../DetailsOption/WallsSelect/WallsSelect';
import {map} from "lodash";
import {PositionOptions} from "../../../../models/builder";
import {connect} from "react-redux";
import {RootState} from "../../../../store";

const ReactWebTabs = require('react-web-tabs');

interface FloorWallsOptionProps {
    selectedWall: PositionOptions | null;
}

interface FloorWallsOptionState {
    currentTab: string
}

export class FloorWallsOption extends React.Component<FloorWallsOptionProps, FloorWallsOptionState> {
    constructor(props: FloorWallsOptionProps) {
        super(props);
        this.state = {
            currentTab: this.floorWallsOptionTabs.floor.id
        }
    }

    floorWallsOptionTabs = {
        floor: {
            id: 'fw__floor',
            name: 'Floor',
            content: <FloorList/>
        },
        walls: {
            id: 'fw__walls',
            name: 'Walls',
            content: <WallsSelect/>
        },
    }

    switchTab = (tab: string) => {
        this.setState({currentTab: tab});
    }

    componentDidUpdate(prevProps: Readonly<FloorWallsOptionProps>, prevState: Readonly<FloorWallsOptionState>, snapshot?: any) {
        if (prevProps.selectedWall === null && this.props.selectedWall !== null) {
            this.switchTab(this.floorWallsOptionTabs.walls.id)
        }
    }

    render() {
        const {currentTab} = this.state;
        return (
            <ReactWebTabs.Tabs defaultTab={currentTab} className="settings-option__wrapper">
                <ReactWebTabs.TabList className="settings-option">
                    {map(this.floorWallsOptionTabs, tab => (
                        <ReactWebTabs.Tab key={tab.id} tabFor={tab.id} className="settings-option__input-wrapper">
                            <input
                                className="settings-option__input"
                                type="radio"
                                id={`input-${tab.id}`}
                                name="fw"
                                checked={currentTab === tab.id}
                                onChange={() => this.switchTab(tab.id)}
                            />
                            <label className="settings-option__label" htmlFor={`input-${tab.id}`}>{tab.name}</label>
                        </ReactWebTabs.Tab>
                    ))}
                </ReactWebTabs.TabList>
                <div className="settings-option__content">
                    {map(this.floorWallsOptionTabs, tab => (
                        <ReactWebTabs.TabPanel key={`${tab.id}__content`} tabId={tab.id}>
                            {tab.content}
                        </ReactWebTabs.TabPanel>
                    ))}
                </div>
            </ReactWebTabs.Tabs>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        selectedWall: state.builder.selectedWall
    };
}

export default connect(
    mapStateToProps,
)(FloorWallsOption);
