import {Measure} from "../models/builder";

export const CONTAINER_DESKTOP_WIDTH = 700;
export const CONTAINER_DESKTOP_HEIGHT = 700;
export const BUILDER_AREA_BORDER_WIDTH = 4;
export const BUILDER_ROOM_WALLS_WIDTH = 23;
export const BUILDER_ROOM_WALLS_WIDTH_MOBILE = 10;
export const BUILDER_OUTER_SPACE = 80;
export const BUILDER_OUTER_SPACE_MOBILE = 20;

export const BUILDER_AREA_BORDER_COLOR = '#4F5962';
export const BUILDER_ROOM_MARKUP_COLOR = '#4F5962';
export const BUILDER_ROOM_MARKUP_DASH = '3 3';
export const BUILDER_BACKGROUND = '#FFFFFF';
export const BUILDER_AREA_ACCENT_COLOR = '#ED6F2D';
export const BUILDER_MARKUP_COLOR_LIGHT = '#00000080';
export const BUILDER_MARKUP_COLOR_LIGHT_DASH = '#00000030';

export const MARKUP_FONT_SIZE = '11px';
export const MARKUP_FONT_WEIGTH = 300;
export const MARKUP_VALUE_FONT_WEIGTH = 700;
export const MARKUP_BLOCK_HEIGHT = 12;
export const MARKUP_BLOCK_HEIGHT_MOBILE = 4;
export const OBJECT_MARKUP_BLOCK_HEIGHT_MOBILE = 6;
export const OBJECT_MARKUP_BLOCK_HEIGHT_BOTTOM_MOBILE = 12;

export const MARKUP_LINE_WIDTH = 1;
export const MARKUP_LINE_MARGIN = 20;
export const MARKUP_LINE_MARGIN_MOBILE = 4;
export const MARKUP_OBJECT_BOUNDARY_HEIGHT = 29;
export const MARKUP_OBJECT_BOUNDARY_HEIGHT_MOBILE = 16;
export const MARKUP_OBJECT_BOUNDARY_ICON_WIDTH = 11;
export const MARKUP_OBJECT_BOUNDARY_ICON_HEIGHT = 9;

export const WALL_OBJECT_COLOR = '#ACB2B8';
export const WALL_OBJECT_HEIGHT = 34;
export const WALL_OBJECT_HEIGHT_MOBILE = 22;
export const WALL_OBJECT_LINE_WIDTH = 8;
export const WALL_OBJECT_LINE_WIDTH_MOBILE = 4;
export const WALL_OBJECT_FONT_SIZE = '12px'

export const ITEM_MENU_OPTION_SIZE = 34;
export const ITEM_MENU_OPTION_MARGIN = 10;
export const ITEM_MENU_MIDDLE_SPACE = 54;
export const ITEM_MENU_OPTION_ICON_SIZE = 14;
export const ITEM_MENU_OPTION_ICON_MARGIN = 10;

export const BUILDER_DOOR_OPEN_COLOR = '#00000010';
export const DOOR_OPEN_ANGLE_INDENT = 5;
export const DOOR_OBJECT_HEIGHT_MOBILE = 16;


export const BASE_MEASURE = Measure.cm;
export const MEASURES_DESC = [
    {
        measure: Measure.cm,
        name: 'Centimeters',
        shortName: 'cm',
    },
    {
        measure: Measure.inches,
        name: 'Inches',
        shortName: 'in'
    }
]
