import {Measure} from "../models/builder";
import {find, round} from "lodash"
import {BASE_MEASURE} from "../constants/builderDefaults";

export function convertMeasure(from: Measure, to: Measure, value: number) {
    const rates = [
        {
            from: Measure.cm,
            to: Measure.cm,
            rate: 1
        },
        {
            from: Measure.inches,
            to: Measure.inches,
            rate: 1
        },
        {
            from: Measure.cm,
            to: Measure.inches,
            rate: 0.39370
        },
        {
            from: Measure.inches,
            to: Measure.cm,
            rate: 2.54
        },
    ]
    const convertRate = find(rates, {from, to})?.rate || 1;
    return round(value*convertRate, 2);
}

export function getBaseMeasureValue(from: Measure, value: number) {
    return convertMeasure(from, BASE_MEASURE, value);
}
