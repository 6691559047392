import React, {Component} from 'react';
import Collapsible from "react-collapsible";
import {ReactComponent as ArrowDropdown} from "../../../../img/svg/arrow-dropdown.svg";
import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";

export interface HeaderMobileCollapsibleProps {
    children?: React.ReactNode;
    title: string;
    link?: string;
}

class HeaderMobileCollapsible extends Component<HeaderMobileCollapsibleProps> {

    render() {
        const {children, title, link} = this.props;

        const CollapsibleTrigger = (
            <div className="collapsible__wrap">
                <div className="collapsible__link">
                    {title}
                </div>
                <div className="collapsible__arrow">
                    <ArrowDropdown/>
                </div>
            </div>
        );

        const CollapsibleTriggerLink = (
            <div className="collapsible__subitem">
                {link && (
                    <div onClick={e => e.stopPropagation()}>
                        <MarketplaceLink path={link} className="collapsible__sublink">
                            {title}
                        </MarketplaceLink>
                    </div>
                )}
                <div className="collapsible__subtoggle"/>
            </div>
        );

        return (
            <div className="mobile-nav__collapsible">
                <Collapsible
                    trigger={link ? CollapsibleTriggerLink : CollapsibleTrigger}
                    transitionTime={300}
                    easing='ease'>
                    <div className="collapsible__dropdown">
                        {children}
                    </div>
                </Collapsible>
            </div>
        );
    }
}

export default HeaderMobileCollapsible;
