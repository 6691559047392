import React, {Component} from 'react';
/*import {ReactComponent as LikeFull} from "../../../../img/svg/like-full.svg";*/
import {ReactComponent as Close} from "../../../../img/svg/close.svg";
import {FurnitureItem} from "../../../../models/builder";
import {getFurnitureProductVariant} from "../../../../utils/furniture";
import {head} from "lodash";
import {FurnitureDimensions} from "../../FurnitureList/FurnitureDimensions/FurnitureDimensions";
import cx from "classnames";

interface CheckoutListItemProps {
    furnitureItem: FurnitureItem;
    handleDelete: () => void;
    handleAddToCart: () => void;
    selectedToCart: boolean;
}

class CheckoutListItem extends Component<CheckoutListItemProps> {

    render() {
        const {furnitureItem, selectedToCart, handleAddToCart} = this.props;
        const {product, variant} = furnitureItem;
        const selectedVariantData = getFurnitureProductVariant(product, variant?.id as string);

        const previewImage = head(selectedVariantData ? selectedVariantData.images : product.images);
        const productFullTitle = product.name// + (selectedVariantData ? `, ${selectedVariantData.name}` : '')

        const optionGroupName = product.productOptions
            .find(
                optionGroup => optionGroup.variants
                    .filter(optionVariant => optionVariant.id === variant?.id)
                    .length > 0
            )?.name

        const price = selectedVariantData?.price || product.price,
            salePrice = selectedVariantData?.salePrice || product.salePrice;

        return (
            <div className="project__item project-item">
                <div className="project-item__wrap project-item__wrap--left">
                    <div className="project-item__box project-item__box--img">
                        {previewImage && (
                            <img className="project-item__img" src={previewImage.file.url} alt={productFullTitle}/>
                        )}
                    </div>
                    <div className="project-item__box project-item__box--data">
                        <p className="project-item__id">Item # {selectedVariantData?.sku || product.sku}</p>
                        <p className="project-item__title">{productFullTitle}</p>
                        <div className="project-item__details">
                            {variant && (<p className="project-item__color">
                                <span className="project-item-details__label">{optionGroupName}:&nbsp;</span>
                                {variant.name}
                            </p>)}
                            <p className="project-item__size">
                                <span className="project-item-details__label">Size:&nbsp;</span>
                                <FurnitureDimensions {...product.attributes}/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="project-item__wrap project-item__wrap--right">
                    {/*<div className="project-item__box project-item__box--dropdown">
                        <p className="project-item-dropdown__text">Quantity:</p>
                        <div className="project-item-dropdown__select">
                            <CustomSelect
                                                defaultText={this.state.defaultSelectText}
                                                optionsList={this.state.counterList}
                                              />
                        </div>
                    </div>*/}
                    <div className="project-item__box project-item__box--price">
                        <p className={cx("project-item__price", {'project-item__price--old': salePrice})}>
                            £{price}
                        </p>
                        {salePrice && (
                            <p className="project-item__price project-item__price--sale">
                                £{salePrice}
                            </p>
                        )}
                    </div>
                    <div className="project-item__box project-item__box--cart">
                        <label className="checkbox">
                            <input className="checkbox__input" type="checkbox"
                                   checked={selectedToCart} onChange={() => handleAddToCart()}
                            />
                            <div className="checkbox__label">
                                <div className="checkbox__item"/>
                                <span className="checkbox__text">Add to cart</span>
                            </div>
                        </label>
                    </div>
                    <div className="project-item__box project-item__box--icons">
                        {/*<div className="project-item__like">
                            <LikeFull/>
                        </div>*/}
                        <div className="project-item__close" onClick={() => this.props.handleDelete()}>
                            <Close/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckoutListItem;
