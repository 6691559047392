import {Builder} from "../Builder";
import {PointArrayAlias, Polygon} from "@svgdotjs/svg.js";
import {BUILDER_ROOM_MARKUP_COLOR, BUILDER_ROOM_MARKUP_DASH} from "../../../../constants/builderDefaults";
import {PositionOptions} from "../../../../models/builder";
import {WALLS_COLOR_DEFAULT} from "../../../../constants/floorWalls";

export class BuilderWalls {
    protected builder: Builder;

    constructor(builder: Builder) {
        this.builder = builder;
    }

    wallsGroupId = 'builder__walls'

    draw() {
        if (!(this.builder.room && this.builder.drawArea && this.builder.roomMainArea)) {
            return;
        }
        this.builder.roomMainArea?.findOne(`#${this.wallsGroupId}`)?.remove();

        const {wallsWidth, borderWidth} = this.builder.builderDefaults;
        const wallsAreaSize = {
            width: (this.builder.room.width() as number) - borderWidth * 2,
            height: (this.builder.room.height() as number) - borderWidth * 2
        }
        const wallsDrawData = [
            {
                wall: PositionOptions.left,
                points: [
                    [0, 0],
                    [wallsWidth, wallsWidth],
                    [wallsWidth, wallsAreaSize.height - wallsWidth],
                    [0, wallsAreaSize.height]
                ]
            },
            {
                wall: PositionOptions.top,
                points: [
                    [0, 0],
                    [wallsAreaSize.width, 0],
                    [wallsAreaSize.width - wallsWidth, wallsWidth],
                    [wallsWidth, wallsWidth],
                ]
            },
            {
                wall: PositionOptions.right,
                points: [
                    [wallsAreaSize.width - wallsWidth, wallsWidth],
                    [wallsAreaSize.width, 0],
                    [wallsAreaSize.width, wallsAreaSize.height],
                    [wallsAreaSize.width - wallsWidth, wallsAreaSize.height - wallsWidth],
                ]
            },
            {
                wall: PositionOptions.bottom,
                points: [
                    [wallsWidth, wallsAreaSize.height - wallsWidth],
                    [wallsAreaSize.width - wallsWidth, wallsAreaSize.height - wallsWidth],
                    [wallsAreaSize.width, wallsAreaSize.height],
                    [0, wallsAreaSize.height]
                ]
            },
        ]

        const wallsSettings = this.builder.props.projectData.floorWallsStyle.walls;

        const walls = this.builder.drawArea.group()
            .attr('id', this.wallsGroupId)
            .translate(this.builder.roomMainArea.x() as number, this.builder.roomMainArea.y() as number)
            .addTo(this.builder.roomMainArea);

        wallsDrawData.forEach((wallData) => {
                const wallDots = wallData.points as PointArrayAlias;
                const wallColor = wallsSettings.find(wallSettings => wallSettings.wall === wallData.wall)?.colorHEX || WALLS_COLOR_DEFAULT
                const wallPolygon = new Polygon()
                    .plot(wallDots)
                    .fill(wallColor)
                    .translate(borderWidth, borderWidth)
                    .stroke({color: BUILDER_ROOM_MARKUP_COLOR, dasharray: BUILDER_ROOM_MARKUP_DASH})

                wallPolygon
                    .on('mouseover', () => {
                        wallPolygon.css({cursor: 'pointer'});
                    })
                    .on('click', () => {
                        this.builder.props.selectWall(wallData.wall);
                    })
                walls.add(wallPolygon)
            }
        )
    }
}
