import React from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../store";
import './EditFurniture.scss';
import {ReactComponent as Close} from '../../../img/svg/close.svg';
import {ReactComponent as NewTabIcon} from '../../../img/svg/new-tab.svg';
import {ReactComponent as Delete} from '../../../img/svg/delete.svg';
/*import {ReactComponent as Like} from '../../../img/svg/like.svg';*/
import {ReactComponent as Ico360} from '../../../img/svg/360-ico.svg';
import {ReactComponent as Rotation360Icon} from '../../../img/svg/rotation_360.svg';
import {FurnitureItem} from "../../../models/builder";
import {head} from "lodash";
import {FurnitureProduct, FurnitureProductsVariant} from "../../../models/furnitureCatalog";
import {
    changeFurnitureItemVariant,
    deleteFurnitureItem,
    replaceProductWithSimilar,
    selectFurnitureItem
} from "../../../actions/furnitureBuilderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {getFurnitureProductVariant} from "../../../utils/furniture";
import FurnitureCatalogDropdown from "../FurnitureCatalog/FurnitureCatalogDropdown/FurnitureCatalogDropdown";
import {FurnitureDimensions} from "../FurnitureList/FurnitureDimensions/FurnitureDimensions";
import {getProductSimilarItems} from "../../../actions/furnitureCatalogActions";
import MarketplaceLink from "../MarketplaceLink/MarketplaceLink";
import cx from "classnames";
import {isMobile} from "../../../utils/responsive";
import FurnitureVariants from "../FurnitureList/FurnitureVariants/FurnitureVariants";
import View360Modal from "../Modal/View360Modal/View360Modal";

interface EditFurnitureProps {
    selectedItemIndex: number;
    furnitureItem: FurnitureItem;
    changeFurnitureItemVariant: (itemIndex: number, variant: FurnitureProductsVariant) => AnyAction;
    deleteFurnitureItem: () => AnyAction;
    selectFurnitureItem: (itemIndex: null) => AnyAction;
    similarItems: FurnitureProduct[] | null;
    getProductSimilarItems: (productId: string) => Promise<void>;
    loadingProductSimilarItems: boolean;
    replaceProductWithSimilar: (similarProduct: FurnitureProduct) => AnyAction;
    handleMobileClose: () => void;
}

interface EditFurnitureState {
    show360Modal: boolean;
}

class EditFurniture extends React.Component<EditFurnitureProps, EditFurnitureState> {
    constructor(props: EditFurnitureProps) {
        super(props);
        this.state = {
            show360Modal: false
        }
    }

    componentDidMount() {
        if (this.props.similarItems === null) {
            this.props.getProductSimilarItems(this.props.furnitureItem.product.id);
        }
    }

    componentDidUpdate(prevProps: Readonly<EditFurnitureProps>, prevState: Readonly<{}>, snapshot?: any) {
        if ((prevProps.furnitureItem.product.id !== this.props.furnitureItem.product.id)
            && this.props.similarItems === null) {
            this.props.getProductSimilarItems(this.props.furnitureItem.product.id)
        }
    }

    handleVariantChange(variantId: string) {
        const {furnitureItem: {product, variant}} = this.props;
        if (variant?.id === variantId) {
            return;
        }
        const selectedVariant = getFurnitureProductVariant(product, variantId) as FurnitureProductsVariant;

        this.props.changeFurnitureItemVariant(this.props.selectedItemIndex, selectedVariant);
    }

    handleDelete() {
        this.props.deleteFurnitureItem()
    }

    handleClose() {
        if (isMobile()) {
            this.props.handleMobileClose();
            return;
        }
        this.props.selectFurnitureItem(null);
    }

    handleSelectDimensions(product: FurnitureProduct) {
        this.props.replaceProductWithSimilar(product)
    }

    handle360Modal(show360Modal: boolean) {
        this.setState({show360Modal})
    }

    render() {
        const {furnitureItem, similarItems, loadingProductSimilarItems} = this.props;
        if (!furnitureItem) {
            return null;
        }
        const {product, variant} = furnitureItem;
        const previewImage = head(variant ? variant.images : product.images);
        const productFullTitle = product.name + (variant ? `, ${variant.name}` : '');

        let marketplaceLink = `product/${product.slug}`;
        if (variant) {
            marketplaceLink += `/${variant.sku}`
        }

        const price = variant?.price || product.price,
            salePrice = variant?.salePrice || product.salePrice;

        const view360 = (variant?.view360 || product.attributes.view360 || []).filter(file => file);
        const {show360Modal} = this.state;

        return (
            <div className="edit-furniture">
                <div className="edit-furniture__header edit-furniture-header">
                    <h2 className="edit-furniture-header__title">Edit Furniture</h2>
                    <div className="edit-furniture-header__close">
                        <Close onClick={() => this.handleClose()}/>
                    </div>
                </div>
                <div className="edit-furniture__content">
                    <div className="edit-furniture-img__wrap">
                        {previewImage && (
                            <img className="edit-furniture__img" src={previewImage.file.url} alt={productFullTitle}/>
                        )}
                        <div className="edit-furniture-img__delete">
                            <Delete onClick={() => this.handleDelete()}/>
                        </div>
                        {(view360.length > 0) && (
                            <>
                                <div className="edit-furniture-img__360"
                                     onClick={() => this.handle360Modal(true)}
                                >
                                    <Ico360/>
                                </div>
                            </>
                        )}
                        {/* <div className="edit-furniture-img__like">
                            <Like/>
                        </div>*/}
                    </div>
                    <div className="edit-furniture__box">
                        <h4 className="edit-furniture__title">{productFullTitle}</h4>
                        <p className="edit-furniture__id">Item # {variant?.sku || product.sku}</p>
                    </div>
                    <p className="edit-furniture__details">
                        <FurnitureDimensions {...product.attributes}/>
                    </p>
                    <div className={`edit-furniture__price--wrap`}>
                        <div
                            className={cx("edit-furniture__price", {'edit-furniture__price--old': salePrice})}
                        >
                            £{price}
                        </div>
                        {salePrice && (
                            <div className="edit-furniture__price edit-furniture__price--sale">
                                £{salePrice}
                            </div>
                        )}
                        {(view360.length > 0) && (
                            <>
                                <button
                                    className="edit-furniture__btn edit-furniture__btn--360 edit-furniture__btn--360-mobile"
                                    onClick={() => this.handle360Modal(true)}
                                >
                                    360° view
                                    <Rotation360Icon/>
                                </button>
                            </>
                        )}
                    </div>

                    {!loadingProductSimilarItems && (<div className={`edit-furniture__dimensions`}>
                        <div className="furniture-settings__title">Dimensions</div>
                        <FurnitureCatalogDropdown title={`Choose dimensions`}
                                                  subtitle={<FurnitureDimensions {...product.attributes}/>}>
                            <ul className="select__options">
                                <li className="custom-select__item">
                                    <FurnitureDimensions {...product.attributes}/>
                                </li>
                                {similarItems?.map(similarItem => {
                                    return (
                                        <li
                                            className="custom-select__item" key={`similar-item-${similarItem.id}`}
                                            onClick={() => this.handleSelectDimensions(similarItem)}
                                        >
                                            <FurnitureDimensions {...similarItem.attributes}/>
                                        </li>
                                    )
                                })}
                            </ul>
                        </FurnitureCatalogDropdown>
                    </div>)}
                    <FurnitureVariants {...{
                        product,
                        selectedVariant: variant?.id,
                        handleVariantChange: (variantId: string) => this.handleVariantChange(variantId),
                        wrapClassname: "edit-furniture__color edit-furniture-color",
                        pickerNamePreffix: 'edit'
                    }}/>
                    <div className="edit-furniture__btns">
                        {(view360.length > 0) && (
                            <>
                                <button
                                    className="edit-furniture__btn edit-furniture__btn--360"
                                    onClick={() => this.handle360Modal(true)}
                                >
                                    360° view
                                    <Rotation360Icon/>
                                </button>
                            </>
                        )}
                        <MarketplaceLink className="edit-furniture__btn" path={marketplaceLink}>
                            Product Information
                            <NewTabIcon/>
                        </MarketplaceLink>
                    </div>
                </div>
                {view360.length > 0 && (<View360Modal
                    isModalOpen={show360Modal}
                    onModalClose={() => this.handle360Modal(false)}
                    product360images={view360}
                    {...{
                        product,
                        selectedVariant: variant?.id,
                        handleVariantChange: (variantId: string) => this.handleVariantChange(variantId),
                    }}
                />)}
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    const selectedItemIndex = state.builder.selectedFurnitureItemIndex;
    const furnitureItem = state.builder.projectData.furnitureItems[selectedItemIndex];
    const similarItems = (state.furnitureCatalog.similarItems
        .find(similarItemsData => similarItemsData.productId === furnitureItem.product.id)?.items) || null
    return {
        selectedItemIndex: selectedItemIndex as number,
        furnitureItem,
        similarItems,
        loadingProductSimilarItems: state.furnitureCatalog.loadingProductSimilarItems
    };
}

export default connect(
    mapStateToProps,
    {
        changeFurnitureItemVariant,
        deleteFurnitureItem,
        selectFurnitureItem,
        getProductSimilarItems,
        replaceProductWithSimilar
    }
)(EditFurniture);
