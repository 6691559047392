import React, {SyntheticEvent, useState} from 'react';
import '../Modal.scss';
import {ReactComponent as Close} from '../../../../img/svg/close.svg';
import {useAppDispatch} from "../../../../store";
import {createNewProject} from "../../../../actions/builderActions";

interface ModalSaveProps {
    isModalOpen: boolean;
    onModalClose: Function;
    onSaveSuccess: Function;
}

const ModalSave = ({isModalOpen, onModalClose, onSaveSuccess}: ModalSaveProps) => {
    const [projectName, setProjectName] = useState('');
    const dispatch = useAppDispatch();

    function closeModal(e: SyntheticEvent) {
        e.preventDefault();
        onModalClose();
    }

    function proceedProjectCreate(e: SyntheticEvent) {
        e.preventDefault();
        if (!projectName || !projectName.length) {
            return
        }
        dispatch(createNewProject(projectName))
            .then(() => {
                onSaveSuccess();
            })
            .catch(err => console.log(err));
    }

    return (
        <div className={isModalOpen ? 'modal__wrap modal__wrap--active' : 'modal__wrap'}>
            <div className="modal">
                <div className="modal__header">
                    <p className="modal__title">
                        Save Project
                    </p>
                    <p className="modal__text">
                        Enter your project name
                    </p>
                    <div className="modal__close">
                        <Close onClick={closeModal}/>
                    </div>
                </div>
                <div className="modal__content">
                    <form className="modal__save-form">
                        <div className="modal__list">
                            <div className="modal__item">
                                <input
                                    className="modal__input"
                                    type="text"
                                    placeholder=""
                                    onChange={e => setProjectName(e.target.value.trim())}
                                />
                            </div>
                            <div className="modal__item modal__item--save">
                                <button type="submit" className="modal__btn" onClick={proceedProjectCreate}>
                                    Save Project
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModalSave;
