import React, {Component} from 'react';
import './ViewerFooterExtended.scss';
/*import {ReactComponent as ArrowUp} from '../../../img/svg/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../img/svg/arrow-down.svg';
import {ReactComponent as Download} from '../../../img/svg/download.svg';*/
import {ReactComponent as Close} from '../../../img/svg/close.svg';
import {AppDispatch, RootState} from "../../../store";
import {connect} from 'react-redux';
/*import UserProfileLinksFooter from "../Auth/UserProfileLinksFooter/UserProfileLinksFooter";*/
import cx from "classnames";
/*import  SaveProjectDialog from "../../builder/SaveProjectDialog/SaveProjectDialog";*/
import Checkout from "../Checkout/Checkout";


interface ViewerFooterExtendedProps {
    expandFooter: boolean;
    toggleFooter: () => void;
}

interface ViewerFooterExtendedState {
}

function mapStateToProps(state: RootState) {
    return {};
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return {};
}

class ViewerFooterExtended extends Component<ViewerFooterExtendedProps, ViewerFooterExtendedState> {

    render() {
        const {expandFooter, toggleFooter} = this.props;
        return (
            <div className="viewer-footer-extended">
                {/* <div
                    className={cx('viewer-footer-extended__header', {'viewer-footer-extended__header--active': expandFooter})}>
                    <div className="viewer-footer-extended__btn-list">
                        <button onClick={() => toggleFooter()}
                                className={cx('viewer-footer-extended__btn viewer-footer-extended__btn--review',
                                    {'viewer-footer-extended__btn--hidden': expandFooter})}>
                            <ArrowUp/>
                            Review
                        </button>
                        <button onClick={() => toggleFooter()}
                                className={cx('viewer-footer-extended__btn viewer-footer-extended__btn--hide',
                                    {'viewer-footer-extended__btn--hidden': !expandFooter})}>
                            <ArrowDown/>
                            Hide
                        </button>
                        <SaveProjectDialog/>
                        <div className="project-header__close" onClick={() => toggleFooter()}>
                            <Close/>
                        </div>
                    </div>
                    <UserProfileLinksFooter/>
                </div> */}

                <div
                    className={cx('viewer-footer-extended__content-wrap',
                        {'viewer-footer-extended__content-wrap--hidden': !expandFooter})}>

                    <div className="viewer-footer-extended__content">
                        <div className="project-header__close" onClick={() => toggleFooter()}>
                            <Close/>
                        </div>
                        <Checkout handleHide={() => toggleFooter()}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ViewerFooterExtended);
