import React, {Component} from 'react';
import {ReactComponent as ArrowDropdown} from "../../../../img/svg/arrow-dropdown.svg";
import cx from 'classnames';

interface FurnitureCatalogDropdownProps {
    children?: React.ReactNode;
    title: string;
    subtitle?: string | React.ReactNode;
    className?: string;
}

interface FurnitureCatalogDropdownState {
    showDropdown: boolean;
}

class FurnitureCatalogDropdown extends Component<FurnitureCatalogDropdownProps, FurnitureCatalogDropdownState> {
    constructor(props: FurnitureCatalogDropdownProps) {
        super(props);
        this.state = {
            showDropdown: false
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    ref = React.createRef<HTMLDivElement>();

    handleClickOutside = (event: MouseEvent | TouchEvent) => {
        const el = this.ref.current;
        if (!el || el.contains(event.target as Node)) {
            return;
        }
        this.setState({
            showDropdown: false
        });
    };

    handleDropdownDisplay = () => {
        this.setState(prevState => {
            return {
                showDropdown: !prevState.showDropdown
            };
        });
    };

    render() {
        const {showDropdown} = this.state;
        const {children, title, subtitle, className} = this.props;
        return (
            <div className="select-menu__item" ref={this.ref}>
                <div
                    className={
                        cx('select', {'select--active': showDropdown}, className)
                    }>
                    <div className="select__content" onClick={() => this.handleDropdownDisplay()}>
                        <div className="select-content__box">
                            <span className="select-content__label">{title}</span>
                            <span className="select-content__text">
                                {subtitle ?? 'Select an option'}
                            </span>
                        </div>
                        <div className="select-content__arrow-wrap">
                            <div className="select-content__arrow">
                                <ArrowDropdown/>
                            </div>
                        </div>
                    </div>
                    {showDropdown && (<div className="select__dropdown">
                        <div className="select__title">{title}</div>
                        {children}
                    </div>)}
                </div>
            </div>
        );
    }
}

export default FurnitureCatalogDropdown;
