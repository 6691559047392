import {
    PROJECT_ERROR_NOTIFY, SELECT_WINDOW_BAY, SET_WINDOW_BAY_INDENT,
    SET_WINDOW_BAY_LENGTH,
    SET_WINDOW_BAY_MIDDLE_WIDTH,
    SET_WINDOW_BAY_POSITION,
    SET_WINDOW_BAY_WIDTH
} from "./types";
import {PositionOptions, WallObject} from "../models/builder";
import store from "../store";
import {
    findPlaceOnWall,
    fitObjectToAvailableSpace,
    getWallObjects,
    getWallWidth, itemsAreInsideWindowBay
} from "../utils/builderRoomSettings";
import {PROJECT_ERRORS} from "../constants/errorMessages";

export function setWindowBayWidth(width: number) {
    const state = store.getState();
    const windowBayObject = {
        width: state.builder.projectData.roomSettings.windowBayWidth,
        wall: state.builder.projectData.roomSettings.windowBayPosition,
        indent: state.builder.projectData.roomSettings.windowBayIndent,
    } as WallObject

    if (width < windowBayObject.width && itemsAreInsideWindowBay()) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: PROJECT_ERRORS.DEFAULT.BAY_CONTAINS_ITEMS
        }
    }

    const indent = fitObjectToAvailableSpace(windowBayObject, width);
    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: `Can't fit window bay to available space`
        }
    }
    return {
        type: SET_WINDOW_BAY_WIDTH,
        width
    }
}

export function setWindowBayMiddleWidth(width: number) {
    return {
        type: SET_WINDOW_BAY_MIDDLE_WIDTH,
        width
    }
}

export function setWindowBayLength(length: number) {
    const state = store.getState();
    if (length < state.builder.projectData.roomSettings.windowBayLength && itemsAreInsideWindowBay()) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: PROJECT_ERRORS.DEFAULT.BAY_CONTAINS_ITEMS // TODO custom objects, objects rotation
        }
    }

    return {
        type: SET_WINDOW_BAY_LENGTH,
        length
    }
}

export function setWindowBayPosition(wall: PositionOptions) {
    if(itemsAreInsideWindowBay()) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: PROJECT_ERRORS.DEFAULT.BAY_CONTAINS_ITEMS
        }
    }
    const state = store.getState();
    const projectData = state.builder.projectData;
    let indent = null;
    const windowBayWidth = projectData.roomSettings.windowBayWidth;

    const wallObjects = getWallObjects(wall);
    if (!wallObjects.length) {
        indent = getWallWidth(projectData, wall)/2 - windowBayWidth/2
    } else {
        indent = findPlaceOnWall(projectData, windowBayWidth, wall, true);
    }

    if (indent === null) {
        return {
            type: PROJECT_ERROR_NOTIFY,
            error: 'No available space'
        }
    } else {
        return {
            type: SET_WINDOW_BAY_POSITION,
            wall,
            indent
        }
    }
}

export function setWindowBayIndent(indent: number) {
    return {
        type: SET_WINDOW_BAY_INDENT,
        indent
    }
}

export function selectWindowBay() {
    return {
        type: SELECT_WINDOW_BAY,
    }
}
