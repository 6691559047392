import React from 'react';
import './RoomSettings.scss';
import RangeSlider from '../../RangeSlider/RangeSlider';
import DetailsHeader from '../../DetailsHeader/DetailsHeader';
/*import {ReactComponent as RotateLeft} from '../../../../img/svg/rotate-left.svg';
import {ReactComponent as RotateRight} from '../../../../img/svg/rotate-right.svg';
import {ReactComponent as MirrorHorizontal} from '../../../../img/svg/mirror-horizontal.svg';
import {ReactComponent as MirrorVertical} from '../../../../img/svg/mirror-vertical.svg';*/
import {connect} from "react-redux";
import {RootState} from "../../../../store";
import {RoomSettings as RoomSettingsType} from "../../../../models/room";
import {ROOM_LENGTH_MAX, ROOM_LENGTH_MIN, ROOM_WIDTH_MAX, ROOM_WIDTH_MIN} from "../../../../constants/roomSettings";
import {setRoomLength, setRoomWidth} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {convertMeasure} from "../../../../utils/convertMeasure";
import {Measure} from "../../../../models/builder";
import {MEASURES_DESC} from "../../../../constants/builderDefaults";
import {find} from "lodash";

interface RoomSettingsProps {
    roomSettings: RoomSettingsType;
    measure: Measure;
    setRoomWidth: (width: number) => AnyAction;
    setRoomLength: (length: number) => AnyAction;
}

function mapStateToProps(state: RootState) {
    return {
        roomSettings: state.builder.projectData.roomSettings,
        measure: state.builder.projectData.measure
    };
}

class RoomSettings extends React.Component<RoomSettingsProps> {
    handleWidthChange = (value: number) => {
        this.props.setRoomWidth(value)
    }
    handleLengthChange = (value: number) => {
        this.props.setRoomLength(value)
    }

    render() {
        const {roomSettings, measure} = this.props;
        return (
            <div className="room-settings">
                <DetailsHeader titleText="Room settings"/>
                <div className="room-settings__range-list">
                    <div className="room-settings__range-item">
                        <RangeSlider
                            inputLabel={'Width'}
                            minValue={convertMeasure(Measure.cm, measure, ROOM_WIDTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, ROOM_WIDTH_MAX)}
                            value={roomSettings.width}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleWidthChange}
                        />
                    </div>
                    <div className="room-settings__range-item">
                        <RangeSlider
                            inputLabel={'Length'}
                            minValue={convertMeasure(Measure.cm, measure, ROOM_LENGTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, ROOM_LENGTH_MAX)}
                            value={roomSettings.length}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            onChange={this.handleLengthChange}
                        />
                    </div>
                </div>
                {/*<div className="room-settings__btn-list">
                    <button className="room-settings__btn room-settings-btn">
            <span className="room-settings-btn__ico-wrap room-settings-btn__ico-wrap--rotate">
              <RotateLeft/>
            </span>
                        Rotate left
                    </button>
                    <button className="room-settings__btn room-settings-btn">
            <span className="room-settings-btn__ico-wrap room-settings-btn__ico-wrap--rotate">
              <RotateRight/>
            </span>
                        Rotate right
                    </button>
                </div>
                <div className="room-settings__btn-list">
                    <button className="room-settings__btn room-settings-btn">
            <span className="room-settings-btn__ico-wrap">
              <MirrorHorizontal/>
            </span>
                        Mirror Horizontally
                    </button>
                    <button className="room-settings__btn room-settings-btn">
            <span className="room-settings-btn__ico-wrap">
              <MirrorVertical/>
            </span>
                        Mirror Vertically
                    </button>
                </div>*/}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        setRoomWidth, setRoomLength
    }
)(RoomSettings);
