import React from 'react';
import './ErrorPages.scss';
import Header from '../../ui/Header/Header';
import {ReactComponent as Ico404} from '../../../img/svg/404.svg';
import RoomTypeModal from "../../ui/RoomTypeModal/RoomTypeModal";
import MarketplaceModalOverlay from "../../ui/Modal/MarketplaceModalOverlay/MarketplaceModalOverlay";
import MarketplaceLink from "../../ui/MarketplaceLink/MarketplaceLink";
import {MARKETPLACE_PATH_PROJECTS} from "../../../constants/marketplaceLinks";

interface Error404Props {
}

interface Error404State {
    showRoomTypeModal: boolean;
}

export default class Error404 extends React.Component<Error404Props, Error404State> {
    constructor(props: Error404Props) {
        super(props);
        this.state = {
            showRoomTypeModal: false
        }
    }

    handleRoomTypeModal(show: boolean) {
        this.setState({showRoomTypeModal: show})
    }

    render() {
        const {showRoomTypeModal} = this.state;
        return (
            <div className="error-page">
                <Header/>
                <div className="error-page__content">
                    <h1 className="error-page__title">Page not found</h1>
                    <p className="error-page__text">Sorry, we couldn’t find the page you were looking for. We suggest
                        that you return to “My projects”.</p>
                    <div className={`error-page__actions`}>
                        <MarketplaceLink path={MARKETPLACE_PATH_PROJECTS} className="error-page__btn">
                            My projects
                        </MarketplaceLink>
                        <button
                            className="error-page__btn"
                            onClick={() => this.handleRoomTypeModal(true)}
                        >
                            New project
                        </button>
                    </div>

                </div>
                <div className="error-page__img-wrap">
                    <Ico404/>
                </div>
                <MarketplaceModalOverlay isOpened={showRoomTypeModal}>
                    <RoomTypeModal onModalClose={() => this.handleRoomTypeModal(false)}/>
                </MarketplaceModalOverlay>
            </div>
        );
    }
}
