import React, {SyntheticEvent} from 'react';
import '../DetailsOption.scss';
import './WindowsSelect.scss';
import {connect} from "react-redux";
import cx from "classnames";
import {ReactComponent as WindowSvg} from '../../../../img/svg/window.svg';
import {ReactComponent as PlusIcon} from '../../../../img/svg/add-select.svg';
import WindowsSettings from '../../SettingsView/WindowsSettings/WindowsSettings';
import {ReactComponent as Delete} from '../../../../img/svg/delete.svg';
import {WindowObject} from "../../../../models/builder";
import {RootState} from "../../../../store";
import {addWindow, removeWindow, selectWindow} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";

interface WindowsSelectProps {
    windows: WindowObject[];
    selectedWindowIndex: number;
    selectWindow: (index: number) => AnyAction;
    addWindow: () => AnyAction;
    removeWindow: (index: number) => AnyAction;
}

export class WindowsSelect extends React.Component<WindowsSelectProps> {
    constructor(props: WindowsSelectProps) {
        super(props);
        if (!props.selectedWindowIndex) {
            props.selectWindow(0);
        }
    }

    handleSelectWindow = (index: number) => {
        this.props.selectWindow(index);
    }

    handleAddWindow = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.addWindow();
    }

    handleRemoveWindow = (e: SyntheticEvent, index: number) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.removeWindow(index);
    }

    render() {
        const {windows, selectedWindowIndex} = this.props;
        return (
            <>
                <div className="details-option">
                    {windows.map((window: WindowObject, index: number) => (
                        <div key={`window-${index}`} className="details-option__item">
                            <input
                                className="details-option__input"
                                type="radio"
                                id={`window-${index}`}
                                checked={index === selectedWindowIndex}
                                onChange={() => this.handleSelectWindow(index)}
                            />
                            <label className="details-option__label" htmlFor={`window-${index}`}>
                                  <span className="details-option__img-wrap">
                                    <WindowSvg/>
                                    <div className="details-option__delete"
                                         onClick={(e) => this.handleRemoveWindow(e, index)}
                                    >
                                      <Delete/>
                                    </div>
                                  </span>
                                <span className="details-option__text">Window #{index+1}</span>
                            </label>
                        </div>
                    ))}
                    <div className="details-option__item">
                        <button className="details-option__btn" onClick={this.handleAddWindow}>
                            <span className="details-option__img-wrap">
                                <PlusIcon/>
                            </span>
                          <span className="details-option__text">Add Window</span>
                        </button>
                    </div>
                </div>
                {windows.map((window: WindowObject, index: number) => (
                    <div key={`window-settings-${index}`}
                         className={cx({'window-settings__settings--hidden': index !== selectedWindowIndex})}>
                        <WindowsSettings window={window}/>
                    </div>
                ))}
            </>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        windows: state.builder.projectData.windows,
        selectedWindowIndex: state.builder.selectedWindowIndex
    };
}

export default connect(
    mapStateToProps,
    {selectWindow, addWindow, removeWindow}
)(WindowsSelect);
