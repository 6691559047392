import React, {Component, SyntheticEvent} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../../../store";
import {ReactComponent as HeaderSearchIcon} from "../../../../../img/svg/header-search.svg";
import {handleSearch} from "../../../../../actions/searchActions";
import {SearchResultFormatted} from "../../../../../models/search";
import MarketplaceLink from "../../../MarketplaceLink/MarketplaceLink";
import {marketplaceUrl} from "../../../../../utils/viewer";
import cx from "classnames";

interface HeaderSearchProps {
    query: string;
    handleSearch: (query: string) => void;
    results: SearchResultFormatted[];
}

interface HeaderSearchState {
    expandSearch: boolean;
}

function mapStateToProps(state: RootState) {
    return {
        query: state.search.query,
        results: state.search.results,
    };
}

const MAX_SEARCH_RESULTS_DISPLAY = 10;

class HeaderSearch extends Component<HeaderSearchProps, HeaderSearchState> {
    constructor(props: HeaderSearchProps) {
        super(props);
        this.state = {
            expandSearch: false
        }
    }

    handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleSearch(e.target.value)
    }

    handleSearchRedirect = (e: SyntheticEvent) => {
        e.preventDefault();
        const {query} = this.props;
        if (query.length) {
            window.open(`${marketplaceUrl()}search?search=${query}`, '_blank')?.focus();
        }
    }

    expandSearchForm = () => {
        this.setState({expandSearch: true})
    }

    hideSearchForm = () => {
        this.setState({expandSearch: false})
    }

    ref = React.createRef<HTMLDivElement>();

    handleClickOutside = (event: MouseEvent | TouchEvent) => {
        const el = this.ref.current;
        if (!el || el.contains(event.target as Node)) {
            return;
        }
        this.hideSearchForm();
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        const {query, results} = this.props;
        const {expandSearch} = this.state;

        return (
            <div
                ref={this.ref}
                className={cx(`header-actions__item header-actions__item--search`, {'header-search--expanded': expandSearch})}
                onMouseEnter={() => this.expandSearchForm()}
            >
                <form className="header-search" onSubmit={this.handleSearchRedirect}>
                    <input className="header-search__input" type="text" name="" placeholder="I’m looking for..."
                           value={query}
                           onChange={this.handleSearchInput}
                    />
                    <button className="header-search__btn" type={`submit`}>
                        <HeaderSearchIcon/>
                    </button>
                </form>
                {expandSearch && results.length > 0 && (<div className={`header-search__results`}>
                    {results.slice(0, MAX_SEARCH_RESULTS_DISPLAY).map((searchData, index) => (
                            <MarketplaceLink
                                key={`search-result-${index}`}
                                path={`/search?search=${searchData.value.replace(/<[^>]*>?/gm, '')}`}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: searchData.value,
                                    }}
                                />
                            </MarketplaceLink>
                        )
                    )}
                </div>)}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {handleSearch}
)(HeaderSearch);
