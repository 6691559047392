import React, {SyntheticEvent} from 'react';
import './ViewerNavigation.scss';
import {connect} from "react-redux";
import {RootState} from "../../../store";
import {BuilderSteps, builderStepsTitles} from "../../../models/builder";
import {navigateToProjectStep} from "../../../actions/builderActions";
import {map} from "lodash";
import cx from "classnames";
import MarketplaceModalOverlay from "../Modal/MarketplaceModalOverlay/MarketplaceModalOverlay";
import RoomTypeModal from "../RoomTypeModal/RoomTypeModal";

import { ReactComponent as Close } from '../../../img/svg/close.svg';
import { ReactComponent as NavRoomType } from '../../../img/svg/nav-room-type.svg';
import { ReactComponent as NavRoomSettings } from '../../../img/svg/nav-room-settings.svg';
import { ReactComponent as NavFloorWalls } from '../../../img/svg/nav-floor-walls.svg';
import { ReactComponent as NavFurniture } from '../../../img/svg/nav-furniture.svg';
import { ReactComponent as BtnReview } from '../../../img/svg/btn-review.svg';
import SaveProjectDialog from '../../builder/SaveProjectDialog/SaveProjectDialog';

const ReactWebTabs = require('react-web-tabs');

function mapStateToProps(state: RootState) {
    return {
        currentProjectStep: state.builder.currentProjectStep
    };
}

interface ViewerNavigationProps {
    currentProjectStep: BuilderSteps;
    navigateToProjectStep: Function;
    toggleFooter: () => void;
}

interface ViewerNavigationState {
    showRoomTypeModal: boolean;
    showNav: boolean;
}

class ViewerNavigation extends React.Component<ViewerNavigationProps, ViewerNavigationState> {
    constructor(props: ViewerNavigationProps) {
        super(props);
        this.state = {
            showRoomTypeModal: false,
            showNav: true,

        }
    }

    handleRoomTypeModal(show: boolean) {
        this.setState({showRoomTypeModal: show})
    }

    toggleNav() {
      this.setState(prevState => ({
        showNav: !prevState.showNav
      }));
      
    }

    render() {
        const {toggleFooter} = this.props;
        const {showNav} = this.state;
        const {currentProjectStep, navigateToProjectStep} = this.props;
        const {showRoomTypeModal} = this.state;
        return (
            <div className={cx('viewer-navigation',
            {'viewer-navigation--hidden': !showNav})}>
              <div className="viewer-navigation__content">
                <div className="viewer-settings-header__menu" onClick={() => this.toggleNav()}>
                    <span className="viewer-settings-header__menu-line"/>
                    <span className="viewer-settings-header__menu-line"/>
                    <span className="viewer-settings-header__menu-line"/>

                    <div className="viewer-settings-header__close">
                      <Close/>
                    </div>
                </div>
                  <ReactWebTabs.TabList className="viewer-navigation__list">
                      {map(BuilderSteps, (builderStep, builderStepCode) => {
                          const isRoomSettings = builderStep === BuilderSteps.RoomSettings;
                          const isFloorWallsSettings = builderStep === BuilderSteps.FloorWallsSettings;
                          const isFurnitureStep = builderStep === BuilderSteps.Furniture;
                          if (builderStep === BuilderSteps.RoomType) {
                              return (
                                  <div
                                      key={`viewer-navigation-${builderStep}`}
                                      className="viewer-navigation__item"
                                      onClick={(e: SyntheticEvent) => {
                                          if (builderStep === BuilderSteps.RoomType){
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.handleRoomTypeModal(true);
                                              return false;
                                          }
                                      }}
                                  >
                                      <input className="viewer-navigation__input"
                                            type="radio"
                                            id={`navigation-${builderStepCode}`}
                                            name="section-3"
                                      />
                                      <label
                                          className={cx("viewer-navigation__label")}
                                          htmlFor={`navigation-${builderStepCode}`}
                                      >
                                          <NavRoomType/>
                                          <span className="viewer-navigation__text">

                                            {builderStepsTitles[builderStep]}
                                          </span>
                                      </label>
                                  </div>)
                          }
                          return (
                              <ReactWebTabs.Tab
                                  key={`viewer-navigation-${builderStep}`}
                                  tabFor={builderStep}
                                  className="viewer-navigation__item"
                              >
                                  <input className="viewer-navigation__input"
                                        type="radio"
                                        id={`navigation-${builderStepCode}`}
                                        name="section-3"
                                        checked={currentProjectStep === builderStep}
                                        onChange={() => {
                                            navigateToProjectStep(builderStep)
                                        }}
                                  />
                                  <label
                                      className={cx(
                                          "viewer-navigation__label",
                                      )}
                                      htmlFor={`navigation-${builderStepCode}`}
                                  >
                                      {isRoomSettings && <NavRoomSettings/>}
                                      {isFloorWallsSettings && <NavFloorWalls/>}
                                      {isFurnitureStep && <NavFurniture/>}
                                      <span className="viewer-navigation__text">
                                        {builderStepsTitles[builderStep]}
                                      </span>
                                  </label>
                              </ReactWebTabs.Tab>
                          )
                      })}
                  </ReactWebTabs.TabList>
                </div>
                <div className="viewer-navigation__btns-wrap">
                    <button className={cx('viewer-navigation__btn viewer-navigation__btn--review')}
                    onClick={() => toggleFooter()}>
                        <BtnReview/>
                        <span className="viewer-navigation__text">Review</span>
                    </button>
                    {/* <button className={cx('viewer-navigation__btn viewer-navigation__btn--save')}>
                        Save
                    </button> */}
                    <SaveProjectDialog/>
                </div>
                <MarketplaceModalOverlay isOpened={showRoomTypeModal}>
                    <RoomTypeModal onModalClose={() => this.handleRoomTypeModal(false)}/>
                </MarketplaceModalOverlay>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, {navigateToProjectStep}
)(ViewerNavigation);
