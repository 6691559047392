import {RoomSettings, RoomType} from "./room";
import {FloorStyle, FloorWallsStyle} from "./floorWalls";
import {FurnitureCustomObject, FurnitureProduct, FurnitureProductsVariant} from "./furnitureCatalog";

export interface BuilderStoreData {
    projectData: ProjectData;

    currentProjectStep: BuilderSteps;

    viewerDataIsReady: boolean;

    projectIsFetching: boolean;
    projectFetchError: string | null;
    projectFetchSuccess: boolean;

    projectInitInProgress: boolean;
    projectInitError: string | null;

    projectSavingInProgress: boolean,

    selectedDoorIndex: number | null;
    selectedWindowIndex: number | null;
    selectedWall: PositionOptions | null;

    windowBaySelected: boolean;

    projectError: string | null;

    floorStyles: FloorStyle[],
    floorStylesFetchStarted: boolean;
    floorStylesFetchError: string | null;

    selectedFurnitureItemIndex: number | null;
    selectedCustomObjectIndex: number | null;

    addToCartProcessing: boolean;
    addToCartError: null | string;
    addToCartSuccess: boolean,

    builderScale: number;

    roomTypes: RoomType[] | null;
    roomTypesAreLoading: boolean;
}

export interface ProjectData {
    id?: number;
    name?: string;
    roomType?: string;
    measure: Measure;
    roomSettings: RoomSettings;
    doors: DoorObject[];
    windows: WindowObject[];
    floorWallsStyle: FloorWallsStyle;
    furnitureItems: FurnitureItem[];
    furnitureCustomObjects: FurnitureCustomObject[];
}

export enum BuilderSteps {
    RoomType = "room-type",
    RoomSettings = "main-room-settings",
    FloorWallsSettings = "floor-walls",
    Furniture = "add-furniture"
}

export const builderStepsTitles = {
    [BuilderSteps.RoomType]: 'Room Type',
    [BuilderSteps.RoomSettings]: 'Room settings',
    [BuilderSteps.FloorWallsSettings]: 'Floor & Walls',
    [BuilderSteps.Furniture]: 'Add Furniture',
}

export enum Measure {
    cm = 'cm',
    inches = 'inches'
}

export enum PositionOptions {
    left = 'left',
    bottom = 'bottom',
    top = 'top',
    right = 'right',
}
export interface WallObject {
    width: number;
    wall: PositionOptions;
    indent: number;
    type: WallObjectType
}

export interface DoorObject extends WallObject {
    doorOpensOutside: boolean;
    length: number;
}

export interface WindowObject extends WallObject {
    length: number;
    height: number;
}


export enum WallObjectType {
    door = 'door',
    window = 'window'
}

export interface FurnitureObject {
    position: FurnitureItemPosition;
    rotate: number;
    level: number;
}

export interface FurnitureItem extends FurnitureObject{
    product: FurnitureProduct;
    variant?: FurnitureProductsVariant;
}

export interface FurnitureItemPosition {
    x: number;
    y: number;
}

export interface FurnitureCartItem {
    product_id: string;
    variant_id: string;
    quantity: number;
}
