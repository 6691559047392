import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {FETCH_HEADER_MENU_ERROR, FETCH_HEADER_MENU_START, FETCH_HEADER_MENU_SUCCESS} from "./types/marketplaceContent";
import {getHeaderMenuRequest} from "../requests/marketplaceContentRequests";

export function fetchHeaderMenu() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: FETCH_HEADER_MENU_START})
        return getHeaderMenuRequest()
            .then((res) => {
                dispatch({
                    type: FETCH_HEADER_MENU_SUCCESS,
                    menuItems: res.data.items,
                    categoriesData: res.data.categoriesData
                })
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_HEADER_MENU_ERROR
                });
            });
    }
}
