import React from 'react';
import './ColorPickerRadio.scss';
import {FloorTextureOption} from "../../../models/floorWalls";

interface ColorPickerRadioProps {
    options: FloorTextureOption[];
    parentName: string;
    selected: string;
    handleOptionChange: (textureId: string) => void;
}

export default class ColorPickerRadio extends React.Component<ColorPickerRadioProps> {
    render() {
        const {options, parentName, selected, handleOptionChange} = this.props;
        return (
            <div className="color-picker-radio">
                <p className="color-picker-radio__title">Available colors for <b>{parentName}</b></p>
                <div className="color-picker-radio__list">
                    {options.map(option => (
                        <label className="color-picker-radio__item" key={`texture-option-${option.textureId}`}>
                            <input
                                className="color-picker-radio__input"
                                type="radio"
                                name="position"
                                checked={option.textureId === selected}
                                onChange={() => {
                                    if (!(option.textureId === selected)) {
                                        handleOptionChange(option.textureId)
                                    }
                                }}
                            />
                            <div className="color-picker-radio__content">
                                <img className="color-picker-radio__img" src={option.textureImg} alt={option.name}/>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        );
    }
}
