import {COLOR_ALL_WALLS, SELECT_WALL, SET_FLOOR_STYLE, SET_WALL_COLOR} from "./types";
import {PositionOptions} from "../models/builder";
import {FloorSettings} from "../models/floorWalls";

export function selectWall(wall: PositionOptions) {
    return {
        type: SELECT_WALL,
        wall
    }
}
export function setWallColor(wall: PositionOptions, colorHEX: string) {
    return {
        type: SET_WALL_COLOR,
        wall,
        colorHEX
    }
}

export function applyColorToAllWalls(colorHEX: string) {
    return {
        type: COLOR_ALL_WALLS,
        colorHEX
    }
}

export function setFloorStyle(floorSettings: FloorSettings) {
    return {
        type: SET_FLOOR_STYLE,
        floorSettings
    }
}
