import React from 'react';
import '../DetailsOption.scss';
import {ReactComponent as SquareRoom} from '../../../../img/svg/square-room.svg';
import {ReactComponent as RectangleRoom} from '../../../../img/svg/rectangle-room.svg';
import {ReactComponent as WindowBayRoom} from '../../../../img/svg/window-bay-room.svg';
import RoomSettings from '../../SettingsView/RoomSettings/RoomSettings';
import RoomSettingsWindowed from '../../SettingsView/RoomSettings/RoomSettingsWindowed/RoomSettingsWindowed';
import {connect} from "react-redux";
import {RootState} from "../../../../store";
import {RoomSettings as RoomSettingsType, RoomShape} from "../../../../models/room";
import {changeRoomShape} from "../../../../actions/builderActions";
import {DEFAULT_ROOM_SHAPE} from "../../../../constants/roomSettings";

const ReactWebTabs = require('react-web-tabs');

interface RoomSelectProps {
    roomSettings: RoomSettingsType;
    changeRoomShape: Function;
}

interface RoomSelectState {
    currentRoomShape: RoomShape;
}

interface RoomSelectTab {
    id: string;
    name: string;
    view: React.ReactNode;
    content: React.ReactNode;
    roomShape: RoomShape;
}

function mapStateToProps(state: RootState) {
    return {
        roomSettings: state.builder.projectData.roomSettings
    };
}

class RoomSelect extends React.Component<RoomSelectProps, RoomSelectState> {
    constructor(props: RoomSelectProps) {
        super(props);
        this.state = {
            currentRoomShape: this.roomSelectTabs.find(tab => tab.roomShape===props.roomSettings.shape)?.roomShape || DEFAULT_ROOM_SHAPE
        };
    }

    componentDidUpdate(prevProps: Readonly<RoomSelectProps>, prevState: Readonly<RoomSelectState>, snapshot?: any) {
        if (prevProps.roomSettings.shape !== this.props.roomSettings.shape) {
            const roomShape = this.roomSelectTabs.find(tab => tab.roomShape === this.props.roomSettings.shape)?.roomShape;
            if (roomShape) {
                this.setState({currentRoomShape: roomShape})
            }
        }
    }

    roomSelectTabs = [
        {
            id: 'rs__square',
            name: 'Square',
            view: <SquareRoom/>,
            content: <RoomSettings/>,
            roomShape: RoomShape.square,
        },
        {
            id: 'rs__rectangle',
            name: 'Rectangle',
            view: <RectangleRoom/>,
            content: <RoomSettings/>,
            roomShape: RoomShape.rectangle,
        },
        {
            id: 'rs__window-bay',
            name: 'Window Bay',
            view: <WindowBayRoom/>,
            content: <RoomSettingsWindowed/>,
            roomShape: RoomShape.windowBay,
        },
    ];

    switchRoomShape = (tab: RoomSelectTab) => {
        this.props.changeRoomShape(tab.roomShape)
    }

    render() {
        const {currentRoomShape} = this.state;
        return (
            <ReactWebTabs.Tabs defaultTab={this.state.currentRoomShape}>
                <ReactWebTabs.TabList className="details-option">
                    {this.roomSelectTabs.map((tab: RoomSelectTab) => (
                            <div className="details-option__item" key={`${tab.id}`}>
                                <input className="details-option__input" type="radio" id={tab.id} name="section-2"
                                       checked={currentRoomShape === tab.roomShape}
                                       onChange={() => this.switchRoomShape(tab)}
                                />
                                <label className="details-option__label" htmlFor={tab.id}>
                                <span className="details-option__img-wrap">
                                    {tab.view}
                                </span>
                                    <span className="details-option__text">{tab.name}</span>
                                </label>
                            </div>
                        )
                    )}
                </ReactWebTabs.TabList>
                {this.roomSelectTabs.map((tab: RoomSelectTab) => (
                    <React.Fragment key={`tab-${tab.id}`}>
                    {(currentRoomShape === tab.roomShape) && (
                        <div key={`tab-${tab.id}`}>
                            {tab.content}
                        </div>
                    )}
                    </React.Fragment>)
                )}

            </ReactWebTabs.Tabs>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        changeRoomShape
    }
)(RoomSelect);
