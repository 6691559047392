import React from 'react';
import '../ColorPickerRadio.scss';

interface ColorPickerRadioSmallProps {
    options: ColorPickerRadioSmallOption[];
    selected?: string;
    handleOptionSelect: (selected: string) => void;
    pickerName: string;
}

export interface ColorPickerRadioSmallOption {
    id: string;
    previewImg: string;
    color: string;
    name: string;
}

export default class ColorPickerRadioSmall extends React.Component<ColorPickerRadioSmallProps> {

    render() {
        const {options, selected, handleOptionSelect, pickerName} = this.props;
        return (
            <div className="color-picker-radio-small">
                <div className="color-picker-radio__list">
                    {options.map(option => (
                        <label className="color-picker-radio__item" key={`picker-option-${option.id}`}>
                            <input className="color-picker-radio__input"
                                   type="radio"
                                   name={pickerName}
                                   checked={option.id === selected}
                                   onChange={() => handleOptionSelect(option.id)}
                            />
                            <div className="color-picker-radio__content" title={option.name}>
                                {option.previewImg
                                    ? (<img className="color-picker-radio__img" src={option.previewImg}
                                            alt={`picker-option-${option.id}`}/>)
                                    : (<div className="color-picker-radio__img"
                                            style={{backgroundColor: option.color}}/>)}
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        );
    }
}
