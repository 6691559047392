import React, {Component, DragEvent} from 'react';
import {ReactComponent as Ico360} from "../../../../img/svg/360-ico.svg";
/*import {ReactComponent as Like} from "../../../../img/svg/like.svg";*/
import {FurnitureProduct} from "../../../../models/furnitureCatalog";
import {head} from "lodash";
import {getFurnitureProductVariant} from "../../../../utils/furniture";
import {FurnitureDimensions} from "../FurnitureDimensions/FurnitureDimensions";
import {
    DND_HANDLED_EVENT,
    DND_HANDLED_EVENT_DATA,
    DROP_FURNITURE_ITEM_EVENT
} from "../../../../constants/dragDropEvents";
import cx from "classnames";
import View360Modal from "../../Modal/View360Modal/View360Modal";
import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";
import {ReactComponent as ProductInfo} from "../../../../img/svg/product-info.svg";
import {ReactComponent as AddFurniture} from "../../../../img/svg/add-furniture.svg";
import {AnyAction} from "@reduxjs/toolkit";
import {connect} from "react-redux";
import {addFurnitureItem} from "../../../../actions/furnitureBuilderActions";

interface FurnitureListItemProps {
    product: FurnitureProduct;
    selectedVariant?: string;
    handleVariantChange: (variantId: string) => void;
    addFurnitureItem: (item: FurnitureProduct, selectedVariant?: string) => AnyAction;
}

interface FurnitureListItemState {
    show360Modal: boolean;
    isHovered: boolean;

}

class FurnitureListItemPreview extends Component<FurnitureListItemProps, FurnitureListItemState> {
    constructor(props: FurnitureListItemProps) {
        super(props);
        this.state = {
            show360Modal: false,
            isHovered: false,
        };
        this.toggleHoveredTrue = this.toggleHoveredTrue.bind(this);
        this.toggleHoveredFalse = this.toggleHoveredFalse.bind(this);
    }

    toggleHoveredTrue = () => {
        this.setState(prevState => ({
          isHovered: true
      }));
    }

    toggleHoveredFalse = () => {
        this.setState(prevState => ({
          isHovered: false
      }));
    }

    dragItem = (e: DragEvent) => {
        const {product, selectedVariant} = this.props;
        let variantId = selectedVariant;

        if (!variantId) {
            const availableVariants = head(product.productOptions)?.variants
            if (availableVariants && availableVariants.length > 0) {
                variantId = head(availableVariants)?.id;
            }
        }

        e.dataTransfer.setData(DND_HANDLED_EVENT, DROP_FURNITURE_ITEM_EVENT)
        e.dataTransfer.setData(DND_HANDLED_EVENT_DATA, JSON.stringify({
            productId: product.id,
            variantId
        }))
    };

    handle360Modal(show360Modal: boolean) {
        this.setState({show360Modal})
    }

    handle360IconClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        this.handle360Modal(true);
    }

    handleProductAdd() {
        const {product, selectedVariant} = this.props
        this.props.addFurnitureItem(product, selectedVariant);
    }

    render() {
        const btnClass = this.state.isHovered ? "furniture-settings__add-btn" : "furniture-settings__add-btn furniture-settings__add-btn--active";

        const {product, selectedVariant} = this.props;
        const selectedVariantData = getFurnitureProductVariant(product, selectedVariant as string);

        const previewImage = head(selectedVariantData ? selectedVariantData.images : product.images);
        const productFullTitle = product.name + (selectedVariantData ? `, ${selectedVariantData.name}` : '');

        const price = selectedVariantData?.price || product.price,
            salePrice = selectedVariantData?.salePrice || product.salePrice;

        const view360 = (selectedVariantData?.view360 || product.attributes.view360 || []).filter(file => file);
        const {show360Modal} = this.state;

        let marketplaceLink = `product/${product.slug}`;
        if (selectedVariantData) {
            marketplaceLink += `/${selectedVariantData.sku}`
        }

        return (
            <div className="furniture-list__label">
                <div
                    className="furniture-list__img-wrap"
                    draggable="true"
                    onDragStart={this.dragItem}
                >
                    {previewImage && (
                        <img className="furniture-list__img" src={previewImage.file.url} alt={productFullTitle}/>
                    )}
                    {view360.length > 0 && (<>
                            <div className="furniture-list__360" onClick={e => this.handle360IconClick(e)}>
                                <Ico360/>
                            </div>
                            <View360Modal
                                isModalOpen={show360Modal}
                                onModalClose={() => this.handle360Modal(false)}
                                product360images={view360}
                                {...{product, selectedVariant}}
                                handleVariantChange={this.props.handleVariantChange}
                            />
                        </>
                    )}

                    {/*<div className="furniture-list__like">
                        <Like/>
                    </div>*/}
                </div>
                <div className="furniture-list__content">
                    <div className="furniture-list__title">{productFullTitle}</div>
                    <div className="furniture-list__description">
                        <FurnitureDimensions {...product.attributes}/>
                    </div>
                    <div className="furniture-list__price-id">
                        <div className={cx("furniture-list__price", {'furniture-list__price--old': salePrice})}>
                            £{price}
                        </div>
                        {salePrice && (
                            <div className="furniture-list__price furniture-list__price--sale">
                                £{salePrice}
                            </div>
                        )}
                        <div className="furniture-list__id">Item # {selectedVariantData?.sku || product.sku}</div>
                    </div>
                    <div className="furniture-list__active-content">
                        <div className="furniture-list__active-content-wrap">
                            <button className={btnClass} onClick={() => this.handleProductAdd()}>
                                <span>Add to room</span>
                                <AddFurniture/>
                            </button>
                            <div onMouseEnter={this.toggleHoveredTrue} onMouseLeave={this.toggleHoveredFalse} className="furniture-settings__btn-wrap">
                              <MarketplaceLink className="furniture-settings__btn" path={marketplaceLink} >
                                  <span>Product details</span>
                                  <ProductInfo/>
                              </MarketplaceLink>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(
    () => ({}), {addFurnitureItem}
)(FurnitureListItemPreview);
