import React, {Component} from 'react';
import './Breadcrumbs.scss';
import {connect} from 'react-redux';
import {RootState} from "../../../store";
import {MARKETPLACE_PATH_PROJECTS} from "../../../constants/marketplaceLinks";
import MarketplaceLink from "../MarketplaceLink/MarketplaceLink";
import {AuthorizedUser} from "../../../models/auth";
import {ProjectData} from "../../../models/builder";
import {RoomType} from "../../../models/room";

interface BreadcrumbsProps {
    user: AuthorizedUser;
    projectData: ProjectData;
    roomType?: RoomType;
}

class Breadcrumbs extends Component<BreadcrumbsProps> {
    render() {
        const {user, projectData, roomType} = this.props;
        return (
            <div className="breadcrumbs">
                <div className="breadcrumbs__item">
                    <MarketplaceLink path={''} className="breadcrumbs__link">
                        Home
                    </MarketplaceLink>
                    <div className="breadcrumbs__separator">/</div>
                </div>
                {/*<div className="breadcrumbs__item">
                    <MarketplaceLink path={'???'} className="breadcrumbs__link">
                        Room Builder
                    </MarketplaceLink>
                    <div className="breadcrumbs__separator">/</div>
                </div>*/}
                {user.isAuthenticated && (
                    <div className="breadcrumbs__item">
                        <MarketplaceLink path={MARKETPLACE_PATH_PROJECTS} className="breadcrumbs__link">
                            My projects
                        </MarketplaceLink>
                        <div className="breadcrumbs__separator">/</div>
                    </div>
                )}
                {projectData.name
                    ? (
                        <div className="breadcrumbs__item">
                            <div className="breadcrumbs__link">{projectData.name}</div>
                            <div className="breadcrumbs__separator">/</div>
                        </div>
                    )
                    : (
                        <div className="breadcrumbs__item">
                            <div className="breadcrumbs__link">
                                New project{roomType && `: ${roomType.name.toLowerCase()}`}
                            </div>
                            <div className="breadcrumbs__separator">/</div>
                        </div>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        user: state.user,
        projectData: state.builder.projectData,
        roomType: state.furnitureCatalog.roomType
    };
}

export default connect(
    mapStateToProps,
)(Breadcrumbs);
