export const RESPONSIVE_BREAKPOINTS = {
    CUSTOM_OBJECT: {
        SQUARE: {
            DOWNSCALE: {
                WIDTH: 50,
                LEN: 75,
            },
            CAPTION: {
                WIDTH: 30,
                LEN: 40,
            }
        },
        ROUND: {
            DOWNSCALE: {
                WIDTH: 75,
                LEN: 75,
            },
            CAPTION: {
                WIDTH: 50,
                LEN: 50,
            }
        }

    }
}
