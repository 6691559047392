import React, {Component} from 'react';
/*import {ReactComponent as View360} from "../../../../img/svg/view360.svg";
import {ReactComponent as NewTabIcon} from "../../../../img/svg/new-tab.svg";
import {ReactComponent as AddFurniture} from "../../../../img/svg/add-furniture.svg";*/
import {FurnitureProduct} from "../../../../models/furnitureCatalog";
/*import MarketplaceLink from "../../MarketplaceLink/MarketplaceLink";*/
import {connect} from 'react-redux';
import {RootState} from "../../../../store";
import {addFurnitureItem} from "../../../../actions/furnitureBuilderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {getFurnitureProductVariant} from "../../../../utils/furniture";
import FurnitureVariants from "../FurnitureVariants/FurnitureVariants";
import {FurnitureDimensions} from "../FurnitureDimensions/FurnitureDimensions";
import cx from "classnames";

interface FurnitureItemExpandedProps {
    product: FurnitureProduct;
    handleVariantChange: (variantId: string) => void;
    selectedVariant?: string;
    addFurnitureItem: (item: FurnitureProduct, selectedVariant?: string) => AnyAction
}

class FurnitureItemExpanded extends Component<FurnitureItemExpandedProps> {
    handleProductAdd() {
        const {product, selectedVariant} = this.props
        this.props.addFurnitureItem(product, selectedVariant);
    }

    render() {
        const {product, handleVariantChange, selectedVariant} = this.props;
        const selectedVariantData = getFurnitureProductVariant(product, selectedVariant as string);
        const productFullTitle = product.name + (selectedVariantData ? `, ${selectedVariantData.name}` : '');
        const price = selectedVariantData?.price || product.price,
            salePrice = selectedVariantData?.salePrice || product.salePrice;
        /*let marketplaceLink = `product/${product.slug}`;
        if (selectedVariantData) {
            marketplaceLink += `/${selectedVariantData.sku}`
        }*/

        return (
            <div className="furniture-settings">
                <div className="furniture-settings__id">Item # {selectedVariantData?.sku || product.sku}</div>
                <div className="furniture-settings__name">{productFullTitle}</div>
                <div className="furniture-settings__description">
                    <FurnitureDimensions {...product.attributes}/>
                </div>
                <div className="furniture-settings__price-id">
                    <div className={cx("furniture-settings__price", {'furniture-settings__price--old': salePrice})}>
                        £{price}
                    </div>
                    {salePrice && (
                        <div className="furniture-settings__price furniture-settings--sale">
                            £{salePrice}
                        </div>
                    )}
                </div>


                <FurnitureVariants
                    {...{
                        product,
                        selectedVariant,
                        handleVariantChange,
                        wrapClassname: "furniture-settings__list furniture-settings__list--variants",
                        pickerNamePreffix: 'list'
                    }}
                />

                {/* <div className="furniture-settings__add-btn-wrap furniture-settings__add-btn-wrap--list">
                    <button className="furniture-settings__btn">
                        360° view
                        <View360/>
                    </button>
                    <MarketplaceLink className="furniture-settings__btn" path={marketplaceLink}>
                        Product Information
                        <NewTabIcon/>
                    </MarketplaceLink>
                </div>
                <div className="furniture-settings__add-btn-wrap">
                    <button className="furniture-settings__add-btn" onClick={() => this.handleProductAdd()}>
                        Add to room
                        <AddFurniture/>
                    </button>
                    <div className="furniture-settings__text">
                        Or simply drag & drop it to the scene
                    </div>
                </div> */}
            </div>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(
    mapStateToProps, {addFurnitureItem}
)(FurnitureItemExpanded);
