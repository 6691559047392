import {AnyAction} from "@reduxjs/toolkit";
import {RESET_USER, FETCH_USER_START, FETCH_USER_SUCCESS, FETCH_USER_ERROR, LOGIN_USER_START, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR} from "../actions/types";
import {AuthorizedUser} from "../models/auth";

const initialState = {
    isAuthenticated: null,
    user: {},
    userIsLoading: false,
    loginIsProcessing: false,
} as AuthorizedUser;

function userReducer(state = initialState, action: AnyAction): AuthorizedUser {
    switch(action.type) {
        case RESET_USER:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            };
        case FETCH_USER_START:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                userIsLoading: true,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                userIsLoading: false,
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                userIsLoading: false,
            };
        case LOGIN_USER_START:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                loginIsProcessing: true
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                loginIsProcessing: false,
            };
        case LOGIN_USER_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                loginIsProcessing: false,
            };
        default: return state;
    }
}

export default userReducer;

