import React, {SyntheticEvent} from 'react';
import '../DetailsOption.scss';
import './DoorsSelect.scss';
import {connect} from 'react-redux';
import {AnyAction} from "@reduxjs/toolkit";
import cx from "classnames";
import {ReactComponent as DoorSvg} from '../../../../img/svg/door.svg';
import {ReactComponent as PlusIcon} from '../../../../img/svg/add-select.svg';
import DoorsSettings from '../../SettingsView/DoorsSettings/DoorsSettings';
import {ReactComponent as Delete} from '../../../../img/svg/delete.svg';
import {RootState} from "../../../../store";
import {DoorObject, WallObject} from "../../../../models/builder";
import {addDoor, removeDoor, selectDoor} from "../../../../actions/builderActions";

interface DoorsSelectProps {
    doors: DoorObject[];
    addDoor: () => AnyAction;
    removeDoor: (index: number) => AnyAction;
    selectDoor: (index: number) => AnyAction;
    selectedDoorIndex: number;
}

class DoorsSelect extends React.Component<DoorsSelectProps> {
    constructor(props: DoorsSelectProps) {
        super(props);
        if(!props.selectedDoorIndex) {
            props.selectDoor(0);
        }
    }

    handleSelectDoor = (index: number) => {
        this.props.selectDoor(index);
    }

    handleAddDoor = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.addDoor();
    }

    handleRemoveDoor = (e: SyntheticEvent, index: number) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.removeDoor(index);
    }

    render() {
        const {doors, selectedDoorIndex} = this.props;
        return (
            <>
                <div className="details-option">
                    {doors.map((door: WallObject, index: number) => (
                        <div className="details-option__item" key={`door-${index}`}>
                            <input
                                className="details-option__input"
                                type="radio"
                                id={`door-${index}`}
                                checked={index === selectedDoorIndex}
                                onChange={() => this.handleSelectDoor(index)}
                            />
                            <label className="details-option__label" htmlFor={`door-${index}`}>
                                <span className="details-option__img-wrap">
                                    <DoorSvg/>
                                    <div className="details-option__delete"
                                         onClick={(e) => this.handleRemoveDoor(e, index)}
                                    >
                                          <Delete/>
                                    </div>
                                </span>
                                <span className="details-option__text">Door #{index + 1}</span>
                            </label>
                        </div>
                    ))}
                    <div className="details-option__item">
                        <button className="details-option__btn" onClick={this.handleAddDoor}>
                            <span className="details-option__img-wrap">
                                <PlusIcon/>
                            </span>
                            <span className="details-option__text">Add Door</span>
                        </button>
                    </div>
                </div>

                {doors.map((door: DoorObject, index: number) => (
                    <div key={`door-settings-${index}`}
                        className={cx({'door-settings__settings--hidden': index !== selectedDoorIndex})}>
                        <DoorsSettings door={door}/>
                    </div>
                ))}
            </>
        );
    }
}


function mapStateToProps(state: RootState) {
    return {
        doors: state.builder.projectData.doors,
        selectedDoorIndex: state.builder.selectedDoorIndex
    };
}

export default connect(
    mapStateToProps,
    { addDoor, removeDoor, selectDoor }
)(DoorsSelect);
