import {BuilderWallObject} from "../BuilderWallObject";
import {Builder} from "../../Builder";
import {DoorObject} from "../../../../../models/builder";
import React from "react";
import {ReactComponent as CopyIcon} from "../../../../../img/svg/new-tab.svg";
import {ReactComponent as MirrorVerticalIcon} from "../../../../../img/svg/mirror-vertical.svg";
import {ReactComponent as DeleteIcon} from "../../../../../img/svg/delete.svg";
import {
    BUILDER_DOOR_OPEN_COLOR,
    BUILDER_ROOM_MARKUP_DASH,
    DOOR_OBJECT_HEIGHT_MOBILE,
    DOOR_OPEN_ANGLE_INDENT,
    WALL_OBJECT_HEIGHT,
    WALL_OBJECT_LINE_WIDTH,
    WALL_OBJECT_LINE_WIDTH_MOBILE
} from "../../../../../constants/builderDefaults";
import {getBaseMeasureValue} from "../../../../../utils/convertMeasure";

export class BuilderDoor extends BuilderWallObject {

    protected doorData: DoorObject;

    constructor(builder: Builder, index: number, doorData: DoorObject) {
        super(builder, index, doorData);

        this.doorData = doorData;

        this.drawView();
        this.position();
        this.handleDrag();

        if (index === this.builder.props.selectedDoorIndex) {
            this.selectObject();
        }
    }

    drawView = () => {
        super.drawView();
        const drawArea = this.builder.drawArea;
        const objectData = this.doorData;
        if (!drawArea || !objectData || !this.objectLine) {
            return;
        }
        const doorOpen = drawArea.group();

        const isMobile = this.builder.isMobile;

        const doorHeight = isMobile ? DOOR_OBJECT_HEIGHT_MOBILE : WALL_OBJECT_HEIGHT;
        const objectLineWidth = isMobile ? WALL_OBJECT_LINE_WIDTH_MOBILE : WALL_OBJECT_LINE_WIDTH

        const doorOpenY = objectData.doorOpensOutside ? 0 - doorHeight : doorHeight;
        const objectWidth = getBaseMeasureValue(this.builder.props.projectData.measure, objectData.width);
        doorOpen.add(drawArea
            .line(0, 0, DOOR_OPEN_ANGLE_INDENT, doorOpenY)
            .stroke({color: BUILDER_DOOR_OPEN_COLOR, dasharray: BUILDER_ROOM_MARKUP_DASH})
        );
        doorOpen.add(drawArea.line(DOOR_OPEN_ANGLE_INDENT, doorOpenY, objectWidth * this.builder.scale, 0)
            .stroke({color: BUILDER_DOOR_OPEN_COLOR, width: objectLineWidth}))
        this.objectLine.before(doorOpen)
    }

    drawMenu = () => {
        super.setPositionMenuOptions(
            wall => this.builder?.props.setDoorWall(wall)
        )

        this.menuBottomOptions = [
            {
                icon: <CopyIcon/>,
                handler: () => this.builder?.props.copyDoor()
            },
            {
                icon: <MirrorVerticalIcon/>,
                handler: () => this.builder?.props.mirrorDoorOpen(),
                active: !this.doorData?.doorOpensOutside
            },
            {
                icon: <DeleteIcon/>,
                handler: () => this.builder?.props.removeDoor(this.index)
            }
        ]
        super.drawMenu()
    }

    handleBeforeDrag() {
        this.builder.props.selectDoor(this.index);
    }

    setObjectIndent(indent: number) {
        this.builder.props.setDoorIndent(indent)
    }
}
