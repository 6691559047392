import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../store";
import cx from "classnames";
import "./MobileObjectActions.scss";
import MobileObjectActionButton from "./MobileObjectActionButton/MobileObjectActionButton";
import {ReactComponent as CopyIcon} from "../../../img/svg/new-tab.svg";
import {ReactComponent as MirrorVerticalIcon} from "../../../img/svg/mirror-vertical.svg";
import {ReactComponent as DeleteIcon} from "../../../img/svg/delete.svg";
import {ReactComponent as EditIcon} from "../../../img/svg/edit.svg";
import {ReactComponent as RotateLeftIcon} from "../../../img/svg/rotate-left.svg";
import {ReactComponent as RotateRightIcon} from "../../../img/svg/rotate-right.svg";
import {ReactComponent as SendToBackIcon} from "../../../img/svg/send-to-back.svg";
import {ReactComponent as SendBackwardIcon} from "../../../img/svg/send-backward.svg";
import {ReactComponent as BringForwardIcon} from "../../../img/svg/bring-forward.svg";
import {ReactComponent as BringToFrontIcon} from "../../../img/svg/bring-to-front.svg";
import {
    copyDoor,
    copyWindow,
    mirrorDoorOpen,
    removeDoor,
    removeWindow,
    selectDoor,
    selectWindow
} from "../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {ReactComponent as CloseIcon} from "../../../img/svg/close.svg";
import {ProjectData} from "../../../models/builder";
import {isMobile} from "../../../utils/responsive";
import {
    deleteCustomObject,
    deleteFurnitureItem, duplicateCustomObject,
    duplicateFurnitureItem, rotateCustomObject,
    rotateFurnitureItem, selectCustomObject,
    selectFurnitureItem, setCustomObjectLevel,
    setFurnitureItemLevel
} from "../../../actions/furnitureBuilderActions";
import {FURNITURE_ITEM_ROTATE_ANGLE} from "../../../constants/furnitureBuilder";
import {getFurnitureCurrentHighestLevel, getFurnitureCurrentLowestLevel} from "../../../utils/furniture";
import {FurnitureDimensions} from "../../ui/FurnitureList/FurnitureDimensions/FurnitureDimensions";

interface MobileObjectActionsProps {
    toggleSettings: () => void;
    toggleFurnitureSettings: () => void;
    toggleCustomObjectSettings: () => void;

    selectedDoorIndex: number | null;
    selectedWindowIndex: number | null;

    copyDoor: () => AnyAction;
    mirrorDoorOpen: () => AnyAction;
    selectDoor: (index: number | null) => AnyAction;
    removeDoor: (index: number) => AnyAction;

    selectWindow: (index: number | null) => AnyAction;
    removeWindow: (index: number) => AnyAction;
    copyWindow: () => AnyAction;

    selectedFurnitureItemIndex: number | null;
    selectFurnitureItem: (index: number | null) => AnyAction;
    deleteFurnitureItem: (index: number | null) => AnyAction;
    rotateFurnitureItem: (rotate: number) => AnyAction;
    duplicateFurnitureItem: () => AnyAction;
    setFurnitureItemLevel: (level: number) => AnyAction;

    selectedCustomObjectIndex: number | null;
    selectCustomObject: (itemIndex: number | null) => AnyAction;
    duplicateCustomObject: () => AnyAction;
    deleteCustomObject: () => AnyAction;
    setCustomObjectLevel: (level: number) => AnyAction;
    rotateCustomObject: (rotate: number) => AnyAction;

    projectData: ProjectData;
}

function mapStateToProps(state: RootState) {
    return {
        selectedDoorIndex: state.builder.selectedDoorIndex,
        selectedWindowIndex: state.builder.selectedWindowIndex,
        selectedFurnitureItemIndex: state.builder.selectedFurnitureItemIndex,
        selectedCustomObjectIndex: state.builder.selectedCustomObjectIndex,
        projectData: state.builder.projectData
    }
}

class MobileObjectActions extends Component<MobileObjectActionsProps> {

    handleFurnitureLevel(level: number) {
        this.props.setFurnitureItemLevel(level)
    }

    handleCustomObjectLevel(level: number) {
        this.props.setCustomObjectLevel(level)
    }

    render() {
        if (!isMobile()) {
            return null;
        }
        const {
            toggleSettings, toggleFurnitureSettings, toggleCustomObjectSettings,
            selectedDoorIndex, selectedWindowIndex,
            copyDoor, mirrorDoorOpen, selectDoor, removeDoor,
            selectWindow, removeWindow, copyWindow,
            selectedFurnitureItemIndex,
            selectFurnitureItem, deleteFurnitureItem, rotateFurnitureItem, duplicateFurnitureItem,
            selectedCustomObjectIndex,
            selectCustomObject, duplicateCustomObject, deleteCustomObject, rotateCustomObject,
            projectData
        } = this.props;

        const furnitureItem = selectedFurnitureItemIndex !== null
            ? projectData.furnitureItems[selectedFurnitureItemIndex]
            : null;

        const customObject = selectedCustomObjectIndex !== null
            ? projectData.furnitureCustomObjects[selectedCustomObjectIndex]
            : null;

        return (
            <>
                <div
                    className={cx(`mobile-object-actions`, {'mobile-object-actions--displayed': selectedDoorIndex !== null})}>
                    <div className={`mobile-object-actions__header-wrap`}>
                        <div className={`mobile-object-actions__title`}>
                            Door #{(selectedDoorIndex as number) + 1}
                        </div>
                        <div className={`mobile-object-actions__close-wrap`}>
                            <CloseIcon onClick={() => selectDoor(null)}/>
                        </div>
                    </div>
                    <div className={`mobile-object-actions__bttn-wrap`}>
                        <MobileObjectActionButton
                            name={`Duplicate`}
                            icon={<CopyIcon/>}
                            handleAction={copyDoor}
                        />
                        <MobileObjectActionButton
                            name={`Open direction`}
                            icon={<MirrorVerticalIcon/>}
                            handleAction={mirrorDoorOpen}
                        />
                    </div>
                    <div className={`mobile-object-actions__bttn-wrap`}>
                        <MobileObjectActionButton
                            name={`Edit door`}
                            icon={<EditIcon/>}
                            handleAction={toggleSettings}
                            styleBig
                        />
                        <MobileObjectActionButton
                            name={`Delete`}
                            icon={<DeleteIcon/>}
                            handleAction={() => removeDoor(selectedDoorIndex as number)}
                            styleBig
                        />
                    </div>
                </div>

                <div
                    className={cx(`mobile-object-actions`, {'mobile-object-actions--displayed': selectedWindowIndex !== null})}>
                    <div className={`mobile-object-actions__header-wrap`}>
                        <div className={`mobile-object-actions__title`}>
                            Window #{(selectedWindowIndex as number) + 1}
                        </div>
                        <div className={`mobile-object-actions__close-wrap`}>
                            <CloseIcon onClick={() => selectWindow(null)}/>
                        </div>
                    </div>
                    <div className={`mobile-object-actions__bttn-wrap`}>
                        <MobileObjectActionButton
                            name={`Duplicate`}
                            icon={<CopyIcon/>}
                            handleAction={copyWindow}
                        />
                    </div>
                    <div className={`mobile-object-actions__bttn-wrap`}>
                        <MobileObjectActionButton
                            name={`Edit window`}
                            icon={<EditIcon/>}
                            handleAction={toggleSettings}
                            styleBig
                        />
                        <MobileObjectActionButton
                            name={`Delete`}
                            icon={<DeleteIcon/>}
                            handleAction={() => removeWindow(selectedWindowIndex as number)}
                            styleBig
                        />
                    </div>
                </div>

                <div
                    className={cx(`mobile-object-actions`,
                        {'mobile-object-actions--displayed': selectedFurnitureItemIndex !== null}
                    )}
                >
                    {furnitureItem && (<>
                            <div className={`mobile-object-actions__header-wrap`}>
                                <div>
                                    <div className={`mobile-object-actions__title`}>
                                        {furnitureItem.product.name}
                                    </div>
                                    <div className={`mobile-object-actions__product-dimensions`}>
                                        <FurnitureDimensions {...furnitureItem.product.attributes}/>
                                    </div>
                                </div>
                                <div className={`mobile-object-actions__close-wrap`}>
                                    <CloseIcon onClick={() => selectFurnitureItem(null)}/>
                                </div>
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Rotate left`}
                                    icon={<RotateLeftIcon/>}
                                    handleAction={() => rotateFurnitureItem(-FURNITURE_ITEM_ROTATE_ANGLE)}
                                />
                                <MobileObjectActionButton
                                    name={`Rotate right`}
                                    icon={<RotateRightIcon/>}
                                    handleAction={() => rotateFurnitureItem(FURNITURE_ITEM_ROTATE_ANGLE)}
                                />
                                <MobileObjectActionButton
                                    name={`Duplicate`}
                                    icon={<CopyIcon/>}
                                    handleAction={duplicateFurnitureItem}
                                />
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Send to back`}
                                    icon={<SendToBackIcon/>}
                                    handleAction={() => this.handleFurnitureLevel(getFurnitureCurrentLowestLevel(projectData) - 1)}
                                />
                                <MobileObjectActionButton
                                    name={`Bring to front`}
                                    icon={<BringToFrontIcon/>}
                                    handleAction={() => this.handleFurnitureLevel(getFurnitureCurrentHighestLevel(projectData) + 1)}
                                />
                            </div>
                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Send backward`}
                                    icon={<SendBackwardIcon/>}
                                    handleAction={() => this.handleFurnitureLevel(furnitureItem.level - 1)}
                                />
                                <MobileObjectActionButton
                                    name={`Bring forward`}
                                    icon={<BringForwardIcon/>}
                                    handleAction={() => this.handleFurnitureLevel(furnitureItem.level + 1)}
                                />
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Edit object`}
                                    icon={<EditIcon/>}
                                    handleAction={toggleFurnitureSettings}
                                    styleBig
                                />
                                <MobileObjectActionButton
                                    name={`Delete`}
                                    icon={<DeleteIcon/>}
                                    handleAction={() => deleteFurnitureItem(selectedFurnitureItemIndex as number)}
                                    styleBig
                                />
                            </div>
                        </>
                    )}
                </div>

                <div
                    className={cx(`mobile-object-actions`,
                        {'mobile-object-actions--displayed': selectedCustomObjectIndex !== null}
                    )}
                >
                    {customObject && (<>
                            <div className={`mobile-object-actions__header-wrap`}>
                                <div>
                                    <div className={`mobile-object-actions__title`}>
                                        Custom object #{selectedCustomObjectIndex as number + 1}
                                    </div>
                                </div>
                                <div className={`mobile-object-actions__close-wrap`}>
                                    <CloseIcon onClick={() => selectCustomObject(null)}/>
                                </div>
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Rotate left`}
                                    icon={<RotateLeftIcon/>}
                                    handleAction={() => rotateCustomObject(-FURNITURE_ITEM_ROTATE_ANGLE)}
                                />
                                <MobileObjectActionButton
                                    name={`Rotate right`}
                                    icon={<RotateRightIcon/>}
                                    handleAction={() => rotateCustomObject(FURNITURE_ITEM_ROTATE_ANGLE)}
                                />
                                <MobileObjectActionButton
                                    name={`Duplicate`}
                                    icon={<CopyIcon/>}
                                    handleAction={duplicateCustomObject}
                                />
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Send to back`}
                                    icon={<SendToBackIcon/>}
                                    handleAction={() => this.handleCustomObjectLevel(getFurnitureCurrentLowestLevel(projectData) - 1)}
                                />
                                <MobileObjectActionButton
                                    name={`Bring to front`}
                                    icon={<BringToFrontIcon/>}
                                    handleAction={() => this.handleCustomObjectLevel(getFurnitureCurrentHighestLevel(projectData) + 1)}
                                />
                            </div>
                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Send backward`}
                                    icon={<SendBackwardIcon/>}
                                    handleAction={() => this.handleCustomObjectLevel(customObject.level - 1)}
                                />
                                <MobileObjectActionButton
                                    name={`Bring forward`}
                                    icon={<BringForwardIcon/>}
                                    handleAction={() => this.handleCustomObjectLevel(customObject.level + 1)}
                                />
                            </div>

                            <div className={`mobile-object-actions__bttn-wrap`}>
                                <MobileObjectActionButton
                                    name={`Edit object`}
                                    icon={<EditIcon/>}
                                    handleAction={toggleCustomObjectSettings}
                                    styleBig
                                />
                                <MobileObjectActionButton
                                    name={`Delete`}
                                    icon={<DeleteIcon/>}
                                    handleAction={() => deleteCustomObject()}
                                    styleBig
                                />
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        copyDoor, mirrorDoorOpen, selectDoor, removeDoor,
        selectWindow, removeWindow, copyWindow,
        selectFurnitureItem, deleteFurnitureItem, rotateFurnitureItem, duplicateFurnitureItem, setFurnitureItemLevel,
        selectCustomObject, duplicateCustomObject, deleteCustomObject, setCustomObjectLevel, rotateCustomObject
    }
)(MobileObjectActions);
