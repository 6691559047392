import React, {Component} from 'react';
import HeaderUserProfile from "./HeaderUserProfile/HeaderUserProfile";
import HeaderWishlist from "./HeaderWishlist/HeaderWishlist";
import HeaderCart from "./HeaderCart/HeaderCart";
import HeaderSearch from "./HeaderSearch/HeaderSearch";

export default class HeaderActions extends Component{
    render() {
        return (
            <>
                <HeaderSearch/>
                <div className="header-actions__wrap">
                    <HeaderUserProfile/>
                    <HeaderWishlist/>
                    <HeaderCart/>
                </div>
            </>
        );
    }
}
