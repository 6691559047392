import React from "react";
import { ReactComponent as Close } from '../../../img/svg/close.svg';

interface ViewerSettingsToggleProps {
    className?: string
    handleToggle: Function;
}

export default function ViewerSettingsToggle({className, handleToggle}: ViewerSettingsToggleProps) {
    return (
        <div
            className={className}
            onClick={() => handleToggle()}>
            <span className="viewer-settings-header__menu-line"/>
            <span className="viewer-settings-header__menu-line"/>
            <span className="viewer-settings-header__menu-line"/>

            <div className="viewer-settings-header__close">
              <Close/>
            </div>
        </div>
    )
}
