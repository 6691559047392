import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {
    getAllFurnitureCategoriesRequest,
    getCategoryFiltersRequest,
    getCategoryProductsRequest,
    getProductSimilarItemsRequest,
    getRecentProductsRequest,
    getRoomTypesCategoriesRequest
} from "../requests/furnitureCatalogRequests";
import {
    APPLY_CATALOG_CATEGORY_ALL,
    APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE,
    APPLY_CATALOG_FILTERS_ALL,
    APPLY_CATALOG_FILTERS_BY_ROOM_TYPE,
    FETCH_CATEGORY_CONTENT_ALL_ERROR,
    FETCH_CATEGORY_CONTENT_ALL_START,
    FETCH_CATEGORY_CONTENT_ALL_SUCCESS,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS,
    FETCH_CATEGORY_FILTERS_ALL_ERROR,
    FETCH_CATEGORY_FILTERS_ALL_START,
    FETCH_CATEGORY_FILTERS_ALL_SUCCESS,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS,
    FETCH_CATEGORY_PRODUCTS_ALL_ERROR,
    FETCH_CATEGORY_PRODUCTS_ALL_START,
    FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START,
    FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
    FETCH_FILTERED_PRODUCTS_ALL_ERROR,
    FETCH_FILTERED_PRODUCTS_ALL_START,
    FETCH_FILTERED_PRODUCTS_ALL_SUCCESS,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START,
    FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
    FETCH_FURNITURE_CATEGORIES_ALL_ERROR,
    FETCH_FURNITURE_CATEGORIES_ALL_START,
    FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START,
    FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS,
    GET_RECENT_ITEMS_ERROR,
    GET_RECENT_ITEMS_START,
    GET_RECENT_ITEMS_SUCCESS, GET_ROOM_TYPE_ERROR, GET_ROOM_TYPE_START, GET_ROOM_TYPE_SUCCESS,
    GET_SIMILAR_ITEMS_ERROR,
    GET_SIMILAR_ITEMS_START,
    GET_SIMILAR_ITEMS_SUCCESS
} from "./types/furnitureCatalog";
import {CatalogFilters} from "../models/furnitureCatalog";
import {find, reverse, uniq} from "lodash";
import {getRoomTypeRequest} from "../requests/projectRequests";
import {AxiosError} from "axios";

export function getFurnitureCategoriesByRoomType() {
    return (dispatch: Dispatch<AnyAction>, getState: Function) => {
        const state = getState();
        dispatch({type: FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_START})
        return getRoomTypesCategoriesRequest(state.builder.projectData.roomType)
            .then(res => {
                dispatch({
                    type: FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_FURNITURE_CATEGORIES_BY_ROOM_TYPE_ERROR
                });
            });
    }
}

export function getAllFurnitureCategories() {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: FETCH_FURNITURE_CATEGORIES_ALL_START})
        return getAllFurnitureCategoriesRequest()
            .then(res => {
                dispatch({
                    type: FETCH_FURNITURE_CATEGORIES_ALL_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_FURNITURE_CATEGORIES_ALL_ERROR
                });
            });
    }
}

export function loadRoomTypeCategoryContent(categoryId: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({
            type: APPLY_CATALOG_CATEGORY_BY_ROOM_TYPE,
            category: categoryId
        })
        dispatch({type: FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_START})

        dispatch({type: FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_START})
        getCategoryProductsRequest(categoryId)
            .then(res => {
                dispatch({
                    type: FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
                    payload: res.data
                });
                if (res.data.results.length === 0) {
                    dispatch(loadRoomTypeCategorySuccess());
                    return;
                }

                dispatch({type: FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_START})
                getCategoryFiltersRequest(categoryId)
                    .then(res => {
                        dispatch({
                            type: FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_SUCCESS,
                            payload: res.data
                        });
                        dispatch(loadRoomTypeCategorySuccess());
                    })
                    .catch((err: Error) => {
                        dispatch({
                            type: FETCH_CATEGORY_FILTERS_BY_ROOM_TYPE_ERROR
                        });
                        dispatch(loadRoomTypeCategoryError());
                    });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_CATEGORY_PRODUCTS_BY_ROOM_TYPE_ERROR
                });
                dispatch(loadRoomTypeCategoryError());
            });
    }
}

function loadRoomTypeCategoryError() {
    return ({type: FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_ERROR})
}

function loadRoomTypeCategorySuccess() {
    return ({type: FETCH_CATEGORY_CONTENT_BY_ROOM_TYPE_SUCCESS})
}

export function loadAllProductsCategoryContent(categoryId: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({
            type: APPLY_CATALOG_CATEGORY_ALL,
            category: categoryId
        })
        dispatch({type: FETCH_CATEGORY_CONTENT_ALL_START})

        dispatch({type: FETCH_CATEGORY_PRODUCTS_ALL_START})
        getCategoryProductsRequest(categoryId)
            .then(res => {
                dispatch({
                    type: FETCH_CATEGORY_PRODUCTS_ALL_SUCCESS,
                    payload: res.data
                });
                if (res.data.results.length === 0) {
                    dispatch(loadAllProductsCategorySuccess());
                    return;
                }

                dispatch({type: FETCH_CATEGORY_FILTERS_ALL_START})
                getCategoryFiltersRequest(categoryId)
                    .then(res => {
                        dispatch({
                            type: FETCH_CATEGORY_FILTERS_ALL_SUCCESS,
                            payload: res.data
                        });
                        dispatch(loadAllProductsCategorySuccess());
                    })
                    .catch((err: Error) => {
                        dispatch({
                            type: FETCH_CATEGORY_FILTERS_ALL_ERROR
                        });
                        dispatch(loadAllProductsCategoryError());
                    });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_CATEGORY_PRODUCTS_ALL_ERROR
                });
                dispatch(loadRoomTypeCategoryError());
            });
    }
}

function loadAllProductsCategoryError() {
    return ({type: FETCH_CATEGORY_CONTENT_ALL_ERROR})
}

function loadAllProductsCategorySuccess() {
    return ({type: FETCH_CATEGORY_CONTENT_ALL_SUCCESS})
}

export function applyRoomTypeFilters(filters: CatalogFilters) {
    return (dispatch: Dispatch<AnyAction>, getState: Function) => {
        dispatch({
            type: APPLY_CATALOG_FILTERS_BY_ROOM_TYPE,
            filters
        })

        const state = getState();
        const category = state.furnitureCatalog.selectedCategory;

        dispatch({type: FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_START})
        return getCategoryProductsRequest(category, filters)
            .then(res => {
                dispatch({
                    type: FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_FILTERED_PRODUCTS_BY_ROOM_TYPE_ERROR
                });
            });
    }
}

export function applyAllProductsFilters(filters: CatalogFilters) {
    return (dispatch: Dispatch<AnyAction>, getState: Function) => {
        dispatch({
            type: APPLY_CATALOG_FILTERS_ALL,
            filters
        })

        const state = getState();
        const category = state.furnitureCatalog.selectedCategory;

        dispatch({type: FETCH_FILTERED_PRODUCTS_ALL_START})
        return getCategoryProductsRequest(category, filters)
            .then(res => {
                dispatch({
                    type: FETCH_FILTERED_PRODUCTS_ALL_SUCCESS,
                    payload: res.data
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: FETCH_FILTERED_PRODUCTS_ALL_ERROR
                });
            });
    }
}

export function getProductSimilarItems(productId: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: GET_SIMILAR_ITEMS_START})
        return getProductSimilarItemsRequest(productId)
            .then(res => {
                dispatch({
                    type: GET_SIMILAR_ITEMS_SUCCESS,
                    productId,
                    items: res.data.results
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: GET_SIMILAR_ITEMS_ERROR
                });
            });
    }
}

export function getRecentProducts(recentProductIds: string[]) {
    return (dispatch: Dispatch<AnyAction>) => {
        const sortedRecents = uniq(reverse(recentProductIds))
        dispatch({type: GET_RECENT_ITEMS_START})
        return getRecentProductsRequest(sortedRecents)
            .then(res => {
                const sortedResults =
                    sortedRecents
                        .map(productId => find(res.data.results, {id: productId}))
                        .filter(data => data)

                dispatch({
                    type: GET_RECENT_ITEMS_SUCCESS,
                    payload: sortedResults
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: GET_RECENT_ITEMS_ERROR
                });
            });
    }
}

export function getRoomTypeData(roomTypeSlug: string) {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: GET_ROOM_TYPE_START})
        return getRoomTypeRequest(roomTypeSlug)
            .then(res => {
                dispatch({
                    type: GET_ROOM_TYPE_SUCCESS,
                    roomType: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_ROOM_TYPE_ERROR,
                    error: (err.response?.statusText || err.message)
                });
            });
    }
}
