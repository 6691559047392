import {marketplaceUrl} from "../utils/viewer";

export const MARKETPLACE_HOMEPAGE = marketplaceUrl();

export const MARKETPLACE_PATH_ACCOUNT = 'profile';
export const MARKETPLACE_PATH_CART = 'cart';
export const MARKETPLACE_PATH_WISHLIST = 'wish-list';
export const MARKETPLACE_PATH_PRODUCT = 'product';
export const MARKETPLACE_PATH_PRODUCTS = 'categories';
export const MARKETPLACE_PATH_SEARCH = 'search';
export const MARKETPLACE_PATH_PAGES = 'pages';
export const MARKETPLACE_PATH_LOGIN = 'login';
export const MARKETPLACE_PATH_BECOME_PARTNER = 'contact-us';
export const MARKETPLACE_PATH_TRACK_ORDER = 'orders';
export const MARKETPLACE_PATH_CONTACT = 'contact-us';
export const MARKETPLACE_PATH_RETURNS = 'returns';
export const MARKETPLACE_PATH_PROJECTS = 'my-projects';
