import React, {Component} from 'react';
import './FurnitureCategories.scss';
import FurnitureCatalogDropdown from "../FurnitureCatalogDropdown/FurnitureCatalogDropdown";
import {FurnitureCategory, FurnitureCategoryMain} from "../../../../models/furnitureCatalog";
import {head, find} from "lodash";
import FurnitureCategoriesOptions from "./FurnitureCategoriesOptions/FurnitureCategoriesOptions";

interface FurnitureCategoriesProps {
    furnitureCategories: FurnitureCategoryMain[];
    handleCategoryChange: (category: string) => void;
    selectedCategory: string | null;
}

export default class FurnitureCategories extends Component<FurnitureCategoriesProps> {

    changeMainCategory(newMainCategory: FurnitureCategoryMain) {
        const newSecondaryCategory = head(newMainCategory.secondaryCategories)
        if (newSecondaryCategory) {
            this.handleCategoryChange(newSecondaryCategory)
        }
    }

    changeSecondaryCategory(newSecondaryCategory: FurnitureCategory) {
        this.handleCategoryChange(newSecondaryCategory)
    }

    handleCategoryChange(selectedSecondaryCategory: FurnitureCategory) {
        this.props.handleCategoryChange(selectedSecondaryCategory.id)
    }

    render() {
        const {furnitureCategories, selectedCategory} = this.props;
        if (!furnitureCategories?.length || !selectedCategory) {
            return null;
        }

        const selectedMainCategory = find(
            furnitureCategories,
                parentCategory => find(parentCategory.secondaryCategories, {id: selectedCategory})
        ) as FurnitureCategoryMain
        if (!selectedMainCategory){
            return null
        }

        const selectedSecondaryCategory = find(
            (selectedMainCategory as FurnitureCategoryMain)?.secondaryCategories, {id: selectedCategory}
        ) as FurnitureCategory
        if (!selectedSecondaryCategory) {
            return null
        }

        return (
            <div className="select-menu__list">

                <FurnitureCatalogDropdown className={'select--label'} title={'Category'}
                                          subtitle={selectedMainCategory?.name}>
                    {selectedMainCategory && (
                        <FurnitureCategoriesOptions
                            furnitureCategories={furnitureCategories}
                            selectedCategory={selectedMainCategory}
                            handleCategoryChange={furnitureCategory => this.changeMainCategory(furnitureCategory as FurnitureCategoryMain)}
                        />
                    )}
                </FurnitureCatalogDropdown>

                <FurnitureCatalogDropdown className={'select--label'} title={'Product Type'}
                                          subtitle={selectedSecondaryCategory?.name}>
                    {selectedMainCategory?.secondaryCategories && selectedSecondaryCategory && (
                        <FurnitureCategoriesOptions
                            furnitureCategories={selectedMainCategory.secondaryCategories}
                            selectedCategory={selectedSecondaryCategory}
                            handleCategoryChange={furnitureCategory => this.changeSecondaryCategory(furnitureCategory as FurnitureCategory)}
                        />
                    )}
                </FurnitureCatalogDropdown>
            </div>
        );
    }
}

