import {Builder} from "../Builder";
import {PositionOptions, WallObject} from "../../../../models/builder";
import {Element, Pattern, PointArrayAlias, Polygon} from "@svgdotjs/svg.js";
import {
    BASE_MEASURE,
    BUILDER_AREA_ACCENT_COLOR,
    BUILDER_AREA_BORDER_COLOR, BUILDER_MARKUP_COLOR_LIGHT, BUILDER_MARKUP_COLOR_LIGHT_DASH,
    BUILDER_ROOM_MARKUP_COLOR,
    BUILDER_ROOM_MARKUP_DASH,
    MARKUP_BLOCK_HEIGHT,
    MARKUP_FONT_SIZE,
    MARKUP_FONT_WEIGTH, MARKUP_LINE_WIDTH,
    MARKUP_VALUE_FONT_WEIGTH,
    MEASURES_DESC
} from "../../../../constants/builderDefaults";
import {FLOOR_COLOR_DEFAULT, WALLS_COLOR_DEFAULT} from "../../../../constants/floorWalls";
import {find, round} from "lodash";
import {convertMeasure, getBaseMeasureValue} from "../../../../utils/convertMeasure";
import {checkAvailabilityToPlace, itemsAreInsideWindowBay} from "../../../../utils/builderRoomSettings";
import {PROJECT_ERRORS} from "../../../../constants/errorMessages";

export class BuilderWindowBay {
    protected builder: Builder;
    public windowBayArea: Element | null;
    public windowBay: Element | null;
    public windowBayPositionMarkup: Element | null;
    public windowBayFloor: Element | null;
    public bayIsHorizontal: boolean;

    constructor(builder: Builder) {
        this.builder = builder;
        this.bayIsHorizontal = false;
        this.windowBayArea = null;
        this.windowBay = null;
        this.windowBayPositionMarkup = null;
        this.windowBayFloor = null;
        this.draw();
    }

    windowBayGroupId = 'builder__window-bay';
    windowBayElements = {
        bayLengthValue: "builder-bay-markup__length",
        bayWidthValue: "builder-bay-markup__width",
        bayLeftSpaceMarkup: "builder-bay-markup__left",
        bayLeftSpaceMarkupStroke: "builder-bay-markup__left__stroke",
        bayRightSpaceMarkup: "builder-bay-markup__right",
        windowBayBackground: "builder-bay__background",
        windowBayFloor: 'builder-bay__floor',
    };

    draw() {
        const {drawArea, builderContent, roomMainArea} = this.builder;
        if (!(drawArea && builderContent && roomMainArea)) {
            return;
        }
        const {
            projectData: {
                roomSettings: {
                    windowBayPosition
                }
            },
            converted: {
                roomSettings:
                    {windowBayWidth, windowBayMiddleWidth, windowBayLength}
            }
        } = this.builder.props;

        if (!(windowBayPosition && windowBayWidth && windowBayMiddleWidth && windowBayLength)) {
            return;
        }

        this.initWindowBay();
        this.drawView();
        this.drawMarkup();
        this.position();
        this.handleDrag();
    }

    initWindowBay(){
        const {drawArea, builderContent} = this.builder;
        if (!(drawArea && builderContent)) {
            return;
        }
        const {
            projectData: {
                roomSettings: {
                    windowBayPosition
                }
            }
        } = this.builder.props;

        this.bayIsHorizontal = windowBayPosition === PositionOptions.top
            || windowBayPosition === PositionOptions.bottom;

        this.windowBayArea = drawArea.group()
            .attr('id', this.windowBayGroupId);
        this.windowBay = drawArea.group();
        this.windowBayArea.add(this.windowBay);
        builderContent.add(this.windowBayArea);
    }

    drawView() {
        if (!this.windowBay) {
            return;
        }
        const {
            windowBayAreaSize,
            builderDefaults: {extra, borderWidth, wallsWidth}
        } = this.builder;

        const widthDiff = (windowBayAreaSize.width - windowBayAreaSize.middleWidth) / 2;
        const windowBayBackgroundPoints = [
            [0, 0],
            [windowBayAreaSize.length + wallsWidth, widthDiff],
            [windowBayAreaSize.length + wallsWidth, windowBayAreaSize.width - widthDiff + extra],
            [0, windowBayAreaSize.width + extra]
        ] as PointArrayAlias;
        const windowBayBackground = new Polygon()
            .plot(windowBayBackgroundPoints)
            .fill(BUILDER_AREA_BORDER_COLOR)
            .attr('id', this.windowBayElements.windowBayBackground)
        this.windowBay.add(
            windowBayBackground
        )

        const windowBayFloor = [
            [0 - extra / 2, extra / 2],
            [windowBayAreaSize.length - extra / 2 + wallsWidth, widthDiff + wallsWidth + borderWidth],
            [windowBayAreaSize.length - extra / 2 + wallsWidth, windowBayAreaSize.width + extra / 2 - widthDiff],
            [0 - extra / 2, windowBayAreaSize.width + extra / 2],
        ] as PointArrayAlias;
        this.windowBayFloor = new Polygon()
            .plot(windowBayFloor)
            .fill(FLOOR_COLOR_DEFAULT)
            .attr('id', this.windowBayElements.windowBayFloor)
        this.windowBay.add(this.windowBayFloor);

        const windowBayWalls = [
            [
                [0 - extra / 2, extra / 2],
                [0 - borderWidth, borderWidth],
                [windowBayAreaSize.length + wallsWidth - borderWidth, widthDiff + borderWidth],
                [windowBayAreaSize.length + wallsWidth - extra / 2, widthDiff + borderWidth + wallsWidth],
            ],
            [
                [windowBayAreaSize.length - extra / 2 + wallsWidth, widthDiff + borderWidth + wallsWidth],
                [windowBayAreaSize.length - borderWidth + wallsWidth, widthDiff + borderWidth],
                [windowBayAreaSize.length - borderWidth + wallsWidth, windowBayAreaSize.width - widthDiff + wallsWidth * 2 + borderWidth],
                [windowBayAreaSize.length - extra / 2 + wallsWidth, windowBayAreaSize.width - widthDiff + wallsWidth + borderWidth],
            ],
            [
                [windowBayAreaSize.length - extra / 2 + wallsWidth, windowBayAreaSize.width - widthDiff + wallsWidth + borderWidth],
                [windowBayAreaSize.length - borderWidth + wallsWidth, windowBayAreaSize.width - widthDiff + wallsWidth * 2 + borderWidth],
                [0 - borderWidth, windowBayAreaSize.width + wallsWidth * 2 + borderWidth],
                [0 - extra / 2, windowBayAreaSize.width + wallsWidth * 2 - wallsWidth + borderWidth],
            ],
        ] as PointArrayAlias[];

        const {
            projectData: {
                roomSettings: {
                    windowBayPosition
                },
                floorWallsStyle: {walls}
            }
        } = this.builder.props;

        const wallColor = walls.find(wallSettings => wallSettings.wall === windowBayPosition)?.colorHEX || WALLS_COLOR_DEFAULT
        windowBayWalls.forEach((wallDots: PointArrayAlias) => {
            this.windowBay?.add(
                new Polygon()
                    .plot(wallDots)
                    .fill(wallColor)
                    .data('window-bay-wall', true)
                    .stroke({color: BUILDER_ROOM_MARKUP_COLOR, dasharray: BUILDER_ROOM_MARKUP_DASH})
            )
        })
    }

    styleFloor = () => {
        if (!this.windowBayFloor || !this.builder.drawArea || !this.builder.floor) {
            return
        }
        const floorPattern = this.builder.floor.floorFill as Pattern;
        this.windowBayFloor.fill(floorPattern);
    }

    colorWalls = () => {
        if (!this.windowBay) {
            return
        }
        const {windowBayPosition} = this.builder.props.projectData.roomSettings;
        const {walls} = this.builder.props.projectData.floorWallsStyle;
        const wallColor = walls.find(wallSettings => wallSettings.wall === windowBayPosition)?.colorHEX || WALLS_COLOR_DEFAULT

        this.windowBay.find(`[data-window-bay-wall]`).forEach(wbWall => wbWall.fill(wallColor))
    }

    drawMarkup() {
        const drawArea = this.builder.drawArea;
        if (!this.windowBay || !drawArea) {
            return;
        }
        const {
            projectData: {roomSettings, measure},
        } = this.builder.props;
        const {windowBayPosition} = roomSettings;
        const {extra, wallsWidth} = this.builder.builderDefaults;
        const {windowBayAreaSize} = this.builder;

        const bayLengthMarkup = drawArea
            .text((add) => {
                add.tspan('Bay Length')
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_FONT_WEIGTH, anchor: 'middle'})
                add.tspan(`${roomSettings.windowBayLength} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .attr('id', this.windowBayElements.bayLengthValue)
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_VALUE_FONT_WEIGTH, anchor: 'middle'})
                    .newLine()
            })
            .leading(2);
        bayLengthMarkup
            .cx(windowBayAreaSize.length / 2 + wallsWidth / 2)
            .cy(windowBayAreaSize.width / 2 + wallsWidth)
            .translate(0 - wallsWidth, 0)
        if (windowBayPosition === PositionOptions.left) {
            bayLengthMarkup.rotate(180)
        }
        const bayLengthMarkupStroke = drawArea
            .line(0 - extra / 2, 0, windowBayAreaSize.length + wallsWidth - extra / 2, 0)
            .stroke({color: BUILDER_ROOM_MARKUP_COLOR, width: 1, dasharray: BUILDER_ROOM_MARKUP_DASH})
            .cy(windowBayAreaSize.width / 2 + wallsWidth)

        this.windowBay.add(bayLengthMarkupStroke);
        this.windowBay.add(bayLengthMarkup);

        const bayWidthMarkup = drawArea
            .text((add) => {
                add.tspan('Inner Width ')
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_FONT_WEIGTH, anchor: 'middle'})
                add.tspan(`${roomSettings.windowBayWidth} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .attr('id', this.windowBayElements.bayWidthValue)
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_VALUE_FONT_WEIGTH, anchor: 'middle'})
            })
        bayWidthMarkup
            .rotate(90)
            .cx(windowBayAreaSize.width / 2 + wallsWidth)
            .translate(0 - extra / 2 - MARKUP_BLOCK_HEIGHT, 0)
        const bayMiddleWidthMarkup = drawArea
            .text((add) => {
                add.tspan('Outer Width ')
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_FONT_WEIGTH, anchor: 'middle'})
                add.tspan(`${roomSettings.windowBayMiddleWidth} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .attr('id', this.windowBayElements.bayWidthValue)
                    .font({size: MARKUP_FONT_SIZE, weight: MARKUP_VALUE_FONT_WEIGTH, anchor: 'middle'})
            })
        bayMiddleWidthMarkup
            .rotate(90)
            .cx(windowBayAreaSize.width / 2 + wallsWidth)
            .dy(0 - windowBayAreaSize.length - extra / 2 - MARKUP_BLOCK_HEIGHT)
        if (windowBayPosition === PositionOptions.bottom) {
            bayWidthMarkup.rotate(180)
            bayMiddleWidthMarkup.rotate(180)
        }

        this.windowBay.add(bayWidthMarkup);
        this.windowBay.add(bayMiddleWidthMarkup);
    }

    updateMarkup() {
        if (!this.windowBay) {
            return;
        }
        const {roomSettings, measure} = this.builder.props.projectData;

        this.windowBay.findOne(`#${this.windowBayElements.bayLengthValue}`).node.textContent =
            `${roomSettings.windowBayLength} ${find(MEASURES_DESC, {measure})?.shortName}`
        this.windowBay.findOne(`#${this.windowBayElements.bayWidthValue}`).node.textContent =
            `${roomSettings.windowBayWidth} / ${roomSettings.windowBayMiddleWidth} ${find(MEASURES_DESC, {measure})?.shortName}`
    }

    position() {
        const {roomSettings} = this.builder.props.projectData;
        const {windowBayPosition} = roomSettings;
        const {
            roomAreaSize, roomMainArea, windowBayAreaSize,
            builderDefaults: {extra, wallsWidth}
        } = this.builder;

        if (!this.windowBay || !this.windowBayArea || !roomMainArea) {
            return
        }

        const bayCalculatedIndent = this.bayCalculatedIndent();

        const windowBayMargin =
            windowBayPosition === PositionOptions.left || windowBayPosition === PositionOptions.right
                ? windowBayAreaSize.length + wallsWidth + MARKUP_BLOCK_HEIGHT
                : windowBayAreaSize.length + extra / 2 + MARKUP_BLOCK_HEIGHT * 2

        switch (windowBayPosition) {
            case PositionOptions.left:
                this.windowBay
                    .rotate(180, 0, 0)
                    .translate(windowBayMargin, 0)
                this.windowBayArea
                    .y(bayCalculatedIndent)
                roomMainArea.dx(windowBayAreaSize.length + wallsWidth + MARKUP_BLOCK_HEIGHT);
                break;
            case PositionOptions.right:
                this.windowBay
                    .dx(roomAreaSize.width + extra)
                this.windowBayArea
                    .y(bayCalculatedIndent)
                break;
            case PositionOptions.top:
                this.windowBay
                    .rotate(270, 0, 0)
                    .translate(
                        0,
                        windowBayAreaSize.length + extra / 2 + MARKUP_BLOCK_HEIGHT * 2
                    )
                this.windowBayArea
                    .x(bayCalculatedIndent)
                roomMainArea.dy(windowBayMargin);
                break;
            case PositionOptions.bottom:
                this.windowBay
                    .rotate(90, 0, 0)
                    .translate(0, roomAreaSize.height + extra)
                this.windowBayArea
                    .x(bayCalculatedIndent)
                break;
        }
    }

    handleDrag() {
        const {roomMainArea, scale} = this.builder;
        const {measure, roomSettings} = this.builder.props.projectData;
        if (!this.windowBayArea || !roomMainArea) {
            return;
        }

        const bayLimits = this.bayIsHorizontal
            ? {
                start: 0,
                end: (roomMainArea.width() as number) - (this.windowBayArea.width() as number),
                guide: this.windowBayArea.y()
            }
            : {

                start: 0,
                end: (roomMainArea.height() as number) - (this.windowBayArea.height() as number),
                guide: this.windowBayArea.x()
            }

        this.windowBayArea
            .draggable()
            .on('mouseover', () => {
                this.windowBayArea?.css({cursor: 'move'})
            })
            .on('dragstart', (e: any) => {
                if (itemsAreInsideWindowBay()) {
                    this.builder.props.raiseProjectError(PROJECT_ERRORS.DEFAULT.BAY_CONTAINS_ITEMS)
                    return
                }
                this.builder.props.selectWindowBay();
            })
            .on('dragmove', (e: any) => {
                const {handler, box} = e.detail
                e.preventDefault();
                if (itemsAreInsideWindowBay()) {
                    return
                }
                (this.windowBayArea?.findOne(`#${this.windowBayElements.windowBayBackground}`) as Polygon)
                    .fill(BUILDER_AREA_ACCENT_COLOR)
                let {x, y} = box;
                if (this.bayIsHorizontal) {
                    y = bayLimits.guide;
                    if (x < bayLimits.start) {
                        x = bayLimits.start
                    }
                    if (x > bayLimits.end) {
                        x = bayLimits.end
                    }
                } else {
                    x = bayLimits.guide;
                    if (y < bayLimits.start) {
                        y = bayLimits.start
                    }
                    if (y > bayLimits.end) {
                        y = bayLimits.end
                    }
                }

                handler.move(x, y);
                this.drawPositionMarkup();
            })
            .on('dragend', (e: any) => {
                if (itemsAreInsideWindowBay()) {
                    return
                }
                (this.windowBayArea?.findOne(`#${this.windowBayElements.windowBayBackground}`) as Polygon)
                    .fill(BUILDER_AREA_BORDER_COLOR)

                const indentBuilder = this.bayIsHorizontal
                        ? this.windowBayArea?.x() as number
                        : (this.windowBayArea?.y() as number) - (roomMainArea.y() as number),
                    indent = convertMeasure(
                        BASE_MEASURE,
                        measure,
                        round(indentBuilder / scale));
                const previousIndent = roomSettings.windowBayIndent;

                const objectData = {
                    width: roomSettings.windowBayWidth,
                    indent: roomSettings.windowBayIndent,
                    wall: roomSettings.windowBayPosition
                } as WallObject

                if (indent !== previousIndent) {
                    if (!checkAvailabilityToPlace(objectData, indent, true)) {
                        const {handler} = e.detail;
                        this.bayIsHorizontal
                            ? handler.move(this.bayCalculatedIndent(), this.windowBayArea?.y())
                            : handler.move(this.windowBayArea?.x(), this.bayCalculatedIndent());
                        this.builder.props.raiseProjectError(`Can't move object here`)
                        this.drawPositionMarkup();
                    } else {
                        this.builder.props.setWindowBayIndent(indent);
                    }
                }
            });

        this.drawPositionMarkup();
    }

    drawPositionMarkup() {
        const {drawArea, builderContent, roomMainArea, scale, windowBayAreaSize, roomAreaSize} = this.builder;
        if (!drawArea || !builderContent || !this.windowBayArea || !roomMainArea) {
            return;
        }

        if (this.windowBayPositionMarkup) {
            this.windowBayPositionMarkup.remove();
        }

        if (!this.builder.props.windowBaySelected) {
            return;
        }

        this.windowBayPositionMarkup = drawArea.group();
        this.windowBayPositionMarkup.addTo(builderContent);

        const {projectData} = this.builder.props;
        const {measure, roomSettings, roomSettings: {windowBayPosition}} = projectData;
        const {wallsWidth, extra} = this.builder.builderDefaults;

        let bayLeftSpace = this.bayIsHorizontal
                ? this.windowBayArea.x() as number
                : (this.windowBayArea.y() as number) - (roomMainArea.y() as number),
            bayLeftSpaceValue = convertMeasure(
                BASE_MEASURE,
                measure,
                round(bayLeftSpace / scale))
        let bayRightSpace = this.bayIsHorizontal
                ? (roomMainArea.width() as number) - ((this.windowBayArea.x() as number) + (this.windowBayArea.width() as number))
                : (roomMainArea.height() as number) - (this.windowBayArea.y() as number) - (this.windowBayArea.height() as number),
            bayRightSpaceValue = convertMeasure(
                    BASE_MEASURE,
                    measure,
                    round(bayRightSpace / scale))
/*
                /!* hotfix for minor calculations errors *!/
        /!* TODO: find better solution *!/
        */
        if (bayLeftSpaceValue < 0) {
            bayLeftSpaceValue = 0
        }
        if (bayRightSpaceValue < 0) {
            bayRightSpaceValue = 0
        }
        const availableSpace = (this.bayIsHorizontal ? roomSettings.width : roomSettings.length) - (roomSettings.windowBayWidth || 0);
        bayLeftSpaceValue > bayRightSpaceValue
            ? bayLeftSpaceValue = round(availableSpace - bayRightSpaceValue, 2)
            : bayRightSpaceValue = round(availableSpace - bayLeftSpaceValue, 2)
                /* hotfix */

        //this.builder.props.setWindowBayIndent(bayLeftSpaceValue);

        const bayLeftSpaceMarkup = drawArea
            .text((add) => {
                add.tspan(`${bayLeftSpaceValue} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .attr('id', this.windowBayElements.bayLeftSpaceMarkup)
                    .font({size: MARKUP_FONT_SIZE, anchor: 'middle'})
                    .fill(BUILDER_MARKUP_COLOR_LIGHT)

            })
        const bayRightSpaceMarkup = drawArea
            .text((add) => {
                add.tspan(`${bayRightSpaceValue} ${find(MEASURES_DESC, {measure})?.shortName}`)
                    .attr('id', this.windowBayElements.bayRightSpaceMarkup)
                    .font({size: MARKUP_FONT_SIZE, anchor: 'middle'})
                    .fill(BUILDER_MARKUP_COLOR_LIGHT)
            })
        this.windowBayPositionMarkup.add(bayLeftSpaceMarkup);
        this.windowBayPositionMarkup.add(bayRightSpaceMarkup);

        const bayLeftSpaceMarkupStroke = drawArea.line()
        const bayRightSpaceMarkupStroke = drawArea.line()
        bayLeftSpaceMarkupStroke
            .stroke({color: BUILDER_MARKUP_COLOR_LIGHT_DASH, width: MARKUP_LINE_WIDTH})
            .attr('id', this.windowBayElements.bayLeftSpaceMarkupStroke)
        bayRightSpaceMarkupStroke.stroke({color: BUILDER_MARKUP_COLOR_LIGHT_DASH, width: MARKUP_LINE_WIDTH})
        this.windowBayPositionMarkup.add(bayLeftSpaceMarkupStroke);
        this.windowBayPositionMarkup.add(bayRightSpaceMarkupStroke);

        switch (windowBayPosition) {
            case PositionOptions.left:
                bayLeftSpaceMarkup
                    .dx(windowBayAreaSize.length / 2 - MARKUP_BLOCK_HEIGHT)
                    .cy(bayLeftSpace / 2)
                    .rotate(270)
                bayRightSpaceMarkup
                    .dx(windowBayAreaSize.length / 2 - MARKUP_BLOCK_HEIGHT)
                    .cy(roomMainArea.height() as number - bayRightSpace / 2)
                    .rotate(270)
                bayLeftSpaceMarkupStroke
                    .plot(0, 0, 0, bayLeftSpace)
                    .dx(windowBayAreaSize.length / 2)
                bayRightSpaceMarkupStroke
                    .plot(0, roomMainArea.height() as number - bayRightSpace,
                        0, roomMainArea.height() as number)
                    .dx(windowBayAreaSize.length / 2)
                break;
            case PositionOptions.right:
                bayLeftSpaceMarkup
                    .dx(roomMainArea.width() as number + wallsWidth + MARKUP_BLOCK_HEIGHT)
                    .cy(bayLeftSpace / 2)
                    .rotate(90)
                bayRightSpaceMarkup
                    .dx(roomMainArea.width() as number + wallsWidth + MARKUP_BLOCK_HEIGHT)
                    .cy(roomMainArea.height() as number - bayRightSpace / 2)
                    .rotate(90)
                bayLeftSpaceMarkupStroke
                    .plot(0, 0, 0, bayLeftSpace)
                    .dx(roomMainArea.width() as number + wallsWidth)
                bayRightSpaceMarkupStroke
                    .plot(0, roomMainArea.height() as number - bayRightSpace,
                        0, roomMainArea.height() as number)
                    .dx(roomMainArea.width() as number + wallsWidth)
                break;
            case PositionOptions.top:
                bayLeftSpaceMarkup
                    .dy(windowBayAreaSize.length / 2)
                    .cx(bayLeftSpace / 2)
                bayRightSpaceMarkup
                    .dy(windowBayAreaSize.length / 2)
                    .cx(roomMainArea.width() as number - bayRightSpace / 2)
                bayLeftSpaceMarkupStroke
                    .plot(0, 0, bayLeftSpace, 0)
                    .dy(windowBayAreaSize.length / 2 + MARKUP_BLOCK_HEIGHT)
                bayRightSpaceMarkupStroke
                    .plot(roomMainArea.width() as number - bayRightSpace, 0, roomMainArea.width() as number, 0)
                    .dy(windowBayAreaSize.length / 2 + MARKUP_BLOCK_HEIGHT)
                break;
            case PositionOptions.bottom:
                bayLeftSpaceMarkup
                    .dy(roomAreaSize.height + extra + windowBayAreaSize.length / 2 + MARKUP_BLOCK_HEIGHT)
                    .cx(bayLeftSpace / 2)
                bayRightSpaceMarkup
                    .dy(roomAreaSize.height + extra + windowBayAreaSize.length / 2 + MARKUP_BLOCK_HEIGHT)
                    .cx(roomMainArea.width() as number - bayRightSpace / 2)
                bayLeftSpaceMarkupStroke
                    .plot(0, 0, bayLeftSpace, 0)
                    .dy(roomAreaSize.height + extra + windowBayAreaSize.length / 2)
                bayRightSpaceMarkupStroke
                    .plot(roomMainArea.width() as number - bayRightSpace, 0, roomMainArea.width() as number, 0)
                    .dy(roomAreaSize.height + extra + windowBayAreaSize.length / 2)
                break;
        }
    }

    bayCalculatedIndent() {
        const {roomSettings, measure} = this.builder.props.projectData;
        const {windowBayIndent} = roomSettings;
        const {
            roomAreaSize, roomMainArea, windowBayAreaSize,
            builderDefaults: {extra},
            scale
        } = this.builder;

        if (!this.windowBay || !this.windowBayArea || !roomMainArea) {
            return 0
        }

        let bayCalculatedIndent = 0;
        if (windowBayIndent !== null) {
            let windowBayIndentValue = windowBayIndent;
            const maxWidth = this.bayIsHorizontal ? roomSettings.width : roomSettings.length;
            if ((windowBayIndent + (roomSettings.windowBayWidth || 0)) > maxWidth) {
                windowBayIndentValue = maxWidth - (roomSettings.windowBayWidth || 0);
            }
            bayCalculatedIndent = getBaseMeasureValue(measure, windowBayIndentValue) * scale;
        } else {
            bayCalculatedIndent = this.bayIsHorizontal
                ? (roomAreaSize.width + extra) / 2 - (windowBayAreaSize.width + extra) / 2
                : (roomAreaSize.height + extra) / 2 - (windowBayAreaSize.width + extra) / 2
        }
        return bayCalculatedIndent;
    }

    getWindowBayWallCoordinates(): number[][] {
        const floorView = this.builder.floor?.floorView;
        if (!this.windowBayFloor || !floorView) {
            return []
        }

        const windowBayFloorBox = this.windowBayFloor.rbox(this.builder.drawArea as Element);

        const {cx: bayCx, cy: bayCy, x: bayX, y: bayY, x2: bayX2, y2: bayY2} = windowBayFloorBox;
        const {middleWidth} = this.builder.windowBayAreaSize;
        const {windowBayPosition} = this.builder.props.projectData.roomSettings;
        const {x: floorX, y: floorY, x2: floorX2, y2: floorY2} = floorView.rbox(this.builder.drawArea as Element);

        switch (windowBayPosition) {
            case PositionOptions.left:
                return [
                    [bayX, bayCy - middleWidth/2, bayX2, bayY],
                    [bayX, bayCy + middleWidth/2, bayX2, bayY2],
                    [bayX, bayCy - middleWidth/2, bayX, bayCy + middleWidth/2],
                    [bayX2, floorY, bayX2, bayY],
                    [bayX2, bayY2, bayX2, floorY2],
                ]
            case PositionOptions.right:
                return [
                    [bayX, bayY, bayX2, bayCy - middleWidth/2],
                    [bayX, bayY2, bayX2, bayCy + middleWidth/2],
                    [bayX2, bayCy - middleWidth/2, bayX2, bayCy + middleWidth/2],
                    [bayX, floorY, bayX, bayY],
                    [bayX, bayY2, bayX, floorY2],
                ]
            case PositionOptions.top:
                return [
                    [bayX,bayY2, bayCx - middleWidth/2,  bayY],
                    [bayCx + middleWidth/2, bayY, bayX2, bayY2],
                    [bayCx - middleWidth/2,  bayY, bayCx + middleWidth/2, bayY],
                    [floorX, bayY2, bayX, bayY2],
                    [bayX2, bayY2, floorX2, bayY2]
                ]
            case PositionOptions.bottom:
                return [
                    [bayX,bayY, bayCx - middleWidth/2,  bayY2],
                    [bayCx + middleWidth/2, bayY2, bayX2, bayY],
                    [bayCx - middleWidth/2,  bayY2, bayCx + middleWidth/2, bayY2],
                    [floorX, bayY, bayX, bayY],
                    [bayX2, bayY, floorX2, bayY]
                ]
            default: return [];
        }
    }
}
