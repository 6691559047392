import React from 'react';
import './DoorsSettings.scss';
import {connect} from "react-redux";
import RangeSlider from '../../RangeSlider/RangeSlider';
import PositionOption from '../../PositionOption/PositionOption';
import DetailsHeader from '../../DetailsHeader/DetailsHeader';
import {DoorObject, Measure, PositionOptions} from "../../../../models/builder";
import {convertMeasure} from "../../../../utils/convertMeasure";
import {RootState} from "../../../../store";
import {
    DOOR_LENGTH_MAX,
    DOOR_LENGTH_MIN,
    DOOR_WIDTH_MAX,
    DOOR_WIDTH_MIN
} from "../../../../constants/doorsWindowsSettings";
import {find} from "lodash";
import {MEASURES_DESC} from "../../../../constants/builderDefaults";
import {setDoorWall, setDoorWidth, setDoorLength} from "../../../../actions/builderActions";
import {AnyAction} from "@reduxjs/toolkit";
import {maxAvailableObjectSpace} from "../../../../utils/builderRoomSettings";

interface DoorsSettingsProps {
    door: DoorObject;
    measure: Measure;
    setDoorWidth: (width: number) => AnyAction;
    setDoorLength: (length: number) => AnyAction;
    setDoorWall: (wall: PositionOptions) => AnyAction;
}

function mapStateToProps(state: RootState) {
    return {
        measure: state.builder.projectData.measure,
    };
}

class DoorsSettings extends React.Component<DoorsSettingsProps> {
    render() {
        const {door, measure, setDoorWidth, setDoorWall, setDoorLength} = this.props;
        const maxDoorWidth = Math.min(convertMeasure(Measure.cm, measure, DOOR_WIDTH_MAX), maxAvailableObjectSpace(door))

        return (
            <div className="doors-settings">
                <DetailsHeader titleText="Setup Door"/>
                <div className="doors-settings__list">
                    <div className="doors-settings__item">
                        <RangeSlider
                            inputLabel={'Width'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            value={door.width}
                            minValue={convertMeasure(Measure.cm, measure, DOOR_WIDTH_MIN)}
                            maxValue={maxDoorWidth}
                            onChange={size => setDoorWidth(size)}
                        />
                    </div>
                    <div className="doors-settings__item">
                        <RangeSlider
                            inputLabel={'Length'}
                            valueLabel={find(MEASURES_DESC, {measure})?.shortName}
                            value={door.length}
                            minValue={convertMeasure(Measure.cm, measure, DOOR_LENGTH_MIN)}
                            maxValue={convertMeasure(Measure.cm, measure, DOOR_LENGTH_MAX)}
                            onChange={size => setDoorLength(size)}
                        />
                    </div>
                </div>
                <div className="doors-settings__list">
                    <div className="doors-settings__item doors-settings__item--position">
                        <PositionOption
                            title={`Door Position`}
                            current={door.wall}
                            changePositionOption={wall => setDoorWall(wall)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {setDoorWidth, setDoorWall, setDoorLength}
)(DoorsSettings);
