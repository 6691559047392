import React from 'react';
import {connect} from 'react-redux';
import './FloorList.scss';
import {chunk, head} from "lodash";
import {FLOOR_COLOR_DEFAULT, FLOOR_SETTINGS_DISPLAY_COLUMNS} from "../../../constants/floorWalls";
import {FloorSettings, FloorStyle, FloorStyleType} from "../../../models/floorWalls";
import FloorStyleSettings from "../FloorStyleSettings/FloorStyleSettings";
import {RootState} from "../../../store";
import {AnyAction} from "@reduxjs/toolkit";
import {setFloorStyle} from "../../../actions/floorWallsStyleActions";
import {fetchFloorStyles} from "../../../actions/builderActions";

interface FloorListProps {
    selectedStyle: FloorSettings;
    setFloorStyle: (floorSettings: FloorSettings) => AnyAction;
    floorStyles: FloorStyle[]
    fetchFloorStyles: Function;
}

function mapStateToProps(state: RootState) {
    return {
        selectedStyle: state.builder.projectData.floorWallsStyle.floor,
        floorStyles: state.builder.floorStyles
    };
}

export class FloorList extends React.Component<FloorListProps> {
    componentDidMount() {
        if (!this.props.floorStyles.length) {
            this.props.fetchFloorStyles();
        }
    }

    handleChangeFloorStyle = (style: FloorStyle) => {
        const {setFloorStyle} = this.props;
        if (style.type === FloorStyleType.color) {
            setFloorStyle({
                type: FloorStyleType.color,
                fill: FLOOR_COLOR_DEFAULT,
            });
            return;
        }
        const selectedTexture = style.textureOptions && style.textureOptions?.length > 0
            ? head(style.textureOptions) as FloorStyle
            : style
        setFloorStyle({
            type: FloorStyleType.texture,
            fill: selectedTexture.textureId as string,
        });
    }

    render() {
        const {selectedStyle} = this.props;
        const floorStyles = [
            {
                name: 'Blank style',
                type: FloorStyleType.color
            },
            ...this.props.floorStyles
        ]
        return (
            <div className="floor-list__wrap">
                <div className="floor-list">
                    {chunk(floorStyles, FLOOR_SETTINGS_DISPLAY_COLUMNS)
                        .map((stylesRow, rowIndex) => {
                                const displayStyleSettings = stylesRow.find((style: FloorStyle) => {
                                    if (selectedStyle.type === FloorStyleType.color
                                        && style.type === FloorStyleType.color) {
                                        return true;
                                    }
                                    if (style.type === selectedStyle.type) {
                                        if (style.textureOptions && style.textureOptions?.length > 0) {
                                            return style.textureOptions
                                                .find(styleOption => styleOption.textureId === selectedStyle.fill)
                                        }
                                        return style.textureId === selectedStyle.fill;
                                    }
                                    return false;
                                });
                                return (
                                    <React.Fragment key={`floor-row-${rowIndex}`}>
                                        {stylesRow.map((floorStyle, optionIndex) => (
                                            <div className="floor-list__item" key={`floor-${rowIndex}-${optionIndex}`}>
                                                <input
                                                    className="floor-list__input"
                                                    type="radio"
                                                    id={`floor-${rowIndex}-${optionIndex}`}
                                                    name="floor-section"
                                                    checked={floorStyle === displayStyleSettings}
                                                    onChange={() => {
                                                        if (!(floorStyle === displayStyleSettings)) {
                                                            this.handleChangeFloorStyle(floorStyle)
                                                        }
                                                    }}
                                                />
                                                <label className="floor-list__label"
                                                       htmlFor={`floor-${rowIndex}-${optionIndex}`}>
                                                      <span className="floor-list__img-wrap">
                                                          {floorStyle.textureImg
                                                              ? <img className="floor-list__img"
                                                                     src={floorStyle.textureImg}
                                                                     alt={floorStyle.name}/>
                                                              :
                                                              <div className="floor-list__img floor-list__img--empty"/>
                                                          }
                                                      </span>
                                                    <span className="floor-list__text">{floorStyle.name}</span>
                                                </label>
                                            </div>
                                        ))
                                        }
                                        {displayStyleSettings && (
                                            <FloorStyleSettings {...{displayStyleSettings, selectedStyle}}/>
                                        )}
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    {setFloorStyle, fetchFloorStyles}
)(FloorList);
