import {AnyAction} from "@reduxjs/toolkit";
import {MarketplaceContent} from "../models/marketplaceContent";
import {FETCH_HEADER_MENU_SUCCESS} from "../actions/types/marketplaceContent";

const initialState = {
    headerMenu: [],
    categoriesData: []
} as MarketplaceContent

export default function marketplaceContentReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case FETCH_HEADER_MENU_SUCCESS: {
            return ({
                ...state,
                headerMenu: action.menuItems,
                categoriesData: action.categoriesData,
            })
        }
        default:
            return state;
    }
}
